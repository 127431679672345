export const fieldKeysNames = {
    opponent_name: 'Opponent Name',
    home_name: 'Home Name',
    sport_name: 'Sport Name',
    gameDate: 'Date',
    longDate: 'Long Date',
    time_string: 'Game Time',
    gameWeekday: 'Weekday of Game',
    cancellation_terms: 'Cancellation Terms',
    comp_level: 'Competition Level',
    comp_tickets: 'Complimentary Tickets',
    cons_tickets: 'Consignment Tickets',
    governing_rules: 'Governing Rules',
    guarantee: 'Guarantee',
    head_coach: 'Head Coach',
    head_coach_email: 'Head Coach Email',
    head_coach_phone: 'Head Coach Phone',
    location: 'Location',
    officials: 'Officials',
    other_conditions: 'Other Conditions',
    radio_rights: 'Radio Rights',
    season: 'Season',
    tv_rights: 'TV Rights',
    broadcast_rights: 'Broadcast Rights',
    force_majeure: 'Force Majeure',
    sent_date: 'Sent Date',
};
