/* eslint-disable react/destructuring-assignment */
import React, { Component, useContext, useState } from 'react';
import {
    Button,
    Icon,
    Image,
    Container,
    Modal,
    Popup,
    Loader,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';
import { Query, Mutation } from 'react-apollo';
import { useQuery, useMutation } from 'react-apollo-hooks';

import {
    Approver,
    AwayDifferentSigner,
    AwaySigner,
    ContactSender,
    HomeSigner,
    RequestCopy,
} from './modalButtons';
import {
    userQuery,
    userOrgContactListQuery,
    userRemoveMutation,
} from '../../gql/user';
import {
    contestContactsQuery,
    contestsSigned,
    contestCancelledInfoQuery,
} from '../../gql/contest';
import { statuses, statusIcons } from '../../utils/statuses';
import { contactUsMutation } from '../../gql/contact';
import { orgsQuery, orgUpdateMutation } from '../../gql/org';
import { isUploaded } from '../../utils/s3';
import PRlogo from '../../images/PRlogo.svg';
import { ContractView } from './contractView';
import {
    sportsDefaultQuery,
    sportsDefaultUpdateMutation,
} from '../../gql/defaults';
import { colors } from '../../utils/colors';
import { AppContext } from '../context/AppContext';
import { ClientMap } from '../landing/howItWorks';

let getUserType = (props) => {
    let { urlQuery, status } = props;
    let type;
    let identity = '';
    let internally_approved = false;
    if (urlQuery.home_signer || urlQuery['?home_signer']) {
        type = 'home_signer';
    } else if (urlQuery.away_signer || urlQuery['?away_signer']) {
        type = `away_signer`;
    } else if (
        urlQuery.away_different_signer ||
        urlQuery['?away_different_signer']
    ) {
        type = `away_different_signer`;
    } else if (urlQuery.home_approval || urlQuery['?home_approval']) {
        type = `home_approver`;
        identity = urlQuery.home_approval || urlQuery['?home_approval'];
        identity = identity.trim();
    } else if (urlQuery.away_approval || urlQuery['?away_approval']) {
        type = `away_approver`;
        identity = urlQuery.away_approval || urlQuery['?away_approval'];
    } else if (urlQuery.admin || urlQuery['?admin']) {
        type = `home_admin`;
    } else {
        type = `read_only`;
    }

    if (urlQuery.internally_approved || urlQuery['?internally_approved']) {
        type =
            status === statuses.SUBMITTED_INTERNAL_AWAY_COMPLETE
                ? 'away_signer'
                : 'away_different_signer';
        internally_approved = true;
    }
    return { type, identity: identity.replace(' ', '+'), internally_approved };
};

let DownloadCopy = (props) => {
    let { guid } = props;
    return (
        <a href={`https://s3-us-west-2.amazonaws.com/contest-pdfs/${guid}.png`}>
            <Button primary fluid>
                Download Copy
            </Button>
        </a>
    );
};

let Header = (props) => (
    <div
        style={{
            margin: '15px 0',
        }}
    >
        <h1>{props.children}</h1>
    </div>
);

let Subheader = (props) => {
    return (
        <div
            style={{
                margin: '0 0 10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...props.style,
            }}
        >
            <div
                style={{
                    width: '60%',
                }}
            >
                <p>{props.text}</p>
            </div>
        </div>
    );
};

let pageContent = {
    away_signer: {
        header: `Sign Contract`,
        subheader: ``,
        helper: () =>
            `Thank you for using Perfect Record! Please review the below contract for accuracy, then sign using the blue ‘Select Internal Approvers / Sign Contract’ button.`,
        signedSubheader: `Thank you for signing! A copy of this signed contract 
        has been emailed to you — you will receive it in your inbox shortly. 
        You may close this window at any time.`,
        modalButtons: [AwaySigner, RequestCopy, ContactSender],
        topButtons: [AwaySigner],
        userEmail: `contract_recipient`,
        canViewButtons: (status) =>
            [
                statuses.SUBMITTED,
                statuses.SUBMITTED_NOTCLICKED,
                statuses.SUBMITTED_AWAY_DIFFERENT_SIGNER,
                statuses.SUBMITTED_INTERNAL_AWAY,
                statuses.SUBMITTED_INTERNAL_AWAY_COMPLETE,
            ].includes(status),
    },
    away_different_signer: {
        header: `Sign Contract`,
        subheader: ``,
        helper: (name) =>
            `Thank you for using Perfect Record! ${name} has requested your signature on this contract.  Please review the below contract for accuracy, then sign using the blue ‘Sign Contract’ button.`,
        signedSubheader: `Thank you for signing! A copy of this signed contract 
        has been emailed to you — you will receive it in your inbox shortly. 
        You may close this window at any time.`,
        modalButtons: [AwayDifferentSigner, ContactSender],
        topButtons: [AwayDifferentSigner],
        userEmail: `away_different_signer_email`,
        canViewButtons: (status) =>
            [statuses.SUBMITTED_AWAY_DIFFERENT_SIGNER].includes(status),
    },
    away_approver: {
        header: `Approve Contract`,
        subheader: `Your institution has selected you as an internal approver for 
        this contract. Please review the contract below for accuracy, then approve 
        using the button below the contract. Your signature will appear on the 
        contract beneath the signature block for your institution.`,
        signedSubheader: `Thank you for approving! This contract will now be sent 
        automatically to the final signatory at your school for signature.  
        You’re all done — you may close this window at any time.`,
        modalButtons: [Approver],
        topButtons: [Approver],
        canViewButtons: (status, contest, identity) => {
            let approver = JSON.parse(contest.away_internal_approvers).find(
                (approver) =>
                    approver.approver_email &&
                    approver.approver_email.trim() === identity
            );
            return (
                approver &&
                [statuses.SUBMITTED_INTERNAL_AWAY].includes(status) &&
                !approver.approver_signature
            );
        },
    },
    home_signer: {
        header: `Sign Contract`,
        subheader: `You have been selected as the primary signatory for your institution 
        for this contract. Please review the contract below for accuracy, then sign 
        using the button below the contract. Your signature will appear in the 
        signature block for your institution.`,
        signedSubheader: `Thank you for signing! You will receive a copy of the fully-executed 
        contract automatically by email after the opposing school signs. You may close this 
        window at any time.`,
        modalButtons: [HomeSigner],
        topButtons: [HomeSigner],
        userEmail: `signor_different_user_email`,
        canViewButtons: (status) =>
            [statuses.SUBMITTED_DIFFERENT_SIGNER].includes(status),
    },
    home_approver: {
        header: `Approve Contract`,
        subheader: `Your institution has selected you as an internal approver for 
        this contract. Please review the contract below for accuracy, then approve 
        using the button below the contract. Your signature will appear on the 
        contract beneath the signature block for your institution.`,
        signedSubheader: `Thank you for approving! You may close this window at any time.`,
        modalButtons: [Approver],
        topButtons: [Approver],
        canViewButtons: (status, contest, identity) => {
            let approver =
                contest.home_internal_approvers &&
                JSON.parse(contest.home_internal_approvers).find(
                    (approver) =>
                        approver.approver_email &&
                        approver.approver_email.trim() === identity
                );
            return (
                approver &&
                [statuses.SUBMITTED_INTERNAL_HOME].includes(status) &&
                !approver.approver_signature
            );
        },
    },
    home_admin: {
        header: ``,
        subheader: ``,
        modalButtons: [],
        canViewButtons: () => false,
    },
    read_only: {
        header: ``,
        subheader: ``,
        modalButtons: [RequestCopy],
        canViewButtons: () => true,
    },
};

let UserInfoWrapper = (props) => {
    if (props.userEmail) {
        return (
            <Query
                query={contestContactsQuery}
                variables={{
                    guid: props.guid,
                }}
            >
                {({ data, loading }) => {
                    if (loading) {
                        return null;
                    }
                    return (
                        <Query
                            query={userQuery}
                            variables={{
                                email: data.contest[props.userEmail],
                            }}
                        >
                            {({ data, loading }) => {
                                if (loading) {
                                    return null;
                                }
                                if (data.user) {
                                    return props.render(data.user);
                                }
                                return props.render();
                            }}
                        </Query>
                    );
                }}
            </Query>
        );
    }
    return props.render();
};

let AwaySchoolUserRow = (props) => {
    let [deletePopupVisible, setDeletePopupVisible] = useState(false);
    let { user = {}, is_primary, refetch, awayOrg } = props;
    let { firstname, lastname, email } = user;
    let displayName = '';
    if (firstname) {
        displayName += `${firstname} `;
    }
    if (lastname) {
        displayName += `${lastname} `;
    }
    if (email) {
        displayName += displayName.length > 0 ? `(${email})` : email;
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '5px',
            }}
        >
            <span
                style={{
                    flex: 2,
                    display: 'flex',
                }}
            >
                {displayName}
            </span>
            <Mutation mutation={orgUpdateMutation}>
                {(orgUpdate) => (
                    <div
                        style={{
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                    >
                        <Button
                            basic={!is_primary}
                            disabled={is_primary}
                            primary
                            onClick={() => {
                                if (!is_primary) {
                                    orgUpdate({
                                        variables: {
                                            id: awayOrg.id,
                                            primary_contact: user.id,
                                        },
                                    }).then(() => {
                                        ReactGA.event({
                                            category: 'Away School Management',
                                            action: 'Make Primary',
                                            label: `${awayOrg.name} made ${user.id} primary`,
                                        });
                                        refetch();
                                    });
                                }
                            }}
                        >
                            <span>
                                {is_primary && (
                                    <Icon
                                        name="check circle outline"
                                        color="white"
                                    />
                                )}
                                {is_primary
                                    ? `Primary Contact`
                                    : `Make Primary Contact`}
                            </span>
                        </Button>
                    </div>
                )}
            </Mutation>
            <Mutation mutation={userRemoveMutation}>
                {(userRemove) => (
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Popup
                            position="top center"
                            on="click"
                            open={deletePopupVisible}
                            onClose={() => setDeletePopupVisible(false)}
                            trigger={
                                <Icon
                                    name="remove circle"
                                    size="large"
                                    color="red"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setDeletePopupVisible(true);
                                    }}
                                />
                            }
                            content={
                                <div>
                                    <div style={{ marginBottom: '10px' }}>
                                        Are you sure you want to remove this
                                        user?
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Button
                                            primary
                                            fluid
                                            onClick={() => {
                                                userRemove({
                                                    variables: {
                                                        id: user.id,
                                                    },
                                                }).then(() => {
                                                    ReactGA.event({
                                                        category:
                                                            'Away School Management',
                                                        action: 'Remove User',
                                                        label: `${
                                                            awayOrg.name
                                                        } removed ${
                                                            user.email &&
                                                            user.email.split(
                                                                '@'
                                                            )[0]
                                                        }`,
                                                    });
                                                    setDeletePopupVisible(
                                                        false
                                                    );
                                                    refetch();
                                                });
                                            }}
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            fluid
                                            onClick={() =>
                                                setDeletePopupVisible(false)
                                            }
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                )}
            </Mutation>
        </div>
    );
};

let AwaySchoolUserManagement = (props) => {
    let { awayOrg } = props;
    return (
        <Query
            query={userOrgContactListQuery}
            variables={{
                away_orgid: awayOrg.id,
            }}
        >
            {({ data, loading, error, refetch }) => {
                if (loading || error) {
                    return null;
                }
                let { awayUserOrgContactList = [], awayPrimaryContact = {} } =
                    data;
                if (awayUserOrgContactList.length === 0) {
                    return null;
                }
                return (
                    <div>
                        <div
                            style={{
                                fontSize: '18px',
                                fontWeight: 'bold',
                                marginTop: '15px',
                                textAlign: 'center',
                            }}
                        >
                            {`Help us ensure that ${awayOrg.name} contracts go to the right person!`}
                        </div>
                        <div
                            style={{
                                marginTop: '15px',
                            }}
                        >
                            {`Below is a list of contract recipients we have identified from your institution, ${awayOrg.name}. Please remove any people 
                            from this list who should not be there. You can also designate your primary contract recipient. We try our best to make the game 
                            contract management process as easy as possible—thank you for your help!`}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: '10px',
                                fontWeight: 'bold',
                            }}
                        >
                            <div style={{ flex: 2 }}>Name</div>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                Primary Contact
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                Remove?
                            </div>
                        </div>
                        {awayUserOrgContactList.map((user, index) => {
                            return (
                                <AwaySchoolUserRow
                                    key={index}
                                    awayOrg={awayOrg}
                                    user={user}
                                    refetch={refetch}
                                    is_primary={
                                        user.id === awayPrimaryContact.id
                                    }
                                />
                            );
                        })}
                    </div>
                );
            }}
        </Query>
    );
};

const OrgsUsingPR = () => {
    const { data: orgsData, loading: orgsLoading } = useQuery(orgsQuery, {
        variables: {
            clients: true,
        },
    });
    if (orgsLoading) {
        return <Loader active />;
    }
    let orgs = orgsData.orgs;
    let orgsSorted = orgs.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    let groupedOrgs = {};
    orgsSorted.forEach((org) => {
        if (!org.hide_client) {
            if (groupedOrgs[org.conference]) {
                groupedOrgs[org.conference].push(org.name);
            } else {
                groupedOrgs[org.conference] = [org.name];
            }
        }
    });
    let conferencesSorted = Object.keys(groupedOrgs).sort();
    return (
        <>
            <div
                style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '18px',
                }}
            >
                Institutions using Perfect Record Software
            </div>
            <div
                style={{
                    padding: '16px',
                }}
            >
                <ClientMap />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                    lineHeight: '1',
                }}
            >
                {conferencesSorted.map((conference, conferenceIndex) => (
                    <div
                        key={conferenceIndex}
                        style={{
                            marginTop: '15px',
                            marginLeft: '5px',
                            flex: '1 1 250px',
                        }}
                    >
                        <div
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {conference}
                        </div>
                        <div
                            style={{
                                marginLeft: '5px',
                            }}
                        >
                            {groupedOrgs[conference].map((name, nameIndex) => (
                                <div key={nameIndex}>{name}</div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

const MarketingModal = ({
    open,
    handleClose,
    handleRequestDemo,
    info,
    awayOrg,
}) => {
    const { setShowContactUsModal } = useContext(AppContext);
    const { data, loading } = useQuery(contestsSigned);

    return (
        <Modal
            {...{
                open,
                onClose: handleClose,
            }}
        >
            <Modal.Header>
                <span
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '20%',
                        }}
                    >
                        <Image src={PRlogo} height="45px" />
                    </div>
                    <div>
                        <Icon size="large" {...statusIcons.SIGNED} />
                        Thank you for signing!
                    </div>
                    <div
                        style={{
                            width: '20%',
                            textAlign: 'right',
                        }}
                    />
                </span>
            </Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        width: '100%',
                        fontSize: '1.2em',
                        padding: '20px 30px 0 30px',
                    }}
                >
                    {`Thank you for using Perfect Record! With over ${
                        loading ? (
                            <Loader active inline />
                        ) : (
                            Number(
                                Math.floor(data.contestsSigned / 100) * 100
                            ).toLocaleString()
                        )
                    } game contracts created, exchanged and 
                    signed through our platform, our mission is to help you modernize and streamline your game contract workflow. To request a 
                    free demo, please click here:`}
                </div>
                <div
                    style={{
                        textAlign: 'center',
                        paddingTop: '24px',
                    }}
                >
                    <Button
                        primary
                        size="big"
                        onClick={() => {
                            setShowContactUsModal(true);
                        }}
                    >
                        Interested? Start a free trial!
                    </Button>
                </div>
                <hr style={{ marginTop: '20px' }} />
                <div style={{ marginTop: '15px' }}>
                    <OrgsUsingPR />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <Button
                            primary
                            onClick={() => {
                                setShowContactUsModal(true);
                            }}
                        >
                            Interested? Start a free trial!
                        </Button>
                    </div>
                    <div>
                        <Button onClick={handleClose}>Close</Button>
                        <Mutation mutation={contactUsMutation}>
                            {(contactUs) => {
                                return (
                                    <Button
                                        primary
                                        onClick={() => {
                                            contactUs({
                                                variables: {
                                                    demo: true,
                                                    ...info,
                                                    school: awayOrg.name || '',
                                                },
                                            }).then(() => {
                                                handleRequestDemo();
                                            });
                                        }}
                                    >
                                        Request Demo
                                    </Button>
                                );
                            }}
                        </Mutation>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

const SuccessModal = (props) => {
    const { setShowContactUsModal } = useContext(AppContext);
    let { open, handleClose = () => {}, awayOrg = {} } = props;

    return (
        <Modal size="large" open={open}>
            <Modal.Header>
                <span
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '20%',
                        }}
                    >
                        <Image src={PRlogo} height="45px" />
                    </div>
                    <div>
                        <Icon size="large" {...statusIcons.SIGNED} />
                        Thank you for signing!
                    </div>
                    <div
                        style={{
                            width: '20%',
                            textAlign: 'right',
                        }}
                    />
                </span>
            </Modal.Header>
            <Modal.Content>
                <AwaySchoolUserManagement awayOrg={awayOrg} />
            </Modal.Content>
            <Modal.Actions>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <Button
                            primary
                            onClick={() => {
                                setShowContactUsModal(true);
                            }}
                        >
                            Interested? Start a free trial!
                        </Button>
                    </div>
                    <div>
                        <Button onClick={handleClose}>Close</Button>
                    </div>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

const userString = (user) =>
    user.firstname && user.lastname
        ? `${user.firstname} ${user.lastname} - ${user.title} (${user.email})`
        : `${user.email} - ${user.title}`;

const CancelledInfo = ({ guid }) => {
    const { data, loading, error } = useQuery(contestCancelledInfoQuery, {
        variables: { guid },
    });
    if (loading || error) return null;
    const { contestCancelledInfo = {} } = data;
    const { cancel_user, date, message, notified } = contestCancelledInfo;
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <div style={{ textAlign: 'left' }}>
                <div
                    style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                >
                    Cancellation notice details:
                </div>
                {notified && (
                    <div>
                        <span style={{ fontWeight: 'bold' }}>
                            Notice sent to:&nbsp;
                        </span>
                        {`${notified}`}
                    </div>
                )}
                {cancel_user && (
                    <div>
                        <span style={{ fontWeight: 'bold' }}>By:&nbsp;</span>
                        {`${userString(cancel_user)}`}
                    </div>
                )}
                {date && (
                    <div>
                        <span style={{ fontWeight: 'bold' }}>Date:&nbsp;</span>
                        {`${new Date(date).toLocaleString()}`}
                    </div>
                )}
                {message && (
                    <div>
                        <span style={{ fontWeight: 'bold' }}>
                            Message:&nbsp;
                        </span>
                        {`${message}`}
                    </div>
                )}
            </div>
        </div>
    );
};

class Sign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approved: false,
            signed: false,
            successModalOpen: false,
            marketingModalOpen: false,
            uploaded: false,
        };
    }

    componentDidMount() {
        let { contestUpdate, urlQuery, guid, signed, status } = this.props;
        if (
            urlQuery.email_link &&
            status === statuses.SUBMITTED_NOTCLICKED &&
            !signed
        ) {
            let { type, identity } = getUserType(this.props);
            contestUpdate({
                variables: {
                    guid,
                    email_link: true,
                    status: statuses.SUBMITTED,
                    updateMeta: JSON.stringify({
                        user_type: type,
                        identity,
                        action: 'email_link',
                    }),
                },
            });
        }
    }

    checkIsUploaded = (guid) => {
        isUploaded(guid).then((uploaded) => {
            if (uploaded) {
                this.setState({ uploaded });
            } else {
                setTimeout(() => this.checkIsUploaded(guid), 200);
            }
        });
    };

    checkIsApproved = ({ type, identity, contest }) => {
        if (type === 'home_approver') {
            let approver = JSON.parse(contest.home_internal_approvers).find(
                (approver) => approver.approver_email === identity
            );
            return approver && approver.approver_signature;
        }
        if (type === 'away_approver') {
            let approver = JSON.parse(contest.away_internal_approvers).find(
                (approver) => approver.approver_email === identity
            );
            return approver && approver.approver_signature;
        }
        return false;
    };

    notify = (success, signed = false, info = {}) => {
        // eslint-disable-next-line react/destructuring-assignment
        let guid = this.props.urlQuery.guid || this.props.urlQuery[`?guid`];
        let { awayOrg } = this.props;
        if (success) {
            if (signed) {
                if (awayOrg.is_client || info.isHome) {
                    this.setState({
                        signed,
                    });
                    let message = `Thank you for signing`;
                    toast.success(message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 4000,
                        hideProgressBar: true,
                    });
                } else {
                    this.setState({
                        successModalOpen: true,
                        info,
                        signed,
                    });
                }
                if (!info.isHome) {
                    this.checkIsUploaded(guid);
                }
            } else {
                this.setState({
                    signed,
                });
                let message = `Your contest has been submitted`;
                toast.success(message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    hideProgressBar: true,
                });
            }
        }
    };

    handleRequestDemo = () => {
        this.setState({
            successModalOpen: false,
        });
        let message = `Thank you! We will be in touch to follow up!`;
        toast.success(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 4000,
            hideProgressBar: true,
        });
    };

    render() {
        let {
            status,
            contest,
            sportsDefault,
            updateAwayDefaults,
            awayOrg,
            signed,
        } = this.props;
        let { type, identity, internally_approved } = getUserType(this.props);
        let { uploaded, approved, signed: stateSigned } = this.state;
        // eslint-disable-next-line react/destructuring-assignment
        let guid = this.props.urlQuery.guid || this.props.urlQuery[`?guid`];
        let content = pageContent[type];
        let headerText =
            status === statuses.CANCELLED
                ? 'Contest Cancelled'
                : content.header;
        let subheaderText =
            status === statuses.CANCELLED
                ? 'This contest has been cancelled. For questions, please contact the home school using the “Contact Sender” button below.'
                : content.subheader;
        let helper =
            content.helper && !signed
                ? content.helper(
                      contest.contract_recipient_name ||
                          contest.contract_recipient
                  )
                : undefined;
        let headerIcon;

        if (approved || this.checkIsApproved({ contest, type, identity })) {
            headerText = 'Contract Approved';
            headerIcon = <Icon size="large" {...statusIcons.SIGNED} />;
            subheaderText =
                content.signedSubheader ||
                'You may close this window at any time';
            helper = null;
        } else if (stateSigned || signed) {
            headerText = 'Contract Signed';
            headerIcon = <Icon size="large" {...statusIcons.SIGNED} />;
            subheaderText =
                content.signedSubheader ||
                'You may close this window at any time';
            helper = null;
        }
        let canViewButtons = content.canViewButtons(status, contest, identity);
        return (
            <Container>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        marginBottom: '30px',
                    }}
                >
                    <Header>
                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {headerIcon}
                            {headerText}
                        </span>
                    </Header>
                    <Subheader
                        text={subheaderText}
                        style={{
                            color:
                                status === statuses.CANCELLED
                                    ? colors.danger
                                    : colors.black,
                            fontSize:
                                status === statuses.CANCELLED ? '18px' : '14px',
                        }}
                    />
                    {helper && status !== statuses.CANCELLED && (
                        <Subheader text={helper} />
                    )}
                    {status === statuses.CANCELLED && (
                        <CancelledInfo guid={guid} />
                    )}
                    <div
                        style={{
                            marginBottom: '15px',
                        }}
                    >
                        {(status === 'SIGNED' || uploaded) && (
                            <div
                                style={{
                                    marginBottom: '5px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        width: '40%',
                                    }}
                                >
                                    <DownloadCopy
                                        contest={contest}
                                        guid={guid}
                                    />
                                </div>
                            </div>
                        )}
                        {canViewButtons &&
                            !approved &&
                            !stateSigned &&
                            content.topButtons &&
                            content.topButtons.map((ModalComponent, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            marginTop: `${
                                                index === 0 ? 20 : 5
                                            }px`,
                                            marginBottom: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '40%',
                                            }}
                                        >
                                            <UserInfoWrapper
                                                userEmail={content.userEmail}
                                                guid={guid}
                                                render={(userInfo = {}) => {
                                                    return (
                                                        <ModalComponent
                                                            key={index}
                                                            type={type}
                                                            guid={guid}
                                                            awaySportsDefault={
                                                                sportsDefault
                                                            }
                                                            updateAwayDefaults={
                                                                updateAwayDefaults
                                                            }
                                                            contest={
                                                                this.props
                                                                    .contest
                                                            }
                                                            contractRefetch={
                                                                this.props
                                                                    .contractRefetch
                                                            }
                                                            contestStatusRefetch={
                                                                this.props
                                                                    .contestStatusRefetch
                                                            }
                                                            identity={identity}
                                                            internally_approved={
                                                                internally_approved
                                                            }
                                                            userInfo={userInfo}
                                                            handleApproved={(
                                                                approved
                                                            ) => {
                                                                this.setState({
                                                                    approved,
                                                                });
                                                            }}
                                                            handleSigned={(
                                                                success,
                                                                signed,
                                                                info
                                                            ) => {
                                                                this.notify(
                                                                    success,
                                                                    signed,
                                                                    info
                                                                );
                                                            }}
                                                        />
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        {status === statuses.CANCELLED && (
                            <div
                                style={{
                                    marginTop: `20px`,
                                    marginBottom: '5px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        width: '40%',
                                    }}
                                >
                                    <UserInfoWrapper
                                        userEmail={content.userEmail}
                                        guid={guid}
                                        render={(userInfo = {}) => {
                                            return (
                                                <ContactSender
                                                    type={type}
                                                    guid={guid}
                                                    awaySportsDefault={
                                                        sportsDefault
                                                    }
                                                    updateAwayDefaults={
                                                        updateAwayDefaults
                                                    }
                                                    contest={this.props.contest}
                                                    contractRefetch={
                                                        this.props
                                                            .contractRefetch
                                                    }
                                                    contestStatusRefetch={
                                                        this.props
                                                            .contestStatusRefetch
                                                    }
                                                    identity={identity}
                                                    internally_approved={
                                                        internally_approved
                                                    }
                                                    userInfo={userInfo}
                                                    handleApproved={(
                                                        approved
                                                    ) => {
                                                        this.setState({
                                                            approved,
                                                        });
                                                    }}
                                                    handleSigned={(
                                                        success,
                                                        signed,
                                                        info
                                                    ) => {
                                                        this.notify(
                                                            success,
                                                            signed,
                                                            info
                                                        );
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <ContractView
                            src={
                                this.props.signed && uploaded && this.props.guid
                            }
                            html={this.props.contract}
                        />
                        {/* {this.props.signed || uploaded ? (
                                <img src={`https://s3-us-west-2.amazonaws.com/contest-pdfs/${this.props.guid}.png`} style={{border: '1px solid grey', boxShadow: '5px 5px 8px #aaa'}}/>
                            ) : (
                                <div 
                                    dangerouslySetInnerHTML={{__html: this.props.contract}}
                                    style={{
                                        display: 'flex',
                                        border: '1px solid grey',
                                        boxShadow: '5px 5px 8px #aaa',
                                    }}>
                                </div>
                            )}
                        </ContractView> */}
                    </div>
                    {canViewButtons &&
                        !this.state.approved &&
                        !this.state.signed &&
                        content.modalButtons.map((ModalComponent, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        marginTop: `${index === 0 ? 20 : 5}px`,
                                        marginBottom: '5px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '40%',
                                        }}
                                    >
                                        <UserInfoWrapper
                                            userEmail={content.userEmail}
                                            guid={guid}
                                            render={(userInfo = {}) => {
                                                return (
                                                    <ModalComponent
                                                        key={index}
                                                        type={type}
                                                        guid={guid}
                                                        awaySportsDefault={
                                                            sportsDefault
                                                        }
                                                        updateAwayDefaults={
                                                            updateAwayDefaults
                                                        }
                                                        contest={
                                                            this.props.contest
                                                        }
                                                        contractRefetch={
                                                            this.props
                                                                .contractRefetch
                                                        }
                                                        contestStatusRefetch={
                                                            this.props
                                                                .contestStatusRefetch
                                                        }
                                                        identity={identity}
                                                        userInfo={userInfo}
                                                        handleApproved={(
                                                            approved
                                                        ) => {
                                                            this.setState({
                                                                approved,
                                                            });
                                                        }}
                                                        handleSigned={(
                                                            success,
                                                            signed,
                                                            info
                                                        ) => {
                                                            this.notify(
                                                                success,
                                                                signed,
                                                                info
                                                            );
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    {status === statuses.CANCELLED && (
                        <div
                            style={{
                                marginTop: `20px`,
                                marginBottom: '5px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    width: '40%',
                                }}
                            >
                                <UserInfoWrapper
                                    userEmail={content.userEmail}
                                    guid={guid}
                                    render={(userInfo = {}) => {
                                        return (
                                            <ContactSender
                                                type={type}
                                                guid={guid}
                                                awaySportsDefault={
                                                    sportsDefault
                                                }
                                                updateAwayDefaults={
                                                    updateAwayDefaults
                                                }
                                                contest={this.props.contest}
                                                contractRefetch={
                                                    this.props.contractRefetch
                                                }
                                                contestStatusRefetch={
                                                    this.props
                                                        .contestStatusRefetch
                                                }
                                                identity={identity}
                                                userInfo={userInfo}
                                                handleApproved={(approved) => {
                                                    this.setState({
                                                        approved,
                                                    });
                                                }}
                                                handleSigned={(
                                                    success,
                                                    signed,
                                                    info
                                                ) => {
                                                    this.notify(
                                                        success,
                                                        signed,
                                                        info
                                                    );
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <SuccessModal
                    awayOrg={awayOrg}
                    open={this.state.successModalOpen}
                    handleClose={() => {
                        this.setState({
                            successModalOpen: false,
                            marketingModalOpen: true,
                        });
                    }}
                />
                <MarketingModal
                    awayOrg={awayOrg}
                    info={this.state.info}
                    open={this.state.marketingModalOpen}
                    handleRequestDemo={() => this.handleRequestDemo()}
                    handleClose={() => {
                        this.setState({
                            marketingModalOpen: false,
                        });
                    }}
                />
            </Container>
        );
    }
}

const SportDefaultWrapper = (props) => {
    let { data, loading, error } = useQuery(sportsDefaultQuery, {
        variables: {
            orgid: props.contest.away_orgid,
        },
    });
    let updateDefaults = useMutation(sportsDefaultUpdateMutation);
    if (loading || error) {
        return null;
    }
    let { properties = '{}' } = data.sportsDefault || {};
    let defaults = JSON.parse(properties);
    return (
        <Sign
            {...props}
            sportsDefault={defaults}
            updateAwayDefaults={updateDefaults}
        />
    );
};

export default SportDefaultWrapper;
