import React, {useState} from 'react';
import Youtube from 'react-youtube';
import { Icon, Popup } from 'semantic-ui-react';

import {BaseControlledModal} from '../modals/modals';
import { colors } from '../../utils/colors';

export const InfoModalVideo = props => {
    const {
        size='large',
        videoId,
        header
    } = props;
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <div
            style={{
                fontSize: '14px'
            }}
        >
            <Popup 
                on='hover'
                position='top center'
                trigger={(
                    <Icon 
                        name='info circle'
                        size={size}
                        onClick={() => setModalOpen(true)}
                        style={{
                            cursor: 'pointer',
                            color: colors.grey
                        }}
                    />
                )}
                content={(
                    <div>
                        View tutorial video
                    </div>
                )}
            />
            
            <BaseControlledModal
                header={header}
                size='small'
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                closeIcon={false}
            >
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <Youtube 
                        videoId={videoId}
                        opts={{
                            playerVars: {
                                rel: 0
                            }
                        }}
                    />
                </div>
                
            </BaseControlledModal>
        </div>
    )
}