import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
    useRef,
} from 'react';
import { useQuery, useMutation } from 'react-apollo-hooks';
import {
    Button,
    Dropdown,
    Checkbox,
    Icon,
    Input,
    Popup,
    Header,
    Sticky,
    TextArea,
    Form,
} from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';
import { Slider } from '@material-ui/core';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import Select from 'react-select';

import 'react-accessible-accordion/dist/fancy-example.css';

import {
    templateQuery,
    templatePdfPreviewMutation,
    orgTemplateCreate,
    orgTemplateQuery,
} from '../../gql/template';
import { ContractView } from '../sign/contractView';
import s3 from '../../utils/s3';
import { fieldKeysNames } from '../../utils/contractFields';

import './template.css';
import { toast } from 'react-toastify';
import { BaseControlledModal, ModalAction } from '../modals/modals';
import Spinner from '../utilityComponents/spinner';
import useDebounce from '../../hooks/useDebounce';
import { colors } from '../../utils/colors';
import { InfoModalVideo } from '../utilityComponents/InfoModalVideo';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '130px',
};

const activeStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};

const handleDeleteImage = (image) => {
    return new Promise((resolve, reject) => {
        const fileParams = {
            Bucket: 'perfect-logos',
            Key: image.Key,
        };
        s3.deleteObject(fileParams, (err, data) => {
            if (err) reject(err);
            resolve(data);
        });
    });
};

let uploadFiles = (files, orgid) => {
    return new Promise((resolve, reject) => {
        let promises = [];
        files.forEach((file) => {
            const fileParams = {
                Bucket: 'perfect-logos',
                Body: file,
                Key: `${orgid}/${file.name}`,
            };
            promises.push(
                new Promise((res, rej) => {
                    s3.upload(fileParams, (err, data) => {
                        if (err) {
                            rej(err);
                        } else {
                            res(data);
                        }
                    });
                })
            );
        });
        Promise.all(promises).then((vals) => {
            resolve();
        });
    });
};

let getImages = (orgid) => {
    return new Promise((resolve, reject) => {
        s3.listObjects(
            {
                Bucket: 'perfect-logos',
                Prefix: `${orgid}/`,
            },
            (err, data) => {
                if (err) reject(err);
                else {
                    resolve(data.Contents);
                }
            }
        );
    });
};

function Dropzone(props) {
    let { orgid, refetchImages } = props;
    const [files, setFiles] = useState([]);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: ['image/png', 'image/jpg', 'image/jpeg'],
        onDrop: (acceptedFiles) => {
            uploadFiles(acceptedFiles, orgid).then(() => {
                refetchImages();
            });
            // setFiles(acceptedFiles.map(file => Object.assign(file, {
            //   preview: URL.createObjectURL(file)
            // })));
        },
        onDropRejected: () => {
            toast.warn(
                'We were not able to accept this image. Please use png, jpg, jpeg files.',
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    hideProgressBar: true,
                }
            );
        },
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject]
    );

    const thumbs = files.map((file) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img src={file.preview} style={img} />
            </div>
        </div>
    ));

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    return (
        <section className="container">
            <div {...getRootProps({ className: 'dropzone', style })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
                <div
                    style={{
                        marginTop: '4px',
                    }}
                >
                    <Icon name="plus circle" size="huge" />
                </div>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
            {/* <button
                onClick={() => {
                    uploadFiles(files, orgid)
                }}
            >
                Upload
            </button> */}
        </section>
    );
}

const gameValueOptions = Object.entries(fieldKeysNames)
    .map(([key, text], index) => {
        return {
            key,
            text,
            value: key,
        };
    })
    .sort((a, b) => a.text.localeCompare(b.text));

const ControlsPopup = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Popup
            open={isOpen}
            on="click"
            position="top center"
            onClose={() => setIsOpen(false)}
            trigger={
                <Button
                    icon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setIsOpen(true)}
                >
                    <Icon name="bold" />
                    <Icon name="align center" />
                </Button>
            }
            content={
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Icon
                            name="remove"
                            size="large"
                            style={{ cursor: 'pointer' }}
                            onClick={() => setIsOpen(false)}
                        />
                    </div>
                    <div
                    // style={{display: 'flex', justifyContent: 'center'}}
                    >
                        {children}
                    </div>
                </div>
            }
        />
    );
};

const getMaxHeaderImageWidth = (headerType) => {
    switch (headerType) {
        case 1:
            return 700;
        case 2:
            return 600;
        default:
            return 300;
    }
};

let ImageTextVarInput = ({
    alignmentHorizontal,
    alignmentVertical,
    alignmentVerticalButtonGroup,
    headerType,
    input,
    alignment,
    margin,
    imageOptions,
    handleChange,
    setInput,
    style = {},
}) => {
    let {
        width,
        type,
        content,
        alignItems,
        marginTop,
        justifyContent,
        fontSize,
        fontStyle,
        fontWeight,
        textDecoration,
        textTransform,
    } = input;

    return (
        <div
            style={{
                width: '100%',
                borderBottom: `1px solid ${colors.greyBorder}`,
                paddingBottom: '24px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    marginTop: '16px',
                    alignItems: 'flex-end',
                    textAlign: 'left',
                    ...style,
                }}
            >
                <div style={{ flex: 3 }}>
                    <label
                        style={{
                            fontWeight: 'bold',
                            fontSize: '10px',
                        }}
                    >
                        Content Type
                    </label>
                    <Dropdown
                        fluid
                        value={type}
                        selection
                        upward
                        options={[
                            {
                                text: 'Text',
                                value: 'string',
                            },
                            {
                                text: 'Image',
                                value: 'image',
                            },
                            {
                                text: 'Contest Field',
                                value: 'value',
                            },
                        ]}
                        onChange={(_, { value }) => {
                            handleChange({ type: value, content: '' });
                        }}
                    />
                </div>
                <div
                    style={{
                        flex: 1,
                        padding: '0 8px',
                    }}
                >
                    <label
                        style={{
                            fontWeight: 'bold',
                            fontSize: '10px',
                        }}
                    >
                        Size
                    </label>
                    {type == 'image' ? (
                        <Slider
                            defaultValue={width}
                            getAriaValueText={(val) => `${val}px`}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={10}
                            marks
                            min={10}
                            max={getMaxHeaderImageWidth(headerType)}
                            onChangeCommitted={(e, value) => {
                                handleChange({ width: value });
                            }}
                        />
                    ) : (
                        <Dropdown
                            fluid
                            selection
                            value={fontSize}
                            onChange={(_, { value }) => {
                                handleChange({ fontSize: value });
                            }}
                            className="icon"
                            options={[
                                { value: 10, text: 10 },
                                { value: 12, text: 12 },
                                { value: 14, text: 14 },
                                { value: 16, text: 16 },
                                { value: 18, text: 18 },
                                { value: 20, text: 20 },
                                { value: 22, text: 22 },
                                { value: 24, text: 24 },
                                { value: 26, text: 26 },
                                { value: 28, text: 28 },
                                { value: 30, text: 30 },
                                { value: 32, text: 32 },
                                { value: 48, text: 48 },
                            ]}
                        />
                    )}
                </div>
                <div style={{}}>
                    <ControlsPopup>
                        {alignment && (
                            <>
                                <Button.Group
                                    buttons={[
                                        {
                                            key: 'align left',
                                            icon: 'align left',
                                            primary:
                                                justifyContent === 'flex-start',
                                            onClick: () => {
                                                handleChange({
                                                    justifyContent:
                                                        'flex-start',
                                                });
                                            },
                                        },
                                        {
                                            key: 'align center',
                                            icon: 'align center',
                                            primary:
                                                justifyContent === 'center',
                                            onClick: () => {
                                                handleChange({
                                                    justifyContent: 'center',
                                                });
                                            },
                                        },
                                        {
                                            key: 'align right',
                                            icon: 'align right',
                                            primary:
                                                justifyContent === 'flex-end',
                                            onClick: () => {
                                                handleChange({
                                                    justifyContent: 'flex-end',
                                                });
                                            },
                                        },
                                    ]}
                                />
                                <Button.Group
                                    buttons={[
                                        {
                                            key: 'arrow up',
                                            icon: 'arrow up',
                                            primary:
                                                alignItems === 'flex-start',
                                            onClick: () => {
                                                handleChange({
                                                    alignItems: 'flex-start',
                                                });
                                            },
                                        },
                                        {
                                            key: 'minus',
                                            icon: 'minus',
                                            primary: alignItems === 'center',
                                            onClick: () => {
                                                handleChange({
                                                    alignItems: 'center',
                                                });
                                            },
                                        },
                                        {
                                            key: 'arrow down',
                                            icon: 'arrow down',
                                            primary: alignItems === 'flex-end',
                                            onClick: () => {
                                                handleChange({
                                                    alignItems: 'flex-end',
                                                });
                                            },
                                        },
                                    ]}
                                />
                            </>
                        )}
                        {alignmentHorizontal && (
                            <>
                                <Button.Group
                                    buttons={[
                                        {
                                            key: 'align left',
                                            icon: 'align left',
                                            primary:
                                                justifyContent === 'flex-start',
                                            onClick: () => {
                                                handleChange({
                                                    justifyContent:
                                                        'flex-start',
                                                });
                                            },
                                        },
                                        {
                                            key: 'align center',
                                            icon: 'align center',
                                            primary:
                                                justifyContent === 'center',
                                            onClick: () => {
                                                handleChange({
                                                    justifyContent: 'center',
                                                });
                                            },
                                        },
                                        {
                                            key: 'align right',
                                            icon: 'align right',
                                            primary:
                                                justifyContent === 'flex-end',
                                            onClick: () => {
                                                handleChange({
                                                    justifyContent: 'flex-end',
                                                });
                                            },
                                        },
                                    ]}
                                />
                            </>
                        )}
                        {alignmentVertical && (
                            <>
                                <Button.Group
                                    buttons={[
                                        {
                                            key: 'arrow up',
                                            icon: 'arrow up',
                                            primary:
                                                alignItems === 'flex-start',
                                            onClick: () => {
                                                handleChange({
                                                    alignItems: 'flex-start',
                                                });
                                            },
                                        },
                                        {
                                            key: 'minus',
                                            icon: 'minus',
                                            primary: alignItems === 'center',
                                            onClick: () => {
                                                handleChange({
                                                    alignItems: 'center',
                                                });
                                            },
                                        },
                                        {
                                            key: 'arrow down',
                                            icon: 'arrow down',
                                            primary: alignItems === 'flex-end',
                                            onClick: () => {
                                                handleChange({
                                                    alignItems: 'flex-end',
                                                });
                                            },
                                        },
                                    ]}
                                />
                            </>
                        )}

                        {alignmentVerticalButtonGroup}

                        {(type === 'string' || type === 'value') && (
                            <>
                                <Button.Group
                                    buttons={[
                                        {
                                            key: 'bold',
                                            icon: 'bold',
                                            primary: fontWeight === 'bold',
                                            onClick: () => {
                                                handleChange({
                                                    fontWeight:
                                                        fontWeight === 'bold'
                                                            ? 'normal'
                                                            : 'bold',
                                                });
                                            },
                                        },
                                        {
                                            key: 'underline',
                                            icon: 'underline',
                                            primary:
                                                textDecoration === 'underline',
                                            onClick: () => {
                                                handleChange({
                                                    textDecoration:
                                                        textDecoration ===
                                                        'underline'
                                                            ? 'normal'
                                                            : 'underline',
                                                });
                                            },
                                        },
                                        {
                                            key: 'italic',
                                            icon: 'italic',
                                            primary: fontStyle === 'italic',
                                            onClick: () => {
                                                handleChange({
                                                    fontStyle:
                                                        fontStyle === 'italic'
                                                            ? 'normal'
                                                            : 'italic',
                                                });
                                            },
                                        },
                                    ]}
                                />
                                <div>
                                    <Button
                                        {...{
                                            key: 'italic',
                                            content: 'CAPS',
                                            primary:
                                                textTransform === 'uppercase',
                                            onClick: () => {
                                                handleChange({
                                                    textTransform:
                                                        textTransform ===
                                                        'uppercase'
                                                            ? 'normal'
                                                            : 'uppercase',
                                                });
                                            },
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        {margin && (
                            <div
                                style={{
                                    marginTop: '8px',
                                }}
                            >
                                <label
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '10px',
                                    }}
                                >
                                    Add space above this row
                                </label>
                                <Dropdown
                                    selection
                                    value={marginTop}
                                    onChange={(_, { value }) => {
                                        handleChange({ marginTop: value });
                                    }}
                                    className="icon"
                                    options={[
                                        { value: 0, text: `0px` },
                                        { value: 8, text: `8px` },
                                        { value: 12, text: `12px` },
                                        { value: 16, text: `16px` },
                                        { value: 24, text: `24px` },
                                        { value: 32, text: `32px` },
                                        { value: 40, text: `40px` },
                                        { value: 48, text: `48px` },
                                    ]}
                                />
                            </div>
                        )}
                    </ControlsPopup>
                </div>
            </div>
            <div style={{ textAlign: 'left' }}>
                <label
                    style={{
                        fontWeight: 'bold',
                        fontSize: '10px',
                    }}
                >
                    Content
                </label>
                {type === 'image' ? (
                    <Dropdown
                        fluid
                        // selection
                        value={content}
                        onChange={(_, { value }) => {
                            handleChange({ content: value });
                        }}
                        className="icon semanticDropdown"
                        options={imageOptions}
                    />
                ) : type === 'value' ? (
                    <Dropdown
                        fluid
                        selection
                        value={content}
                        onChange={(_, { value }) => {
                            handleChange({ content: value });
                        }}
                        className="icon"
                        options={gameValueOptions}
                    />
                ) : (
                    <Form>
                        <TextArea
                            fluid="true"
                            type="text"
                            value={content}
                            placeholder="Type Content Here"
                            rows={1}
                            onChange={(e, { value }) => {
                                handleChange({ content: value });
                            }}
                        />
                    </Form>
                )}
            </div>
        </div>
    );
};

const columnLabels = {
    0: 'Top-Left Item',
    1: 'Center Item',
    2: 'Top-Right Item',
};

const toOrdinalSuffix = (num) => {
    const int = parseInt(num),
        digits = [int % 10, int % 100],
        ordinals = ['st', 'nd', 'rd', 'th'],
        oPattern = [1, 2, 3, 4],
        tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
        ? int + ordinals[digits[0] - 1]
        : int + ordinals[3];
};

let HeaderContent = ({
    alignItems,
    onAlignItemsChange,
    headerType,
    headerContent,
    helperText,
    onChange,
    imageOptions,
    onRemoveColumn,
}) => {
    let columns = [...headerContent];
    let handleChange = (i, j, update) => {
        let newVals = [...headerContent];
        newVals[i].content[j] = {
            ...newVals[i].content[j],
            ...update,
        };
        onChange(i, newVals[i]);
    };

    let handleAdd = (i) => {
        let newVals = [...headerContent];
        newVals[i].content.push({
            type: 'string',
            content: '',
            fontSize: 16,
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecoration: 'none',
        });
        onChange(i, newVals[i]);
    };

    let handleRemoveColumnRow = (i, j) => {
        let newVals = [...headerContent];
        newVals[i].content.splice(j, 1);
        onChange(i, newVals[i]);
    };

    let cols = [];
    let numColumns = headerType ? headerType : columns.length;
    for (let i = 0; i < numColumns; i++) {
        let { content } = columns[i];
        let inputs = [];
        for (let j = 0; j < content.length; j++) {
            inputs.push(
                <div
                    key={`${i}-${j}`}
                    style={{
                        marginTop: '24px',
                        textAlign: 'left',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <ImageTextVarInput
                        {...{
                            input: columns[i].content[j],
                            headerType,
                            alignmentHorizontal: true,
                            alignmentVerticalButtonGroup: (
                                <Button.Group
                                    buttons={[
                                        {
                                            key: 'arrow up',
                                            icon: 'arrow up',
                                            primary:
                                                alignItems === 'flex-start',
                                            onClick: () => {
                                                onAlignItemsChange(
                                                    'flex-start'
                                                );
                                            },
                                        },
                                        {
                                            key: 'minus',
                                            icon: 'minus',
                                            primary: alignItems === 'center',
                                            onClick: () => {
                                                onAlignItemsChange('center');
                                            },
                                        },
                                        {
                                            key: 'arrow down',
                                            icon: 'arrow down',
                                            primary: alignItems === 'flex-end',
                                            onClick: () => {
                                                onAlignItemsChange('flex-end');
                                            },
                                        },
                                    ]}
                                />
                            ),
                            imageOptions,
                            margin: true,
                            handleChange: (update) => {
                                handleChange(i, j, update);
                            },
                            style: {
                                marginTop: '4px',
                            },
                        }}
                    />
                    <Popup
                        content="Delete this row?"
                        trigger={
                            <Icon
                                name="remove circle"
                                size="large"
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    handleRemoveColumnRow(i, j);
                                }}
                            />
                        }
                    />
                </div>
            );
        }
        let label = (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <label
                    style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                    }}
                >
                    {headerType
                        ? headerType === 1
                            ? 'Single Item'
                            : columnLabels[i]
                        : `${toOrdinalSuffix(i + 1)} Item`}
                </label>
                {headerType ? null : (
                    <Popup
                        content="Delete this column?"
                        trigger={
                            <Icon
                                name="remove circle"
                                size="large"
                                style={{
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    onRemoveColumn(i);
                                }}
                            />
                        }
                    />
                )}
            </div>
        );
        cols.push(
            <div
                key={i}
                style={{
                    marginTop: '24px',
                    paddingBottom: '12px',
                    textAlign: 'left',
                    borderBottom: `6px solid ${colors.greyBorder}`,
                }}
            >
                {label}
                {inputs}
                <div style={{ marginTop: '24px' }}>
                    <Button onClick={() => handleAdd(i)}>{`Add Row`}</Button>
                </div>
            </div>
        );
    }
    return (
        <>
            {helperText && (
                <div style={{ textAlign: 'left', marginBottom: '16px' }}>
                    {helperText}
                </div>
            )}
            {cols}
        </>
    );
};

const BodyHeaderContent = ({
    bodyHeader,
    newDefault = {},
    imageOptions,
    onChange,
    addNewButton,
    disableAddRow,
}) => {
    const handleChange = (i, update) => {
        let newBodyHeader = [...bodyHeader];
        newBodyHeader[i] = {
            ...newBodyHeader[i],
            ...update,
        };
        onChange(newBodyHeader);
    };
    const handleAdd = () => {
        let newBodyHeader = [...bodyHeader];
        newBodyHeader.push({
            type: 'string',
            content: '',
            width: 24,
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'normal',
            fontStyle: 'none',
            textDecoration: 'none',
            fontSize: 24,
            ...newDefault,
        });
        onChange(newBodyHeader);
    };
    const handleRemove = (i) => {
        let newBodyHeader = [...bodyHeader];
        newBodyHeader.splice(i, 1);
        onChange(newBodyHeader);
    };
    let rows = bodyHeader.map((item, i) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
                key={i}
            >
                <ImageTextVarInput
                    key={i}
                    {...{
                        alignmentHorizontal: true,
                        margin: true,
                        imageOptions,
                        input: item,
                        handleChange: (update) => {
                            handleChange(i, update);
                        },
                    }}
                />
                <Popup
                    content="Delete this row?"
                    trigger={
                        <Icon
                            name="remove circle"
                            size="large"
                            style={{
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                handleRemove(i);
                            }}
                        />
                    }
                />
            </div>
        );
    });
    return (
        <div>
            {rows}
            {!disableAddRow && (
                <div style={{ marginTop: '24px' }}>
                    <Button onClick={handleAdd}>
                        {addNewButton || `Add Row`}
                    </Button>
                </div>
            )}
        </div>
    );
};

const GameTable = ({ onChange, fieldsFormatting }) => {
    let { fontWeight, fontStyle, textDecoration, textTransform } =
        fieldsFormatting;
    return (
        <div
            style={{
                margin: '10px 0',
                textAlign: 'left',
            }}
        >
            <div
                style={{
                    marginBottom: '16px',
                }}
            >
                Use the buttons below to alter the appearance of the field names
                in this section. The fields shown on this contract are just a
                sample -- there may be additional fields (for example,
                Cancellation Terms, Guarantee, Future Conditions, etc.) visible
                on any individual contract that you send out, depending on
                whether you elect to include information for that field on each
                individual contract.
            </div>
            <div>
                <label
                    style={{
                        fontWeight: 'bold',
                        fontSize: '10px',
                    }}
                >
                    Field Label Text
                </label>
            </div>
            <div
                style={{
                    marginBottom: '8px',
                }}
            >
                <Button.Group
                    buttons={[
                        {
                            key: 'caps',
                            children: 'Caps',
                            primary: textTransform === 'uppercase',
                            onClick: () => {
                                onChange({
                                    textTransform:
                                        textTransform === 'uppercase'
                                            ? 'none'
                                            : 'uppercase',
                                });
                            },
                        },
                    ]}
                />
            </div>
            <Button.Group
                buttons={[
                    {
                        key: 'bold',
                        icon: 'bold',
                        primary: fontWeight === 'bold',
                        onClick: () => {
                            onChange({
                                fontWeight:
                                    fontWeight === 'bold' ? 'normal' : 'bold',
                            });
                        },
                    },
                    {
                        key: 'underline',
                        icon: 'underline',
                        primary: textDecoration === 'underline',
                        onClick: () => {
                            onChange({
                                textDecoration:
                                    textDecoration === 'underline'
                                        ? 'normal'
                                        : 'underline',
                            });
                        },
                    },
                    {
                        key: 'italic',
                        icon: 'italic',
                        primary: fontStyle === 'italic',
                        onClick: () => {
                            onChange({
                                fontStyle:
                                    fontStyle === 'italic'
                                        ? 'normal'
                                        : 'italic',
                            });
                        },
                    },
                ]}
            />
        </div>
    );
};

const onMouseEnter = (id) => {
    let el = document.getElementById(id);
    if (el) {
        let classes = (el.className || '').split(' ');
        if (classes.includes('highlight')) {
        } else {
            classes.push('highlight');
        }

        if (el.offsetHeight === 0) {
            classes.push('addHeight');
        }

        el.className = classes.join(' ');
    }
};

const onMouseLeave = (id) => {
    let el = document.getElementById(id);
    if (el) {
        let classes = (el.className || '').split(' ');
        if (classes.includes('highlight')) {
            let indexOf = classes.findIndex(
                (className) => className === 'highlight'
            );
            classes.splice(indexOf, 1);
        }

        if (classes.includes('addHeight')) {
            let indexOf = classes.findIndex(
                (className) => className === 'addHeight'
            );
            classes.splice(indexOf, 1);
        }

        el.className = classes.join(' ');
    }
};

let getImageUrl = (key) =>
    `https://perfect-logos.s3-us-west-2.amazonaws.com/${key}`;

let OrgImages = ({ images, handleDeleteImage, refetchImages }) => {
    let [deleteImageConfirm, setDeleteImageConfirm] = useState(null);
    return (
        <div>
            <div
                style={{
                    textAlign: 'left',
                    marginBottom: '16px',
                    marginTop: '16px',
                }}
            >
                <label
                    style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                >
                    {`Uploaded Images (${images.length})`}
                </label>
            </div>
            {images.map((image) => (
                <div style={{ ...thumb, position: 'relative' }} key={image.Key}>
                    <div style={thumbInner}>
                        <img src={getImageUrl(image.Key)} style={img} />
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            top: '-8px',
                            right: '-12px',
                        }}
                    >
                        <Popup
                            position="top center"
                            on="click"
                            open={deleteImageConfirm === image.Key}
                            onClose={() => setDeleteImageConfirm(null)}
                            trigger={
                                <Icon
                                    name="remove circle"
                                    size="large"
                                    style={{
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setDeleteImageConfirm(image.Key);
                                    }}
                                />
                            }
                            content={
                                <div>
                                    <div style={{ marginBottom: '10px' }}>
                                        Are you sure you want to remove this
                                        image from your library?
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Button
                                            primary
                                            fluid
                                            onClick={() => {
                                                handleDeleteImage(image).then(
                                                    () => {
                                                        refetchImages();
                                                    }
                                                );
                                            }}
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            fluid
                                            onClick={() =>
                                                setDeleteImageConfirm(null)
                                            }
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};

const customSelectStyles = {
    singleValue: (provided, state) => {
        let { data } = state;
        return {
            ...provided,
            fontFamily: data && data.fontFamily,
        };
    },
    option: (provided, { data }) => {
        return {
            ...provided,
            fontFamily: data.fontFamily,
        };
    },
};

const fontOptions = [
    {
        label: 'Arial',
        value: 'Arial, sans-serif;',
        fontFamily: 'Arial, sans-serif;',
    },
    {
        label: 'Century Gothic',
        value: '"Century Gothic", CenturyGothic, AppleGothic, sans-serif;',
        fontFamily: '"Century Gothic", CenturyGothic, AppleGothic, sans-serif;',
    },
    {
        label: 'Rockwell',
        value: 'Rockwell, Arial, sans-serif;',
        fontFamily: 'Rockwell, Arial, sans-serif;',
    },
    {
        label: 'Times New Roman',
        value: '"Times New Roman", Times, serif;',
        fontFamily: '"Times New Roman", Times, serif;',
    },
    {
        label: 'Trebuchet',
        value: '"Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif',
        fontFamily:
            '"Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif',
    },
    {
        label: 'Verdana',
        value: 'Verdana, Geneva, sans-serif;',
        fontFamily: 'Verdana, Geneva, sans-serif;',
    },
];

const defaultOpts = {
    fieldsFormatting: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        textDecoration: 'none',
        textTransform: 'none',
    },
    headerBreak: false,
    headerAlignItems: 'center',
    headerContent: [
        {
            content: [
                {
                    type: 'image',
                    content:
                        'https://perfect-logos.s3-us-west-2.amazonaws.com/5011/PRhex.png',
                    width: 100,
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    textDecoration: 'none',
                    fontSize: 24,
                },
            ],
        },
        {
            content: [
                {
                    type: 'value',
                    content: 'home_name',
                    width: 24,
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    fontStyle: 'normal',
                    textDecoration: 'none',
                    fontSize: 30,
                },
            ],
        },
        {
            content: [
                {
                    type: 'image',
                    content:
                        'https://perfect-logos.s3-us-west-2.amazonaws.com/5011/PRhex.png',
                    width: 100,
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    textDecoration: 'none',
                    fontSize: 24,
                },
            ],
        },
    ],
    headerType: 2,
    bodyHeader: [
        {
            type: 'string',
            content: 'Athletic Contest Agreement',
            width: 24,
            marginTop: 12,
            fontWeight: 'normal',
            fontStyle: 'none',
            textDecoration: 'none',
            fontSize: 24,
            textTransform: 'uppercase',
            alignItems: 'center',
            justifyContent: 'center',
        },
        {
            type: 'string',
            content: 'between',
            width: 24,
            marginTop: 8,
            fontWeight: 'normal',
            fontStyle: 'none',
            textDecoration: 'none',
            fontSize: 16,
            alignItems: 'center',
            justifyContent: 'center',
        },
        {
            type: 'value',
            content: 'home_name',
            width: 24,
            marginTop: 8,
            fontWeight: 'bold',
            fontStyle: 'none',
            textDecoration: 'none',
            fontSize: 20,
            alignItems: 'center',
            justifyContent: 'center',
        },
        {
            type: 'string',
            content: 'and',
            width: 24,
            marginTop: 8,
            fontWeight: 'normal',
            fontStyle: 'none',
            textDecoration: 'none',
            fontSize: 16,
            alignItems: 'center',
            justifyContent: 'center',
        },
        {
            type: 'value',
            content: 'opponent_name',
            width: 24,
            marginTop: 8,
            fontWeight: 'bold',
            fontStyle: 'none',
            textDecoration: 'none',
            fontSize: 20,
            alignItems: 'center',
            justifyContent: 'center',
        },
    ],
    bodyFooter: [
        {
            type: 'string',
            content:
                'The undersigned agree that an athletic contest shall be held in accordance with the above conditions.',
            width: 24,
            marginTop: 12,
            fontWeight: 'normal',
            fontStyle: 'none',
            textDecoration: 'none',
            fontSize: 14,
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
    ],
    contentFooter: [
        {
            type: 'string',
            content:
                'Please electronically sign and return through PerfectRecordSoftware.com',
            width: 24,
            marginTop: 12,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecoration: 'none',
            fontSize: 14,
            alignItems: 'center',
            justifyContent: 'center',
        },
    ],
    footerAlignItems: 'center',
    footerImages: [
        {
            content: [
                {
                    type: 'image',
                    content:
                        'https://perfect-logos.s3-us-west-2.amazonaws.com/5011/PRlogo.png',
                    width: 140,
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            ],
            justifyContent: 'center',
            alignItems: 'center',
        },
    ],
    watermarkImage: '',
    hasWatermark: true,
    watermark: {
        width: 65,
        content:
            'https://perfect-logos.s3-us-west-2.amazonaws.com/5011/PRhex.png',
        opacity: 13,
        top: 25,
        left: 0,
    },
    signatureBlockOrgNames: true,
    signatureBlock: [
        {
            type: 'string',
            content: 'Approved By:',
            width: 24,
            fontWeight: 'normal',
            fontStyle: 'normal',
            textDecoration: 'none',
            fontSize: 16,
            alignItems: 'center',
            justifyContent: 'center',
        },
    ],
    hasSignatureLineText: true,
    signatureLineText: 'Athletic Representative',
    fontFamily: fontOptions[0].value,
};

const templateAccordionSection = {
    header: 'Header Row',
    bodyHeader: 'Body Custom Content',
    gameTableLabels: 'Contest Field Labels',
    bodyFooter: 'Body Custom Content 2',
    signatureBlock: 'Signature Block',
    contentFooter: 'Footer Content',
    footer: 'Bottom Footer Row',
};

const templateAccordionMap = {
    header: 'headerAccordion',
    bodyHeader: 'bodyHeaderAccordion',
    gameTableLabels: 'gameTableLabelsAccordion',
    bodyFooter: 'bodyFooterAccordion',
    signatureBlock: 'signatureBlockAccordion',
    contentFooter: 'contentFooterAccordion',
    footer: 'footerAccordion',
};

const getImageOptionText = (key, orgid) => {
    return key.replace(`${orgid}/`, '');
};

function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            console.log('Changed props:', changedProps);
        }
        prev.current = props;
    });
}

let Template = (props) => {
    let { user, data, error, loading, refetch } = props;
    let contractRef = useRef(null);
    let [images, setImages] = useState([]);
    let [preview, setPreview] = useState(null);
    let [loadingPreview, setLoadingPreview] = useState(false);
    let [confirmModalOpen, setConfirmModalOpen] = useState(false);
    let [opts, setOpts] = useState(defaultOpts);
    let {
        capitalizeFieldNames,
        headerBreak,
        headerType,
        headerContent,
        headerAlignItems,
        bodyHeader,
        bodyFooter,
        contentFooter,
        signatureBlock,
        watermarkImage,
        footerImages,
        footerAlignItems,
        hasWatermark,
        fieldsFormatting,
        watermark,
        hasSignatureLineText,
    } = opts;

    const [, cancel] = useDebounce(
        () => {
            refetch({
                orgid: user.orgid,
                ...opts,
            });
        },
        500,
        [JSON.stringify(opts)]
    );

    let {
        data: orgTemplateData,
        loading: orgTemplateLoading,
        error: orgTemplateError,
        refetch: refetchOrgTemplate,
    } = useQuery(orgTemplateQuery, {
        variables: {
            orgid: user.orgid,
        },
    });
    let pdfPreview = useMutation(templatePdfPreviewMutation, {
        fetchPolicy: 'no-cache',
    });
    let createOrgTemplate = useMutation(orgTemplateCreate, {
        fetchPolicy: 'no-cache',
    });
    const refetchImages = () => {
        getImages(user.orgid).then((images) => {
            setImages(images);
        });
    };
    useEffect(() => {
        refetchImages();
    }, []);

    let imageOptions = images.map((image, index) => {
        return {
            key: image.Key,
            text: getImageOptionText(image.Key, user.orgid),
            value: getImageUrl(image.Key),
            image: { avatar: true, src: getImageUrl(image.Key) },
        };
    });

    useEffect(() => {
        if (!orgTemplateLoading && orgTemplateData) {
            if (
                orgTemplateData.orgTemplate &&
                orgTemplateData.orgTemplate.contracts
            ) {
                let contractsString = orgTemplateData.orgTemplate.contracts;
                let contracts = JSON.parse(contractsString);
                setOpts({ ...opts, ...contracts.default });
            }
        }
    }, [orgTemplateData]);
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '15px 50px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Header as="h1">Template Builder</Header>

                    <InfoModalVideo
                        {...{
                            videoId: '42-7M8wn8Qk',
                            header: 'Create your contract template',
                        }}
                    />
                </div>
                <div
                    style={{
                        width: '50%',
                        textAlign: 'justify',
                    }}
                >
                    <div style={{ marginTop: '8px' }}>
                        Use this tool to design your institution’s contract
                        template. Make selections among the various options on
                        the right, and your choices will be reflected on the
                        template preview on the left.
                    </div>

                    <div style={{ marginTop: '8px' }}>
                        <strong>Please note</strong>: if your organization
                        already has a custom-built template, the template you
                        design with this tool will override your existing custom
                        template.
                    </div>

                    <div style={{ marginTop: '8px' }}>
                        When you are satisfied with the appearance of your
                        template, click the “Preview” button. You will then see
                        a sample of what a final contract will look like --
                        there will be some very minor aesthetic differences
                        between the sample you see here and the preview you saw
                        while building the template, so please review to be sure
                        that this final contract is satisfactory. If you are
                        satisfied, click the “Save and Finish” button at the
                        bottom of the window.
                    </div>
                </div>
            </div>
            <div
                ref={contractRef}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '15px 50px',
                    justifyContent: 'center',
                    marginBottom: '130px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingBottom: '120px',
                    }}
                    onClick={(e) => {
                        let id;
                        let current = e.target;
                        let count = 0;
                        while (!id && count < 10) {
                            if (current.id) {
                                id = current.id;
                            }
                            current = current.parentElement;
                            count++;
                        }
                        if (id && templateAccordionMap[id]) {
                            let accItem = document.getElementsByName(
                                templateAccordionMap[id]
                            )[0];
                            if (accItem) accItem.click();
                            toast.warn(
                                `Use the "${templateAccordionSection[id]}" section in the panel to the right to edit this section of the contract`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 4000,
                                    hideProgressBar: true,
                                }
                            );
                        } else {
                            toast.warn(
                                `Use the panel on the right to edit the contract`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 4000,
                                    hideProgressBar: true,
                                }
                            );
                        }
                    }}
                >
                    <div
                        style={{
                            width: '800px',
                        }}
                    >
                        {loading ? null : (
                            <ContractView
                                html={data.template.html}
                                scaled={0.85}
                            />
                        )}
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        paddingLeft: '48px',
                    }}
                >
                    <div>
                        <Button
                            disabled={loadingPreview}
                            onClick={() => {
                                setLoadingPreview(true);
                                pdfPreview({
                                    variables: {
                                        orgid: user.orgid,
                                        ...opts,
                                    },
                                }).then(({ data }) => {
                                    if (
                                        data &&
                                        data.templatePdfPreview.location
                                    ) {
                                        setPreview(
                                            data &&
                                                data.templatePdfPreview.location
                                        );
                                        setConfirmModalOpen(true);
                                        setLoadingPreview(false);
                                    }
                                });
                            }}
                        >
                            {loadingPreview ? <Spinner /> : `Preview`}
                        </Button>

                        <Accordion
                            allowZeroExpanded={false}
                            style={{
                                margin: '12px 0',
                                width: '500px',
                            }}
                            preExpanded={['images']}
                        >
                            <AccordionItem uuid="images">
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Images
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div
                                        style={{
                                            marginBottom: '16px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Drag images into the image uploader.
                                        Once uploaded your images will show
                                        below and will be available to use in
                                        the contract template. You can use them
                                        by selecting them in the other sections
                                        of this page (i.e., "Header Row",
                                        "Watermark", "Body Custom Content",
                                        etc.,)
                                    </div>
                                    <div>
                                        <Dropzone
                                            orgid={user.orgid}
                                            refetchImages={refetchImages}
                                        />
                                        <OrgImages
                                            {...{
                                                images,
                                                handleDeleteImage,
                                                refetchImages,
                                            }}
                                        />
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Font
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div style={{ textAlign: 'left' }}>
                                        <label
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '10px',
                                            }}
                                        >
                                            Font Family
                                        </label>
                                        <Select
                                            value={fontOptions.find(
                                                (font) =>
                                                    font.value ===
                                                    opts.fontFamily
                                            )}
                                            options={fontOptions}
                                            styles={customSelectStyles}
                                            onChange={({ value }) => {
                                                setOpts({
                                                    ...opts,
                                                    fontFamily: value,
                                                });
                                            }}
                                        />
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem
                                onMouseEnter={() => {
                                    onMouseEnter('header');
                                }}
                                onMouseLeave={() => {
                                    onMouseLeave('header');
                                }}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton
                                        name={templateAccordionMap.header}
                                    >
                                        {templateAccordionSection.header}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div
                                        style={{
                                            marginBottom: '16px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        First select how many columns you want
                                        in your header (1, 2, or 3). Note: if
                                        you select two columns, Column 1 will be
                                        in the upper-left corner, and Column 2
                                        will be centered on the page.
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                marginTop: '16px',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}
                                        >
                                            <Checkbox
                                                radio
                                                label="Single item"
                                                name="headerTypeRadio"
                                                value={1}
                                                checked={opts.headerType === 1}
                                                onChange={(e, { value }) => {
                                                    let headerContent = [
                                                        {
                                                            content: [
                                                                {
                                                                    ...defaultOpts
                                                                        .headerContent[0]
                                                                        .content[0],
                                                                    ...opts
                                                                        .headerContent[0]
                                                                        .content[0],
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                },
                                                            ],
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                        },
                                                        {
                                                            content: [],
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                        },
                                                        {
                                                            content: [],
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                        },
                                                    ];
                                                    setOpts({
                                                        ...opts,
                                                        headerContent,
                                                        headerType: value,
                                                    });
                                                }}
                                            />
                                            <img
                                                src={require('./images/singleItem.png')}
                                                width={150}
                                                style={{
                                                    borderTop:
                                                        '.5px solid black',
                                                    borderRight:
                                                        '.5px solid black',
                                                    borderLeft:
                                                        '.5px solid black',
                                                    padding: '3px',
                                                    height: '28px',
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                marginTop: '16px',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}
                                        >
                                            <Checkbox
                                                radio
                                                label="Two items - top-left and center"
                                                name="headerTypeRadio"
                                                value={2}
                                                checked={opts.headerType === 2}
                                                onChange={(e, { value }) => {
                                                    setOpts({
                                                        ...opts,
                                                        headerType: value,
                                                    });
                                                }}
                                            />
                                            <img
                                                src={require('./images/twoItem.png')}
                                                width={150}
                                                style={{
                                                    borderTop:
                                                        '.5px solid black',
                                                    borderRight:
                                                        '.5px solid black',
                                                    borderLeft:
                                                        '.5px solid black',
                                                    padding: '3px',
                                                    height: '28px',
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                marginTop: '16px',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                width: '100%',
                                            }}
                                        >
                                            <Checkbox
                                                radio
                                                label="Three items - left, center, and right"
                                                name="headerTypeRadio"
                                                value={3}
                                                checked={opts.headerType === 3}
                                                onChange={(e, { value }) => {
                                                    setOpts({
                                                        ...opts,
                                                        headerType: value,
                                                    });
                                                }}
                                            />
                                            <img
                                                src={require('./images/threeItem.png')}
                                                width={150}
                                                style={{
                                                    borderTop:
                                                        '.5px solid black',
                                                    borderRight:
                                                        '.5px solid black',
                                                    borderLeft:
                                                        '.5px solid black',
                                                    padding: '3px',
                                                    height: '28px',
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <HeaderContent
                                            {...{
                                                headerContent,
                                                headerType,
                                                imageOptions,
                                                alignItems: headerAlignItems,
                                            }}
                                            onAlignItemsChange={(value) => {
                                                setOpts({
                                                    ...opts,
                                                    headerAlignItems: value,
                                                });
                                            }}
                                            onChange={(index, value) => {
                                                let newHeaderContent = [
                                                    ...opts.headerContent,
                                                ];
                                                newHeaderContent[index] = value;
                                                setOpts({
                                                    ...opts,
                                                    headerContent:
                                                        newHeaderContent,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div style={{ marginTop: '16px' }}>
                                        <Checkbox
                                            label={`Header Break Line?`}
                                            checked={opts.headerBreak}
                                            onChange={(e, { checked }) => {
                                                setOpts({
                                                    ...opts,
                                                    headerBreak: checked,
                                                });
                                            }}
                                        />
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem
                                onMouseEnter={() => {
                                    onMouseEnter('watermark');
                                }}
                                onMouseLeave={() => {
                                    onMouseLeave('watermark');
                                }}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Watermark
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div>
                                        <div
                                            style={{
                                                textAlign: 'left',
                                                padding: '8px 0',
                                            }}
                                        >
                                            <Checkbox
                                                label={`Watermark?`}
                                                checked={opts.hasWatermark}
                                                onChange={(e, { checked }) => {
                                                    setOpts({
                                                        ...opts,
                                                        hasWatermark: checked,
                                                        watermark: {
                                                            content: '',
                                                            width: 75,
                                                            opacity: 20,
                                                            top: 25,
                                                            left: 0,
                                                        },
                                                    });
                                                }}
                                            />
                                        </div>
                                        {opts.hasWatermark ? (
                                            <div>
                                                <div
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    <div>
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '10px',
                                                            }}
                                                        >
                                                            Select Image
                                                        </label>
                                                    </div>
                                                    <Dropdown
                                                        selection
                                                        value={
                                                            watermark.content
                                                        }
                                                        onChange={(
                                                            _,
                                                            { value }
                                                        ) => {
                                                            setOpts({
                                                                ...opts,
                                                                watermark: {
                                                                    ...watermark,
                                                                    content:
                                                                        value,
                                                                },
                                                            });
                                                        }}
                                                        className="icon"
                                                        options={imageOptions}
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        paddingBottom: '24px',
                                                    }}
                                                >
                                                    <div style={{ flex: 1 }}>
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '10px',
                                                            }}
                                                        >
                                                            Size
                                                        </label>
                                                        <Slider
                                                            defaultValue={
                                                                watermark.width
                                                            }
                                                            getAriaValueText={(
                                                                val
                                                            ) => `${val}%`}
                                                            aria-labelledby="discrete-slider"
                                                            valueLabelDisplay="auto"
                                                            valueLabelFormat={(
                                                                val
                                                            ) => `${val}%`}
                                                            step={5}
                                                            marks
                                                            min={5}
                                                            max={100}
                                                            onChangeCommitted={(
                                                                e,
                                                                value
                                                            ) => {
                                                                setOpts({
                                                                    ...opts,
                                                                    watermark: {
                                                                        ...watermark,
                                                                        width: value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '10px',
                                                            }}
                                                        >
                                                            Opacity
                                                        </label>
                                                        <Slider
                                                            defaultValue={
                                                                watermark.opacity
                                                            }
                                                            getAriaValueText={(
                                                                val
                                                            ) => `${val}%`}
                                                            aria-labelledby="discrete-slider"
                                                            valueLabelDisplay="auto"
                                                            valueLabelFormat={(
                                                                val
                                                            ) => `${val}%`}
                                                            step={2}
                                                            marks
                                                            min={5}
                                                            max={100}
                                                            onChangeCommitted={(
                                                                e,
                                                                value
                                                            ) => {
                                                                setOpts({
                                                                    ...opts,
                                                                    watermark: {
                                                                        ...watermark,
                                                                        opacity:
                                                                            value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '10px',
                                                            }}
                                                        >
                                                            Horizontal Position
                                                        </label>
                                                        <Slider
                                                            defaultValue={
                                                                watermark.left
                                                            }
                                                            getAriaValueText={(
                                                                val
                                                            ) => `${val}%`}
                                                            aria-labelledby="slider"
                                                            valueLabelDisplay="auto"
                                                            min={
                                                                -(
                                                                    100 -
                                                                    watermark.width
                                                                )
                                                            }
                                                            max={
                                                                100 -
                                                                watermark.width
                                                            }
                                                            track={false}
                                                            valueLabelDisplay="off"
                                                            onChangeCommitted={(
                                                                e,
                                                                value
                                                            ) => {
                                                                setOpts({
                                                                    ...opts,
                                                                    watermark: {
                                                                        ...watermark,
                                                                        left: value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: '100px',
                                                        }}
                                                    >
                                                        <label
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                                fontSize:
                                                                    '10px',
                                                            }}
                                                        >
                                                            Vertical Position
                                                        </label>
                                                        <Slider
                                                            orientation="vertical"
                                                            defaultValue={
                                                                100 -
                                                                watermark.top
                                                            }
                                                            getAriaValueText={(
                                                                val
                                                            ) => `${val}%`}
                                                            aria-labelledby="slider"
                                                            valueLabelDisplay="auto"
                                                            min={0}
                                                            max={100}
                                                            track={false}
                                                            valueLabelDisplay="off"
                                                            onChangeCommitted={(
                                                                e,
                                                                value
                                                            ) => {
                                                                setOpts({
                                                                    ...opts,
                                                                    watermark: {
                                                                        ...watermark,
                                                                        top:
                                                                            100 -
                                                                            value,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem
                                onMouseEnter={() => {
                                    onMouseEnter('bodyHeader');
                                }}
                                onMouseLeave={() => {
                                    onMouseLeave('bodyHeader');
                                }}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton
                                        name={templateAccordionMap.bodyHeader}
                                    >
                                        {templateAccordionSection.bodyHeader}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <BodyHeaderContent
                                        {...{
                                            bodyHeader,
                                            imageOptions,
                                        }}
                                        onChange={(newBodyHeader) => {
                                            setOpts({
                                                ...opts,
                                                bodyHeader: newBodyHeader,
                                            });
                                        }}
                                    />
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem
                                onMouseEnter={() => {
                                    onMouseEnter('gameTableLabels');
                                }}
                                onMouseLeave={() => {
                                    onMouseLeave('gameTableLabels');
                                }}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton
                                        name={
                                            templateAccordionMap.gameTableLabels
                                        }
                                    >
                                        {
                                            templateAccordionSection.gameTableLabels
                                        }
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <GameTable
                                        {...{
                                            fieldsFormatting,
                                            onChange: (update) => {
                                                setOpts({
                                                    ...opts,
                                                    fieldsFormatting: {
                                                        ...opts.fieldsFormatting,
                                                        ...update,
                                                    },
                                                });
                                            },
                                        }}
                                    />
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem
                                onMouseEnter={() => {
                                    onMouseEnter('bodyFooter');
                                }}
                                onMouseLeave={() => {
                                    onMouseLeave('bodyFooter');
                                }}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton
                                        name={templateAccordionMap.bodyFooter}
                                    >
                                        {templateAccordionSection.bodyFooter}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <BodyHeaderContent
                                        {...{
                                            bodyHeader: bodyFooter,
                                            imageOptions,
                                            newDefault: {
                                                fontSize: 14,
                                                justifyContent: 'flex-start',
                                            },
                                        }}
                                        onChange={(newBodyFooter) => {
                                            setOpts({
                                                ...opts,
                                                bodyFooter: newBodyFooter,
                                            });
                                        }}
                                    />
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem
                                onMouseEnter={() => {
                                    onMouseEnter('signatureBlock');
                                }}
                                onMouseLeave={() => {
                                    onMouseLeave('signatureBlock');
                                }}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton
                                        name={
                                            templateAccordionMap.signatureBlock
                                        }
                                    >
                                        {
                                            templateAccordionSection.signatureBlock
                                        }
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div>
                                        <BodyHeaderContent
                                            {...{
                                                bodyHeader: signatureBlock,
                                                imageOptions,
                                                onChange: (newSigBlock) => {
                                                    setOpts({
                                                        ...opts,
                                                        signatureBlock:
                                                            newSigBlock,
                                                    });
                                                },
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            marginTop: '24px',
                                            borderTop: `1px solid ${colors.greyBorder}`,
                                            paddingTop: '16px',
                                        }}
                                    >
                                        <Checkbox
                                            label={`Include organization names above signature block?`}
                                            checked={
                                                opts.signatureBlockOrgNames
                                            }
                                            onChange={(e, { checked }) => {
                                                setOpts({
                                                    ...opts,
                                                    signatureBlockOrgNames:
                                                        checked,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            marginTop: '24px',
                                            borderTop: `1px solid ${colors.greyBorder}`,
                                            paddingTop: '16px',
                                        }}
                                    >
                                        <Checkbox
                                            label={`Include text immediately below signature line?`}
                                            checked={opts.hasSignatureLineText}
                                            onChange={(e, { checked }) => {
                                                setOpts({
                                                    ...opts,
                                                    hasSignatureLineText:
                                                        checked,
                                                    signatureLineText: checked
                                                        ? 'Athletic Representative'
                                                        : '',
                                                });
                                            }}
                                        />
                                        {hasSignatureLineText && (
                                            <div
                                                style={{
                                                    textAlign: 'left',
                                                    marginTop: '16px',
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '10px',
                                                    }}
                                                >
                                                    Content
                                                </label>
                                                <Input
                                                    fluid
                                                    type="text"
                                                    value={
                                                        opts.signatureLineText
                                                    }
                                                    placeholder="Type Content Here"
                                                    onChange={(
                                                        e,
                                                        { value }
                                                    ) => {
                                                        setOpts({
                                                            ...opts,
                                                            signatureLineText:
                                                                value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem
                                onMouseEnter={() => {
                                    onMouseEnter('contentFooter');
                                }}
                                onMouseLeave={() => {
                                    onMouseLeave('contentFooter');
                                }}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton
                                        name={
                                            templateAccordionMap.contentFooter
                                        }
                                    >
                                        {templateAccordionSection.contentFooter}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <BodyHeaderContent
                                        {...{
                                            bodyHeader: contentFooter,
                                            imageOptions,
                                        }}
                                        onChange={(newContentFooter) => {
                                            setOpts({
                                                ...opts,
                                                contentFooter: newContentFooter,
                                            });
                                        }}
                                    />
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem
                                onMouseEnter={() => {
                                    onMouseEnter('footer');
                                }}
                                onMouseLeave={() => {
                                    onMouseLeave('footer');
                                }}
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton
                                        name={templateAccordionMap.footer}
                                    >
                                        {templateAccordionSection.footer}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <HeaderContent
                                        {...{
                                            headerContent: footerImages,
                                            imageOptions,
                                            headerType: footerImages.length,
                                            addNewButton: 'Add Item to Row',
                                            alignItmes: footerAlignItems,
                                            helperText:
                                                'The bottom footer row items are tiled horizontally -- you can add as many items you want using the “Add New Item” button below, and each item can have multiple rows within the item.',
                                        }}
                                        onAlignItemsChange={(value) => {
                                            setOpts({
                                                ...opts,
                                                footerAlignItems: value,
                                            });
                                        }}
                                        onChange={(index, value) => {
                                            let newFooter = [
                                                ...opts.footerImages,
                                            ];
                                            newFooter[index] = value;
                                            setOpts({
                                                ...opts,
                                                footerImages: newFooter,
                                            });
                                        }}
                                        onRemoveColumn={(i) => {
                                            let newFooter = [
                                                ...opts.footerImages,
                                            ];
                                            newFooter.splice(i, 1);
                                            setOpts({
                                                ...opts,
                                                footerImages: newFooter,
                                            });
                                        }}
                                    />
                                    <div style={{ marginTop: '24px' }}>
                                        <Button
                                            onClick={() => {
                                                let newFooter = [
                                                    ...opts.footerImages,
                                                ];
                                                newFooter.push({
                                                    content: [
                                                        {
                                                            type: 'string',
                                                            content: '',
                                                            fontSize: 16,
                                                            alignItems:
                                                                'center',
                                                            justifyContent:
                                                                'center',
                                                            fontWeight:
                                                                'normal',
                                                            fontStyle: 'normal',
                                                            textDecoration:
                                                                'none',
                                                        },
                                                    ],
                                                });
                                                setOpts({
                                                    ...opts,
                                                    footerImages: newFooter,
                                                });
                                            }}
                                        >
                                            {`Add New Item`}
                                        </Button>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
                <BaseControlledModal
                    header="Save Contract"
                    open={confirmModalOpen}
                    onClose={() => {
                        setConfirmModalOpen(false);
                    }}
                    actions={[
                        <ModalAction
                            key={0}
                            title={'Save and Finish'}
                            primary
                            onClick={() => {
                                createOrgTemplate({
                                    variables: {
                                        orgid: user.orgid,
                                        ...opts,
                                    },
                                }).then(() => {
                                    setConfirmModalOpen(false);
                                    toast.success(
                                        `Template saved successfully!`,
                                        {
                                            position: toast.POSITION.TOP_CENTER,
                                            autoClose: 4000,
                                            hideProgressBar: true,
                                        }
                                    );
                                });
                            }}
                        />,
                    ]}
                >
                    <div>
                        {preview ? <ContractView fullSrc={preview} /> : null}
                    </div>
                </BaseControlledModal>
            </div>
        </div>
    );
};

const TemplateWrapper = (props) => {
    let { data, loading, error, refetch } = useQuery(templateQuery, {
        variables: {
            orgid: props.user.orgid,
            ...defaultOpts,
        },
    });
    return <Template {...{ ...props, data, loading, error, refetch }} />;
};

export default TemplateWrapper;
