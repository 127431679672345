import React, { useRef } from 'react';
import { Message } from 'semantic-ui-react';
import {
    LandingHeading,
    LandingSections,
} from './components/landing/howItWorks';
import LandingContainer from './components/landing/landingContainer';
import Nav from './components/nav/nav';

export const ErrorPage: (props: { error: any }) => JSX.Element = ({
    error,
}) => {
    const demoRef = useRef(null);
    console.log({ error });
    return (
        <div>
            <Nav loggedIn={false} notAUser error />
            <div style={{ paddingTop: '65px' }}>
                <LandingHeading
                    message={
                        <div
                            style={{
                                marginBottom: '48px',
                            }}
                        >
                            <Message color="red">
                                <Message.Header>
                                    System Maintenance
                                </Message.Header>
                                <p>
                                    We are currently undergoing system
                                    maintenance. If this continues to show,
                                    please contact{' '}
                                    <a
                                        href="mailto:info@perfectrecordsoftware.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        info@perfectrecordsoftware.com
                                    </a>
                                    .
                                </p>
                            </Message>
                        </div>
                    }
                />
                <LandingSections demoRef={demoRef} />
            </div>
        </div>
    );
};
