import React, {Component, useState, useEffect} from 'react';
import { compose, graphql, Mutation, Query} from 'react-apollo';
import {
    Button,
    Checkbox, 
    Modal,
    Dropdown,
    Form,
    Input,
    TextArea
} from 'semantic-ui-react';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import TextField from 'material-ui/TextField';
import Select from 'react-select';
import {toast} from 'react-toastify';

import {
    sportDropdownOptions,
    sportSelectOptions
} from '../../utils/sports';
import {
    contestSubmitMutation
} from '../../gql/contest';
import  {
    contractQuery
} from '../../gql/contract';
import {
    sportsDefaultQuery
} from '../../gql/defaults';

import { statuses } from '../../utils/statuses';

import {
    BaseModal, 
    BaseControlledModal,
    ModalAction,
    ModalDropdownTypeahead,
    ModalSelect,
    ModalFormInput,
    ModalFormTextArea 
} from './modals';
import Spinner from '../utilityComponents/spinner';
import RequestCopyButtons from '../utilityComponents/requestCopy';
import { validateEmail } from '../../utils/validators';
import { numbersOptions, RequireAwayHeadCoachInfo, checkSubmitRequiredPairs } from '../defaults/defaults';
import { orgQuery } from '../../gql/org';
import { InfoModalVideo } from '../utilityComponents/InfoModalVideo';

let ReadOnly = (props) => {
    let {submitPairError, attemptedSubmit} = props;
    let [hasChanged, setHasChanged] = useState(false);
    useEffect(() => {
        if (attemptedSubmit) {
            setHasChanged(false)
        }
    }, [attemptedSubmit])
    let error = submitPairError && !hasChanged;
    return (
        <div style={props.style}>
            <div>
                <Checkbox 
                    checked={props.submitContest.home_read_only}
                    label={{children: <p style={{fontWeight: props.submitContest.home_read_only ? 'bold' : 'normal'}}>Send a read-only copy of this contract to people within my institution</p>}}
                    onChange={(e, data) => props.onChange('home_read_only', !props.submitContest.home_read_only)} />
            </div>
            {props.submitContest.home_read_only ? (
                <div>
                    <p>Input emails of read-only recipients (comma separated). These individuals will receive a link to view    this contract as a read-only viewer.</p>
                    <Input 
                        fluid 
                        error={error}
                        type="text" 
                        value={props.submitContest.home_read_only_recipients} 
                        placeholder={`jane@college.edu, john@college.edu, etc.`}
                        onChange={(e, data) => props.onChange('home_read_only_recipients', data.value)} />
                </div>
            ) : null}
        </div>
    )
}

let InternalApproval = (props) => {
    let {submitContest, submitPairError, attemptedSubmit} = props;
    let [hasChanged, setHasChanged] = useState(false);
    useEffect(() => {
        if (attemptedSubmit) {
            setHasChanged(false)
        }
    }, [attemptedSubmit])
    let error = submitPairError && !hasChanged;
    return (
        <div style={props.style}>
            <div>
                <Checkbox 
                    checked={props.submitContest.home_requires_internal_approval}
                    label={{children: <p style={{fontWeight: props.submitContest.home_requires_internal_approval ? 'bold' : 'normal'}}>Require internal approval (head coach, etc.)</p>}}
                    onChange={(e, data) => {
                        props.onChange('home_requires_internal_approval', !props.submitContest.home_requires_internal_approval)
                    }} />
            </div>
            {props.submitContest.home_requires_internal_approval ? (
                <div>
                    <p>Input emails of required approvers (comma separated). Once the internal approvers sign off, the contract will be sent to the recipient at the opposing school as directed below.</p>
                    <div
                        style={{
                            marginBottom: '5px'
                        }}
                    >
                        {props.headCoachEmail && (
                            <Button basic onClick={() => {
                                let value;
                                if (props.submitContest.home_internal_approvers && props.submitContest.home_internal_approvers.length > 0 && props.submitContest.home_internal_approvers.indexOf(props.headCoachEmail) < 0) {
                                    value = `${props.submitContest.home_internal_approvers}, ${props.headCoachEmail}`;
                                } else {
                                    value = `${props.headCoachEmail}`;
                                }
                                props.onChange('home_internal_approvers', value)
                            }}>
                                Add head coach
                            </Button>
                        )}
                    </div>
                    <Input 
                        fluid 
                        type="text" 
                        error={error}
                        value={props.submitContest.home_internal_approvers || ''} 
                        placeholder={`jane@college.edu, john@college.edu, etc.`}
                        onChange={(e, data) => {
                            setHasChanged(true)
                            props.onChange('home_internal_approvers', data.value)
                        }} 
                    />
                </div>
            ) : null}
        </div>
    )
}

let OtherSigner = (props) => {
    let {submitPairError, attemptedSubmit} = props;
    let [hasChanged, setHasChanged] = useState(false);
    useEffect(() => {
        if (attemptedSubmit) {
            setHasChanged(false)
        }
    }, [attemptedSubmit])
    let error = submitPairError && !hasChanged;
    let options = props.userOrgContactList.map((contact, i) => ({
        text: `${contact.firstname && contact.lastname ? `${contact.firstname} ${contact.lastname} <${contact.email}>` : `<${contact.email}>`}`,
        value: contact.email,
    }))
    return (
        <div style={props.style}>
            <div>
                <Checkbox 
                    checked={props.submitContest.signor_different_user}
                    label={{children: <p style={{fontWeight: props.submitContest.signor_different_user ? 'bold' : 'normal'}}>Require a different person to be the primary signatory for my school</p>}}
                    onChange={(e, data) => {
                        props.onBlankContract();
                        props.onChange('signor_different_user', !props.submitContest.signor_different_user)}
                    }
                />
            </div>
            {props.submitContest.signor_different_user && (
                <div
                    style={{
                        marginBottom: '25px'
                    }}
                >
                    <p>Use the dropdown below to select the primary signatory on this contract. After any internal approvals are complete, this person will receive an email with a link to the contract requesting review and signature. The dropdown includes all registered Perfect Record users for your school, so if the person you want choose as the signatory is not on the dropdown list, please create a user account for that person (under the 'MY ACCOUNT' section).</p>
                    <Dropdown 
                        fluid
                        scrolling
                        error={error}
                        upward 
                        selection
                        type="text" 
                        options={options}
                        value={props.submitContest.signor_different_user_email} 
                        placeholder={`Select a recipient`}
                        onChange={(e, data) => props.onChange('signor_different_user_email', data.value)} 
                    />
                </div>
            )}                  
        </div>
    )
}

let ContractRecipient = (props) => {
    let {attemptedSubmit, awayOrg, setAttemptedSubmit=() => {}, awayPrimaryContact, userOrgContactList, submitContest={}} = props;
    let {contract_recipient, contract_recipient_new} = submitContest;
    let {firstname, lastname, email, title} = awayPrimaryContact || {};

    let [warning, setWarning] = useState(false);
    let [dirty, setDirty] = useState(false);
    let [selectWarning, setSelectWarning] = useState(false);

    useEffect(() => {
        if (contract_recipient === 'Add a new recipient') {
            let hasWarning = !validateEmail(contract_recipient_new)
            setWarning(hasWarning || contract_recipient_new.length === 0)
        }
        if (attemptedSubmit) {
            if (!contract_recipient) {
                setSelectWarning(true)
            }
            setDirty(true);
            setAttemptedSubmit(false);
        }
    }, [attemptedSubmit, contract_recipient, contract_recipient_new])

    let options = awayPrimaryContact ? [{
        text: `${firstname && lastname ? `${firstname} ${lastname} <${title}> | ${email} (primary contact)` : `${email} (primary contract)`}`,
        value: email,
        label: { color: 'blue', empty: true, circular: true }
    }] : [];
    userOrgContactList.forEach((contact, i) => {
        if (contact.email != email) {
            if (awayOrg.is_client) {
                let is_admin = contact.is_admin;
                let permissions = JSON.parse(contact.permissions || '{}') || {};
                if (is_admin || (permissions[submitContest.sport] && permissions[submitContest.sport].write)) {
                    options.push({
                        text: `${contact.firstname && contact.lastname ? `${contact.firstname} ${contact.lastname} <${contact.title}> | ${contact.email}` : `${contact.email}`}`,
                        value: contact.email,
                    })
                }
            } else {
                options.push({
                    text: `${contact.firstname && contact.lastname ? `${contact.firstname} ${contact.lastname} <${contact.title}> | ${contact.email}` : `${contact.email}`}`,
                    value: contact.email,
                })
            }
        }
    })
    if (contract_recipient && options.filter((contact) => {
        return contact.value === contract_recipient
    }).length === 0) {
        options.push({
            text: contract_recipient,
            value: contract_recipient,
        })
    }

    if (contract_recipient !== 'Add a new recipient') {
        options.push({
            text: 'Add a new recipient',
            value: 'Add a new recipient'
        })
    }

    let dropdownValue = contract_recipient;
    if (options.length === 1) {
        dropdownValue = 'Add a new recipient'
    }
    return (
        <div style={props.style}>
            <div>
                <p style={{fontWeight: 'bold'}}>Select the recipient of this contract at the opposing school:</p>
                <Dropdown 
                    scrolling
                    upward
                    fluid
                    error={selectWarning}
                    selection
                    options={options}
                    value={dropdownValue} 
                    placeholder={`Select a recipient`}
                    onChange={(e, data) => props.onChange('contract_recipient', data.value)} 
                />
                {dropdownValue === 'Add a new recipient' && (
                    <div
                        style={{
                            marginTop: '15px'
                        }}
                    >
                        <Input 
                            fluid 
                            type="text" 
                            error={warning && dirty}
                            value={contract_recipient_new} 
                            placeholder={`jane@college.edu`}
                            required={true}
                            onChange={(e, data) => props.onChange(
                                'contract_recipient_new', data.value
                            )} 
                            onBlur={() => setDirty(true)}
                            onFocus={() => setDirty(false)}
                        />
                        {warning && dirty && (
                            <span style={{fontSize: '10px', color: '#9f3a38'}}>Please enter a valid email</span>
                        )}
                    </div>
                    
                )}
            </div>  
        </div>
    )
}

export const ReminderInterval = props => {
    let {remind, interval, onIntervalChange, onRemindChange} = props;
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '15px'
            }}
        >
            <Checkbox 
                checked={remind}
                label={{children: (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <span>
                            {remind ? (
                                <span>
                                    Send a reminder after every {
                                        remind ? <Dropdown 
                                            inline
                                            upward
                                            options={numbersOptions}
                                            value={interval}
                                            onChange={(event, {name, value}) => onIntervalChange(value)}
                                        /> : null
                                    } of inactivity
                                </span>
                            ) : (
                                `Send automatic reminders for this contract`
                            )}
                            
                        </span>
                    </div>
                )}}
                onChange={onRemindChange}
            />
        </div>
    )
}

let sectionStyle = {
    margin: '10px 0'
}

const getHomeInternalApprovers = (obj) => {
    try {
        let parsed = JSON.parse(obj);
        let approverEmails = [];
        parsed.forEach((item, _) => {
            if (item.approver_email) {
                approverEmails.push(item.approver_email)
            }
        })
        return approverEmails.join(', ');
    }
    catch {
        return obj
    }
}


class SubmitContestModal extends Component {
    constructor(props) {
        super(props);
        let {contest, sportsDefault} = props;
        let theSportsDefault;
        try {
            theSportsDefault = JSON.parse(sportsDefault && sportsDefault.sportsDefault && sportsDefault.sportsDefault.properties);
        } catch (err) {
            console.log({err})
        }
        let sportDefault = theSportsDefault ? theSportsDefault[props.contest.sport] : undefined;
        this.state = {
            opponentSearchQuery: '',
            sportSearchQuery: '',
            blank: false,
            different_signer: contest.signor_different_user || false,
            errors: {},
            submitPairErrors: [],
            submitContest: {
                home_read_only: !!contest.home_read_only_recipients ||  false,
                home_read_only_recipients: contest.home_read_only_recipients ||  '',
                home_requires_internal_approval: contest.home_requires_internal_approval || false,
                home_internal_approvers: (sportDefault && sportDefault.home_internal_approvers) || '',
                signor_different_user: contest.signor_different_user || false,
                signor_different_user_email: contest.signor_different_user_email || '',
                contract_recipient: undefined,
                contract_recipient_new: '',
                submitter_email: props.user.email,
                submitter_fullname: props.user.firstname && props.user.lastname ? `${props.user.firstname} ${props.user.lastname}` : props.user.email,
                user_email: props.user.email,
                user_fullname: props.user.firstname && props.user.lastname ? `${props.user.firstname} ${props.user.lastname}` : props.user.email,
                user_office_phone: props.user.phone_work,
                user_title: props.user.title,
                submit_type: 'ContestSubmit',
                remind: props.contest && props.contest.remind,
                interval: props.contest && props.contest.interval,
                require_away_head_coach_info: props.contest.require_away_head_coach_info || '{}',
                sport: props.contest.sport,
            }
        }
    }

    componentWillMount() {
        if (this.props.userOrgContactList.awayPrimaryContact && !this.state.submitContest.contract_recipient) {
            let {submitContest} = this.state;
            submitContest.contract_recipient = this.props.userOrgContactList.awayPrimaryContact.email;
            this.setState({
                submitContest
            })
        }
    }

    componentDidUpdate(prevProps) {
        let submitContest = {...this.state.submitContest};
        let toSetState = false;
        if (prevProps.contest.remind !== this.props.contest.remind ||
            prevProps.contest.interval !== this.props.contest.interval
        ) {
            submitContest.remind = this.props.contest.remind;
            submitContest.interval = this.props.contest.interval;
            toSetState = true;
        }
        if (prevProps.contest.require_away_head_coach_info !== this.props.contest.require_away_head_coach_info) {
            submitContest.require_away_head_coach_info = this.props.contest.require_away_head_coach_info || '{}';
            toSetState = true;
        }

        if (prevProps.contest.id !== this.props.contest.id) {
            let contest = this.props.contest
            let {sportsDefault} = this.props;
            let theSportsDefault;
            try {
                theSportsDefault = JSON.parse(sportsDefault && sportsDefault.sportsDefault && sportsDefault.sportsDefault.properties);
            } catch (err) {
                console.log({err})
            }
            let sportDefault = theSportsDefault ? theSportsDefault[this.props.contest.sport] : undefined;
            Object.assign(submitContest, {
                home_read_only: !!contest.home_read_only_recipients ||  false,
                home_read_only_recipients: contest.home_read_only_recipients ||  '',
                home_requires_internal_approval: contest.home_requires_internal_approval || false,
                home_internal_approvers: (sportDefault && sportDefault.home_internal_approvers) || '',
                signor_different_user: contest.signor_different_user || false,
                signor_different_user_email: contest.signor_different_user_email || '',
            })
        }

        if (!this.props.open && this.state.submitContest.contract_recipient) {
            let {sportsDefault} = this.props;
            let theSportsDefault;
            try {
                theSportsDefault = JSON.parse(sportsDefault && sportsDefault.sportsDefault && sportsDefault.sportsDefault.properties);
            } catch (err) {
                console.log({err})
            }
            let sportDefault = theSportsDefault ? theSportsDefault[this.props.contest.sport] : undefined;
            Object.assign(submitContest, {
                contract_recipient: null,
                contract_recipient_new: '',
                submitter_email: this.props.user.email,
                submitter_fullname: this.props.user.firstname && this.props.user.lastname ? `${this.props.user.firstname} ${this.props.user.lastname}` : this.props.user.email,
                user_email: this.props.user.email,
                user_fullname: this.props.user.firstname && this.props.user.lastname ? `${this.props.user.firstname} ${this.props.user.lastname}` : this.props.user.email,
                user_office_phone: this.props.user.phone_work,
                user_title: this.props.user.title,
                submit_type: 'ContestSubmit',
                home_read_only: !!submitContest.home_read_only_recipients ||  false,
                home_read_only_recipients: submitContest.home_read_only_recipients ||  '',
                home_requires_internal_approval: submitContest.home_requires_internal_approval || false,
                home_internal_approvers: (sportDefault && sportDefault.home_internal_approvers) || '',
                signor_different_user: submitContest.signor_different_user || false,
                signor_different_user_email: submitContest.signor_different_user_email || '',
                sport: this.props.contest.sport,
            })
            toSetState = true;
        }
        if (this.props.open && this.props.userOrgContactList.awayPrimaryContact && !this.state.submitContest.contract_recipient) {
            submitContest.contract_recipient = this.props.userOrgContactList.awayPrimaryContact.email;
            toSetState = true;
        }

        if (toSetState) {
            this.setState({
                different_signer: submitContest.signor_different_user,
                submitContest,
                submitPairErrors: []
            })
        }
    }

    submitContestChange = (attr, value) => {
        let submitContest = this.state.submitContest;
        submitContest[attr] = value;
        this.setState({
            submitContest
        })
    }

    resolveSubmitType = () => {
        let contest = this.state.submitContest;
        if (contest.home_requires_internal_approval) {
            return 'ContestSubmitInternalHome'
        } else if (contest.signor_different_user && !contest.home_requires_internal_approval) {
            return 'ContestSubmitDifferentSigner'
        } else {
            return 'ContestSubmit'
        }
    }

    notify = (success, args = {}) => {
        let {message='Contest submitted successfully'} = args
        if (success) {
            toast.success(message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            });
        }
    }

    copyRequest = () => {
        return (
            <RequestCopyButtons
                key={-3} 
                user={this.props.user}
                guid={this.props.contest.guid}
                onClose={this.props.onClose}
                notify={this.notify}
            />
        )
    }

    render() {
        let { sportsDefault={} } = this.props;
        let {submitContest, submitPairErrors, blank} = this.state;
        let user = {...this.props.user};
        delete user._typename;
        let theSportsDefault;
        try {
            theSportsDefault = JSON.parse(sportsDefault && sportsDefault.sportsDefault && sportsDefault.sportsDefault.properties);
        } catch (err) {
            console.log({err})
        }

        let sportDefault = theSportsDefault ? theSportsDefault[this.props.contest.sport] : undefined;
        return(
            <Mutation mutation={contestSubmitMutation}>
                {(contestSubmit, {data, loading, error}) => {
                    return (
                        <BaseControlledModal
                            header={(
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    Send Contract
                                    <InfoModalVideo {...{
                                        videoId: 'OTrXINvlDw0',
                                        header: 'Send Contract'
                                    }} />
                                </div>
                            )}
                            open={this.props.open}
                            onClose={() => {
                                this.setState({
                                    submitContest:  {
                                        home_read_only: false,
                                        contract_recipient: null,
                                        contract_recipient_new: '',
                                        signor_different_user_email: '',
                                        submitter_email: this.props.user.email,
                                        submitter_fullname: this.props.user.firstname && this.props.user.lastname ? `${this.props.user.firstname} ${this.props.user.lastname}` : this.props.user.email,
                                        user_email: this.props.user.email,
                                        user_fullname: this.props.user.firstname && this.props.user.lastname ? `${this.props.user.firstname} ${this.props.user.lastname}` : this.props.user.email,
                                        user_office_phone: this.props.user.phone_work,
                                        user_title: this.props.user.title,
                                        submit_type: 'ContestSubmit',
                                        home_requires_internal_approval: false,
                                        home_read_only_recipients: '',
                                        home_internal_approvers: ''
                                    }
                                }, () => {
                                    this.props.onClose()
                                })
                            }}
                            leftActions={[
                                this.copyRequest(),
                            ]}
                            actions={[
                                <ModalAction
                                    key={0} 
                                    title={`Send Contract`}
                                    primary
                                    onClick={() => {
                                        let contest = {...this.state.submitContest};
                                        let propsContest = this.props.contest;
                                        let submitPairErrors = checkSubmitRequiredPairs(contest);
                                        if (submitPairErrors.length > 0) {
                                            this.setState({submitPairErrors, attemptedSubmit: true})
                                        } else {
                                            let updatedRemind = false;
                                            if (propsContest.remind !== contest.remind || propsContest.interval !== contest.interval) {
                                                updatedRemind = true;
                                            }
                                            if (!contest.home_read_only) {
                                                contest.home_read_only_recipients = ''
                                            }
                                            if (contest.contract_recipient === 'Add a new recipient' || !contest.contract_recipient) {
                                                if (contest.contract_recipient_new && validateEmail(contest.contract_recipient_new)) {
                                                    contest.contract_recipient = contest.contract_recipient_new;
                                                    contestSubmit({variables: Object.assign({},
                                                        contest,
                                                        {
                                                            guid: this.props.contest.guid,
                                                            firstSubmit: true,
                                                            submit_type: this.resolveSubmitType(),
                                                            updatedRemind
                                                        }
                                                    )}).then(res => {
                                                        this.props.contestsRefetch();
                                                        this.notify(true);
                                                        this.props.onClose();
                                                    }, err => {
                                                        console.log('submit err', err)
                                                    })
                                                } else {
                                                    this.setState({attemptedSubmit: true})
                                                }
                                            } else {
                                                contestSubmit({variables: Object.assign({},
                                                    contest,
                                                    {
                                                        guid: this.props.contest.guid,
                                                        firstSubmit: true,
                                                        submit_type: this.resolveSubmitType(),
                                                        updatedRemind
                                                    }
                                                )}).then(res => {
                                                    this.props.contestsRefetch();
                                                    this.notify(true);
                                                    this.props.onClose();
                                                }, err => {
                                                    console.log('submit err', err)
                                                })
                                            }
                                        }                                        
                                    }}
                                />
                            ]}
                            >
                            <Query 
                                query={contractQuery} 
                                fetchPolicy={`network-only`}
                                variables={{
                                    id: this.props.contest.id,
                                    guid: 'preview',
                                    blank: this.state.blank,
                                    different_signer: this.state.different_signer,
                                    require_away_head_coach_info: submitContest.require_away_head_coach_info,
                                    away: false,
                                    user: {
                                        firstname: user.firstname,
                                        lastname: user.lastname,
                                        email: user.email,
                                        phone_work: user.phone_work,
                                        title: user.title
                                    }
                                    
                                }}>
                                {({loading, error, data, refetch}) => {
                                    if (loading) {
                                        return (
                                            <div
                                                style={{
                                                    height: '1000px'
                                                }}
                                            >
                                                <Spinner />
                                            </div>
                                        )
                                    }
                                    if (error) return <div>Something went wrong</div>
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginBottom: '40px'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '800px',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                <div 
                                                    dangerouslySetInnerHTML={{__html: data.contract.html}}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        border: '1px solid grey',
                                                        boxShadow: '5px 5px 8px #aaa',
                                                    }}>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }}    
                            </Query>
                            <div
                                style={sectionStyle}
                            >
                                <ReadOnly 
                                    submitContest={this.state.submitContest}
                                    attemptedSubmit={this.state.attemptedSubmit}
                                    onChange={this.submitContestChange} 
                                    submitPairError={submitPairErrors.includes('home_read_only_recipients')}
                                />
                            </div>
                            <div
                                style={sectionStyle}
                            >  
                                <InternalApproval
                                    submitContest={this.state.submitContest}
                                    headCoachEmail={sportDefault && sportDefault.head_coach_email}
                                                headCoachEmail={sportDefault && sportDefault.head_coach_email} 
                                    headCoachEmail={sportDefault && sportDefault.head_coach_email}
                                                headCoachEmail={sportDefault && sportDefault.head_coach_email} 
                                    headCoachEmail={sportDefault && sportDefault.head_coach_email}
                                    onChange={this.submitContestChange} 
                                                onChange={this.submitContestChange} 
                                    onChange={this.submitContestChange} 
                                                onChange={this.submitContestChange} 
                                    onChange={this.submitContestChange} 
                                    attemptedSubmit={this.state.attemptedSubmit}
                                    submitPairError={submitPairErrors.includes('home_internal_approvers')}
                                />
                            </div>
                            <div
                                style={sectionStyle}
                            >
                                <OtherSigner 
                                    orgid={this.props.contest.orgid}
                                    attemptedSubmit={this.state.attemptedSubmit}
                                    submitContest={this.state.submitContest}
                                    onChange={this.submitContestChange}
                                    userOrgContactList={this.props.userOrgContactList.homeUserOrgContactList}
                                    onBlankContract={() => {
                                        this.setState({
                                            different_signer: !this.state.different_signer
                                        })
                                    }}  
                                    submitPairError={submitPairErrors.includes('signor_different_user_email')}
                                />
                            </div>
                            <div
                                style={sectionStyle}
                            >
                                <RequireAwayHeadCoachInfo 
                                    location='submitContestModal'
                                    require_away_head_coach_info={this.state.submitContest.require_away_head_coach_info}
                                    onChange={require_away_head_coach_info => this.submitContestChange('require_away_head_coach_info', require_away_head_coach_info)}
                                />
                            </div>
                            <div
                                style={sectionStyle}
                            >
                                <Query
                                    query={orgQuery}
                                    variables={{
                                        id: this.props.contest.away_orgid
                                    }}
                                >
                                    {({data, loading, error}) => {
                                        if (loading || error) {
                                            return null
                                        }
                                        return (
                                            <ContractRecipient
                                                awayOrg={data.org}
                                                orgid={this.props.contest.away_orgid} 
                                                submitContest={this.state.submitContest}
                                                errors={this.state.errors}
                                                attemptedSubmit={this.state.attemptedSubmit}
                                                setAttemptedSubmit={attemptedSubmit => this.setState({attemptedSubmit})}
                                                onChange={this.submitContestChange}
                                                userOrgContactList={this.props.userOrgContactList.awayUserOrgContactList}
                                                awayPrimaryContact={this.props.userOrgContactList.awayPrimaryContact} 
                                            />
                                        )
                                    }}
                                </Query>
                                
                            </div>
                            <div
                                style={sectionStyle}
                            >
                                <ReminderInterval 
                                    remind={this.state.submitContest.remind}
                                    interval={this.state.submitContest.interval}
                                    onRemindChange={() => {
                                        this.submitContestChange('remind', !this.state.submitContest.remind);
                                    }}
                                    onIntervalChange={interval => {
                                        this.submitContestChange('interval', interval)
                                    }}
                                />
                            </div>
                            
                        </BaseControlledModal>
                    )
                }}
            </Mutation>
        )
    }
};

export default SubmitContestModal
