import React, {Component} from 'react';
import { Mutation, Query } from 'react-apollo';
import { Route } from 'react-router-dom';
import qs from 'qs';

import { contestStatusQuery, contestUpdateMutation } from '../../gql/contest';
import { contractQuery } from '../../gql/contract';

import Sign from './sign';
import Spinner from '../utilityComponents/spinner';
import { orgQuery } from '../../gql/org';

let SignContainer = props => {
    let urlQuery = qs.parse(props.location.search);
    let guid = urlQuery.guid || urlQuery[`?guid`];
    return (
        <Query
            query={contestStatusQuery}
            variables={{
                guid
            }}
        >
            {({loading, data, refetch: contestStatusRefetch, error}) => {
                if (loading) {
                    return <Spinner />
                }
                let signed = false;
                if (data && data.contest) {
                    if (data.contest.status === 'SIGNED') {
                        signed = true
                    }
                } 
                
                return (
                    <Query
                        query={contractQuery}
                        variables={{
                            guid
                        }}
                    >
                        {({loading, data, refetch, error}) => {
                            if (loading) {
                                return <Spinner />
                            }                                
                            
                            if (data.contract && !data.contract.html && data.contract.message === 'noContest') {
                                return (
                                    <div
                                        style={{
                                            marginTop: '35px'
                                        }}
                                    >
                                        <div>
                                            It looks like the contract you are attempting to view does not exist or may have been edited by the sender. 
                                        </div>
                                        <div
                                            style={{
                                                marginTop: '10px'
                                            }}
                                        >
                                            Please check for a more recent email for this contest from <a href="mailto:no-reply@perfectrecordsoftware.com">no-reply@perfectrecordsoftware.com</a>, or you may wait for the newly submitted contract.
                                        </div>
                                    </div>
                                )
                            } else if (data.contract) {
                                
                                return (
                                    <Query
                                        query={orgQuery}
                                        variables={{id: data.contract.contest.away_orgid}}
                                    >
                                        {({data: orgData, loading: orgLoading}) => {
                                            if (orgLoading) {
                                                return null
                                            } else {
                                                return (
                                                    <Mutation mutation={contestUpdateMutation}>
                                                        {(contestUpdate) => (
                                                            <Sign 
                                                                {...props} 
                                                                contestUpdate={contestUpdate} 
                                                                guid={guid} 
                                                                signed={signed} 
                                                                urlQuery={urlQuery} 
                                                                contract={data.contract.html} 
                                                                status={data.contract.status}
                                                                contest={data.contract.contest}
                                                                contractRefetch={refetch} 
                                                                contestStatusRefetch={contestStatusRefetch}
                                                                awayOrg={orgData.org}
                                                            />
                                                        )}
                                                    </Mutation>
                                                )
                                            }
                                        }}
                                    </Query>
                                )
                            } else {
                                return (
                                    <div>
                                        There was a problem locating your contract...
                                    </div>
                                )
                            }
                            
                        }}
                    </Query>
                )
            }}
            
        </Query>
        
    )
}


export default SignContainer;
