import auth0 from 'auth0-js';
import React, {Component} from 'react'
import {Redirect, withRouter} from 'react-router-dom';

import history from './history';
import urls from './urls';

export default class Auth {
    constructor() {
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.handleAuthentication = this.handleAuthentication.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
        this.emailVerified = false;
        // let hasToken = JSON.parse(localStorage.getItem('perfect_access_token'));
        // let expiresAt = JSON.parse(localStorage.getItem('perfect_expires_at'));
        // let isValid = new Date().getTime() < expiresAt;
        // if (hasToken && isValid) {
        //     this.createManagement(hasToken)
        // }
    }

    auth0 = new auth0.WebAuth({
        domain: 'perfectrecordauth.auth0.com',
        clientID: 'H7PJVa7TAAjk6SfDpxSNbgrJWgkg6zte',
        redirectUri: urls.callback,
        audience: 'https://perfectrecordauth.auth0.com/userinfo',
        responseType: 'token id_token',
        scope: 'openid email email_verified'
    });

    // createManagement(accessToken) {
    //     this.management = new auth0.WebAuth({
    //         domain: 'perfectrecordauth.auth0.com',
    //         accessToken
    //     })
    // }

    // check
    
    login(props, cb=() => {}) {
        this.refferer = props.location;
        this.auth0.authorize({state: JSON.stringify({location: props.location})});
    }

    handleAuthentication(props, cb=() => {}) {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult).then(() => {
                    if (authResult.state) {
                        let location = JSON.parse(authResult.state).location;
                        props.history.replace(location.pathname)
                    } else {
                        props.history.replace('/')
                    }
                });
                
            } else if (err) {
                console.log(err);
                cb()
          }
        });
    }
    
    setSession(authResult) {
        return new Promise((resolve, reject) => {
            // Set the time that the Access Token will expire at
            let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
            localStorage.setItem('perfect_access_token', authResult.accessToken);
            localStorage.setItem('perfect_id_token', authResult.idToken);
            localStorage.setItem('perfect_expires_at', expiresAt);
            localStorage.setItem('perfect_email', authResult.idTokenPayload.email);
            localStorage.setItem('perfect_email_verified', authResult.idTokenPayload.email_verified);
            resolve();
        })
        
    }

    changePassword({email}, cb=() => {}) {
        auth0.changePassword({
            email
          }, function (err, resp) {
            if(err){
              console.log(err.message);
            }else{
              console.log(resp);
            }
          });
    }

    logout(props, cb=() => {}) {
        // Clear Access Token and ID Token from local storage
        localStorage.removeItem('perfect_access_token');
        localStorage.removeItem('perfect_id_token');
        localStorage.removeItem('perfect_expires_at');
        localStorage.removeItem('perfect_email');
        localStorage.removeItem('perfect_email_verified');
        cb()
        window.location.href = `https://perfectrecordauth.auth0.com/v2/logout?returnTo=${urls.returnTo}`
    }

    isAuthenticated() {
        // Check whether the current time is past the 
        // Access Token's expiry time
        let expiresAt = JSON.parse(localStorage.getItem('perfect_expires_at'));
        return new Date().getTime() < expiresAt;
    }

    isVerified() {
        return JSON.parse(localStorage.getItem('perfect_email_verified'))
    }
}
