/* eslint-disable no-param-reassign */
export const sports: Record<string, string> = {
    ACRO: 'Acrobatics & Tumbling',
    BASE: 'Baseball',
    BOWL: 'Bowling',
    CSAIL: 'Coed Sailing',
    EQU: 'Equestrian',
    FB: 'Football',
    FH: 'Field Hockey',
    MASKI: "Men's Skiing (Alpine)",
    MBB: "Men's Basketball",
    MFEN: "Men's Fencing",
    MGOLF: "Men's Golf",
    MGYM: "Men's Gymnastics",
    MHCREW: "Men's Heavyweight Crew",
    MIH: "Men's Ice Hockey",
    MITRACK: "Men's Indoor Track and Field",
    MLAX: "Men's Lacrosse",
    MLCREW: "Men's Lightweight Crew",
    MNSKI: "Men's Skiing (Nordic)",
    MOTRACK: "Men's Outdoor Track and Field",
    MRIF: "Men's Rifle",
    MSO: "Men's Soccer",
    MSQ: "Men's Squash",
    MSWIM: "Men's Swimming and Diving",
    MTEN: "Men's Tennis",
    MVB: "Men's Volleyball",
    MWP: "Men's Water Polo",
    MXC: "Men's Cross Country",
    SFB: 'Sprint Football',
    SOFT: 'Softball',
    WASKI: "Women's Skiing (Alpine)",
    WBB: "Women's Basketball",
    WBVB: "Women's Beach Volleyball",
    WFEN: "Women's Fencing",
    WGOLF: "Women's Golf",
    WGYM: "Women's Gymnastics",
    WHCREW: "Women's Heavyweight Crew",
    WIH: "Women's Ice Hockey",
    WITRACK: "Women's Indoor Track and Field",
    WLAX: "Women's Lacrosse",
    WLCREW: "Women's Lightweight Crew",
    WNSKI: "Women's Skiing (Nordic)",
    WOTRACK: "Women's Outdoor Track and Field",
    WRES: 'Wrestling',
    WWRES: "Women's Wrestling",
    WRIF: "Women's Rifle",
    WRUG: "Women's Rugby",
    WSAIL: "Women's Sailing",
    WSO: "Women's Soccer",
    WSQ: "Women's Squash",
    WSWIM: "Women's Swimming and Diving",
    WTEN: "Women's Tennis",
    WVB: "Women's Volleyball",
    WWP: "Women's Water Polo",
    WXC: "Women's Cross Country",

    MWSWIM: "Men's and Women's Swimming and Diving",
    MWSQ: "Men's and Women's Squash",
    MWTEN: "Men's and Women's Tennis",
    MWITRACK: "Men's and Women's Indoor Track and Field",
    MWOTRACK: "Men's and Women's Outdoor Track and Field",
    MWRIF: "Men's and Women's Rifle",
    MWASKI: "Men's and Women's Skiing (Alpine)",
    MWNSKI: "Men's and Women's Skiing (Nordic)",
    MWXC: "Men's and Women's Cross Country",
};

export const combinedSports: Record<string, string[]> = {
    MWSWIM: ['MSWIM', 'WSWIM'],
    MWSQ: ['MSQ', 'WSQ'],
    MWTEN: ['MTEN', 'WTEN'],
    MWITRACK: ['MITRACK', 'WITRACK'],
    MWOTRACK: ['MOTRACK', 'WOTRACK'],
    MWRIF: ['WRIF', 'MRIF'],
    MWASKI: ['MASKI', 'WASKI'],
    MWNSKI: ['MNSKI', 'WNSKI'],
    MWXC: ['MXC', 'WXC'],
};

export const sportSelectOptions = Object.keys(sports).map((sport) => ({
    value: sport,
    label: sports[sport],
}));
export const sportDropdownOptions = Object.keys(sports).map((sport) => ({
    value: sport,
    text: sports[sport],
}));

export const sportsAlphabetized: Record<string, string> = {
    ACRO: 'Acrobatics & Tumbling',
    BASE: 'Baseball',
    MBB: 'Basketball (M)',
    WBB: 'Basketball (W)',
    WBVB: 'Beach Volleyball (W)',
    BOWL: 'Bowling',
    CSAIL: 'Coed Sailing',
    MWXC: 'Cross Country (M/W)',
    MXC: 'Cross Country (M)',
    WXC: 'Cross Country (W)',
    EQU: 'Equestrian',
    MFEN: 'Fencing (M)',
    WFEN: 'Fencing (W)',
    FH: 'Field Hockey',
    FB: 'Football',
    MGOLF: 'Golf (M)',
    WGOLF: 'Golf (W)',
    MGYM: 'Gymnastics (M)',
    WGYM: 'Gymnastics (W)',
    WHCREW: 'Heavyweight Crew (W)',
    MHCREW: 'Heavyweight Crew (M)',
    MIH: 'Ice Hockey (M)',
    WIH: 'Ice Hockey (W)',
    MLAX: 'Lacrosse (M)',
    WLAX: 'Lacrosse (W)',
    WLCREW: 'Lightweight Crew (W)',
    MLCREW: 'Lightweight Crew (M)',
    MWRIF: 'Rifle (M/W)',
    MRIF: 'Rifle (M)',
    WRIF: 'Rifle (W)',
    WRUG: 'Rugby (W)',
    WSAIL: 'Sailing (W)',
    MWASKI: 'Skiing Alpine (M/W)',
    MASKI: 'Skiing Alpine (M)',
    WASKI: 'Skiing Alpine (W)',
    MWNSKI: 'Skiing Nordic (M/W)',
    MNSKI: 'Skiing Nordic (M)',
    WNSKI: 'Skiing Nordic (W)',
    MSO: 'Soccer (M)',
    WSO: 'Soccer (W)',
    SOFT: 'Softball',
    SFB: 'Sprint Football',
    MWSQ: 'Squash (M/W)',
    MSQ: 'Squash (M)',
    WSQ: 'Squash (W)',
    MWSWIM: 'Swimming & Diving (M/W)',
    MSWIM: 'Swimming & Diving (M)',
    WSWIM: 'Swimming & Diving (W)',
    MWTEN: 'Tennis (M/W)',
    MTEN: 'Tennis (M)',
    WTEN: 'Tennis (W)',
    MWITRACK: 'Track & Field Indoor (M/W)',
    MITRACK: 'Track & Field Indoor (M)',
    WITRACK: 'Track & Field Indoor (W)',
    MWOTRACK: 'Track & Field Outdoor (M/W)',
    MOTRACK: 'Track & Field Outdoor (M)',
    WOTRACK: 'Track & Field Outdoor (W)',
    MVB: 'Volleyball (M)',
    WVB: 'Volleyball (W)',
    MWP: 'Water Polo (M)',
    WWP: 'Water Polo (W)',
    WRES: 'Wrestling',
    WWRES: 'Wrestling (W)',
};

export const sportsAlphaWithGenders = Object.entries(sports).map((sport) => {
    if (sport[1].slice(0, 17) === "Men's and Women's" || sport[0] === 'CSAIL') {
        // @ts-ignore
        sport[2] = 'MW';
    } else if (
        sport[1].slice(0, 5) === "Men's" ||
        ['BASE', 'FB', 'SFB', 'WRES'].includes(sport[0])
    ) {
        // @ts-ignore
        sport[2] = 'M';
    } else if (
        sport[1].slice(0, 7) === "Women's" ||
        ['BOWL', 'EQU', 'FH', 'SOFT', 'ACRO'].includes(sport[0])
    ) {
        // @ts-ignore
        sport[2] = 'W';
    } else {
        console.log(
            'Could not find sport gender -- sports.js sportGenders variable'
        );
        // @ts-ignore
        sport[2] = 'W';
    }
    // @ts-ignore
    sport[3] = sportsAlphabetized[sport[0]];
    return sport;
});
