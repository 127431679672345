/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import {
    Button,
    Checkbox,
    Form,
    Icon,
    Input,
    Loader,
    Modal,
    Pagination,
    Popup,
    SemanticCOLORS,
    SemanticICONS,
    TextArea,
    FormField as Field,
} from 'semantic-ui-react';
import {
    adminOrgsQuery,
    Org,
    orgCreateMutation,
    orgsLengthQuery,
    orgsQuery,
    orgsUsageArgsQuery,
    orgUpdateMutation,
} from '../../gql/org';
import { sports, sportsAlphabetized } from '../../utils/sports';
import { BaseControlledModal } from '../modals/modals';
import { SportToggle } from '../account/myOrg';
import {
    userCreateMutation,
    userOrgContactListQuery,
    userRemoveMutation,
    userUpdateMutation,
} from '../../gql/user';
import { EditInPlaceField } from '../utilityComponents/EditInPlaceField';
import { CustomDatePickerInner } from '../utilityComponents/form/materialUI';
import { formatDate } from '../schedule/schedule';
import urls from '../../urls';
import { colors } from '../../utils/colors';
import {
    NewCustomer,
    QBCustomer,
    quickbooksAccountLink,
    quickbooksCustomersQuery,
    refreshQuickbooksToken,
} from '../../gql/qb';
import { InvoicesModal } from './invoices';
import { FormRowInput } from '../utilityComponents/form/formRow';
import { BlurInput } from '../utilityComponents/BlurInput';

const icons: {
    [key: string]: (color?: SemanticCOLORS) => {
        name: SemanticICONS;
        color: SemanticCOLORS;
    };
} = {
    check: (color = 'green') => ({ name: 'check circle', color }),
    x: (color = 'red') => ({ name: 'remove circle', color }),
    minus: (color = 'yellow') => ({ name: 'minus circle', color }),
    none: (color = 'grey') => ({ name: 'circle outline', color }),
    skip: (color = 'grey') => ({ name: 'remove circle', color }),
};

export const formatDollar = ({
    amount,
    decimalCount = 2,
    decimal = '.',
    thousands = ',',
    excludeDollar,
    hideZeroDecimal = false,
}: {
    amount: number;
    decimalCount?: number;
    decimal?: string;
    thousands?: string;
    excludeDollar?: boolean;
    hideZeroDecimal?: boolean;
}): string => {
    try {
        const negativeSign = amount < 0 ? '-' : '';
        const i = parseInt(
            Math.abs(amount).toFixed(decimalCount),
            10
        ).toString();
        const j = i.length > 3 ? i.length % 3 : 0;
        const decimalNumber = Math.abs(amount) - parseInt(i, 10);
        return `${negativeSign} ${excludeDollar ? '' : '$'}${
            j ? i.substr(0, j) + thousands : ''
        }${i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)}${
            decimalCount && (!hideZeroDecimal || decimalNumber !== 0)
                ? `${decimal}${decimalNumber.toFixed(decimalCount).slice(2)}`
                : ''
        }`;
    } catch (e) {
        console.log({ e });
        return 'error';
    }
};

export interface User {
    id: string;
    firstname?: string;
    lastname?: string;
    title?: string;
    email: string;
    phone_work: string;
    phone_cell: string;
    is_admin?: boolean;
    permission_level: string;
}

const UserRow: React.FC<{
    user: Partial<User>;
    usersGql: any;
    primaryUser?: User;
    onChange: (update: any, callback?: () => void) => void;
    onPrimaryContactChange?: (user_id: string) => void;
    onRemove?: () => void;
    onSave?: () => void;
    isNew?: boolean;
}> = ({
    user,
    usersGql,
    onChange,
    primaryUser,
    onPrimaryContactChange,
    onRemove,
    onSave,
    isNew,
}) => {
    const [removePopupVisible, setRemovePopupVisible] = useState<string>('');
    const removeUser = useMutation(userRemoveMutation);

    return (
        <div
            key={user.id}
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px 4px',
            }}
        >
            <div style={{ flex: 1 }}>
                <EditInPlaceField
                    value={user.firstname}
                    fluid
                    onUpdate={(firstname, callback) => {
                        onChange({ firstname }, callback);
                    }}
                    placeholder="First Name"
                />
            </div>
            <div style={{ flex: 1 }}>
                <EditInPlaceField
                    value={user.lastname}
                    fluid
                    onUpdate={(lastname, callback) => {
                        onChange({ lastname }, callback);
                    }}
                    placeholder="Last Name"
                />
            </div>
            <div style={{ flex: 2 }}>
                <EditInPlaceField
                    value={user.title}
                    fluid
                    onUpdate={(title, callback) => {
                        onChange({ title }, callback);
                    }}
                    placeholder="Title"
                />
            </div>
            <div style={{ flex: 3 }}>
                <EditInPlaceField
                    value={user.email}
                    fluid
                    onUpdate={(email, callback) => {
                        onChange({ email }, callback);
                    }}
                    placeholder="Email"
                />
            </div>
            <div style={{ flex: 1 }}>
                <EditInPlaceField
                    value={user.phone_work}
                    fluid
                    onUpdate={(phone_work, callback) => {
                        onChange({ phone_work }, callback);
                    }}
                    placeholder="Work Phone"
                />
            </div>
            <div style={{ flex: 1 }}>
                {!isNew ? (
                    <Checkbox
                        toggle
                        checked={user.is_admin}
                        onChange={() => {
                            onChange({
                                is_admin: !user.is_admin,
                            });
                        }}
                    />
                ) : null}
            </div>
            <div style={{ flex: 1 }}>
                <Checkbox
                    toggle
                    checked={user.permission_level === 'FULL'}
                    onChange={() => {
                        onChange({
                            permission_level:
                                user.permission_level === 'FULL'
                                    ? 'RECEIVE_ONLY'
                                    : 'FULL',
                        });
                    }}
                />
            </div>
            <div
                style={{
                    flex: 1,
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {primaryUser && onPrimaryContactChange ? (
                    <Checkbox
                        toggle
                        disabled={primaryUser.id === user.id}
                        checked={primaryUser.id === user.id}
                        onClick={() => {
                            if (user.id && primaryUser.id !== user.id) {
                                onPrimaryContactChange(user.id);
                            }
                        }}
                    />
                ) : null}
            </div>
            <div
                style={{
                    flex: 1,
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {user.id && !isNew ? (
                    <Popup
                        position="top center"
                        on="click"
                        open={removePopupVisible === user.id}
                        trigger={
                            <Button
                                icon={{ name: 'trash' }}
                                onClick={() => {
                                    setRemovePopupVisible(user.id as string);
                                }}
                            />
                        }
                        content={
                            <div>
                                <div
                                    style={{
                                        marginBottom: '10px',
                                    }}
                                >
                                    Are you sure you want to remove this user?
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Button
                                        primary
                                        fluid
                                        onClick={() => {
                                            removeUser({
                                                variables: {
                                                    id: user.id,
                                                },
                                            }).then(() => {
                                                setRemovePopupVisible('');
                                                usersGql.refetch();
                                            });
                                        }}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        fluid
                                        onClick={() =>
                                            setRemovePopupVisible('')
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        }
                    />
                ) : (
                    <>
                        <Button icon={{ name: 'trash' }} onClick={onRemove} />
                        <Button icon={{ name: 'check' }} onClick={onSave} />
                    </>
                )}
            </div>
        </div>
    );
};

interface UsersModalProps {
    orgid: string | null;
    onClose: () => void;
}

const UsersModal: React.FunctionComponent<UsersModalProps> = ({
    orgid,
    onClose,
}) => {
    const [newUsers, setNewUsers] = useState<Partial<User>[]>([]);
    const usersGql = useQuery(userOrgContactListQuery, {
        variables: {
            orgid,
        },
        skip: !orgid,
    });

    const createUser = useMutation(userCreateMutation);
    const updateOrg = useMutation(orgUpdateMutation);
    const updateUser = useMutation(userUpdateMutation);
    const users = usersGql.loading
        ? []
        : usersGql.data
        ? usersGql.data.homeUserOrgContactList || []
        : [];
    const primaryUser = usersGql.loading
        ? {}
        : usersGql.data
        ? usersGql.data.homePrimaryContact || {}
        : {};

    const handleSaveNewUser = (index: number) => {
        const u = newUsers[index];
        createUser({
            variables: {
                orgid,
                creatingUser: '4011',
                permission_level: 'FULL',
                ...u,
            },
        }).then(() => {
            usersGql.refetch();
            const us = [...newUsers];
            us.splice(index, 1);
            setNewUsers(us);
        });
    };

    const handleUpdateUser = (
        id: string,
        email: string,
        update: any,
        callback = () => {}
    ) => {
        updateUser({
            variables: {
                id,
                email,
                ...update,
            },
        }).then(() => {
            usersGql.refetch();
            callback();
        });
    };

    const handlePrimaryContactChange = (orgid: string, user_id: string) => {
        updateOrg({
            variables: {
                id: orgid,
                primary_contact: user_id,
            },
        }).then(() => {
            usersGql.refetch();
        });
    };

    if (!orgid) return null;

    return (
        <BaseControlledModal open={!!orgid} onClose={onClose} size="fullscreen">
            {usersGql.loading ? (
                <Loader active />
            ) : (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px 4px',
                        }}
                    >
                        <div style={{ flex: 1, fontWeight: 'bold' }}>
                            First Name
                        </div>
                        <div style={{ flex: 1, fontWeight: 'bold' }}>
                            Last Name
                        </div>
                        <div style={{ flex: 2, fontWeight: 'bold' }}>Title</div>
                        <div style={{ flex: 3, fontWeight: 'bold' }}>Email</div>
                        <div style={{ flex: 1, fontWeight: 'bold' }}>Phone</div>
                        <div style={{ flex: 1, fontWeight: 'bold' }}>Admin</div>
                        <div style={{ flex: 1, fontWeight: 'bold' }}>
                            Permission Level
                        </div>
                        <div
                            style={{
                                flex: 1,
                                fontWeight: 'bold',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            Primary?
                        </div>
                        <div
                            style={{
                                flex: 1,
                                fontWeight: 'bold',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            Actions
                        </div>
                    </div>
                    {users.map((user: any) => {
                        return (
                            <UserRow
                                key={user.id}
                                user={user}
                                usersGql={usersGql}
                                onChange={(update, callback) => {
                                    handleUpdateUser(
                                        user.id,
                                        user.email,
                                        update,
                                        callback
                                    );
                                }}
                                primaryUser={primaryUser}
                                onPrimaryContactChange={(user_id) => {
                                    handlePrimaryContactChange(orgid, user_id);
                                }}
                            />
                        );
                    })}
                    {newUsers.map((user, index) => {
                        return (
                            <UserRow
                                user={user}
                                usersGql={usersGql}
                                isNew
                                onChange={(update) => {
                                    const u = [...newUsers];
                                    u[index] = {
                                        ...u[index],
                                        ...update,
                                    };
                                    setNewUsers(u);
                                }}
                                onRemove={() => {
                                    const u = [...newUsers];
                                    u.splice(index, 1);
                                    setNewUsers(u);
                                }}
                                onSave={() => {
                                    handleSaveNewUser(index);
                                }}
                            />
                        );
                    })}
                    <div>
                        <Button
                            primary
                            onClick={() => setNewUsers((u) => [...u, {}])}
                        >
                            Add New User
                        </Button>
                    </div>
                </div>
            )}
        </BaseControlledModal>
    );
};

const TrialDatePicker: React.FC<{
    onChange: (value: string) => void;
    value: string;
}> = ({ value, onChange }) => {
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    return (
        <div
            style={{
                display: 'flex',
                flex: 1,
                cursor: 'pointer',
                height: '100%',
                width: '100%',
                justifyContent: 'center',
            }}
            onClick={() => setShowDatePicker(true)}
        >
            {showDatePicker ? (
                <CustomDatePickerInner
                    hintText="Select Date"
                    hideLocalePicker
                    value={value ? new Date(value) : new Date()}
                    onChange={(data: string) => {
                        setShowDatePicker(false);
                        onChange(data);
                    }}
                />
            ) : value ? (
                formatDate(value, true)
            ) : (
                '+'
            )}
        </div>
    );
};

const integrateQuickbooks: (params: {
    orgid: string;
    userid: string;
    pathname: string;
}) => Promise<void> = async ({ orgid, userid, pathname }) => {
    try {
        const res = await fetch(
            `${urls.apiRoot}/auth-qb?orgid=${orgid}&userid=${userid}&pathname=${pathname}`,
            {
                // mode: 'no-cors',
                cache: 'no-cache',
                // redirect: 'follow',
            }
        );
        const resJson = await res.json();
        if (resJson.location) {
            console.log('redirected');
            // try opening this in a new tab, then refetch orgQuickbooks query
            // could just send a page that says they can close the new tab.
            window.location.href = resJson.location;
        }
        // return res;
    } catch (e) {
        console.error({ e });
    }
};

export const OrgModal: React.FC<{
    open: boolean;
    onClose: () => void;
    orgid: string;
}> = ({ open, onClose, orgid }) => {
    const [year, setYear] = React.useState<number>(2022);
    const orgsUsagePastArgsGql = useQuery(orgsUsageArgsQuery, {
        variables: {
            year: year - 1,
            orgid,
        },
        skip: !orgid,
    });
    const orgsUsageArgsGql = useQuery(orgsUsageArgsQuery, {
        variables: {
            year,
            orgid,
        },
        skip: !orgid,
    });
    const orgsUsage2023 = useQuery(orgsUsageArgsQuery, {
        variables: {
            year: 2023,
            orgid,
        },
        skip: !orgid,
    });
    const orgsUsage2024 = useQuery(orgsUsageArgsQuery, {
        variables: {
            year: 2024,
            orgid,
        },
        skip: !orgid,
    });
    const usage = orgsUsageArgsGql.data?.orgsUsageArgs || {};
    const past = orgsUsagePastArgsGql.data?.orgsUsageArgs || {};
    const usage2023 = orgsUsage2023.data?.orgsUsageArgs || {};
    const usage2024 = orgsUsage2024.data?.orgsUsageArgs || {};
    return (
        <BaseControlledModal
            open={open}
            onClose={onClose}
            size="fullscreen"
            header="Usage"
        >
            <Modal.Content>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div>
                        <div
                            style={{
                                fontWeight: 'bold',
                                marginBottom: '24px;',
                            }}
                        >
                            July 1 2021 - June 30 2022
                        </div>
                        {orgsUsagePastArgsGql.loading ? (
                            `Loading...`
                        ) : (
                            <>
                                <div
                                    style={{ marginBottom: 12 }}
                                >{`All Contests Made: ${past.all || 0}`}</div>
                                <div
                                    style={{ marginBottom: 12 }}
                                >{`All Contests Sent: ${past.sent || 0}`}</div>
                                <div style={{ marginBottom: 12 }}>
                                    <div style={{ fontWeight: 'bold' }}>
                                        Contests Made By Sport:
                                    </div>
                                    <div style={{ marginLeft: '12px' }}>
                                        {Object.entries(past.allBySport || {})
                                            .sort((a, b) =>
                                                a[0].localeCompare(b[0])
                                            )
                                            .map(([sport, num]) => {
                                                return (
                                                    <div>{`${sports[sport]}: ${num}`}</div>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div style={{ marginBottom: 12 }}>
                                    <div style={{ fontWeight: 'bold' }}>
                                        Contests Sent By Sport:
                                    </div>
                                    <div style={{ marginLeft: '12px' }}>
                                        {Object.entries(past.sentBySport || {})
                                            .sort((a, b) =>
                                                a[0].localeCompare(b[0])
                                            )
                                            .map(([sport, num]) => {
                                                return (
                                                    <div>{`${sports[sport]}: ${num}`}</div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div style={{ marginLeft: '48px' }}>
                        <div
                            style={{
                                fontWeight: 'bold',
                                marginBottom: '24px;',
                            }}
                        >{`July 1 ${year} - June 30 ${year + 1}`}</div>
                        {orgsUsageArgsGql.loading ? (
                            <div>Loading...</div>
                        ) : (
                            <>
                                <div
                                    style={{ marginBottom: 12 }}
                                >{`All Contests Made: ${usage.all || 0}`}</div>
                                <div
                                    style={{ marginBottom: 12 }}
                                >{`All Contests Sent: ${usage.sent || 0}`}</div>
                                <div style={{ marginBottom: 12 }}>
                                    <div style={{ fontWeight: 'bold' }}>
                                        Contests Made By Sport:
                                    </div>
                                    <div style={{ marginLeft: '12px' }}>
                                        {Object.entries(usage.allBySport || {})
                                            .sort((a, b) =>
                                                a[0].localeCompare(b[0])
                                            )
                                            .map(([sport, num]) => {
                                                return (
                                                    <div>{`${sports[sport]}: ${num}`}</div>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div style={{ marginBottom: 12 }}>
                                    <div style={{ fontWeight: 'bold' }}>
                                        Contests Sent By Sport:
                                    </div>
                                    <div style={{ marginLeft: '12px' }}>
                                        {Object.entries(usage.sentBySport || {})
                                            .sort((a, b) =>
                                                a[0].localeCompare(b[0])
                                            )
                                            .map(([sport, num]) => {
                                                return (
                                                    <div>{`${sports[sport]}: ${num}`}</div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div style={{ marginLeft: '48px' }}>
                        <div
                            style={{
                                fontWeight: 'bold',
                                marginBottom: '24px;',
                            }}
                        >
                            July 1 2023 - June 30 2024
                        </div>
                        {orgsUsage2023.loading ? (
                            <div>Loading...</div>
                        ) : (
                            <>
                                <div
                                    style={{ marginBottom: 12 }}
                                >{`All Contests Made: ${
                                    usage2023.all || 0
                                }`}</div>
                                <div
                                    style={{ marginBottom: 12 }}
                                >{`All Contests Sent: ${
                                    usage2023.sent || 0
                                }`}</div>
                                <div style={{ marginBottom: 12 }}>
                                    <div style={{ fontWeight: 'bold' }}>
                                        Contests Made By Sport:
                                    </div>
                                    <div style={{ marginLeft: '12px' }}>
                                        {Object.entries(
                                            usage2023.allBySport || {}
                                        )
                                            .sort((a, b) =>
                                                a[0].localeCompare(b[0])
                                            )
                                            .map(([sport, num]) => {
                                                return (
                                                    <div>{`${sports[sport]}: ${num}`}</div>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div style={{ marginBottom: 12 }}>
                                    <div style={{ fontWeight: 'bold' }}>
                                        Contests Sent By Sport:
                                    </div>
                                    <div style={{ marginLeft: '12px' }}>
                                        {Object.entries(
                                            usage2023.sentBySport || {}
                                        )
                                            .sort((a, b) =>
                                                a[0].localeCompare(b[0])
                                            )
                                            .map(([sport, num]) => {
                                                return (
                                                    <div>{`${sports[sport]}: ${num}`}</div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div style={{ marginLeft: '48px' }}>
                        <div
                            style={{
                                fontWeight: 'bold',
                                marginBottom: '24px;',
                            }}
                        >
                            July 1 2024 - June 30 2025
                        </div>
                        {orgsUsage2024.loading ? (
                            <div>Loading...</div>
                        ) : (
                            <>
                                <div
                                    style={{ marginBottom: 12 }}
                                >{`All Contests Made: ${
                                    usage2024.all || 0
                                }`}</div>
                                <div
                                    style={{ marginBottom: 12 }}
                                >{`All Contests Sent: ${
                                    usage2024.sent || 0
                                }`}</div>
                                <div style={{ marginBottom: 12 }}>
                                    <div style={{ fontWeight: 'bold' }}>
                                        Contests Made By Sport:
                                    </div>
                                    <div style={{ marginLeft: '12px' }}>
                                        {Object.entries(
                                            usage2024.allBySport || {}
                                        )
                                            .sort((a, b) =>
                                                a[0].localeCompare(b[0])
                                            )
                                            .map(([sport, num]) => {
                                                return (
                                                    <div>{`${sports[sport]}: ${num}`}</div>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div style={{ marginBottom: 12 }}>
                                    <div style={{ fontWeight: 'bold' }}>
                                        Contests Sent By Sport:
                                    </div>
                                    <div style={{ marginLeft: '12px' }}>
                                        {Object.entries(
                                            usage2024.sentBySport || {}
                                        )
                                            .sort((a, b) =>
                                                a[0].localeCompare(b[0])
                                            )
                                            .map(([sport, num]) => {
                                                return (
                                                    <div>{`${sports[sport]}: ${num}`}</div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Modal.Content>
        </BaseControlledModal>
    );
};

const CreateNewCustomer: (props: {
    orgid: string;
    org: Org;
    onClose: () => void;
    onCloseModal: () => void;
    refetchOrgs: () => Promise<any>;
}) => JSX.Element = ({ orgid, onClose, onCloseModal, refetchOrgs, org }) => {
    const usersGql = useQuery(userOrgContactListQuery, {
        variables: {
            orgid,
        },
        skip: !orgid,
    });
    const { line1, postal_code } = org.addr?.split(',').reduce(
        (acc, item, i) => {
            if (i === 0) {
                acc.line1 = item.trim();
            }
            if (i === 2) {
                acc.postal_code = item.trim().split(' ')?.[1];
            }
            return acc;
        },
        {
            line1: '',
            postal_code: '',
        }
    );
    const [customer, setCustomer] = useState<NewCustomer>({
        email_address: '',
        line1,
        city: org.city,
        region: org.region,
        postal_code,
        country: org.country || 'US',
        first_name: '',
        last_name: '',
        phone: '',
    });

    const users = usersGql.loading
        ? []
        : usersGql.data
        ? usersGql.data.homeUserOrgContactList || []
        : [];
    const primaryUser = usersGql.loading
        ? {}
        : usersGql.data
        ? usersGql.data.homePrimaryContact || {}
        : {};

    useEffect(() => {
        if (primaryUser) {
            setCustomer({
                ...customer,
                email_address: primaryUser.email,
                first_name: primaryUser.firstname,
                last_name: primaryUser.lastname,
                phone: primaryUser.phone_work || primaryUser.phone_cell,
            });
        }
    }, [JSON.stringify(primaryUser)]);

    const createCustomer = useMutation(quickbooksAccountLink);

    const handleCreate = () => {
        const toSave = {
            ...customer,
        };
        createCustomer({
            variables: {
                orgid,
                ...toSave,
            },
        }).then(() => {
            refetchOrgs();
            onClose();
            onCloseModal();
        });
    };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button onClick={onClose}>Close</Button>
                <Button primary onClick={handleCreate}>
                    Save
                </Button>
            </div>
            <div>
                <FormRowInput
                    label="Email"
                    type="text"
                    field="email_address"
                    value={customer.email_address}
                    formUpdate={(update: any) => {
                        setCustomer({
                            ...customer,
                            email_address: update.email_address,
                        });
                    }}
                />
                <FormRowInput
                    label="Contact First Name"
                    type="text"
                    field="first_name"
                    value={customer.first_name}
                    formUpdate={(update: any) => {
                        setCustomer({
                            ...customer,
                            first_name: update.first_name,
                        });
                    }}
                />
                <FormRowInput
                    label="Contact Last Name"
                    type="text"
                    field="last_name"
                    value={customer.last_name}
                    formUpdate={(update: any) => {
                        setCustomer({
                            ...customer,
                            last_name: update.last_name,
                        });
                    }}
                />
                <FormRowInput
                    label="Contact Phone"
                    type="text"
                    field="phone"
                    value={customer.phone}
                    formUpdate={(update: any) => {
                        setCustomer({
                            ...customer,
                            phone: update.phone,
                        });
                    }}
                />
                <FormRowInput
                    label="Address"
                    type="text"
                    field="line1"
                    value={customer.line1}
                    formUpdate={(update: any) => {
                        setCustomer({
                            ...customer,
                            line1: update.line1,
                        });
                    }}
                />
                <FormRowInput
                    label="City"
                    type="text"
                    field="city"
                    value={customer.city}
                    formUpdate={(update: any) => {
                        setCustomer({
                            ...customer,
                            city: update.city,
                        });
                    }}
                />
                <FormRowInput
                    label="State"
                    type="text"
                    field="region"
                    value={customer.region}
                    formUpdate={(update: any) => {
                        setCustomer({
                            ...customer,
                            region: update.region,
                        });
                    }}
                />
                <FormRowInput
                    label="Zip"
                    type="text"
                    field="postal_code"
                    value={customer.postal_code}
                    formUpdate={(update: any) => {
                        setCustomer({
                            ...customer,
                            postal_code: update.postal_code,
                        });
                    }}
                />
                <FormRowInput
                    label="Country"
                    type="text"
                    field="country"
                    value={customer.country}
                    formUpdate={(update: any) => {
                        setCustomer({
                            ...customer,
                            country: update.country,
                        });
                    }}
                />
            </div>
        </div>
    );
};

const QBCustomersModal: React.FC<{
    open: boolean;
    onClose: () => void;
    onSelect: (Id: string) => void;
    onCreate: () => void;
    orgid?: string | null;
    org?: Org;
    refetchOrgs: () => Promise<any>;
}> = ({ open, onClose, onSelect, onCreate, orgid, org }) => {
    const [createNew, setCreateNew] = useState<boolean>(false);
    const qbCustomersGql = useQuery(quickbooksCustomersQuery);
    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>Select QB Customer</Modal.Header>
            <Modal.Content>
                <div>
                    {createNew && orgid && org ? (
                        <CreateNewCustomer
                            onClose={() => setCreateNew(false)}
                            onCloseModal={onClose}
                            orgid={orgid}
                            org={org}
                            refetchOrgs={() => Promise.resolve()}
                        />
                    ) : (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button onClick={() => setCreateNew(true)}>
                                    Cannot Find QB Customer -&gt; Create New
                                </Button>
                            </div>
                            {qbCustomersGql.loading ? (
                                <Loader active />
                            ) : (
                                qbCustomersGql.data?.quickbooksCustomers?.map(
                                    (qbC: QBCustomer, index: number) => {
                                        return (
                                            <div
                                                key={qbC.Id}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                    marginTop: '12px',
                                                    backgroundColor:
                                                        index % 2
                                                            ? colors.lightGrey
                                                            : colors.white,
                                                }}
                                            >
                                                <div>{qbC.DisplayName}</div>
                                                <Button
                                                    onClick={() => {
                                                        onSelect(qbC.Id);
                                                    }}
                                                >
                                                    Select
                                                </Button>
                                            </div>
                                        );
                                    }
                                )
                            )}
                        </>
                    )}
                </div>
            </Modal.Content>
        </Modal>
    );
};
const OrgCreateModal: React.FC<{
    open: boolean;
    onClose: () => void;
    refetchOrgs: () => Promise<any>;
}> = ({ open, onClose, refetchOrgs }) => {
    const createOrg = useMutation(orgCreateMutation);
    const [orgFields, setOrgFields] = useState<Partial<Org>>({});
    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>Create Org</Modal.Header>
            <Modal.Content>
                <Form>
                    <Field>
                        <label>Name</label>
                        <Input
                            onChange={(e, d) =>
                                setOrgFields({ ...orgFields, name: d.value })
                            }
                        />
                    </Field>
                    <Field>
                        <Checkbox
                            label="Client"
                            toggle
                            onChange={(e, d) =>
                                setOrgFields({
                                    ...orgFields,
                                    is_client: d.checked,
                                })
                            }
                        />
                    </Field>
                    <Field>
                        <label>Nick</label>
                        <Input
                            onChange={(e, d) =>
                                setOrgFields({ ...orgFields, nick: d.value })
                            }
                        />
                    </Field>
                    <Field>
                        <label>Team Nick</label>
                        <Input
                            onChange={(e, d) =>
                                setOrgFields({
                                    ...orgFields,
                                    team_nick: d.value,
                                })
                            }
                        />
                    </Field>
                    <Field>
                        <label>Conference</label>
                        <Input
                            onChange={(e, d) =>
                                setOrgFields({
                                    ...orgFields,
                                    conference: d.value,
                                })
                            }
                        />
                    </Field>
                    <Field>
                        <label>Division</label>
                        <Input
                            onChange={(e, d) =>
                                setOrgFields({
                                    ...orgFields,
                                    division: d.value,
                                })
                            }
                        />
                    </Field>
                    <Field>
                        <label>City</label>
                        <Input
                            onChange={(e, d) =>
                                setOrgFields({ ...orgFields, city: d.value })
                            }
                        />
                    </Field>
                    <Field>
                        <label>Region</label>
                        <Input
                            onChange={(e, d) =>
                                setOrgFields({ ...orgFields, region: d.value })
                            }
                        />
                    </Field>
                    <Field>
                        <label>Country</label>
                        <Input
                            onChange={(e, d) =>
                                setOrgFields({ ...orgFields, country: d.value })
                            }
                        />
                    </Field>
                    <Field>
                        <label>Addr</label>
                        <Input
                            onChange={(e, d) =>
                                setOrgFields({ ...orgFields, addr: d.value })
                            }
                        />
                    </Field>
                    <Field>
                        <label>Notes</label>
                        <TextArea
                            onChange={(e, d) =>
                                setOrgFields({
                                    ...orgFields,
                                    notes: d.value as string,
                                })
                            }
                        />
                    </Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => {
                        createOrg({
                            variables: {
                                ...orgFields,
                            },
                        }).then((res) => {
                            refetchOrgs();
                            onClose();
                        });
                    }}
                >
                    Create
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export const Admin = () => {
    const [editOrgSportsModal, setEditOrgSportsModal] = useState<string | null>(
        null
    );
    const [qbCustomersModal, setQbCustomersModal] = useState<string | null>(
        null
    );
    const [invoicesModalOrg, setInvoicesModalOrg] = useState<string | null>(
        null
    );
    const [orgCreateModalOpen, setOrgCreateModalOpen] =
        useState<boolean>(false);
    const [clientsFilter, setClientsFilter] = useState(true);
    const [loadingIsClient, setLoadingIsClient] = useState<
        Record<string, boolean>
    >({});
    const [search, setSearch] = useState<string | number>('');
    const [page, setPage] = useState<number>(1);
    const [usersModalId, setUsersModalId] = useState<string | null>(null);
    const [orgModalId, setOrgModalId] = useState<string>('');
    const orgUpdate = useMutation(orgUpdateMutation);
    const linkQB = useMutation(quickbooksAccountLink);
    const refreshOrgQuickbooks = useMutation(refreshQuickbooksToken, {
        variables: {
            orgid: '5011',
        },
    });
    const orgsGql = useQuery(adminOrgsQuery, {
        variables: {
            clients: clientsFilter,
            page,
            search,
        },
    });

    const orgsLengthGql = useQuery(orgsLengthQuery, {
        variables: {
            clients: clientsFilter,
            search,
        },
    });

    const handleUpdateOrgSports = (billing_sports: any) => {
        orgUpdate({
            variables: {
                id: editOrgSportsModal,
                billing_sports,
            },
        }).then(() => {
            orgsGql.refetch();
        });
    };

    const handleOrgUpdate = (id: string, update: any, callback = () => {}) => {
        console.log({ id, update });
        orgUpdate({
            variables: {
                id,
                ...update,
            },
        }).then(() => {
            orgsGql.refetch().then(() => {
                callback();
            });
        });
    };

    const orgs: Org[] = (orgsGql.data && orgsGql.data.orgs) || [];
    const org = orgs.find((o: any) => o.id === editOrgSportsModal);
    const invoicesOrg = orgs.find((o: any) => o.id === invoicesModalOrg);
    const billing_sports = (org && org.billing_sports) || '[]';
    const parsedSports = JSON.parse(billing_sports);

    const invoiceColumnWidth = 72;

    return (
        <div
            style={{
                padding: '24px 48px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '12px',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <BlurInput
                        placeholder="Search..."
                        value={search}
                        onBlurChange={(newSearch) => {
                            setSearch(newSearch);
                        }}
                    />
                    {search && (
                        <div
                            style={{
                                marginLeft: '12px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {orgsLengthGql?.data?.orgsLength || 0} Results
                        </div>
                    )}
                    {orgsLengthGql?.data?.orgsLength > 250 && (
                        <div
                            style={{
                                marginLeft: '12px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {`Showing ${orgs.length * (page - 1)} - ${
                                orgs.length * page
                            } of ${orgsLengthGql?.data?.orgsLength}`}
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                        }}
                    >
                        All
                        <div style={{ margin: '0 8px' }}>
                            <Checkbox
                                toggle
                                checked={clientsFilter}
                                onClick={() => {
                                    setClientsFilter(!clientsFilter);
                                }}
                            />
                        </div>
                        Clients Only
                    </div>
                    <div
                        style={{
                            marginLeft: '12px',
                        }}
                    >
                        <Button
                            primary
                            onClick={() => {
                                setOrgCreateModalOpen(true);
                            }}
                        >
                            Create Org
                        </Button>
                    </div>
                    <div
                        style={{
                            marginLeft: '12px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            <Button
                                onClick={() => {
                                    integrateQuickbooks({
                                        orgid: '5011',
                                        userid: '4011',
                                        pathname: location.pathname,
                                    });
                                }}
                            >
                                Integrate QB
                            </Button>
                            <Button
                                onClick={() => {
                                    refreshOrgQuickbooks();
                                }}
                            >
                                Refresh QB
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 4px',
                        backgroundColor: colors.white,
                    }}
                >
                    <div
                        style={{
                            width: '40px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                        }}
                    >
                        PRID
                    </div>
                    <div
                        style={{
                            width: '40px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                        }}
                    >
                        QBID
                    </div>
                    <div
                        style={{
                            flex: 3,
                            fontWeight: 'bold',
                            textAlign: 'left',
                        }}
                    >
                        Organization
                    </div>
                    <div
                        style={{
                            flex: 1,
                            fontWeight: 'bold',
                            textAlign: 'left',
                        }}
                    >
                        Conference
                    </div>
                    <div
                        style={{
                            flex: 2,
                            fontWeight: 'bold',
                            textAlign: 'left',
                        }}
                    >
                        Notes
                    </div>
                    <div
                        style={{
                            flex: 1,
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        Client
                    </div>
                    <div
                        style={{
                            flex: 1,
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        Free Trial End Date
                    </div>

                    <div
                        style={{
                            flex: 1,
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        # Sports
                    </div>
                    <div
                        style={{
                            flex: 1,
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        $ / Sport
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: `${invoiceColumnWidth * 3}px`,
                            fontWeight: 'bold',
                            alignItems: 'center',
                        }}
                    >
                        <div>Invoices</div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '8px',
                            }}
                        >
                            <div
                                style={{
                                    width: `${invoiceColumnWidth}px`,
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                2022
                            </div>
                            <div
                                style={{
                                    width: `${invoiceColumnWidth}px`,
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                2023
                            </div>
                            <div
                                style={{
                                    width: `${invoiceColumnWidth}px`,
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                2024
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            width: '84px',
                            fontWeight: 'bold',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        Actions
                    </div>
                </div>
                {orgs.map((org: Org, index: number) => {
                    const billing_sports = org.billing_sports;
                    const parsedBillingSports = billing_sports
                        ? JSON.parse(billing_sports)
                        : [];
                    const invoices = org.invoices;
                    const invoice2022 = invoices?.find(
                        (i) => i.DocNumber.split('-')?.[0] === `2022`
                    );
                    const invoice2023 = invoices?.find(
                        (i) => i.DocNumber.split('-')?.[0] === `2023`
                    );
                    const invoice2024 = invoices?.find(
                        (i) => i.DocNumber.split('-')?.[0] === `2024`
                    );

                    return (
                        <div
                            key={org.id}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '8px 4px',
                                backgroundColor:
                                    index % 2 ? '#f4f4f4' : 'white',
                            }}
                        >
                            <div
                                style={{
                                    width: '40px',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                }}
                            >
                                {org.id}
                            </div>
                            <div
                                style={{
                                    width: '40px',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                }}
                            >
                                {org.quickbooks_customer_id || (
                                    <Button
                                        onClick={() => {
                                            setQbCustomersModal(org.id);
                                        }}
                                        icon={{
                                            name: 'chain',
                                        }}
                                    />
                                )}
                            </div>
                            <div
                                style={{
                                    flex: 3,
                                    cursor: 'pointer',
                                    textAlign: 'left',
                                }}
                                onClick={() => setOrgModalId(org.id)}
                            >
                                {org.name}
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    textAlign: 'left',
                                }}
                            >
                                <Form>
                                    <EditInPlaceField
                                        value={org.conference}
                                        fluid
                                        multiline
                                        presentOneLine
                                        onUpdate={(conference, callback) => {
                                            handleOrgUpdate(
                                                org.id,
                                                {
                                                    conference,
                                                },
                                                callback
                                            );
                                        }}
                                        placeholder="Conference"
                                    />
                                </Form>
                            </div>
                            <div
                                style={{
                                    flex: 2,
                                    textAlign: 'left',
                                }}
                            >
                                <Form>
                                    <EditInPlaceField
                                        value={org.notes}
                                        fluid
                                        multiline
                                        presentOneLine
                                        Component={TextArea}
                                        onUpdate={(notes, callback) => {
                                            handleOrgUpdate(
                                                org.id,
                                                {
                                                    notes,
                                                },
                                                callback
                                            );
                                        }}
                                        placeholder="Notes"
                                    />
                                </Form>
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                {loadingIsClient[org.id] ? (
                                    <Loader active inline size="mini" />
                                ) : (
                                    <Checkbox
                                        toggle
                                        checked={org.is_client}
                                        onClick={() => {
                                            setLoadingIsClient({
                                                ...loadingIsClient,
                                                [org.id]: true,
                                            });
                                            handleOrgUpdate(
                                                org.id,
                                                {
                                                    is_client: !org.is_client,
                                                },
                                                () => {
                                                    setLoadingIsClient(
                                                        (prevIsLoading) => ({
                                                            ...prevIsLoading,
                                                            [org.id]: false,
                                                        })
                                                    );
                                                }
                                            );
                                        }}
                                    />
                                )}
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <TrialDatePicker
                                    value={org?.trial_end_date}
                                    onChange={(date: string) =>
                                        handleOrgUpdate(org.id, {
                                            trial_end_date: date,
                                        })
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setEditOrgSportsModal(org.id);
                                }}
                            >
                                {org.billing_sports_num ||
                                    parsedBillingSports.length ||
                                    0}
                            </div>
                            <div style={{ flex: 1 }}>
                                <EditInPlaceField
                                    value={org.billing_price_per_sport}
                                    fluid
                                    formatter={(value: number) => {
                                        return formatDollar({
                                            amount: value,
                                        });
                                    }}
                                    onUpdate={(
                                        billing_price_per_sport,
                                        callback
                                    ) => {
                                        handleOrgUpdate(org.id, {
                                            billing_price_per_sport:
                                                billing_price_per_sport
                                                    ? parseFloat(
                                                          billing_price_per_sport
                                                      )
                                                    : 0,
                                        });
                                    }}
                                    placeholder="$ / Sport"
                                />
                            </div>
                            <div
                                style={{
                                    width: `${invoiceColumnWidth}px`,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Popup
                                    trigger={
                                        <Icon
                                            size="big"
                                            style={{
                                                cursor: !invoice2024
                                                    ? 'pointer'
                                                    : 'default',
                                            }}
                                            onClick={() => {
                                                if (!invoice2022) {
                                                    handleOrgUpdate(org.id, {
                                                        conf_bill:
                                                            !org.conf_bill,
                                                    });
                                                }
                                            }}
                                            {...icons[
                                                org.conf_bill
                                                    ? 'skip'
                                                    : invoice2022
                                                    ? invoice2022.Balance === 0
                                                        ? 'check'
                                                        : 'minus'
                                                    : 'none'
                                            ]?.()}
                                        />
                                    }
                                    on="hover"
                                    content={
                                        org.conf_bill ? (
                                            'Billed by Conference'
                                        ) : invoice2022 ? (
                                            <div style={{ width: '300px' }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Doc Number:</div>
                                                    <div>
                                                        {invoice2022.DocNumber}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Total Amt:</div>
                                                    <div>{`$${invoice2022.TotalAmt}`}</div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Balance:</div>
                                                    <div>{`$${invoice2022.Balance}`}</div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Recipient:</div>
                                                    <div>
                                                        {
                                                            invoice2022
                                                                .BillEmail
                                                                .Address
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Txn Date:</div>
                                                    <div>
                                                        {new Date(
                                                            invoice2022.TxnDate
                                                        ).toLocaleDateString()}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Due Date:</div>
                                                    <div>
                                                        {new Date(
                                                            invoice2022.DueDate
                                                        ).toLocaleDateString()}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            'No Invoice'
                                        )
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    width: `${invoiceColumnWidth}px`,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Popup
                                    trigger={
                                        <Icon
                                            size="big"
                                            style={{
                                                cursor: !invoice2024
                                                    ? 'pointer'
                                                    : 'default',
                                            }}
                                            onClick={() => {
                                                if (!invoice2023) {
                                                    handleOrgUpdate(org.id, {
                                                        conf_bill:
                                                            !org.conf_bill,
                                                    });
                                                }
                                            }}
                                            {...icons[
                                                org.conf_bill
                                                    ? 'skip'
                                                    : invoice2023
                                                    ? invoice2023.Balance === 0
                                                        ? 'check'
                                                        : 'minus'
                                                    : 'none'
                                            ]?.()}
                                        />
                                    }
                                    on="hover"
                                    content={
                                        org.conf_bill ? (
                                            'Billed by Conference'
                                        ) : invoice2023 ? (
                                            <div style={{ width: '300px' }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Doc Number:</div>
                                                    <div>
                                                        {invoice2023.DocNumber}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Total Amt:</div>
                                                    <div>{`$${invoice2023.TotalAmt}`}</div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Balance:</div>
                                                    <div>{`$${invoice2023.Balance}`}</div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Recipient:</div>
                                                    <div>
                                                        {
                                                            invoice2023
                                                                .BillEmail
                                                                .Address
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Txn Date:</div>
                                                    <div>
                                                        {new Date(
                                                            invoice2023.TxnDate
                                                        ).toLocaleDateString()}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Due Date:</div>
                                                    <div>
                                                        {new Date(
                                                            invoice2023.DueDate
                                                        ).toLocaleDateString()}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            'No Invoice'
                                        )
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    width: `${invoiceColumnWidth}px`,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Popup
                                    trigger={
                                        <Icon
                                            size="big"
                                            style={{
                                                cursor: !invoice2024
                                                    ? 'pointer'
                                                    : 'default',
                                            }}
                                            onClick={() => {
                                                if (!invoice2024) {
                                                    handleOrgUpdate(org.id, {
                                                        conf_bill:
                                                            !org.conf_bill,
                                                    });
                                                }
                                            }}
                                            {...icons[
                                                org.conf_bill
                                                    ? 'skip'
                                                    : invoice2024
                                                    ? invoice2024.Balance === 0
                                                        ? 'check'
                                                        : 'minus'
                                                    : 'none'
                                            ]?.()}
                                        />
                                    }
                                    on="hover"
                                    content={
                                        org.conf_bill ? (
                                            'Billed by Conference'
                                        ) : invoice2024 ? (
                                            <div style={{ width: '300px' }}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Doc Number:</div>
                                                    <div>
                                                        {invoice2024.DocNumber}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Total Amt:</div>
                                                    <div>{`$${invoice2024.TotalAmt}`}</div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Balance:</div>
                                                    <div>{`$${invoice2024.Balance}`}</div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Recipient:</div>
                                                    <div>
                                                        {
                                                            invoice2024
                                                                .BillEmail
                                                                .Address
                                                        }
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Txn Date:</div>
                                                    <div>
                                                        {new Date(
                                                            invoice2024.TxnDate
                                                        ).toLocaleDateString()}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                    }}
                                                >
                                                    <div>Due Date:</div>
                                                    <div>
                                                        {new Date(
                                                            invoice2024.DueDate
                                                        ).toLocaleDateString()}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            'No Invoice'
                                        )
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    width: '84px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Button
                                    icon={{ name: 'users' }}
                                    onClick={() => {
                                        setUsersModalId(org.id);
                                    }}
                                />
                                <Button
                                    icon={{
                                        name: 'money bill alternate outline',
                                    }}
                                    onClick={() => {
                                        setInvoicesModalOrg(org.id);
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
            {orgsLengthGql?.data?.orgsLength > 250 && (
                <Pagination
                    activePage={page}
                    onPageChange={(e, data) => {
                        setPage(data.activePage as number);
                    }}
                    totalPages={Math.ceil(
                        orgsLengthGql?.data?.orgsLength / 250
                    )}
                />
            )}
            <QBCustomersModal
                orgid={qbCustomersModal}
                org={orgs.find((o: any) => o.id === qbCustomersModal)}
                open={!!qbCustomersModal}
                refetchOrgs={orgsGql.refetch}
                onClose={() => setQbCustomersModal(null)}
                onSelect={(quickbooks_customer_id: string) => {
                    linkQB({
                        variables: {
                            quickbooks_customer_id,
                            orgid: qbCustomersModal,
                        },
                    }).then(() => {
                        orgsGql.refetch();
                        setQbCustomersModal(null);
                    });
                }}
                onCreate={() => {
                    linkQB({
                        variables: {
                            orgid: qbCustomersModal,
                        },
                    }).then(() => {
                        orgsGql.refetch();
                        setQbCustomersModal(null);
                    });
                }}
            />
            <BaseControlledModal
                header="Billing Sports"
                size="tiny"
                open={!!editOrgSportsModal}
                onClose={() => setEditOrgSportsModal(null)}
            >
                {org ? (
                    <>
                        <div
                            style={{
                                padding: '16px 0',
                                marginBottom: '16px',
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: 'bold',
                                    marginBottom: '8px',
                                }}
                            >
                                Billing Sports Override
                            </div>
                            <BlurInput
                                value={org?.billing_sports_num || ''}
                                onBlurChange={(value) => {
                                    if (value) {
                                        handleOrgUpdate(org.id, {
                                            billing_sports_num: parseInt(
                                                `${value}`,
                                                10
                                            ),
                                        });
                                    } else {
                                        handleOrgUpdate(org.id, {
                                            billing_sports_num: 0,
                                        });
                                    }
                                }}
                            />
                        </div>
                        <div
                            style={{
                                marginBottom: '16px',
                            }}
                        >
                            or select sports below
                        </div>
                        {Object.keys(sportsAlphabetized)
                            .sort((a, b) => {
                                return sportsAlphabetized[a] <
                                    sportsAlphabetized[b]
                                    ? -1
                                    : 1;
                            })
                            .map((sport, index) => {
                                const checked = parsedSports.includes(sport);

                                return (
                                    <SportToggle
                                        key={index}
                                        sport={sport}
                                        checked={checked}
                                        onChange={() => {
                                            let newSports = [...parsedSports];
                                            if (checked) {
                                                let sportIndex =
                                                    parsedSports.indexOf(sport);
                                                newSports.splice(sportIndex, 1);
                                            } else {
                                                newSports.push(sport);
                                            }
                                            handleUpdateOrgSports(
                                                JSON.stringify(newSports)
                                            );
                                        }}
                                    />
                                );
                            })}
                        <SportToggle
                            key="Misc"
                            sport="Misc"
                            checked={parsedSports.includes('Misc')}
                            onChange={() => {
                                let newSports = [...parsedSports];
                                if (parsedSports.includes('Misc')) {
                                    let sportIndex =
                                        parsedSports.indexOf('Misc');
                                    newSports.splice(sportIndex, 1);
                                } else {
                                    newSports.push('Misc');
                                }
                                handleUpdateOrgSports(
                                    JSON.stringify(newSports)
                                );
                            }}
                        />
                    </>
                ) : null}
            </BaseControlledModal>
            <OrgCreateModal
                refetchOrgs={orgsGql.refetch}
                open={orgCreateModalOpen}
                onClose={() => setOrgCreateModalOpen(false)}
            />
            <InvoicesModal
                open={!!invoicesModalOrg}
                onClose={() => setInvoicesModalOrg(null)}
                org={invoicesOrg || ({} as Org)}
            />
            <UsersModal
                orgid={usersModalId}
                onClose={() => {
                    setUsersModalId(null);
                }}
            />
            <OrgModal
                open={!!orgModalId}
                onClose={() => setOrgModalId('')}
                orgid={orgModalId}
            />
        </div>
    );
};
