import { GOOGLE_API_KEY } from "../settings"

const google = window.google;
let map;

const addMarker = (map, position, title) => {
    console.log({position, title})
    const infowindow = new google.maps.InfoWindow({
        content: title
    });
    const marker = new google.maps.Marker({
        position,
        map,
        title
    })
    marker.addListener('click', () => {
        infowindow.open(map, marker)
    })
}

const callback = (requests, map, service) => {
    for (let i = 0; i < requests.length; i++) {
        (function (j, timeout) {
            setTimeout(() => {
                service.findPlaceFromQuery(requests[j], (results, status) => {
                    console.log({status})
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        addMarker(map, results[0].geometry.location, requests[j].query)
                    }
                })
            }, timeout*1000)
        })(i, Math.floor(i/9))
    }
}

export const placeSearch = (orgs) => {
    if (google) {
        const centerUS = new google.maps.LatLng(39.8283, -98.5795);

        map = new google.maps.Map(
            document.getElementById('map'), {center: centerUS, zoom: 4});
        orgs.forEach(org => {
            if (!org.hide_client) {
                addMarker(map, JSON.parse(org.location), org.name)
            }
        })
    }
}