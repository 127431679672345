import React, {Component, createRef, useState} from 'react';
import {Mutation} from 'react-apollo';
import { useMutation } from 'react-apollo-hooks';
import {
    Button,
    Checkbox,
    Form,
    Popup,
} from 'semantic-ui-react';
import {toast} from 'react-toastify';

import { contestRemoveMutation, contestUpdateMutation, contestCancelMutation } from '../../gql/contest';
import s3, {albumBucketName } from '../../utils/s3';
import {
    sportSelectOptions
} from '../../utils/sports';

import {
    BaseModal, 
    BaseControlledModal,
    ModalAction,
    ModalSelect,
} from './modals';

import {
    FormRow,
    FormRowDropdown,
    FormRowInput,
    FormRowTextArea
} from '../utilityComponents/form/formRow';

import {
    CustomDatePicker,
    CustomTimePicker,
} from '../utilityComponents/form/materialUI';
import { statuses } from '../../utils/statuses';
import { requestCopyMutation } from '../../gql/contract';
import RequestCopyButtons from '../utilityComponents/requestCopy';

  
const offPlatformExchangeInfoText = (
    <div>
        <div>
        If you plan to exchange this contract outside of our electronic contract
        exchange system (through US Mail, for example), you can still record the
        contract's status within our system &mdash; check this box to do so.
        </div>
    </div>
);

const paperContractReceivedInfoText = (
    <div>
        <div>
        Check the <strong>Paper contract has been received</strong> box if you have
        received this contract from the opposing school <em>outside</em> of our
        electronic contract exchange system. Checking this
        box will change the status of this contract to a green check (i.e. complete).
        </div>
    </div>
);

const paperContractSentInfoText = (
    <div>
        <div>
        Check the <strong>Paper contract has been sent</strong> box if you have
        sent this contract to the opposing school <em>outside</em> of our
        electronic contract exchange system, but have not yet received the
        completed, signed contract back from the opposing school. Checking this
        box will change the status of this contract to a yellow line (i.e. in
        transit).
        </div>
    </div>
);

const paperContractCompleteInfoText = (
    <div>
        <div>
        Check the <strong>Paper contract complete</strong> box if you have
        received a completed, signed contract back from the opposing school{" "}
        <em>outside</em> of our electronic exchange system. Checking this box will
        change the status of this contact to a green checkmark.
        </div>
    </div>
);

let uploadContract = (contest, away=true, updateFunction, updateMeta={}) => {
    return new Promise((resolve, reject) => {
        var files = document.getElementById(`${contest.id}-contract-upload`).files;
        var file = files[0];
        var fileName = file.name;
        const params = {
			Body: file,
			Bucket: albumBucketName, 
			Key: `${contest.guid}.${file.type.split('/')[1]}`
		};
		s3.upload(params, (err, data) => {
		  	if (err) {
                reject(err.message)
				return alert('There was an error uploading your file: ', err.message);
			}
			// data.location = entire url
            // data.key = just the key within the contest-pdfs bucket
            updateFunction({
                variables: {
                    id: contest.id,
                    paper_contract_received: true,
                    paper_contract_key: data.key,
                    status: away ? statuses.AWAY_PLACEHOLDER_PAPER_RECEIVED : statuses.HOME_PAPER_CONTRACT_RECEIVED,
                    updateMeta
                }
            }).then(({data}) => {
                resolve(data)
            })
		});
    })
}

let AwayContestFields = props => {
    return (
        <div>
            {/* 
                paper contract received
                    upload received

                download uploaded
            */}
            <FormRow
                label='Select Opponent'
                required
            >
                <ModalSelect
                    placeholder='Opponent'
                    name='orgid'
                    value={props.contest.orgid}
                    options={props.opponentOptions}
                    onChange={(name, data) => props.contestChange(name, data.value)} />
            </FormRow>
            <FormRow
                label='Sport'
                required
            >
                <ModalSelect
                    placeholder='Select sport'
                    name='sport'
                    multiple={true}
                    value={props.contest.sport}
                    options={sportSelectOptions}
                    onChange={props.contestChange} 
                />
            </FormRow>
            <FormRow
                label={'Date'}
                required
            >
                <CustomDatePicker 
                    hintText="Date"
                    user={props.user}
                    value={getDate(props.contest.date)}
                    onChange={(data) => {props.contestChange('date', data)}}
                />
            </FormRow>
            <FormRow
                label={'Time'}
            >
                <CustomTimePicker 
                    hintText="Time - TBA"
                    minutesStep={5}
                    hour={props.contest.hour}
                    minute={props.contest.minute}
                    setTBA={() => {
                        props.contestChange('hour', 24);
                        props.contestChange('minute', 0);
                    }}
                    value={new Date(2018, 6, 1, props.contest.hour, props.contest.minute)}
                    onChange={(e, data) => {
                        props.contestChange('hour', data.getHours())
                        props.contestChange('minute', data.getMinutes())
                    }}
                />
            </FormRow>
            <FormRowInput 
                label={'Description'}
                field={'away_placeholder_description'}
                value={props.contest.away_placeholder_description}
                type='text'
                formUpdate={update => {
                    props.contestChange('away_placeholder_description', update['away_placeholder_description'])
                }}
            />
            <FormRow
                label={'Paper contract received:'}
                info={paperContractReceivedInfoText}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                
                    <Checkbox 
                        checked={props.contest.paper_contract_received}
                        onChange={(e, data) => {
                            props.contestChange(
                                'paper_contract_received', !props.contest.paper_contract_received
                            )
                            props.contestChange(
                                'status', 
                                props.contest.paper_contract_received ? statuses.AWAY_PLACEHOLDER_PAPER_RECEIVED : statuses.AWAY_PLACEHOLDER
                            )
                        }}
                    />
                </div>
            </FormRow>
            {props.contest.paper_contract_received && (
                <FormRow
                    label={'Paper contract upload:'}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        {props.contest.paper_contract_key && (
                            <Button
                                onClick={() => {
                                    props.viewUploadedContract()
                                }}
                            >
                                View Uploaded Contract
                            </Button>
                        )}
                        <div>
                            <Button
                                style={{
                                    position: 'relative',
                                    cursor: 'pointer'
                                }}
                            >
                                <label 
                                    htmlFor={`${props.contest.id}-contract-upload`} 
                                    style={{
                                        position: 'absolute',
                                        cursor: 'pointer',
                                        top: 0,
                                        right: 0,
                                        left: 0,
                                        bottom: 0,
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    {`Upload ${props.contest.paper_contract_key ? 'a new ' : ''}Contract`}
                                </label>
                                <div
                                    style={{
                                        color: 'transparent'
                                    }}
                                >
                                    {`Upload ${props.contest.paper_contract_key ? 'a new ' : ''}Contract`}
                                </div>
                            </Button>
                            
                            <input 
                                type='file' 
                                style={{display: 'none'}}
                                id={`${props.contest.id}-contract-upload`} 
                                onChange={() => {
                                    let updateMeta = JSON.stringify({
                                        user_email: props.user.email,
                                        user_id: props.user.id
                                    })
                                    uploadContract(props.contest, true, props.contestUpdate, updateMeta).then((data) => {
                                        props.contestsRefetch();
                                        props.onClose();
                                    })
                                }}
                            />
                        </div>
                    </div>
                </FormRow>
            )}
        </div>
    )
}

let additionalFields = [
    {
        key: 'head_coach',
        label: 'Head Coach',
        input: FormRowInput,
        inputType: 'input'
    },
    {
        key: 'head_coach_email',
        label: 'Head Coach Email',
        input: FormRowInput,
        inputType: 'input'
    },
    {
        key: 'head_coach_phone',
        label: 'Head Coach Phone',
        input: FormRowInput,
        inputType: 'input'
    },
    {
        key: 'season',
        label: 'Season',
        input: FormRowDropdown,
        inputType: 'select',
        options: [
            {value: '', text: ''}, 
            {value: 'FALL', text: 'Fall'}, 
            {value: 'WINTER', text: 'Winter'}, 
            {value: 'SPRING', text: 'Spring'}
        ]
    },
    {
        key: 'comp_level',
        label: 'Competition Level',
        input: FormRowInput,
        inputType: 'input'
    },
    {
        key: 'cancellation_terms',
        label: 'Cancellation Terms',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
    {
        key: 'force_majeure',
        label: 'Force Majeure',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
    {
        key: 'comp_tickets',
        label: 'Complimentary Tickets',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
    {
        key: 'cons_tickets',
        label: 'Consignment Tickets',
        input: FormRowInput,
        inputType: 'input'
    },
    {
        key: 'governing_rules',
        label: 'Governing Rules',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
    {
        key: 'radio_rights',
        label: 'Radio Rights',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
    {
        key: 'tv_rights',
        label: 'Video Rights',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
    {
        key: 'broadcast_rights',
        label: 'Broadcast Rights',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
]

const getDate = contestDate => {
    let date = new Date(contestDate);
    let timeZoneOffset = date.getTimezoneOffset();
    let timeZoneOffsetHours = timeZoneOffset / 60;
    date.setHours(date.getHours() + timeZoneOffsetHours);
    return date
}

class HomeContestFields extends Component {
    state = {
        expanded: false
    }
    checkboxRef = createRef();
    render() {
        let {sportsFilterOptions} = this.props;
        return (
            <div>
                <FormRow
                    label={'Opponent'}
                    required
                >
                    <ModalSelect
                        placeholder='Select opponent'
                        name='away_orgid'
                        value={this.props.contest.away_orgid}
                        options={this.props.opponentOptions}
                        onChange={(name, data) => this.props.contestChange(name, data.value)} 
                    />
                </FormRow>
                <FormRow
                    label={'Sport'}
                    required
                >
                    <ModalSelect
                        placeholder='Select sport'
                        name='sport'
                        value={this.props.contest.sport}
                        options={sportsFilterOptions}
                        onChange={this.props.contestChange} 
                    />
                </FormRow>
                <FormRow
                    label={'Date'}
                    required
                >
                    <CustomDatePicker 
                        hintText="Select Date"
                        user={this.props.user}
                        value={getDate(this.props.contest.date)}
                        onChange={(data) => {this.props.contestChange('date', data)}}
                    />
                </FormRow>
                <FormRow
                    label={'Time'}
                >
                    <CustomTimePicker 
                        hintText="Select Time"
                        minutesStep={5}
                        hour={this.props.contest.hour}
                        minute={this.props.contest.minute}
                        setTBA={() => {
                            this.props.contestChange('hour', 24);
                            this.props.contestChange('minute', 0);
                        }}
                        value={new Date(2018, 6, 1, this.props.contest.hour, this.props.contest.minute)}
                        onChange={(e, data) => {
                            this.props.contestChange('hour', data.getHours())
                            this.props.contestChange('minute', data.getMinutes())
                        }}
                    />
                </FormRow>
                <Form>
                    <FormRowInput
                        label={'Location'}
                        field={'location'}
                        type='text'
                        value={this.props.contest.location}
                        formUpdate={update => {
                            this.props.contestChange('location', update['location'])
                        }}
                    />
                    <FormRowInput
                        label={'Officials'}
                        field={'officials'}
                        type='text'
                        value={this.props.contest.officials}
                        formUpdate={update => {
                            this.props.contestChange('officials', update['officials'])
                        }}
                    />
                    <FormRowInput
                        label={'Description'}
                        field={'description'}
                        type='text'
                        value={this.props.contest.description}
                        formUpdate={update => {
                            this.props.contestChange('description', update['description'])
                        }}
                    />
                    <FormRowTextArea
                        label={'Other Conditions'}
                        field={'other_conditions'}
                        type='text'
                        value={this.props.contest.other_conditions}
                        formUpdate={update => {
                            this.props.contestChange('other_conditions', update['other_conditions'])
                        }}
                    />
                    
                    <FormRowTextArea
                        label={'Future Commitment'}
                        field={'future'}
                        type='text'
                        value={this.props.contest.future}
                        formUpdate={update => {
                            this.props.contestChange('future', update['future'])
                        }}
                    />
                    <FormRowTextArea
                        label={'Guarantee'}
                        field={'guarantee'}
                        type='text'
                        value={this.props.contest.guarantee}
                        formUpdate={update => {
                            this.props.contestChange('guarantee', update['guarantee'])
                        }}
                    />
                    <FormRow
                        label={'Contract to be exchanged off Perfect Record Platform'}
                        info={offPlatformExchangeInfoText}
                        reversed
                        style={{
                            marginTop: '10px'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-end'
                            }}
                        >
                            <Popup 
                                trigger={(
                                    <Checkbox 
                                        checked={this.props.contest.paper_contract_exchange}
                                        onChange={(e, data) => {
                                            let paper_contract_exchange = !this.props.contest.paper_contract_exchange;
                                            if (this.props.contest.status !== statuses.EDITED && paper_contract_exchange) {
                                                this.setState({confirmPaperContract: true})
                                            } else {
                                                this.props.contestChange(
                                                    'paper_contract_exchange', paper_contract_exchange
                                                )
                                                this.props.contestChange(
                                                    'status', 
                                                    paper_contract_exchange ? statuses.HOME_PAPER_CONTRACT : statuses.EDITED
                                                )
                                            }
                                            
                                        }}
                                    />
                                )}
                                // context={this.checkboxRef}
                                onClose={() => this.setState({confirmPaperContract: false})}
                                on='click'
                                onOpen={() => {
                                    if (this.props.contest.status !== statuses.EDITED && !this.props.contest.paper_contract_exchange) {
                                        this.setState({confirmPaperContract: true})
                                    } else {
                                        this.setState({confirmPaperContract: false})
                                    }
                                }}
                                open={this.state.confirmPaperContract}
                                position='top center'
                                content={(
                                    <div
                                        style={{
                                            padding: '5px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        This contest has already been submitted electronically. By turning this on, the contract will not be able to be signed online. And if you toggle off you will have to resubmit.
                                        <Button
                                            primary
                                            onClick={() => {
                                                let paper_contract_exchange = !this.props.contest.paper_contract_exchange;
                                                this.props.contestChange(
                                                    'paper_contract_exchange', paper_contract_exchange
                                                )
                                                this.props.contestChange(
                                                    'status', 
                                                    paper_contract_exchange ? statuses.HOME_PAPER_CONTRACT : statuses.EDITED
                                                )
                                                this.setState({confirmPaperContract: false})
                                            }}
                                        >
                                            Update anyway
                                        </Button>
                                    </div>
                                )}
                            />
                            {/* <Checkbox 
                                ref={this.checkboxRef}
                                checked={this.props.contest.paper_contract_exchange}
                                onChange={(e, data) => {
                                    let paper_contract_exchange = !this.props.contest.paper_contract_exchange;
                                    if (this.props.contest.status !== statuses.EDITED && paper_contract_exchange) {
                                        this.setState({confirmPaperContract: true})
                                    } else {
                                        this.props.contestChange(
                                            'paper_contract_exchange', paper_contract_exchange
                                        )
                                        this.props.contestChange(
                                            'status', 
                                            paper_contract_exchange ? statuses.HOME_PAPER_CONTRACT : statuses.EDITED
                                        )
                                    }
                                    
                                }}
                            /> */}
                            
                        </div>
                    </FormRow>
                    {this.props.contest.paper_contract_exchange && (
                        <div>
                            <FormRow
                                label={'Paper contract has been sent'}
                                info={paperContractSentInfoText}
                                reversed
                                style={{
                                    marginTop: '10px'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-end'
                                    }}
                                >
                                    <Checkbox 
                                        checked={this.props.contest.paper_contract_sent}
                                        onChange={(e, data) => {
                                            this.props.contestChange(
                                                'paper_contract_sent', !this.props.contest.paper_contract_sent
                                            )
                                            this.props.contestChange(
                                                'status', 
                                                this.props.contest.paper_contract_sent ? statuses.HOME_PAPER_CONTRACT_SENT : statuses.HOME_PAPER_CONTRACT
                                            )
                                        }}
                                    />
                                </div>
                            </FormRow>
                            <FormRow
                                label={'Paper contract complete'}
                                info={paperContractCompleteInfoText}
                                reversed
                                style={{
                                    marginTop: '10px'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-end'
                                    }}
                                >
                                    <Checkbox 
                                        checked={this.props.contest.paper_contract_received}
                                        onChange={(e, data) => {
                                            this.props.contestChange(
                                                'paper_contract_received', !this.props.contest.paper_contract_received
                                            )
                                            this.props.contestChange(
                                                'status', 
                                                this.props.contest.paper_contract_received ? statuses.HOME_PAPER_CONTRACT_RECEIVED : statuses.HOME_PAPER_CONTRACT_SENT
                                            )
                                        }}
                                    />
                                </div>
                            </FormRow>
                            {this.props.contest.paper_contract_received && (
                                <FormRow
                                    label={'Paper contract upload:'}
                                    style={{
                                        marginTop: '10px'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        {this.props.contest.paper_contract_key && (
                                            <Button
                                                onClick={() => {
                                                    this.props.viewUploadedContract()
                                                }}
                                            >
                                                View Uploaded Contract
                                            </Button>
                                        )}
                                        <div>
                                            <Button
                                                style={{
                                                    position: 'relative',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <label 
                                                    htmlFor={`${this.props.contest.id}-contract-upload`}
                                                    style={{
                                                        position: 'absolute',
                                                        cursor: 'pointer',
                                                        top: 0,
                                                        right: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {`Upload ${this.props.contest.paper_contract_key ? 'a new ' : ''}Contract`}
                                                </label>
                                                <div
                                                    style={{
                                                        color: 'transparent'
                                                    }}
                                                >
                                                    {`Upload ${this.props.contest.paper_contract_key ? 'a new ' : ''}Contract`}
                                                </div>
                                            </Button>
                                                
                                            <input 
                                                type='file' 
                                                style={{display: 'none'}}
                                                id={`${this.props.contest.id}-contract-upload`} 
                                                onChange={() => {
                                                    uploadContract(this.props.contest, false, this.props.contestUpdate).then((data) => {
                                                        this.props.contestsRefetch();
                                                        this.props.onClose();
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    
                                </FormRow>
                            )}
                        </div>
                    )}
                    
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <div style={{flex: 1, margin: '0 7px'}} />
                        <div style={{flex: 3, margin: '0 7px'}}>
                            <Button basic onClick={() => {this.setState({expanded: !this.state.expanded})}}>{`${this.state.expanded ? 'Hide' : 'Show'} additional fields`}</Button>
                        </div>
                    </div>
                    {this.state.expanded && (
                        additionalFields.map((field, index) => {
                            let Input = field.input;
                            return (
                                <Input 
                                    key={index} 
                                    field={field.key}
                                    {...field}
                                    value={this.props.contest[field.key]}
                                    type='text'
                                    formUpdate={update => {
                                        this.props.contestChange(field.key, update[field.key])
                                    }}
                                /> 
                            )
                        })
                    )}
                </Form>
            </div>
        )
    }
};

const CancelContestAction = ({guid, user_id, status, onClose=()=>{}}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    return [statuses.EDITED, statuses.CANCELLED, statuses.AWAY_PLACEHOLDER, statuses.AWAY_PLACEHOLDER_PAPER_RECEIVED].includes(status) ? null : (
        <Mutation
            mutation={contestCancelMutation}
        >
            {(cancelContest) => (
                <Popup 
                    open={showPopup}
                    onClose={() => {setShowPopup(false)}}
                    on='click'
                    position='top center'
                    wide='very'
                    trigger={<Button color='orange' onClick={() => {setShowPopup(true)}}>Cancel Contest</Button>}
                    content={
                        <div>
                            <div style={{marginBottom: '10px'}}>
                                Are you sure you want to cancel this contest? If so, type a message to explain why, then click "Cancel Contest and Notify Opponent." This message will be included in the cancellation notification that is sent to the opposing school.
                            </div>
                            <div>
                                <Form>
                                    <Form.TextArea 
                                        value={message}
                                        onChange={(e, {value}) => {
                                            setMessage(value)
                                        }}
                                        placeholder='Type why you are cancelling this contest (required)'
                                        rows={3}
                                    />
                                </Form>
                            </div>
                            {status === statuses.SIGNED && (
                                <div
                                    style={{
                                        marginTop: '8px',
                                        fontSize: '12px'
                                    }}
                                >
                                    Please note that this cancellation functionality is intended to make it easier for you to manage your schedule and notify your opponents of a desired contest cancellation. This functionality does not affect your legal right to cancel the contest, which is determined by the terms of your contest contract.
                                </div>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: '16px'
                                }}
                            >
                                <Button
                                    primary
                                    fluid
                                    disabled={loading || !message}
                                    onClick={() => {
                                        setLoading(true)
                                        cancelContest({
                                            variables: {
                                                guid,
                                                message,
                                                cancel_user_id: user_id
                                            }
                                        }).then(() => {
                                            setLoading(false);
                                            setShowPopup(false);
                                            onClose()
                                        })
                                    }}
                                >
                                    Cancel Contest and Notify Opponent
                                </Button>
                                <Button
                                    fluid
                                    onClick={() => setShowPopup(false)}
                                >
                                    Do not cancel contest
                                </Button>
                            </div>
                        </div>
                    }
                />
            )}
            
        </Mutation>

    )
}

const getHomeCheckboxes = status => ({
    paper_contract_exchange: [
        statuses.HOME_PAPER_CONTRACT, 
        statuses.HOME_PAPER_CONTRACT_RECEIVED, 
        statuses.HOME_PAPER_CONTRACT_SENT
    ].includes(status),
    paper_contract_sent: [
        statuses.HOME_PAPER_CONTRACT_RECEIVED, 
        statuses.HOME_PAPER_CONTRACT_SENT
    ].includes(status),
})

export default class EditContestModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opponentSearchQuery: '',
            sportSearchQuery: '',
            contest: {...props.contest},
            popupVisible: false,
            deletePopupVisible: false,
            dirty: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.contest.id !== nextProps.contest.id) {
            let contest = {...nextProps.contest};
            this.setState({
                contest: {
                    ...contest,
                    ...getHomeCheckboxes(contest.status)
                }
            })
        }
    }

    cancelChanges = () => {
        let contest = {...this.props.contest};
        this.setState({
            contest,
            dirty: false
        })
    }

    contestChange = (attr, value) => {
        let contest = this.state.contest;
        contest[attr] = value;
        this.setState({
            contest,
            dirty: true
        })
    }

    notify = (success, args={}) => {
        let {message="Contest updated successfully"} = args
        if (success) {
            toast.success(message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            });
        }
    }

    getModalAction = (contestUpdate) => {

        let modalAction;
        let {contest} = this.state;
        let updateMeta = JSON.stringify({
            user_email: this.props.user.email,
            user_id: this.props.user.id
        })
        let updateContest = (isEdited=false, needsEmail=false) => {
            contestUpdate({variables: Object.assign({},
                contest,
                {
                    date: new Date(contest.date),
                    updateMeta,
                    isEdited,
                    needsEmail,
                    sport: contest.sport.value
                }
            )}).then(res => {
                this.props.contestsRefetch();
                this.notify(true);
                this.props.onClose();
            }, err => {
                console.log('editing error', err)
            })
        };
        if ([statuses.EDITED, statuses.AWAY_PLACEHOLDER, statuses.AWAY_PLACEHOLDER_PAPER_RECEIVED, statuses.HOME_PAPER_CONTRACT, statuses.HOME_PAPER_CONTRACT_RECEIVED, statuses.HOME_PAPER_CONTRACT_SENT].includes(contest.status)) {
            modalAction = (
                <ModalAction
                    key={0} 
                    primary
                    title={`Update Contest`}
                    onClick={() => {
                        updateContest()
                    }}
                />
            )
        } else if (contest.status === statuses.SIGNED) {
            modalAction = (
                <Popup
                    key={1}
                    position='top center'
                    on='click'
                    open={this.state.popupVisible}
                    onClose={() => this.setState({popupVisible: false})}
                    trigger={<Button primary onClick={() => this.setState({popupVisible: true})}>Update Contest</Button>}
                    content={
                        <div>
                            <div style={{marginBottom: '10px'}}>
                                This contest has a signed contract in place.  If you edit this contest, you must send the revised contract to the opposing school.  Until the revised contract is signed and complete, the previously-signed contract is still valid.
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Button
                                    primary
                                    fluid
                                    onClick={() => {
                                        updateContest(true, true)
                                    }}
                                >
                                    Update Anyway
                                </Button>
                                <Button
                                    fluid
                                    onClick={() => this.setState({popupVisible: false})}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    }
                />
                    
            )
        } else {
            modalAction = (
                <Popup
                    key={2}
                    position='top center'
                    on='click'
                    open={this.state.popupVisible}
                    onClose={() => this.setState({popupVisible: false})}
                    trigger={<Button primary onClick={() => this.setState({popupVisible: true})}>Update Contest</Button>}
                    content={(
                        <div>
                            <div style={{marginBottom: '10px'}}>
                                {contest.status === statuses.CANCELLED ? 
                                    `This contest has been marked as cancelled.  If you edit this contest, it will no longer show as cancelled in our system, and it will be reset to its initial status of “No signed contract in place.” You may then send out the contract when you are ready.` : 
                                    `This contest has already been submitted.  If you edit this contest, you must resubmit this contest.`
                                }
                                
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <Button
                                    primary
                                    style={{
                                        flex: 1
                                    }}
                                    onClick={() => {
                                        updateContest(true, true)
                                    }}
                                >
                                    Update Anyway
                                </Button>
                                <Button
                                    style={{
                                        flex: 1
                                    }}
                                    onClick={() => this.setState({popupVisible: false})}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                   )}
                />
                    
            )
        }
        return modalAction
    }

    getCancelChanges = () => {
        return this.state.dirty ? (
            <ModalAction
                key={-1} 
                title={`Cancel Changes`}
                onClick={() => {
                    this.cancelChanges()
                }}
            />
        ) : null
    }

    deleteContest = () => {
        return (
            <Mutation
                key={-2} 
                mutation={contestRemoveMutation}
            >
                {(contestRemove, {loading, error}) => (
                    <Popup
                        position='top center'
                        on='click'
                        open={this.state.deletePopupVisible}
                        onClose={() => this.setState({deletePopupVisible: false})}
                        trigger={<Button negative onClick={() => this.setState({deletePopupVisible: true})}>Delete Contest</Button>}
                        content={
                            <div>
                                <div style={{marginBottom: '10px'}}>
                                    Are you sure you want to delete this contest?
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <Button
                                        primary
                                        fluid
                                        onClick={() => {
                                            contestRemove({
                                                variables: {
                                                    id: this.props.contest.id,
                                                }
                                            }).then(() => {
                                                this.props.onClose();
                                                this.setState({deletePopupVisible: false})
                                                this.props.contestsRefetch();
                                            })
                                        }}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        fluid
                                        onClick={() => this.setState({deletePopupVisible: false})}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        }
                    />
                )}
            </Mutation>
        )
    }

    copyRequest = () => {
        return (
            <RequestCopyButtons
                key={-3} 
                user={this.props.user}
                guid={this.state.contest.guid}
                onClose={this.props.onClose}
                notify={this.notify}
            />
        )
    }

    render() {
        let {permissions={}} = this.props;
        let sportsFilterOptions = !permissions ? sportSelectOptions : sportSelectOptions.filter(option => {
            return permissions[option.value] && permissions[option.value].write
        });
        let opponentOptions = [];
        Object.keys(this.props.orgs).forEach((opponentId) => {
            if (opponentId !== this.props.user.orgid) {
                opponentOptions.push({
                    label: this.props.orgs[opponentId],
                    value: opponentId,
                })
            }
        })
        opponentOptions.sort((a, b) => {
            return a.text < b.text ? -1 : 1
        })
        return(
            <Mutation mutation={contestUpdateMutation}>
                {(contestUpdate, {data, loading, error}) => (
                    <BaseControlledModal
                        header={this.props.header}
                        size={'small'}
                        open={this.props.open}
                        onClose={() => {
                            this.setState({
                                popupVisible: false,
                                deletePopupVisible: false,
                            })
                            this.props.onClose();
                        }}
                        actions={[
                            this.deleteContest(),
                            <CancelContestAction 
                                key='cancel' 
                                guid={this.state.contest.guid}
                                user_id={this.props.user.id}
                                status={this.state.contest.status}
                                onClose={() => {
                                    this.props.onClose();
                                    this.props.contestsRefetch();
                                }} 
                            />,
                            this.getCancelChanges(),
                            this.getModalAction(contestUpdate),
                        ]}
                        leftActions={this.props.contest.orgid === this.props.user.orgid ? [
                            this.copyRequest(),
                        ] : []}
                        >
                        {this.props.contest.orgid === this.props.user.orgid ? 
                            <HomeContestFields
                                {...this.props} 
                                contestChange={this.contestChange}
                                contest={this.state.contest}
                                contestsRefetch={this.props.contestsRefetch}
                                contestUpdate={contestUpdate}
                                opponentOptions={opponentOptions} 
                                opponentSearchQuery={this.state.opponentSearchQuery}
                                sportSearchQuery={this.state.sportSearchQuery}
                                sportsFilterOptions={sportsFilterOptions}
                                onChangeSearchQuery={(query, value) => {this.setState({[query]: value})}}
                                onClose={this.props.onClose}
                            /> : 
                            <AwayContestFields 
                                {...this.props} 
                                contestChange={this.contestChange}
                                contest={this.state.contest}
                                contestsRefetch={this.props.contestsRefetch}
                                contestUpdate={contestUpdate}
                                opponentOptions={opponentOptions} 
                                opponentSearchQuery={this.state.opponentSearchQuery}
                                sportSearchQuery={this.state.sportSearchQuery}
                                sportsFilterOptions={sportsFilterOptions}
                                onChangeSearchQuery={(query, value) => {this.setState({[query]: value})}}
                                onClose={this.props.onClose}
                            />
                        }
                    </BaseControlledModal>
                )}   
            </Mutation>
        )
    }
};

