import gql from 'graphql-tag';

export const contestsSigned = gql`
    query contestsSigned {
        contestsSigned
    } 
`;

export const contestRemoveMutation = gql`
    mutation contestRemove (
        $id: ID!
    ) {
        contestRemove (
            id: $id
        )
    }
`;

export const contestStatusQuery = gql`
    query contest (
        $guid: ID
    ) {
        contest (
            guid: $guid
        ) {
            guid
            id
            status
        }
    }
`;

export const contestContactsQuery = gql`
    query contest (
        $guid: ID
    ) {
        contest (
            guid: $guid
        ) {
            guid
            id
            away_different_signer_email
            contract_recipient
            signor_different_user_email
        }
    }
`;

export const contestsQuery = gql`
    query contests(
        $user_id: ID
        $orgid: ID
        $academicYears: [Int]
        $dateFilterStart: String
        $dateFilterEnd: String
        $homeOrAway: String
        $lastActivity: Int
        $opponents: [ID]
        $past: Boolean
        $seasons: [String]
        $sports: [String]
        $statuses: [String]
    ) {
        contests (
            user_id: $user_id
            orgid: $orgid
            academicYears: $academicYears
            dateFilterStart: $dateFilterStart
            dateFilterEnd: $dateFilterEnd
            homeOrAway: $homeOrAway
            lastActivity: $lastActivity
            opponents: $opponents
            past: $past,
            seasons: $seasons
            sports: $sports
            statuses: $statuses
        ) {
            id
            guid
            away_different_signer
            away_different_signer_email
            away_orgid
            away_internal_approvers
            away_placeholder_description
            away_requires_internal_approval
            broadcast_rights
            cancellation_terms
            comp_level
            comp_tickets
            cons_tickets
            contract_recipient
            created_at
            created_by
            creating_orgid
            date
            description
            force_majeure
            future
            governing_rules
            guarantee
            head_coach
            head_coach_email
            head_coach_phone
            home_read_only_recipients
            home_internal_approvers
            home_requires_internal_approval
            hour
            last_activity
            last_reminder
            location
            minute
            officials
            orgid
            other_conditions
            paper_contract_received
            paper_contract_key
            radio_rights
            remind
            require_away_head_coach_info
            interval
            season
            sent_date
            signature_date
            signor_different_user
            signor_different_user_email
            signors_email
            signors_phone
            signors_title
            sport
            status
            submitted_to_away_different_signer_date
            submitted_to_different_signer_date
            submitter_email
            submitter_fullname
            tv_rights
            user_email
            user_fullname
        }
    }
`;

export const contestCreateMutation = gql`
    mutation contestCreate(
        $away_orgid: ID
        $away_placeholder_description: String
        $broadcast_rights: String
        $cancellation_terms: String
        $comp_level: String
        $comp_tickets: String
        $cons_tickets: String
        $created_at: String
        $created_by: ID
        $creating_orgid: ID
        $date: String
        $description: String
        $force_majeure: String
        $future: String
        $governing_rules: String
        $guarantee: String
        $head_coach: String
        $head_coach_email: String
        $head_coach_phone: String
        $homeOrAway: String
        $home_read_only_recipients: String
        $home_requires_internal_approval: Boolean
        $home_internal_approvers: String
        $hour: Int
        $location: String
        $minute: Int
        $officials: String
        $orgid: ID
        $other_conditions: String
        $radio_rights: String
        $remind: Boolean
        $require_away_head_coach_info: String
        $interval: Int
        $signor_different_user: Boolean
        $signor_different_user_email: String
        $season: String
        $sport: String
        $tv_rights: String
    ) {
        contestCreate (
            away_orgid: $away_orgid
            away_placeholder_description: $away_placeholder_description
            broadcast_rights: $broadcast_rights
            cancellation_terms: $cancellation_terms
            comp_level: $comp_level
            comp_tickets: $comp_tickets
            cons_tickets: $cons_tickets
            created_at: $created_at
            created_by: $created_by
            creating_orgid: $creating_orgid
            date: $date
            description: $description
            force_majeure: $force_majeure
            future: $future
            governing_rules: $governing_rules
            guarantee: $guarantee
            head_coach: $head_coach
            head_coach_email: $head_coach_email
            head_coach_phone: $head_coach_phone
            homeOrAway: $homeOrAway
            home_read_only_recipients: $home_read_only_recipients
            home_requires_internal_approval: $home_requires_internal_approval
            home_internal_approvers: $home_internal_approvers
            hour: $hour
            location: $location
            minute: $minute
            officials: $officials
            orgid: $orgid
            other_conditions: $other_conditions
            radio_rights: $radio_rights
            remind: $remind
            require_away_head_coach_info: $require_away_head_coach_info
            interval: $interval
            season: $season
            signor_different_user: $signor_different_user
            signor_different_user_email: $signor_different_user_email
            sport: $sport
            tv_rights: $tv_rights
        ) 
    }
`;

export const contestsCreateMutation = gql`
    mutation contestsCreate (
        $contests: [contestInput] 
    ) {
        contestsCreate (
            contests: $contests
        )
    }
`;

export const contestSafeUpdate = gql`
    mutation contestSafeUpdate (
        $guid: ID
        $remind: Boolean
        $interval: Int
    ) {
        contestSafeUpdate (
            guid: $guid
            remind: $remind
            interval: $interval
        )
    }
`;

export const contestUpdateMutation = gql`
    mutation contestUpdate(
        $id: ID
        $isEdited: Boolean
        $needsEmail: Boolean
        $guid: ID
        $away_orgid: ID
        $away_placeholder_description: String
        $broadcast_rights: String
        $cancellation_terms: String
        $comp_level: String
        $comp_tickets: String
        $cons_tickets: String
        $created_by: ID
        $creating_orgid: ID
        $date: String
        $description: String
        $email_link: Boolean
        $force_majeure: String
        $future: String
        $governing_rules: String
        $guarantee: String
        $head_coach: String
        $head_coach_email: String
        $head_coach_phone: String
        $hour: Int
        $location: String
        $minute: Int
        $officials: String
        $orgid: ID
        $other_conditions: String
        $paper_contract_received: Boolean
        $paper_contract_key: String
        $radio_rights: String
        $require_away_head_coach_info: String
        $season: String
        $sport: String
        $status: String
        $tv_rights: String
        $updateMeta: String
    ) {
        contestUpdate(
            id: $id
            isEdited: $isEdited
            needsEmail: $needsEmail
            guid: $guid
            away_orgid: $away_orgid
            away_placeholder_description: $away_placeholder_description
            broadcast_rights: $broadcast_rights
            cancellation_terms: $cancellation_terms
            comp_level: $comp_level
            comp_tickets: $comp_tickets
            cons_tickets: $cons_tickets
            created_by: $created_by
            creating_orgid: $creating_orgid
            date: $date
            description: $description
            email_link: $email_link
            force_majeure: $force_majeure
            future: $future
            governing_rules: $governing_rules
            guarantee: $guarantee
            head_coach: $head_coach
            head_coach_email: $head_coach_email
            head_coach_phone: $head_coach_phone
            hour: $hour
            location: $location
            minute: $minute
            officials: $officials
            orgid: $orgid
            other_conditions: $other_conditions
            paper_contract_received: $paper_contract_received
            paper_contract_key: $paper_contract_key
            radio_rights: $radio_rights
            require_away_head_coach_info: $require_away_head_coach_info
            season: $season
            sport: $sport    
            status: $status        
            tv_rights: $tv_rights
            updateMeta: $updateMeta
        ) 
    }
`;

export const contestSubmitMutation = gql`
    mutation contestSubmit (
        $guid: ID
        $away_different_signer: Boolean
        $away_different_signer_email: String
        $away_internal_approvers: String
        $away_read_only_recipients: String
        $away_requires_internal_approval: Boolean
        $away_submitter_email: String
        $away_submitter_name: String
        $contract_recipient: String
        $firstSubmit: Boolean
        $home_internal_approvers: String
        $home_read_only_recipients: String
        $home_requires_internal_approval: Boolean
        $signor_different_user: Boolean
        $signor_different_user_email: String
        $submit_type: contestSubmitType
        $submitter_email: String
        $submitter_fullname: String
        $updateMeta: String
        $user_email: String
        $user_fullname: String
        $user_office_phone: String
        $user_title: String
        $remind: Boolean
        $require_away_head_coach_info: String
        $interval: Int
        $updatedRemind: Boolean
        $away_head_coach_name: String
        $away_head_coach_email: String
        $away_head_coach_phone: String
    ) {
        contestSubmit (
            guid: $guid
            away_different_signer: $away_different_signer
            away_different_signer_email: $away_different_signer_email
            away_internal_approvers: $away_internal_approvers
            away_read_only_recipients: $away_read_only_recipients
            away_requires_internal_approval: $away_requires_internal_approval
            away_submitter_email: $away_submitter_email
            away_submitter_name: $away_submitter_name
            contract_recipient: $contract_recipient
            firstSubmit: $firstSubmit
            home_internal_approvers: $home_internal_approvers
            home_read_only_recipients: $home_read_only_recipients
            home_requires_internal_approval: $home_requires_internal_approval
            require_away_head_coach_info: $require_away_head_coach_info
            signor_different_user: $signor_different_user
            signor_different_user_email: $signor_different_user_email
            submit_type: $submit_type
            submitter_email: $submitter_email
            submitter_fullname: $submitter_fullname
            updateMeta: $updateMeta
            user_email: $user_email
            user_fullname: $user_fullname
            user_office_phone: $user_office_phone
            user_title: $user_title
            remind: $remind
            interval: $interval
            updatedRemind: $updatedRemind
            away_head_coach_name: $away_head_coach_name
            away_head_coach_email: $away_head_coach_email
            away_head_coach_phone: $away_head_coach_phone
        )
    }
`;

export var contestApproveMutation = gql`
    mutation contestApprove (
        $guid: ID
        $email: String
        $isHomeApproval: Boolean
        $signature: String
        $title: String
    ) {
        contestApprove (
            guid: $guid
            email: $email
            isHomeApproval: $isHomeApproval
            signature: $signature
            title: $title
        )
    }
`;

export var contestSignMutation = gql`
    mutation contestSign(
        $guid: ID
        $isHomeSignature: Boolean
        $away_read_only_recipients: String
        $email: String
        $phone: String
        $signature: String
        $title: String
        $away_head_coach_name: String
        $away_head_coach_email: String
        $away_head_coach_phone: String
    ) {
        contestSign (
            guid: $guid
            isHomeSignature: $isHomeSignature
            away_read_only_recipients: $away_read_only_recipients
            email: $email
            phone: $phone
            signature: $signature
            title: $title
            away_head_coach_name: $away_head_coach_name
            away_head_coach_email: $away_head_coach_email
            away_head_coach_phone: $away_head_coach_phone
        )
    }
`;

export const contestUpdateRecipientsMutation = gql`
    mutation contestUpdateRecipients (
        $guid: ID
        $away_different_signer: Boolean
        $away_different_signer_email: String
        $away_requires_internal_approval: Boolean
        $away_internal_approvers: String
        $contract_recipient: String
        $home_requires_internal_approval: Boolean
        $home_internal_approvers: String
        $signor_different_user: Boolean
        $signor_different_user_email: String
        $status: String
    ) {
        contestUpdateRecipients (
            guid: $guid
            away_different_signer: $away_different_signer
            away_different_signer_email: $away_different_signer_email
            away_requires_internal_approval: $away_requires_internal_approval
            away_internal_approvers: $away_internal_approvers
            contract_recipient: $contract_recipient
            home_requires_internal_approval: $home_requires_internal_approval
            home_internal_approvers: $home_internal_approvers
            signor_different_user: $signor_different_user
            signor_different_user_email: $signor_different_user_email
            status: $status
        )
    }
`;

export const contestResubmitMutation = gql`
    mutation contestResubmit (
        $guid: ID
    ) {
        contestResubmit (
            guid: $guid
        )
    }
`;

export const contestCancelMutation = gql`
    mutation contestCancel (
        $guid: ID!
        $message: String
        $cancel_user_id: ID
    ) {
        contestCancel (
            guid: $guid
            message: $message
            cancel_user_id: $cancel_user_id
        )
    }
`;

export const contestCancelledInfoQuery = gql`
    query contestCancelledInfo (
        $guid: ID!
    ) {
        contestCancelledInfo (
            guid: $guid
        ) {
            date
            notified
            message
            cancel_user_id
            cancel_user {
                id
                firstname
                lastname
                email
                title
            }
        }
    }
`;
