import React, {Component, useEffect, useState} from 'react';
import { Mutation, Query } from 'react-apollo';
import {
    Modal, 
    Button, 
    Input, 
    Form,
    Checkbox,
    Dropdown
} from 'semantic-ui-react';
import {toast} from 'react-toastify';

import {FormRowInput, FormRowTextArea} from '../utilityComponents/form/formRow';

import {
    contestApproveMutation, 
    contestSignMutation,
    contestSubmitMutation,
} from '../../gql/contest';

import {
    contactOwnerMutation,
    requestCopyMutation,
} from '../../gql/contract';

import  {
    userOrgContactListFromContestQuery
} from '../../gql/user';
import { validateEmail } from '../../utils/validators';



export class RequestCopy extends Component {
    state = {
        modalOpen: false,
        email: '',
        errors: {}
    };

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })

    formUpdate = (nextState) => {
        this.setState(nextState)
    }

    notify = success => {
    
        if (success) {
            toast.success("You will receive a copy of the contract in your email shortly", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            });
        }
    }

    render() { 
        let {type} = this.props;
        return (
            <Modal
                size={'tiny'}
                
                open={this.state.modalOpen}
                trigger={
                    <Button basic fluid onClick={this.handleOpen}>Request Copy for Printing</Button>
                }
            >
                <Modal.Header>Request Copy of Contract</Modal.Header>
                <Modal.Content>
                    <Form>
                        <FormRowInput 
                            field='email'
                            label='Email'
                            error={this.state.errors.email && this.state.errors.email(this.state.email)}
                            required={true}
                            placeholder='Type your email'
                            value={this.state.email}
                            formUpdate={this.formUpdate}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose}>Close</Button>
                    <Mutation mutation={requestCopyMutation}>
                        {(requestCopy, {data, loading, error}) => {
                            return (
                                <Button 
                                    primary
                                    onClick={() => {
                                        let errors = {};
                                        let errorFields = ['email']
                                        errorFields.forEach(field => {
                                            if (field === 'email') {
                                                if (this.state[field].indexOf('@') <= 0) {
                                                    errors[field] = value => value.indexOf('@') <= 0
                                                }
                                            }
                                        })
                                        if (Object.keys(errors).length > 0) {
                                            this.setState({errors})
                                        } else {
                                            requestCopy({
                                                variables: {
                                                    guid: this.props.guid,
                                                    recipient: this.state.email,
                                                    away: ['away_signer', 'away_different_signer', 'away_approver', 'read_only'].includes(type),
                                                }
                                            }).then(() => {
                                                this.handleClose();
                                                this.notify(true);
                                            })
                                        }
                                    }}
                                >
                                    Request Copy
                                </Button>
                            )
                        }}
                    </Mutation>
                </Modal.Actions>
            </Modal>
        )
    }
}

export class HomeSigner extends Component {
    constructor(props) {
        super(props);
        let phone = (props.userInfo && props.userInfo.phone_work) || '';
        
        let state = Object.assign({},
            {
                modalOpen: false,
                signature: '',
                errors: {},
                email: '',
                phone,
                title: '',
            },
            {...props.userInfo}
        )
        this.state = {...state}
    }

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })

    formUpdate = (nextState) => {
        this.setState(nextState)
    }

    render() { 
        return (
            <Modal
                size={'tiny'}
                
                open={this.state.modalOpen}
                trigger={
                    <Button primary fluid onClick={this.handleOpen}>Sign Contract</Button>
                }
            >
                <Modal.Header>Sign Contract</Modal.Header>
                <Modal.Content>
                    <p>
                        Please fill out the information below. When you click 
                        the "Submit Contract" button at the bottom of this window, 
                        you will have signed this contract. You will automatically 
                        receive a copy of the fully executed contract at the email 
                        address you list below once the contract is signed by the 
                        opposing school.
                    </p>
                    <div>
                        <Form>
                            <FormRowInput 
                                field={'email'}
                                label={'Email'}
                                error={this.state.errors.email && this.state.errors.email(this.state.email)}
                                placeholder={'Type your email'}
                                value={this.state.email}
                                formUpdate={this.formUpdate}
                                required={true}
                            />
                            <FormRowInput 
                                field={'phone'}
                                label={'Phone'}
                                error={this.state.errors.phone && this.state.errors.phone(this.state.phone)}
                                value={this.state.phone}
                                placeholder={'Type your phone number'}
                                formUpdate={this.formUpdate}
                                required={true}
                            />
                            <FormRowInput 
                                field={'title'}
                                label={'Title'}
                                error={this.state.errors.title && this.state.errors.title(this.state.title)}
                                value={this.state.title}
                                placeholder={'Type your title'}
                                formUpdate={this.formUpdate}
                                required={true}
                            />
                            <FormRowInput 
                                field={'signature'}
                                label={'Signature'}
                                error={this.state.errors.signature && this.state.errors.signature(this.state.signature)}
                                value={this.state.signature}
                                placeholder={'Type your full name'}
                                formUpdate={this.formUpdate}
                                required={true}
                            />
                        </Form>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <div style={{flex: 1, margin: '0 7px'}} />
                            <div style={{flex: 3, margin: '0 10px'}}>
                                <p>
                                    By typing your name here you are signing your approval of this contract.  After your submit this form, the contract will automatically be sent to the opposing institution.
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    
                    <Button onClick={this.handleClose}>Close</Button>
                    <Mutation
                        mutation={contestSignMutation}
                    >
                        {(contestSign, {data, loading, error}) => {
                            return (
                                <Button 
                                    primary
                                    onClick={() => {
                                        let { newContest } = this.state;
                                        let errors = {};
                                        let errorFields = ['email', 'title', 'signature', 'phone']
                                        errorFields.forEach(field => {
                                            if (!this.state[field]) {
                                                errors[field] = value => !value
                                            }
                                            if (field === 'email') {
                                                if (this.state[field].indexOf('@') <= 0) {
                                                    errors[field] = value => value.indexOf('@') <= 0
                                                }
                                            }
                                        })
                                        if (Object.keys(errors).length > 0) {
                                            this.setState({errors})
                                        } else {
                                            contestSign({
                                                variables: {
                                                    guid: this.props.guid,
                                                    isHomeSignature: true,
                                                    email: this.state.email,
                                                    phone: this.state.phone,
                                                    signature: this.state.signature,
                                                    title: this.state.title,
                                                }
                                            }).then(() => {
                                                this.handleClose();
                                                this.props.contractRefetch();
                                                this.props.handleSigned(true, true, {isHome: true});
                                            })
                                        }
                                    }}
                                >
                                    Submit Contract
                                </Button>
                            )
                        }}
                    </Mutation>
                </Modal.Actions>
            </Modal>
        )
    }
}

let checkListEmails = emailsString => {
    
    let emails = emailsString.split(',');
    let hasWarning = !emails.every(email => {
        return email.trim().length === 0 || validateEmail(email.trim())
    })
    
    console.log({emails, hasWarning})
    if (hasWarning || emailsString.length === 0) {
        return true
    } else {
        return false
    }
}

let ReadOnly = (props) => {
    let {away_read_only, away_read_only_recipients, attemptedSubmit, setAttemptedSubmit=() => {}} = props;
    let [warning, setWarning] = useState(false);
    let [dirty, setDirty] = useState(false);
    useEffect(() => {
        setWarning(checkListEmails(away_read_only_recipients))
        if (attemptedSubmit) {
            setDirty(true);
            setAttemptedSubmit(false);
        }
    }, [away_read_only_recipients, attemptedSubmit])
    return (
        <div style={props.style}>
            <div>
                <Checkbox 
                    checked={away_read_only}
                    label={`Send a read-only copy of this contract to people within my institution`}
                    onChange={(e, data) => props.formUpdate({
                            away_read_only: !away_read_only
                        })
                    } 
                />
            </div>
            
            {props.away_read_only ? (
                <div
                    style={{
                        margin: '10px 0 25px',
                        padding: '0 35px',
                    }}
                >
                    <p>
                        Input emails of read-only recipients (comma separated). 
                        These individuals will receive a link to view this contract 
                        as a read-only viewer. (Note: the final signer for your 
                        institution will automatically receive a copy of the 
                        completed contract, so no need to include that person or yourself 
                        here as well)</p>
                    <Form.Field>
                        <Input 
                            fluid 
                            error={warning && dirty}
                            type="text" 
                            value={away_read_only_recipients} 
                            placeholder={`jane@college.edu, john@college.edu, etc.`}
                            onChange={(e, data) => props.formUpdate({
                                    away_read_only_recipients: data.value
                                })
                            } 
                            onBlur={()=>setDirty(true)}
                            onFocus={()=>setDirty(false)}
                        />
                    </Form.Field> 
                    <div
                        style={{
                            marginTop: '3px',
                            height: '15px',
                        }}
                    >
                        {warning && dirty && (
                            <span style={{fontSize: '10px', color: '#9f3a38'}}>Please ensure all emails entered are valid</span>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

let InternalApproval = (props) => {
    let {
        awaySportDefault,
        away_requires_internal_approval,
        away_internally_approved,
        away_submitter_name,
        away_submitter_email,
        away_internal_approvers,
        attemptedSubmit, 
        internally_approved,
        setAttemptedSubmit=() => {}
    } = props;
    const [nameWarning, setNameWarning] = useState(false)
    const [nameDirty, setNameDirty] = useState(false)
    const [emailWarning, setEmailWarning] = useState(false)
    const [emailDirty, setEmailDirty] = useState(false)
    const [approversWarning, setApproversWarning] = useState(false)
    const [approversDirty, setApproversDirty] = useState(false)

    useEffect(() => {
        setApproversWarning(checkListEmails(away_internal_approvers))
        if (attemptedSubmit) {
            setApproversDirty(true)
            setAttemptedSubmit(false);
        }
    }, [away_internal_approvers, approversDirty, attemptedSubmit])

    useEffect(() => {
        let hasWarning = !validateEmail(away_submitter_email)
        setEmailWarning(hasWarning || away_submitter_email.length === 0)
        if (attemptedSubmit) {
            setEmailDirty(true);
            setAttemptedSubmit(false);
        }
    }, [away_submitter_email, attemptedSubmit])

    useEffect(() => {
        setNameWarning(!away_submitter_name)
        if (attemptedSubmit) {
            setNameDirty(true);
            setAttemptedSubmit(false);
        }
    }, [away_submitter_name, attemptedSubmit])

    return (
        <div style={{margin: '0 15px', ...props.style}}>
            <div>
                <Checkbox 
                    checked={away_requires_internal_approval}
                    label={`Require internal approval (head coach, etc.)`}
                    onChange={(e, data) => {
                        props.formUpdate({
                            away_requires_internal_approval: !away_requires_internal_approval
                        })
                    }} 
                />
            </div>
            {away_requires_internal_approval ? (
                <div
                    style={{
                        margin: '10px 0 25px',
                        padding: '0 35px'
                    }}
                >
                    {!away_internally_approved && (
                        <div>
                            <p>
                                Use this to require internal users (head coach, administrators, etc.) 
                                to approve this contract before you sign it. Please enter your name 
                                and email below. When you click the "Submit Contract" button at the 
                                bottom of this window, your internal approvers will receive an email 
                                link to view and sign off on the contract. Once the internal approvals 
                                are complete, you will receive another email prompting you to sign 
                                this contract.
                            </p>
                            <div
                                style={{
                                    margin: '15px 0 25px',
                                }}
                            >
                                <p>
                                    Enter your name (so that your internal approvers know who is requesting):
                                </p>
                                <Input 
                                    fluid 
                                    type="text" 
                                    error={nameWarning && nameDirty}
                                    value={away_submitter_name} 
                                    placeholder={`Your name`}
                                    required={true}
                                    onChange={(e, data) => props.formUpdate({
                                            away_submitter_name: data.value
                                        })
                                    } 
                                    onBlur={() => setNameDirty(true)}
                                    onFocus={() => setNameDirty(false)}
                                />
                                <div
                                    style={{
                                        marginTop: '3px',
                                        height: '15px',
                                    }}
                                >
                                    {nameWarning && nameDirty && (
                                        <span style={{fontSize: '10px', color: '#9f3a38'}}>Please enter your name</span>
                                    )}
                                </div>
                            </div>
                            <div
                                style={{
                                    margin: '15px 0 25px',
                                }}
                            >
                                <p>
                                    Enter your email (so that we can notify you when your contract has been approved):
                                </p>
                                <Input 
                                    fluid 
                                    type="text" 
                                    error={emailWarning && emailDirty}
                                    value={away_submitter_email} 
                                    placeholder={`Your email`}
                                    required={true}
                                    onChange={(e, data) => props.formUpdate({
                                            away_submitter_email: data.value
                                        })
                                    } 
                                    onBlur={() => setEmailDirty(true)}
                                    onFocus={() => setEmailDirty(false)}
                                />
                                {emailWarning && emailDirty && (
                                    <span style={{fontSize: '10px', color: '#9f3a38'}}>Please enter a valid email</span>
                                )}
                            </div>
                            <div
                                style={{
                                    margin: '15px 0 25px',
                                }}
                            >
                                <p>
                                    Enter the emails of required internal approvers (comma separated):
                                </p>
                                {awaySportDefault.head_coach_email && (
                                    <Button basic onClick={() => {
                                        let current = away_internal_approvers || ''
                                        if (current.indexOf(awaySportDefault.head_coach_email) === -1) {
                                            if (current && current.length > 0) {
                                                current += `, ${awaySportDefault.head_coach_email}`
                                            } else {
                                                current = awaySportDefault.head_coach_email
                                            }
                                            props.formUpdate({
                                                away_internal_approvers: current
                                            })
                                        }
                                        
                                    }}>
                                        Add head coach
                                    </Button>
                                )}
                                <Input 
                                    fluid 
                                    type="text" 
                                    value={away_internal_approvers} 
                                    error={approversWarning && approversDirty}
                                    required={true}
                                    placeholder={`jane@college.edu, john@college.edu, etc.`}
                                    onChange={(e, data) => props.formUpdate({
                                            away_internal_approvers: data.value
                                        })
                                    } 
                                    onBlur={() => setApproversDirty(true)}
                                    onFocus={() => setApproversDirty(true)}
                                />
                                {approversWarning && approversDirty && (
                                    <span style={{fontSize: '10px', color: '#9f3a38'}}>Please ensure all emails entered are valid</span>
                                )}
                            </div>
                        </div>
                    )}
                    <div
                        style={{
                            margin: '15px 0 25px',
                        }}
                    >
                        <Checkbox 
                            checked={away_internally_approved}
                            label={`This has been internally approved`}
                            onChange={(e, data) => {
                                props.formUpdate({
                                    away_internally_approved: !away_internally_approved
                                })
                            }} 
                        />
                        {!away_internally_approved && (
                            <p>
                                Check this box if you have already received all of your 
                                internal approvals, or if an internal approver gave 
                                you verbal/other approval instead of using our system 
                                to approve. Checking this will allow you to proceed 
                                with signing the contract.
                            </p>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

let OtherSigner = (props) => {
    let {
        away_different_signer, 
        away_different_signer_email, 
        away_different_signer_email_new, 
        attemptedSubmit, 
        setAttemptedSubmit=() => {}
    } = props;
    let [warning, setWarning] = useState(false);
    let [dirty, setDirty] = useState(false);
    useEffect(() => {
        if (away_different_signer_email === 'Add a new recipient') {
            let hasWarning = !validateEmail(away_different_signer_email_new)
            setWarning(hasWarning || away_different_signer_email_new.length === 0)
            if (attemptedSubmit) {
                setDirty(true);
                setAttemptedSubmit(false);
            }
        }
    }, [away_different_signer_email_new, away_different_signer_email, attemptedSubmit])
    return (
        <Query query={userOrgContactListFromContestQuery} variables={{guid: props.guid}}>
            {({loading, error, data}) => {
                if (loading) {
                    return null
                }
                let {awayOrg} = data.userOrgContactListFromContest
                let userOrgContactList = awayOrg.contactList || [];
                let options = userOrgContactList.map((contact, i) => {
                    return ({
                        text: `${contact.firstname && contact.lastname ? `${contact.firstname} ${contact.lastname} <${contact.email}>` : `<${contact.email}>`}`,
                        value: contact.email,
                    })
                })
                if (away_different_signer_email !== 'Add a new recipient') {
                    options.push({
                        text: 'Add a new recipient',
                        value: 'Add a new recipient'
                    })
                }
                return (
                    <div style={props.style}>
                        <div>
                            <Checkbox 
                                checked={away_different_signer}
                                label={`Require a different person to be the primary signatory for my school`}
                                onChange={(e, data) => {
                                    props.formUpdate({
                                        away_different_signer: !away_different_signer
                                    })
                                }} 
                            />
                        </div>
                        {away_different_signer && (
                            <div
                                style={{
                                    margin: '15px 0 25px',
                                    padding: '0 35px'
                                }}
                            >
                                <p>
                                    Use the dropdown below to select the primary signatory on 
                                    this contract. After any internal approvals are complete, 
                                    this person will receive an email with a link to the 
                                    contract requesting review and signature. If the recipient 
                                    you are looking for is not included in the dropdown you may 
                                    type their email address in the space provided.
                                </p>
                                <Dropdown 
                                    fluid
                                    scrolling
                                    upward 
                                    selection
                                    search
                                    type="text" 
                                    options={options}
                                    value={away_different_signer_email} 
                                    placeholder={`Select a recipient`}
                                    onChange={(e, data) => props.formUpdate({
                                            away_different_signer_email: data.value
                                        })
                                    } 
                                />
                                {away_different_signer_email === 'Add a new recipient' && (
                                    <div
                                        style={{
                                            marginTop: '15px'
                                        }}
                                    >
                                        <Input 
                                            fluid 
                                            type="text" 
                                            error={warning && dirty}
                                            value={away_different_signer_email_new} 
                                            placeholder={`jane@college.edu`}
                                            required={true}
                                            onChange={(e, data) => props.formUpdate({
                                                away_different_signer_email_new: data.value
                                                })
                                            } 
                                            onBlur={() => setDirty(true)}
                                            onFocus={() => setDirty(false)}
                                        />
                                        {warning && dirty && (
                                            <span style={{fontSize: '10px', color: '#9f3a38'}}>Please enter a valid email</span>
                                        )}
                                    </div>
                                    
                                )}
                            </div>
                        )}                  
                    </div>
                )}}
        </Query>
    )
}

export class AwaySigner extends Component {
    constructor(props) {
        super(props);
        let {contest={}, userInfo={}, awaySportsDefault={}} = props;
        let awaySportDefault = awaySportsDefault && awaySportsDefault[contest.sport] ? awaySportsDefault[contest.sport] : {};
        let {require_away_head_coach_info='{}'} = contest;
        let requireAwayHeadCoachInfo = JSON.parse(require_away_head_coach_info) || {};
        let headCoachInfo = {};
        Object.keys(requireAwayHeadCoachInfo).forEach(key => {
            if (requireAwayHeadCoachInfo[key]) {
                if (key === 'name') {
                    headCoachInfo[`away_head_coach_${key}`] = awaySportDefault.head_coach;

                } else {
                    headCoachInfo[`away_head_coach_${key}`] = awaySportDefault[`head_coach_${key}`];

                }
            }
        })
        let state = Object.assign({},
            {
                modalOpen: false,
                away_read_only: contest.away_read_only_recipients || false,
                away_read_only_recipients: contest.away_read_only_recipients || '',
                away_requires_internal_approval: contest.away_requires_internal_approval || false,
                away_internally_approved: props.internally_approved,
                away_internal_approvers: contest.away_internal_approvers ? JSON.parse(contest.away_internal_approvers).map(approver => approver.approver_email).join(', ') : '',
                away_different_signer: contest.away_different_signer ? true : false,
                away_different_signer_email: contest.away_different_signer_email || null,
                email: '',
                phone: '',
                signature: '',
                away_submitter_email: contest.contract_recipient || props.userInfo.email || '',
                away_submitter_name: contest.contract_recipient_name || (userInfo.firstname && userInfo.lastname ? `${userInfo.firstname} ${userInfo.lastname}` : false) || '',
                title: '',
                errors: {},
                headCoachInfo
            },
            {...userInfo},
            {
                phone: userInfo.phone_work || ''
            },
            {}
        )
        this.state = {...state}
    }

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })

    formUpdate = (nextState) => {
        this.setState(nextState)
    }

    resolveSubmitType = () => {
        if (this.state.away_requires_internal_approval) {
            return 'ContestSubmitInternalAway'
        } else {
            return 'ContestSubmitAwayDifferentSigner'
        }
    }

    render() { 
        let {contest={}, userInfo={}, awaySportsDefault={}, updateAwayDefaults} = this.props;
        let awaySportDefault = awaySportsDefault && awaySportsDefault[contest.sport] ? awaySportsDefault[contest.sport] : {};
        let {headCoachInfo={}} = this.state;
        let {require_away_head_coach_info='{}'} = contest;
        let requireAwayHeadCoachInfo = JSON.parse(require_away_head_coach_info);
        let requiresHeadCoachInfo = headCoachInfo && Object.keys(headCoachInfo).length > 0;
        return (
            <Modal
                size={'tiny'}
                
                open={this.state.modalOpen}
                trigger={
                    <Button primary fluid onClick={this.handleOpen}>Select Internal Approvers / Sign Contract</Button>
                }
            >
                <Modal.Header>Sign Contract</Modal.Header>
                <Modal.Content>
                    <div>
                        <ReadOnly 
                            away_read_only={this.state.away_read_only}
                            away_read_only_recipients={this.state.away_read_only_recipients}
                            attemptedSubmit={this.state.attemptedSubmit}
                            setAttemptedSubmit={attemptedSubmit => this.setState({attemptedSubmit})}
                            formUpdate={this.formUpdate}
                            style={{
                                margin: '5px 0'
                            }}
                        />
                        <InternalApproval 
                            awaySportDefault={awaySportDefault}
                            away_requires_internal_approval={this.state.away_requires_internal_approval}
                            away_internal_approvers={this.state.away_internal_approvers}
                            away_internally_approved={this.state.away_internally_approved}
                            away_submitter_email={this.state.away_submitter_email}
                            away_submitter_name={this.state.away_submitter_name}
                            attemptedSubmit={this.state.attemptedSubmit}
                            setAttemptedSubmit={attemptedSubmit => this.setState({attemptedSubmit})}
                            formUpdate={this.formUpdate}
                            style={{
                                margin: '5px 0'
                            }}
                        />
                        <OtherSigner 
                            away_different_signer={this.state.away_different_signer}
                            away_different_signer_email={this.state.away_different_signer_email}
                            away_different_signer_email_new={this.state.away_different_signer_email_new}
                            attemptedSubmit={this.state.attemptedSubmit}
                            setAttemptedSubmit={attemptedSubmit => this.setState({attemptedSubmit})}
                            guid={this.props.guid}
                            formUpdate={this.formUpdate}   
                            style={{
                                margin: '5px 0'
                            }}
                        />
                    </div>
                    <Form>
                        {
                            !(this.state.away_requires_internal_approval || this.state.away_different_signer) || 
                            this.state.away_internally_approved ? 
                            (
                                <div>
                                    <p>
                                        Please fill out the information below. When you click the 
                                        "Sign Contract" button at the bottom of this window, you 
                                        will have signed this contract. You will automatically receive 
                                        a copy of the fully executed contract at the email address 
                                        you list below.
                                    </p>
                                    <div>
                                            <FormRowInput 
                                                field={'email'}
                                                label={'Email'}
                                                error={this.state.errors.email && this.state.errors.email(this.state.email)}
                                                required={true}
                                                placeholder={'Type your email'}
                                                value={this.state.email}
                                                formUpdate={this.formUpdate}
                                            />
                                            <FormRowInput 
                                                field={'phone'}
                                                label={'Phone'}
                                                error={this.state.errors.phone && this.state.errors.phone(this.state.phone)}
                                                required={true}
                                                value={this.state.phone}
                                                placeholder={'Type your phone number'}
                                                formUpdate={this.formUpdate}
                                            />
                                            <FormRowInput 
                                                field={'title'}
                                                label={'Title'}
                                                error={this.state.errors.title && this.state.errors.title(this.state.title)}
                                                required={true}
                                                value={this.state.title}
                                                placeholder={'Type your title'}
                                                formUpdate={this.formUpdate}
                                            />
                                            <FormRowInput 
                                                field={'signature'}
                                                label={'Signature'}
                                                error={this.state.errors.signature && this.state.errors.signature(this.state.signature)}
                                                required={true}
                                                value={this.state.signature}
                                                placeholder={'Type your full name'}
                                                formUpdate={this.formUpdate}
                                            />

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row'
                                                }}
                                            >
                                                <div style={{flex: 1, margin: '0 7px'}} />
                                                <div style={{flex: 3, margin: '0 10px'}}>
                                                    <p>
                                                        By typing your name here you are signing your approval of this contract.
                                                    </p>
                                                </div>
                                            </div>

                                            {
                                                requiresHeadCoachInfo && (
                                                    <div
                                                        style={{
                                                            marginTop: '10px'
                                                        }}
                                                    >
                                                        <div>
                                                            The submitting school has requested additional information for this contract. Please complete the following fields before submitting.
                                                        </div>
                                                        {Object.keys(headCoachInfo).map((key, index) => {
                                                            let words = key.split('_').map(word => {
                                                                let first = word.slice(0, 1);
                                                                let rest = word.slice(1);
                                                                return `${first.toUpperCase()}${rest}`;
                                                            })
                                                            let label = words.slice(1).join(' ')
                                                            if (label === 'Head Coach Phone') {
                                                                label = 'Head Coach Cell'
                                                            }
                                                            return (
                                                                <FormRowInput 
                                                                    key={index}
                                                                    field={key}
                                                                    label={label}
                                                                    error={this.state.errors[key] && this.state.errors[key](headCoachInfo[key])}
                                                                    required={true}
                                                                    value={headCoachInfo[key]}
                                                                    placeholder={`Type the ${label}`}
                                                                    formUpdate={update => {
                                                                        let headCoachInfoCopy = {...headCoachInfo};
                                                                        headCoachInfoCopy[key] = update[key];
                                                                        this.formUpdate({headCoachInfo: headCoachInfoCopy}) 
                                                                    }}
                                                                />
                                                            )
                                                        })}

                                                    </div>
                                                )
                                            }
                                        
                                    </div>
                                </div>
                            ) : (
                                requiresHeadCoachInfo ? (
                                    <div
                                        style={{
                                            marginTop: '10px'
                                        }}
                                    >
                                        <div>
                                            The submitting school has requested additional information for this contract. Please complete the following fields before submitting.
                                        </div>
                                        {Object.keys(headCoachInfo).map((key, index) => {
                                            let words = key.split('_').map(word => {
                                                let first = word.slice(0, 1);
                                                let rest = word.slice(1);
                                                return `${first.toUpperCase()}${rest}`;
                                            })
                                            let label = words.slice(1).join(' ');
                                            if (label === 'Head Coach Phone') {
                                                label = 'Head Coach Cell'
                                            }
                                            return (
                                                <FormRowInput 
                                                    key={index}
                                                    field={key}
                                                    label={label}
                                                    error={this.state.errors[key] && this.state.errors[key](headCoachInfo[key])}
                                                    required={true}
                                                    value={headCoachInfo[key]}
                                                    placeholder={`Type the ${label}`}
                                                    formUpdate={update => {
                                                        let headCoachInfoCopy = {...headCoachInfo};
                                                        headCoachInfoCopy[key] = update[key];
                                                        this.formUpdate({headCoachInfo: headCoachInfoCopy}) 
                                                    }}
                                                />
                                            )
                                        })}
                                    </div>
                                ) : null
                            )
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    {!(this.state.away_different_signer || this.state.away_requires_internal_approval) || this.state.away_internally_approved ? (
                        <Mutation
                            mutation={contestSignMutation}
                        >
                            {(contestSign, {data, loading, error}) => {
                                return (
                                    <Button 
                                        primary
                                        onClick={() => {
                                            let errors = {};
                                            let errorFields = ['email', 'title', 'signature', 'phone']
                                            let readOnlyErrorFields = [
                                                'away_read_only_recipients' 
                                            ]
                                            if (requiresHeadCoachInfo) {
                                                errorFields = errorFields.concat(Object.keys(headCoachInfo));
                                            }
                                            if (this.state.away_read_only) {
                                                errorFields = errorFields.concat(readOnlyErrorFields)
                                            }
                                            errorFields.forEach(field => {
                                                if (Object.keys(headCoachInfo).length > 0 && Object.keys(headCoachInfo).includes(field)) {
                                                    if (!this.state.headCoachInfo[field]) {
                                                        errors[field] = value => !value
                                                    }
                                                } else if (!this.state[field]) {
                                                    errors[field] = value => !value
                                                }
                                                
                                                if (field === 'email') {
                                                    if (!validateEmail(this.state[field])) {
                                                        errors[field] = value => !validateEmail(value)
                                                    }
                                                }
                                                if (field === 'away_read_only_recipients') {
                                                    if (checkListEmails(this.state[field])) {
                                                        errors[field] = value => checkListEmails(value)
                                                    }
                                                }
                                            })
                                            if (Object.keys(errors).length > 0) {
                                                this.setState({errors, attemptedSubmit: true})
                                            } else {
                                                contestSign({
                                                    variables: {
                                                        guid: this.props.guid,
                                                        isHomeSignature: false,
                                                        email: this.state.email,
                                                        phone: this.state.phone,
                                                        signature: this.state.signature,
                                                        title: this.state.title,
                                                        away_read_only_recipients: this.state.away_read_only_recipients,
                                                        ...headCoachInfo
                                                    }
                                                }).then(() => {
                                                    if (requiresHeadCoachInfo) {
                                                        let updated = false;
                                                        Object.keys(requireAwayHeadCoachInfo).forEach(key => {
                                                            if (requireAwayHeadCoachInfo[key]) {
                                                                if (key === 'name') {
                                                                    if (headCoachInfo[`away_head_coach_${key}`] !== awaySportDefault.head_coach) {
                                                                        updated = true;
                                                                        awaySportDefault.head_coach = headCoachInfo[`away_head_coach_${key}`];
                                                                    }
                                                
                                                                } else {
                                                                    if (headCoachInfo[`away_head_coach_${key}`] !== awaySportDefault[`head_coach_${key}`]) {
                                                                        updated = true
                                                                        awaySportDefault[`head_coach_${key}`] = headCoachInfo[`away_head_coach_${key}`]
                                                                    }
                                                
                                                                }
                                                            }
                                                        })
                                                        if (updated) {
                                                            updateAwayDefaults({
                                                                variables: {
                                                                    orgid: contest.away_orgid,
                                                                    properties: JSON.stringify(awaySportsDefault)
                                                                }
                                                            })
                                                        }
                                                    }
                                                    this.props.contractRefetch();
                                                    this.props.handleSigned(true, true, {
                                                        email: this.state.email,
                                                        name: this.state.signature,
                                                        phone: this.state.phone
                                                    });
                                                    this.handleClose();
                                                }, (e) => {
                                                    console.log({e})
                                                    if (e && e.graphQLErrors && e.graphQLErrors.length && e.graphQLErrors[0] && e.graphQLErrors[0].message) {
                                                        toast.error(e.graphQLErrors[0].message)
                                                    }
                                                })
                                            }
                                        }}
                                    >
                                        Sign Contract
                                    </Button>
                                )
                            }}
                            
                        </Mutation>
                    ) : (
                        <Mutation
                            mutation={contestSubmitMutation}
                        >
                            {(contestSubmit, {data, loading, error}) => {
                                return (
                                    <Button 
                                        primary
                                        onClick={() => {
                                            let errors = {};
                                            let readOnlyErrorFields = [
                                                'away_read_only_recipients' 
                                            ]
                                            let internalApprovalErrorFields = [
                                                'away_submitter_name',
                                                'away_submitter_email',
                                                'away_internal_approvers',
                                            ];
                                            let awayDifferentSignerErrorFields = [
                                                'away_different_signer_email_new'
                                            ];
                                            
                                            let errorFields = [];
                                            if (this.state.away_read_only) {
                                                errorFields = errorFields.concat(readOnlyErrorFields)
                                            }
                                            if (this.state.away_requires_internal_approval) {
                                                errorFields = errorFields.concat(internalApprovalErrorFields)
                                            }
                                            if (this.state.away_different_signer && this.state.away_different_signer_email === 'Add a new recipient') {
                                                errorFields = errorFields.concat(awayDifferentSignerErrorFields)
                                            }
                                            if (requiresHeadCoachInfo) {
                                                errorFields = errorFields.concat(Object.keys(headCoachInfo));
                                            }
                                            errorFields.forEach(field => {
                                                
                                                if ([
                                                    'away_read_only_recipients',
                                                    'away_internal_approvers'
                                                ].includes(field)) {
                                                    if (checkListEmails(this.state[field])) {
                                                        errors[field] = value => checkListEmails(value)
                                                    }
                                                } else if ([
                                                    'away_submitter_email',
                                                    'away_different_signer_email_new'
                                                ].includes(field)) {
                                                    if (!validateEmail(this.state[field])) {
                                                        errors[field] = value => !validateEmail(value)
                                                    }
                                                } else if (Object.keys(headCoachInfo).length > 0 && Object.keys(headCoachInfo).includes(field)) {
                                                    if (!this.state.headCoachInfo[field]) {
                                                        errors[field] = value => !value
                                                    }
                                                } else if (!this.state[field]) {
                                                    errors[field] = value => !value
                                                } 
                                            })
                                            if (Object.keys(errors).length > 0) {
                                                this.setState({errors, attemptedSubmit: true})
                                            } else {
                                                contestSubmit({
                                                    variables: {
                                                        guid: this.props.guid,
                                                        submit_type: this.resolveSubmitType(),
                                                        away_different_signer: this.state.away_different_signer,
                                                        away_different_signer_email: this.state.away_different_signer_email === 'Add a new recipient' ? this.state.away_different_signer_email_new : this.state.away_different_signer_email,
                                                        away_read_only_recipients: this.state.away_read_only_recipients,
                                                        away_requires_internal_approval: this.state.away_requires_internal_approval,
                                                        away_internal_approvers: this.state.away_internal_approvers.replace(/ /g, ''),
                                                        away_submitter_email: this.state.away_submitter_email,
                                                        away_submitter_name: this.state.away_submitter_name,
                                                        firstSubmit: false,
                                                        ...headCoachInfo
                                                    }
                                                }).then(() => {
                                                    if (requiresHeadCoachInfo) {
                                                        let updated = false;
                                                        Object.keys(requireAwayHeadCoachInfo).forEach(key => {
                                                            if (requireAwayHeadCoachInfo[key]) {
                                                                if (key === 'name') {
                                                                    if (headCoachInfo[`away_head_coach_${key}`] !== awaySportDefault.head_coach) {
                                                                        updated = true;
                                                                        awaySportDefault.head_coach = headCoachInfo[`away_head_coach_${key}`];
                                                                    }
                                                
                                                                } else {
                                                                    if (headCoachInfo[`away_head_coach_${key}`] !== awaySportDefault[`head_coach_${key}`]) {
                                                                        updated = true
                                                                        awaySportDefault[`head_coach_${key}`] = headCoachInfo[`away_head_coach_${key}`]
                                                                    }
                                                
                                                                }
                                                            }
                                                        })
                                                        if (updated) {
                                                            updateAwayDefaults({
                                                                variables: {
                                                                    orgid: contest.away_orgid,
                                                                    properties: JSON.stringify(awaySportsDefault)
                                                                }
                                                            })
                                                        }
                                                    }
                                                    this.handleClose();
                                                    this.props.handleSigned(true, false)
                                                })
                                            }}
                                        }
                                    >
                                        Submit Contract
                                    </Button>
                                )
                            }}
                            
                        </Mutation>
                    )}
                    
                    <Button onClick={this.handleClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export class AwayDifferentSigner extends Component {
    state = {
        modalOpen: false,
        email: '',
        phone: '',
        signature: '',
        title: '',
        errors: {},
    }

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })

    formUpdate = (nextState) => {
        this.setState(nextState)
    }

    notify = success => {
    
        if (success) {
            toast.success("Thank you for signing!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            });
        }
    }

    render() { 
        return (
            <Modal
                size={'tiny'}
                
                open={this.state.modalOpen}
                trigger={
                    <Button primary fluid onClick={this.handleOpen}>Sign Contract</Button>
                }
            >
                <Modal.Header>Sign Contract</Modal.Header>
                <Modal.Content>
                    <div>
                        <p>
                            Please fill out the information below. When you click the 
                            "Sign Contract" button at the bottom of this window, you 
                            will have signed this contract. You will automatically receive 
                            a copy of the fully executed contract at the email address 
                            you list below.
                        </p>
                        <div>
                            <Form>
                                <FormRowInput 
                                    field={'email'}
                                    label={'Email'}
                                    error={this.state.errors.email && this.state.errors.email(this.state.email)}
                                    required={true}
                                    placeholder={'Type your email'}
                                    value={this.state.email}
                                    formUpdate={this.formUpdate}
                                />
                                <FormRowInput 
                                    field={'phone'}
                                    label={'Phone'}
                                    error={this.state.errors.phone && this.state.errors.phone(this.state.phone)}
                                    required={true}
                                    value={this.state.phone}
                                    placeholder={'Type your phone number'}
                                    formUpdate={this.formUpdate}
                                />
                                <FormRowInput 
                                    field={'title'}
                                    label={'Title'}
                                    error={this.state.errors.title && this.state.errors.title(this.state.title)}
                                    required={true}
                                    value={this.state.title}
                                    placeholder={'Type your title'}
                                    formUpdate={this.formUpdate}
                                />
                                <FormRowInput 
                                    field={'signature'}
                                    label={'Signature'}
                                    error={this.state.errors.signature && this.state.errors.signature(this.state.signature)}
                                    required={true}
                                    value={this.state.signature}
                                    placeholder={'Type your full name'}
                                    formUpdate={this.formUpdate}
                                />
                            </Form>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}
                            >
                                <div style={{flex: 1, margin: '0 7px'}} />
                                <div style={{flex: 3, margin: '0 10px'}}>
                                    <p>
                                        By typing your name here you are signing your approval of this contract.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Mutation
                        mutation={contestSignMutation}
                    >
                        {(contestSign, {data, loading, error}) => {
                            return (
                                <Button 
                                    primary
                                    onClick={() => {
                                        let { newContest } = this.state;
                                        let errors = {};
                                        let errorFields = ['email', 'title', 'signature', 'phone']
                                        errorFields.forEach(field => {
                                            if (!this.state[field]) {
                                                errors[field] = value => !value
                                            }
                                            if (field === 'email') {
                                                if (!validateEmail(this.state[field])) {
                                                    errors[field] = value => !validateEmail(value)
                                                }
                                            }
                                        })
                                        if (Object.keys(errors).length > 0) {
                                            this.setState({errors})
                                        } else {
                                            contestSign({
                                                variables: {
                                                    guid: this.props.guid,
                                                    isHomeSignature: false,
                                                    email: this.state.email,
                                                    phone: this.state.phone,
                                                    signature: this.state.signature,
                                                    title: this.state.title,
                                                }
                                            }).then(() => {
                                                this.props.handleSigned(true, true, {
                                                    email: this.state.email,
                                                    name: this.state.signature,
                                                    phone: this.state.phone
                                                });
                                                this.props.contractRefetch();
                                                
                                                this.handleClose();
                                            })
                                        }
                                    }}
                                >
                                    Sign Contract
                                </Button>
                            )
                        }}
                        
                    </Mutation>
                    <Button onClick={this.handleClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export class Approver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            email: props.identity || '',
            title: '',
            signature: '',
            errors: {},
        }
    }

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })

    formUpdate = (nextState) => {
        this.setState(nextState)
    }

    notify = success => {
        if (success) {
            toast.success("Thank you for approving!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            });
        }
    }

    render() {
        return (
            <Modal
                size={'tiny'}
                onClose={() => {console.log('closed')}}
                open={this.state.modalOpen}
                trigger={
                    <Button primary fluid onClick={this.handleOpen}>Approve Contract</Button>
                }
            >
                <Modal.Header>Approve Contract</Modal.Header>
                <Modal.Content>

                    <div>
                        <Form>
                            <FormRowInput 
                                field={'email'}
                                label={'Email'}
                                disabled
                                error={this.state.errors.email && this.state.errors.email(this.state.email)}
                                required={true}
                                placeholder={'Type your email'}
                                value={this.state.email}
                                formUpdate={this.formUpdate}
                            />
                            <FormRowInput 
                                field={'title'}
                                label={'Title'}
                                error={this.state.errors.title && this.state.errors.title(this.state.title)}
                                required={true}
                                value={this.state.title}
                                placeholder={'Type your title'}
                                formUpdate={this.formUpdate}
                            />
                            <FormRowInput 
                                field={'signature'}
                                label={'Signature'}
                                error={this.state.errors.signature && this.state.errors.signature(this.state.signature)}
                                required={true}
                                value={this.state.signature}
                                placeholder={'Type your full name'}
                                formUpdate={this.formUpdate}
                            />
                        </Form>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <div style={{flex: 1, margin: '0 7px'}} />
                            <div style={{flex: 3, margin: '0 10px'}}>
                                <p>
                                    By typing your name here, you approve this contract.  Your approval is for internal purposes only — you are not the final signatory from your institution.
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Mutation
                        mutation={contestApproveMutation}
                    >
                        {(contestApprove, { data, loading, error }) => {
                            return (
                                <Button 
                                    primary
                                    onClick={() => {
                                        let { newContest } = this.state;
                                        let errors = {};
                                        let errorFields = ['email', 'title', 'signature']
                                        errorFields.forEach(field => {
                                            if (!this.state[field]) {
                                                errors[field] = value => !value
                                            }
                                            if (field === 'email') {
                                                if (this.state[field].indexOf('@') <= 0) {
                                                    errors[field] = value => value.indexOf('@') <= 0
                                                }
                                            }
                                        })
                                        if (Object.keys(errors).length > 0) {
                                            this.setState({errors})
                                        } else {
                                            contestApprove({
                                                variables: {
                                                    guid: this.props.guid,
                                                    isHomeApproval: this.props.type === 'home_approver',
                                                    email: this.state.email,
                                                    title: this.state.title,
                                                    signature: this.state.signature,
                                                }
                                            }).then(({data}) => {
                                                this.props.contractRefetch();
                                                this.notify(true);
                                                this.props.handleApproved(true);
                                                this.handleClose();
                                            })
                                        }
                                    }}
                                >
                                    Approve Contract
                                </Button>
                            )
                        }}
                    </Mutation>
                    <Button onClick={this.handleClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        )
    }
}

export class ContactSender extends Component {
    state = {
        modalOpen: false,
        name: '',
        email: '',
        message: ''
    };

    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })

    formUpdate = (nextState) => {
        this.setState(nextState)
    }

    notify = success => {
    
        if (success) {
            toast.success("Your message has been sent!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            });
        }
    }

    render() {
        let {contest} = this.props;
        return (
            <Modal
                size={'tiny'}
                
                open={this.state.modalOpen}
                trigger={
                    <Button fluid onClick={this.handleOpen}>Contact Sender</Button>
                }
            >
                <Modal.Header>Contact Owner</Modal.Header>
                <Modal.Content>
                    <div>
                        {`Question about the terms of this contract? Use this form to send a message directly to ${contest.submitter_fullname} (${contest.submitter_email})`}
                    </div>
                    <Form>
                        <FormRowInput
                            field={'name'}
                            placeholder={'Type your name'}
                            value={this.state.name}
                            label={'Your Name'}
                            formUpdate={this.formUpdate}
                        />
                        <FormRowInput
                            field={'email'}
                            placeholder={'Type your email'}
                            value={this.state.email}
                            label={'Your Email'}
                            formUpdate={this.formUpdate}
                        />
                        <FormRowTextArea 
                            field='message'
                            placeholder='Type your message'
                            value={this.state.message}
                            label='Message'
                            formUpdate={this.formUpdate}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose}>Close</Button>
                    <Mutation
                        mutation={contactOwnerMutation}
                    >
                        {(contactOwner, {data, loading, error}) => {
                            return (
                                <Button 
                                    primary 
                                    onClick={() => {
                                        contactOwner({
                                            variables: {
                                                guid: this.props.guid,
                                                sender_email: this.state.email,
                                                sender_name: this.state.name,
                                                message: this.state.message
                                            }
                                        }).then(() => {
                                            this.handleClose();
                                            this.notify(true);
                                        })
                                    }}
                                >
                                    Send Message
                                </Button>
                            )
                        }}
                    </Mutation>
                </Modal.Actions>
            </Modal>
        )
    }
}
