import React, {
    Component,
    FunctionComponent,
    useState,
    useEffect,
} from 'react';
import { Mutation, Query } from 'react-apollo';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { Button, Form, Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { ModalFormInput, ModalFormTextArea } from './modals';

import { contactUsMutation } from '../../gql/contact';

import { orgQuery } from '../../gql/org';

const DemoModalInput: React.FunctionComponent<{
    name: string;
    value: string;
    required?: boolean;
    onChange: (field: string, value: string) => void;
}> = ({ name, value, onChange, required }) => {
    return (
        <Form.Input
            name={name.toLowerCase()}
            value={value}
            type="text"
            fluid
            required={required}
            placeholder={name}
            onChange={(event, { name, value }) => onChange(name, value)}
        />
    );
};

const defaultContactForm = {
    email: '',
    name: '',
    phone: '',
    city: '',
    state: '',
};

export const RequestNewOrgModal: React.FunctionComponent<{
    onClose: () => void;
    open: boolean;
    user?: any;
    input?: string;
}> = ({ user = {}, input, open, onClose }) => {
    console.log({ input });

    const [contactForm, setContactForm] = useState<{
        email: string;
        name: string;
        phone: string;
        city: string;
        state: string;
        website?: string;
        conference?: string;
    }>({
        ...defaultContactForm,
    });
    const [newOrg, setNewOrg] = useState<string>('');
    const [school, setSchool] = useState<string>('');
    const { email, name, phone, city, state, website, conference } =
        contactForm;

    useEffect(() => {
        const newForm = {
            ...contactForm,
            email: user.email || '',
            name:
                user.firstname && user.lastname
                    ? `${user.firstname} ${user.lastname}`
                    : '',
            phone: user.phone_work || '',
        };
        setContactForm(newForm);
    }, [JSON.stringify(user)]);

    useEffect(() => {
        setNewOrg(input || '');
    }, [input]);

    const orgGql = useQuery(orgQuery, {
        variables: {
            id: user?.orgid,
        },
        skip: !user?.orgid,
    });

    useEffect(() => {
        if (orgGql?.data?.org) {
            setSchool(orgGql.data.org.name || '');
        }
    }, [JSON.stringify(orgGql)]);

    const contactUs = useMutation(contactUsMutation);

    const handleChange = (field: any, val: any) => {
        setContactForm({
            ...contactForm,
            [field]: val,
        });
    };

    const handleSuccess = () => {
        toast.success(
            'Your request has been received! We will contact you shortly when we have made the update.',
            {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            }
        );
    };
    console.log({ user, contactForm });

    return (
        <Modal onClose={onClose} open={open} size="small">
            <Modal.Header>Request A New Organization Update</Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="New or Updated Organization"
                        value={newOrg}
                        onChange={(f, val) => {
                            setNewOrg(val);
                        }}
                    />
                </div>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="City"
                        value={city}
                        onChange={(f, val) => {
                            setContactForm({
                                ...contactForm,
                                city: val,
                            });
                        }}
                    />
                </div>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="State"
                        value={state}
                        onChange={(f, val) => {
                            setContactForm({
                                ...contactForm,
                                state: val,
                            });
                        }}
                    />
                </div>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="Website (optional)"
                        value={website || ''}
                        onChange={(f, val) => {
                            setContactForm({
                                ...contactForm,
                                website: val,
                            });
                        }}
                    />
                </div>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="Conference (optional)"
                        value={conference || ''}
                        onChange={(f, val) => {
                            setContactForm({
                                ...contactForm,
                                conference: val,
                            });
                        }}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={!email || !city || !state}
                    primary
                    onClick={() => {
                        if (email) {
                            contactUs({
                                variables: {
                                    ...contactForm,
                                    school,
                                    message: `
                                        School to be added: ${newOrg} ---             
                                        City: ${city} --- \n
                                        State: ${state} --- \n
                                        Website: ${website} --- \n
                                        Conference: ${conference}
                                    `,
                                    emailTemplate: 'none',
                                },
                            }).then(() => {
                                setContactForm(defaultContactForm);
                                onClose();
                                handleSuccess();
                            });
                        }
                    }}
                >
                    Request New Organization Update
                </Button>

                <Button onClick={onClose}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
};

export const FreeTrialModal: React.FunctionComponent<{
    onClose: () => void;
    open: boolean;
    user?: any;
    title?: string;
}> = ({ user = {}, title = 'Sign up for your Free Trial!', open, onClose }) => {
    const defaultContactForm = {
        school: '',
        message: '',
        demo: false,
        email: user.email || '',
        name:
            user.firstname && user.lastname
                ? `${user.firstname} ${user.lastname}`
                : '',
        phone: user.phone_work || '',
    };
    const [contactForm, setContactForm] = useState<{
        email: string;
        name: string;
        school: string;
        phone: string;
        message: string;
    }>({
        ...defaultContactForm,
    });
    const { email, name, school, phone } = contactForm;

    useEffect(() => {
        setContactForm({
            school: '',
            message: 'Free trial',
            email: user.email || '',
            name:
                user.firstname && user.lastname
                    ? `${user.firstname} ${user.lastname}`
                    : '',
            phone: user.phone_work || '',
        });
    }, [JSON.stringify(user)]);

    const contactUs = useMutation(contactUsMutation);

    const handleChange = (field: any, val: any) => {
        setContactForm({
            ...contactForm,
            [field]: val,
        });
    };

    const handleSuccess = () => {
        toast.success(
            'Your request has been received! We will contact you shortly with login details.',
            {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            }
        );
    };

    return (
        <Modal onClose={onClose} open={open} size="mini">
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="Email"
                        value={email}
                        onChange={handleChange}
                    />
                </div>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="Name"
                        value={name}
                        onChange={handleChange}
                    />
                </div>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="School"
                        value={school}
                        onChange={handleChange}
                    />
                </div>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="Phone"
                        value={phone}
                        onChange={handleChange}
                    />
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    primary
                    onClick={() => {
                        // add email validation
                        if (email) {
                            contactUs({
                                variables: {
                                    ...contactForm,
                                    emailTemplate: 'freeTrial',
                                },
                            }).then(() => {
                                setContactForm(defaultContactForm);
                                onClose();
                                handleSuccess();
                            });
                        }
                    }}
                >
                    Sign Up for Free Trial
                </Button>

                <Button onClick={onClose}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
};

const RequestDemoModal: React.FunctionComponent<{
    user: any;
    triggerFunc: (
        func: React.Dispatch<React.SetStateAction<boolean>>
    ) => React.ReactElement;
    title: string;
    demo: boolean;
}> = ({
    user = {},
    triggerFunc = () => null,
    title = 'Request A Demo',
    demo,
}) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const defaultContactForm = {
        school: '',
        message: '',
        demo: true,
        email: user.email || '',
        name:
            user.firstname && user.lastname
                ? `${user.firstname} ${user.lastname}`
                : '',
        phone: user.phone_work || '',
    };
    const [contactForm, setContactForm] = useState<{
        email: string;
        name: string;
        school: string;
        phone: string;
        message: string;
    }>({
        ...defaultContactForm,
    });
    const { email, name, school, phone, message } = contactForm;
    const orgGql = useQuery(orgQuery, {
        variables: {
            id: user?.orgid,
        },
        skip: !user?.orgid,
    });

    useEffect(() => {
        setContactForm({
            school: '',
            message: '',
            email: user.email || '',
            name:
                user.firstname && user.lastname
                    ? `${user.firstname} ${user.lastname}`
                    : '',
            phone: user.phone_work || '',
        });
    }, [JSON.stringify(user)]);

    useEffect(() => {
        if (orgGql?.data?.org) {
            setContactForm({
                ...contactForm,
                school: orgGql.data.org.name,
            });
        }
    }, [JSON.stringify(orgGql)]);

    const contactUs = useMutation(contactUsMutation);

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleChange = (field: any, val: any) => {
        setContactForm({
            ...contactForm,
            [field]: val,
        });
    };

    const handleSuccess = () => {
        toast.success(
            'Your message has been received! We will contact you shortly.',
            {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            }
        );
    };

    return (
        <Modal
            trigger={triggerFunc(setModalOpen)}
            onClose={handleClose}
            open={modalOpen}
            size="mini"
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="Email"
                        value={email}
                        onChange={handleChange}
                    />
                </div>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="Name"
                        value={name}
                        onChange={handleChange}
                    />
                </div>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="School"
                        value={school}
                        onChange={handleChange}
                    />
                </div>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <DemoModalInput
                        name="Phone"
                        value={phone}
                        onChange={handleChange}
                    />
                </div>
                <div
                    style={{
                        margin: '10px 0',
                    }}
                >
                    <Form>
                        <ModalFormTextArea
                            name="Message"
                            defaultValue={message}
                            onChange={handleChange}
                        />
                    </Form>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    primary
                    onClick={() => {
                        if (email) {
                            contactUs({
                                variables: {
                                    demo,
                                    ...contactForm,
                                },
                            }).then(() => {
                                setContactForm(defaultContactForm);
                                handleClose();
                                handleSuccess();
                            });
                        }
                    }}
                >
                    Submit
                </Button>

                <Button onClick={handleClose}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
};

// class RequestDemoModal extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             modalOpen: false,
//             email: props.user.email || '',
//             name: props.user.firstname && props.user.lastname ? `${props.user.firstname} ${props.user.lastname}` : '',
//             message: '',
//             phone: props.user.phone_work || '',
//             school: '',
//         }
//     }

//     notify = success => {
//         if (success) {
//             toast.success("Your message has been received! We will contact you shortly.", {
//                 position: toast.POSITION.TOP_CENTER,
//                 autoClose: 4000,
//                 hideProgressBar: true,
//             });
//         }
//     }

//     requestChange = (attr, value) => {
//         this.setState({
//             [attr]: value
//         })
//     }

//     handleOpen = ({messagePlaceholder}) => this.setState({ modalOpen: true, message: messagePlaceholder })

//     handleClose = () => this.setState({ modalOpen: false })

//     render() {
//         let {user} = this.props;
//         return (
//             <Modal
//                 trigger={this.props.trigger}
//                 open={this.state.modalOpen}
//                 onClose={this.handleClose}
//                 size={'mini'}
//                 closeIcon
//             >

//             </Modal>
//         )
//     }
// }

export default RequestDemoModal;
