import React, {Component, useEffect, useState} from 'react';
import qs from 'qs';

import {Button} from 'semantic-ui-react';

import {
    BaseModal, 
    BaseControlledModal,
    ModalAction,
    ModalDropdownTypeahead,
    ModalSelect,
    ModalFormInput,
    ModalFormTextArea 
} from './modals';
import Spinner from '../utilityComponents/spinner';
import privacyPolicy from '../policies/privacyPolicy';
import termsOfService from '../policies/termsOfService';
import ChangeLog from '../changelog/changelog';

export const Policies = (props) => {
    let {style={}} = props;
    const [state, setState] = useState({changelogOpen: false})
    let urlQuery = qs.parse(props.location.search);
    console.log({urlQuery})
    useEffect(() => {
        if (urlQuery.policy || urlQuery['?policy']) {
            setState({
                showPolicy: true,
                document: urlQuery.policy || urlQuery['?policy']
            })
        }
    }, [urlQuery.policy, urlQuery['?policy']])
    return (
        <div
            style={{
                // position: 'absolute',
                // bottom: 0,
                // left: 0,
                // right: 0
            }}
        >
            <PolicyModal 
                document={state.document}
                open={state.showPolicy}
                onClose={() => {
                    setState({
                        showPolicy: false
                    })
                }}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '25px 0',
                    backgroundColor: style.backgroundColor || 'white'
                }}
            >
                <div
                    onClick={() => {
                        setState({
                            showPolicy: true,
                            document: 'tos'
                        })
                    }}
                    style={{
                        textDecoration: 'underline',
                        marginRight: '10px',
                        cursor: 'pointer'
                    }}
                >
                    Terms of Service
                </div>
                <div
                    onClick={() => {
                        setState({
                            showPolicy: true,
                            document: 'privacy'
                        })
                    }}
                    style={{
                        textDecoration: 'underline',
                        marginRight: '10px',
                        cursor: 'pointer'
                    }}
                >
                    Privacy Policy
                </div>
                <ChangeLog 
                    hideGotIt
                    trigger={(
                        <div
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                        >
                            Change Log
                        </div>
                    )}
                />
            </div>
        </div>
    )
    
}

class PolicyModal extends Component {
    render() {
        let {document, onClose, open} = this.props;
        let html;
        let header;
        switch (document) {
            case 'privacy':
                html = privacyPolicy
                header = 'Privacy Policy'
                break;
            case 'tos':
                html = termsOfService
                header = 'Terms of Service'
                break;
        }
        
        return(
            <BaseControlledModal
                header={header}
                open={open}
                onClose={onClose}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '40px'
                    }}
                >
                    <div
                        style={{
                            width: '800px',
                            textAlign: 'left',
                        }}
                    >
                        <div 
                            dangerouslySetInnerHTML={{__html: html}}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                border: '1px solid grey',
                                boxShadow: '5px 5px 8px #aaa',
                            }}>
                        </div>
                    </div>
                </div>
            </BaseControlledModal>
        )
    }
};

export default PolicyModal
