/* eslint-disable react/no-unescaped-entities */
import React, { Component, useState } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

const itemContainer = {
    marginBottom: '1.5em',
};

const itemHeader = {
    fontWeight: 'bold',
    fontSize: '1.2em',
};

const newestItemContainer = {
    ...itemContainer,
    backgroundColor: 'rgba(33, 186, 69, .2)',
    borderRadius: '5px',
    padding: '5px',
};

const ChangeLog = (props) => {
    const { open: propsOpen, trigger, hideGotIt } = props;
    let [open, setOpen] = useState(propsOpen);

    return (
        <div>
            {trigger && <div onClick={() => setOpen(true)}>{trigger}</div>}

            <Modal closeIcon open={open} onClose={() => setOpen(false)}>
                <Modal.Header
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        Change Log
                    </div>
                    {!hideGotIt && (
                        <Button
                            primary
                            onClick={() => {
                                props.onGotIt().then(() => {
                                    setOpen(false);
                                });
                            }}
                        >
                            Got it &mdash; hide this window until next update
                        </Button>
                    )}
                </Modal.Header>
                <Modal.Content scrolling>
                    <div style={newestItemContainer}>
                        <div style={itemHeader}>
                            July 24, 2021 &mdash; Contract Defaults: "All
                            Sports" vs. sport-specific defaults
                        </div>
                        <div>
                            After fielding a number of questions on the topic,
                            we realized that the interaction between the
                            individual sport defaults and the "All Sports"
                            category was a bit confusing. As a quick refresher,
                            sport-specific contract defaults will override
                            defaults set under the "All Sports" section. When a
                            contract is created, each field will first check for
                            defaults set under the specific sport, and if there
                            is a default saved, the sport-specific default will
                            apply. If there is no sport-specific default set,
                            the field will then check the "All Sports" default
                            section. If both the sport-specific field and the
                            "All Sports" field are blank, no default information
                            will be entered into the contract for that field. We
                            have hopefully made this interaction a bit more
                            intuitive with{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                two changes to the "Edit Contract Defaults"
                                page:
                            </span>
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            First, you will see an icon (<Icon name="sitemap" />
                            ) next to a field if that field has text entered in
                            both the "All Sports" category and the individual
                            sport defaults. When looking at the "All Sports"
                            section, if you click on the <Icon name="sitemap" />{' '}
                            icon for a particular field, you will see a list of
                            sports where a sport-specific entry exists (which
                            will override the "All Sports" entry for that
                            field). When looking at a individual sport, on the
                            other hand, if you click on the{' '}
                            <Icon name="sitemap" /> icon for a particular field,
                            you will see the language from the "All Sports"
                            category that will be overriden by the
                            sport-specific language you have entered in that
                            field.
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            Second, if you are looking at a specific sport's
                            defaults, you will see placeholder text in any field
                            where there is an entry in "All Sports" but no
                            overriding sport-specifc entry. This should allow
                            you to better see which fields will be filled in by
                            the "All Sports" defaults.
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            As always, we would love to hear any feedback you
                            may have on this change. We hope this makes it
                            easier to manage your contracts!
                        </div>
                    </div>
                    <div style={itemContainer}>
                        <div style={itemHeader}>
                            July 17, 2020 &mdash; Tutorial Videos; Contest
                            Cancellation Feature
                        </div>
                        <div
                            style={{
                                marginTop: '12px',
                                textDecoration: 'underline',
                            }}
                        >
                            Contest Cancellation Feature
                        </div>
                        <div>
                            With many institutions needing to cancel contests
                            due to COVID-19, we wanted to make it easier for you
                            to both 1) notify opponents of cancellations, as
                            well as 2) mark contests as cancelled on your
                            schedule. In the "Edit Contest" window, you will see
                            a new button at the bottom of the window labeled
                            "Cancel Contest". If you click this button, you will
                            receive a confirmation popup which requires you to
                            type in a reason for the cancellation.
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            When you confirm the cancellation, an email will be
                            sent to both you and the opposing school with
                            notification that the contest is cancelled, and this
                            email will include the reason you typed when
                            confirming the cancellation. In addition to
                            notifying the opposing school, our system will also
                            mark cancelled contests with a new "Cancelled"
                            status. This status has its own status icon (
                            <Icon color="green" name="copyright" />
                            ), which should make it easier to keep track of on
                            your schedule.
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            Please note that this functionality does not affect
                            your legal right to cancel a contest with a signed
                            contract in place &mdash; this is determined by the
                            terms of your contest contract. Please let us know
                            if you have any suggestions on how this feature can
                            be improved!
                        </div>
                        <div
                            style={{
                                marginTop: '12px',
                                textDecoration: 'underline',
                            }}
                        >
                            Tutorial Videos
                        </div>
                        <div>
                            We have added a series of video tutorials on how to
                            use different features of our platform. The videos
                            are available in the FAQ section on the top
                            navigation bar. You can also access the videos in
                            their relevant sections of the platform by clicking
                            the small information icons at the top of the
                            section &mdash; the tutorial on sending contracts is
                            viewable in the Send Contract window, the contract
                            defaults tutorial is viewable in the Edit Contract
                            Defaults page, etc.
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            These videos are a great way to get a refresher on
                            certain functions, as well as an easy method of
                            getting new staff members up to speed on how our
                            platform works. We are also putting up a new section
                            called "How It Works" on the main homepage that is
                            visible to non-users -- these videos will be
                            available on that page as well, so they are a great
                            way to let other people know about Perfect Record!
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            Our videos cover a number of different topics, but
                            if you have suggestions for additional tutorial
                            videos that would be helpful, please let us know!
                        </div>
                    </div>
                    <div>
                        <div style={itemHeader}>
                            November 21, 2019 &mdash; Improved Sent Contract
                            Management
                        </div>
                        <div>
                            We have completely reworked (and improved!) the
                            handling of contests when they are "in process",
                            i.e. they have been sent out, but aren't yet
                            complete. Once a contract has been initially sent,
                            instead of seeing a "Re-send contract" button on the
                            schedule page, you will now see a "Contract Status"
                            button. This contract status window will allow you
                            to better control the recipients of the contract.
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            For example, say you want to change the recipient of
                            the contract at the away school, but your internal
                            approver has already signed off on the contract.
                            Under the old system, you had to completely re-send
                            the contract from the beginning to change any
                            recipient. With the new contract status window, you
                            can change any current or future recipient of the
                            contract with having to restart the entire signature
                            process.
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            As always, we would love to hear any feedback you
                            may have on this change. We hope this makes it
                            easier to manage your contracts!
                        </div>
                    </div>
                    <div style={itemContainer}>
                        <div style={itemHeader}>
                            October 8, 2019 &mdash; Schedule export to CSV
                            (Excel file)
                        </div>
                        <div>
                            On the SCHEDULE page, you can now export part or all
                            of your schedule into a .csv file (which can be
                            opened by Microsoft Excel). Simply filter your
                            schedule view to show the portion of the schedule
                            that you want to download (filter by sport, for
                            example), then click the green "Export Visible
                            Schedule" button along the left side of the page.
                        </div>
                    </div>
                    <div style={itemContainer}>
                        <div style={itemHeader}>
                            September 27, 2019 &mdash; Bug fix: default internal
                            approvers
                        </div>
                        <div>
                            We identified and fixed a bug that was preventing
                            certain contracts from displaying properly. There
                            was an error occurring when contests were re-sent in
                            a sport that had default internal approvers selected
                            on the MY ACCOUNT page.
                        </div>
                    </div>
                    <div style={itemContainer}>
                        <div style={itemHeader}>
                            May 7, 2019 &mdash; Defaults for contract send
                            options; Streamlined contract recipient lists
                        </div>
                        <div
                            style={{
                                marginTop: '12px',
                                textDecoration: 'underline',
                            }}
                        >
                            Defaults for contract send options
                        </div>
                        <div>
                            You may now set defaults for the various options for
                            sending a contract (internal approvers, different
                            primary signer, etc.). As with other default
                            settings, these defaults will only apply to
                            contracts created <em>after</em> setting the
                            defaults.
                        </div>
                        <div
                            style={{
                                marginTop: '12px',
                                textDecoration: 'underline',
                            }}
                        >
                            Streamlined contract recipient lists
                        </div>
                        <div>
                            &bull; When opposing schools prepare to send your
                            school a contract, the only possibile recipients
                            they will see will be your users with signature
                            authority for that sport (admins, and those with
                            sport write permissions).
                            <br />
                            &bull; After a non-client signs a contract, we will
                            prompt them to select who should receive future
                            contracts for their institution. This should help us
                            maintain a clean database for non-clients, ensuring
                            that the correct people show up as possible
                            recipients for all schools.
                        </div>
                    </div>
                    <div style={itemContainer}>
                        <div style={itemHeader}>
                            April 29, 2019 &mdash; Require head coach
                            information for away school (optional)
                        </div>
                        <div>
                            You may now choose to require the away school to
                            enter information about their head coach (name,
                            email, and/or phone) when they sign your contracts.
                            Previously we required away schools to enter only
                            the signer's information (name, title, email,
                            phone). If you require an away school to include its
                            head coach information, your own institution's head
                            coach information will also appear on the contract.
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            You can require this away coach information on a
                            contract-by-contract basis when you send out the
                            contract. You can also set defaults for this option
                            on a sport-by-sport basis (MY ACCOUNT -&gt; My
                            Organization -&gt; Edit Default Contract Fields
                            -&gt; Require Away Head Coach Info).
                        </div>
                    </div>
                    <div style={itemContainer}>
                        <div style={itemHeader}>
                            April 12, 2019 &mdash; Flexible User Permissions;
                            Customized Sport List
                        </div>
                        <div
                            style={{
                                marginTop: '12px',
                                textDecoration: 'underline',
                            }}
                        >
                            Flexible User Permissions
                        </div>
                        <div>
                            You may now set detailed permission levels for other
                            users within your organization. User permissions are
                            set on the MY ACCOUNT page (My Organization's Other
                            Users).
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            <strong>Administrator authority</strong>: full
                            authority to act on behalf of your organization,
                            including setting permission levels for other users.
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            <strong>Sport-by-sport authority</strong>:
                        </div>
                        <div style={{ marginLeft: '20px' }}>
                            “Can read”: view contracts only
                        </div>
                        <div style={{ marginLeft: '20px' }}>
                            “Can write”: view contracts, create contracts, send
                            contracts, set defaults
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            <em>Please note</em>: All of your organization's
                            existing users as of the date of this update have
                            Administrator authority. Please decide within your
                            organization who should be allowed to retain this
                            status, keeping in mind that any Administrator can
                            change the permission levels of other users within
                            your organization.
                        </div>
                        <div
                            style={{
                                marginTop: '12px',
                                textDecoration: 'underline',
                            }}
                        >
                            Customized Sport List
                        </div>
                        <div>
                            You may now customize your sport list to show only
                            your organization's sponsored sports, which will
                            streamline certain sections of the platform
                            (contract defaults, user permissions, etc.). To edit
                            your sport list, head to the MY ACCOUNT page and
                            click on the "Edit My Organization's Sports" button
                            under the My Organization section of the page.
                        </div>
                    </div>
                    <div style={itemContainer}>
                        <div style={itemHeader}>
                            March 28, 2019 &mdash; Change Log; Bulk Contest
                            Creation
                        </div>
                        <div>
                            Welcome to our new change log! If you click the blue
                            button at the top of this window, you won't see this
                            change log again until we add in a new feature that
                            we think you should know about. You can always
                            access this change log by clicking the button at the
                            bottom of any page on our platform.
                        </div>
                        <div style={{ marginTop: '12px' }}>
                            Bulk contest creation is here! This feature will
                            allow you to upload a spreadsheet to create multiple
                            contests at once. To start using bulk create, click
                            on the "Create Multiple Contests" button on the
                            schedule page. You can learn more about how bulk
                            creation works by clicking the "Instructions" button
                            on the bulk create page.
                        </div>
                    </div>
                    <div style={itemContainer}>
                        <div style={itemHeader}>
                            March 13, 2019 &mdash; Updated Date Picker Tool
                        </div>
                        <div>
                            We replaced the date picker tool (used to select the
                            contest date when creating/editing a contest) with a
                            different version. This new tool is more
                            streamlined, easier to use, and allows you to select
                            the week format for the calendar you see (Sun-Sat or
                            Mon-Sun).
                        </div>
                    </div>
                    <div style={itemContainer}>
                        <div style={itemHeader}>
                            March 3, 2019 &mdash; Custom Reminder Intervals
                        </div>
                        <div>
                            When we first launched the automatic reminders,
                            reminders were hard-coded to happen every 72 hours
                            for each incomplete future contest. You can now
                            customize the automatic reminder settings for each
                            of your contests individually.
                        </div>
                        <ul>
                            <li>
                                You can set defaults (yes/no for automatic
                                reminders, and reminder internal) on a
                                sport-by-sport basis (MY ACCOUNT -&gt; My
                            </li>
                            <li>
                                When you send a contract, your default reminder
                                choice will appear at the bottom of the window,
                                and you can edit the reminder settings for each
                                contract as it goes out
                            </li>
                            <li>
                                After a contract has been sent, you can still
                                alter its reminder interval through the contract
                                history panel (accessed by clicking the contract
                                status icon on the schedule page)
                            </li>
                        </ul>
                    </div>
                    <div style={itemContainer}>
                        <div style={itemHeader}>
                            March 1, 2019 &mdash; Updated Time Picker Tool
                        </div>
                        <div>
                            We replaced the time picker tool (used to select the
                            contest start time when creating/editing a contest)
                            with a different version. The previous time picker
                            did not work properly on Firefox, and this new time
                            picker is more minimal and streamlined.
                        </div>
                    </div>
                    <div style={itemContainer}>
                        <div style={itemHeader}>
                            February 21, 2019 &mdash; Major Update; Automatic
                            Reminders; New Login Instructions
                        </div>
                        <div>
                            Today we launched a major update to our system. Much
                            of this work was behind the scenes &mdash; you will
                            notice an updated look/feel to the platform, but the
                            functionality remains largely the same.
                        </div>
                        <div
                            style={{
                                marginTop: '12px',
                                textDecoration: 'underline',
                            }}
                        >
                            Automatic reminders
                        </div>
                        <div>
                            Our new platform includes an automatic contract
                            reminder system. If you have sent out a contract but
                            someone in the approval chain hasn’t signed yet, our
                            system will send that person a reminder email 72
                            hours later, and every 72 hours after that until
                            they sign the contract. This means you won’t need to
                            send manual reminders!
                        </div>
                        <div style={{ marginTop: '8px' }}>
                            <em>Note:</em> If any contracts you sent out through
                            our platform were signed and sent back to you
                            outside our platform (printed-out copy, etc), please
                            go into the system and mark those contracts as
                            complete so that the opposing school will not
                            receive reminder emails for them.
                        </div>
                        <div
                            style={{
                                marginTop: '12px',
                                textDecoration: 'underline',
                            }}
                        >
                            New log-in instructions
                        </div>
                        <ul>
                            <li>
                                Click "LOG IN" in the upper-right corner of the
                                landing page -- this will direct you to a
                                separate page through Auth0, our new log-in
                                system.
                            </li>
                            <li>
                                On this page, you will have two options, "Log
                                In" and "Sign Up".
                                <ul>
                                    <li>
                                        <strong>Sign Up:</strong> The first time
                                        you access the new platform, you should
                                        click on the "Sign Up" option, where you
                                        will enter your email address and create
                                        a new password for your account. You
                                        will then receive an email from our
                                        system asking you to verify your email
                                        address. Once you verify your address
                                        through the link in the email, you will
                                        be able to log in to the platform.
                                    </li>
                                    <li>
                                        <strong>Log In:</strong> Any future time
                                        you want to access the platform, enter
                                        your credentials under the "Log in"
                                        section instead of the "Sign Up"
                                        section.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                That's it! This new log-in system is more robust
                                that our previous one, and you should notice
                                some nice improvements (easier signing of away
                                contracts, not having to re-enter your password
                                every time you log in from the same machine,
                                etc.)
                            </li>
                        </ul>
                    </div>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default ChangeLog;
