import gql from 'graphql-tag';

export const refreshQuickbooksToken = gql`
    mutation refreshQuickbooksToken($orgid: ID!) {
        refreshQuickbooksToken(orgid: $orgid)
    }
`;

export interface QBCustomer {
    Id: string;
    DisplayName: string;
    PrimaryEmailAddr?: {
        Address: string;
    };
}

export const quickbooksCustomersQuery = gql`
    query quickbooksCustomers {
        quickbooksCustomers {
            Id
            DisplayName
            PrimaryEmailAddr {
                Address
            }
        }
    }
`;

export interface NewCustomer {
    email_address?: string;
    line1?: string;
    city?: string;
    region?: string;
    postal_code?: string;
    country?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
}

export const quickbooksAccountLink = gql`
    mutation quickbooksAccountLink(
        $orgid: ID
        $quickbooks_customer_id: ID
        $email_address: String
        $line1: String
        $region: String
        $postal_code: String
        $city: String
        $first_name: String
        $last_name: String
        $phone: String
        $country: String
    ) {
        quickbooksAccountLink(
            orgid: $orgid
            quickbooks_customer_id: $quickbooks_customer_id
            email_address: $email_address
            line1: $line1
            region: $region
            postal_code: $postal_code
            city: $city
            first_name: $first_name
            last_name: $last_name
            phone: $phone
            country: $country
        )
    }
`;

interface QBLine {
    Id: string;
    Amount: number;
    Description: string;
    SalesItemLineDetail: {
        ItemRef: {
            value: string;
            name: string;
        };
        Qty: number;
        UnitPrice: number;
    };
}

export type EmailStatusType = 'NotSet' | 'NeedToSend' | 'EmailSent';

export interface QBInvoice {
    Id: string;
    CustomerRef: {
        value: string;
        name: string;
    };
    SyncToken: string;
    BillEmail: { Address: string }; // must be a valid email
    TxnDate: string; // yyyy/MM/dd
    AllowOnlineACHPayment: boolean; // may need to track whether the client has this enabled
    AllowOnlineCreditCardPayment: boolean;
    DueDate: string; // UTC: YYYY-MM-DDZ
    PrivateNote?: string;
    CustomerMemo?: { value: string };
    EmailStatus?: EmailStatusType; // NotSet, NeedToSend, EmailSent
    TotalAmt: number;
    Balance: number;
    DocNumber: string;
    Line: QBLine[];
}

export const quickbooksInvoicesQuery = gql`
    query quickbooksInvoices($orgid: ID) {
        quickbooksInvoices(orgid: $orgid) {
            Id
            BillEmail {
                Address
            }
            TxnDate
            DueDate
            TotalAmt
            Balance
            EmailStatus
            DocNumber
            Line {
                Amount
                Description
                SalesItemLineDetail {
                    ItemRef {
                        value
                        name
                    }
                    Qty
                    UnitPrice
                }
            }
        }
    }
`;

export const quickbooksInvoiceCreateAndSend = gql`
    mutation quickbooksInvoiceCreateAndSend(
        $orgid: ID
        $due_date: String
        $txn_date: String
        $doc_number: String
        $bill_email: String
        $qty: Float
        $unit_price: Float
        $memo: String
        $sales_item_detail_item_ref_name: String
        $description: String
    ) {
        quickbooksInvoiceCreateAndSend(
            orgid: $orgid
            due_date: $due_date
            txn_date: $txn_date
            doc_number: $doc_number
            bill_email: $bill_email
            qty: $qty
            unit_price: $unit_price
            memo: $memo
            sales_item_detail_item_ref_name: $sales_item_detail_item_ref_name
            description: $description
        )
    }
`;
