import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';

import urls from './urls';

// const apiRoot = process.env.REACT_APP_API_ROOT || 'http://localhost:8080'
const apiRoot = urls.apiRoot  //'/api';


// let uri = `${apiRoot}/graphql`;
let uri = `${apiRoot}/graphql`;

const httpLink = createHttpLink({ uri });
const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors)
	  graphQLErrors.map(({ message, locations, path }) =>
		console.log(
		  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
		),
	  );
  
	if (networkError) console.log(`[Network error]: ${networkError}`);
})

const link = errorLink.concat(httpLink);

export const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
});
