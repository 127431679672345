import React, {Component} from 'react';
import {Mutation} from 'react-apollo';
import {
    Button,
    Checkbox,
    Form,
    Popup,
} from 'semantic-ui-react';
import {toast} from 'react-toastify';

import { contestRemoveMutation, contestUpdateMutation } from '../../gql/contest';
import s3, {albumBucketName } from '../../utils/s3';
import {
    sportSelectOptions
} from '../../utils/sports';

import {
    BaseModal, 
    BaseControlledModal,
    ModalAction,
    ModalSelect,
} from './modals';

import {
    FormRow,
    FormRowDropdown,
    FormRowInput,
    FormRowTextArea
} from '../utilityComponents/form/formRow';

import {
    CustomDatePicker,
    CustomTimePicker,
} from '../utilityComponents/form/materialUI';
import { statuses } from '../../utils/statuses';
import { ActionButton } from '../schedule/schedule';


let uploadContract = (contest, away=true, updateFunction, updateMeta={}) => {
    return new Promise((resolve, reject) => {
        var files = document.getElementById(`${contest.id}-contract-upload`).files;
        var file = files[0];
        var fileName = file.name;
        const params = {
			Body: file,
			Bucket: albumBucketName, 
			Key: `${contest.guid}.${file.type.split('/')[1]}`
		};
		s3.upload(params, (err, data) => {
		  	if (err) {
                reject(err.message)
				return alert('There was an error uploading your file: ', err.message);
			}
			// data.location = entire url
            // data.key = just the key within the contest-pdfs bucket
            updateFunction({
                variables: {
                    id: contest.id,
                    paper_contract_received: true,
                    paper_contract_key: data.key,
                    status: away ? statuses.AWAY_PLACEHOLDER_PAPER_RECEIVED : statuses.HOME_PAPER_CONTRACT_RECEIVED,
                    updateMeta
                }
            }).then(({data}) => {
                resolve(data)
            })
		});
    })
}

let AwayContestFields = props => {
    return (
        <div>
            {/* 
                paper contract received
                    upload received

                download uploaded
            */}
            <FormRow
                label='Select Opponent'
                required
            >
                <ModalSelect
                    placeholder='Opponent'
                    name='opponentId'
                    value={props.contest.opponentId}
                    options={props.opponentOptions}
                    onChange={(field, data) => props.contestChange(field, data.value)} 
                />
            </FormRow>
            <FormRow
                label='Sport'
                required
            >
                <ModalSelect
                    placeholder='Select sport'
                    name='sport'
                    multiple={true}
                    value={props.contest.sport}
                    options={sportSelectOptions}
                    onChange={(attr, data) => props.contestChange('sport', data.value)} 
                />
            </FormRow>
            <FormRow
                label={'Date'}
                required
            >
                <CustomDatePicker 
                    hintText="Date"
                    user={props.user}
                    value={new Date(props.contest.date)}
                    onChange={(data) => {props.contestChange('date', data)}}
                />
            </FormRow>
            <FormRow
                label={'Time'}
            >
                <CustomTimePicker 
                    hintText="Time - TBA"
                    minutesStep={5}
                    hour={props.contest.hour}
                    minute={props.contest.minute}
                    setTBA={() => {
                        props.contestChange('hour', 24);
                        props.contestChange('minute', 0);
                    }}
                    value={new Date(2018, 6, 1, props.contest.hour, props.contest.minute)}
                    onChange={(e, data) => {
                        props.contestChange('hour', data.getHours())
                        props.contestChange('minute', data.getMinutes())
                    }}
                />
            </FormRow>
            <FormRowInput 
                label={'Description'}
                field={'away_placeholder_description'}
                value={props.contest.away_placeholder_description}
                type='text'
                formUpdate={update => {
                    props.contestChange('away_placeholder_description', update['away_placeholder_description'])
                }}
            />
        </div>
    )
}

let additionalFields = [
    {
        key: 'head_coach',
        label: 'Head Coach',
        input: FormRowInput,
        inputType: 'input'
    },
    {
        key: 'head_coach_email',
        label: 'Head Coach Email',
        input: FormRowInput,
        inputType: 'input'
    },
    {
        key: 'head_coach_phone',
        label: 'Head Coach Phone',
        input: FormRowInput,
        inputType: 'input'
    },
    {
        key: 'season',
        label: 'Season',
        input: FormRowDropdown,
        inputType: 'select',
        options: [
            {value: '', text: ''}, 
            {value: 'FALL', text: 'Fall'}, 
            {value: 'WINTER', text: 'Winter'}, 
            {value: 'SPRING', text: 'Spring'}
        ]
    },
    {
        key: 'comp_level',
        label: 'Competition Level',
        input: FormRowInput,
        inputType: 'input'
    },
    {
        key: 'cancellation_terms',
        label: 'Cancellation Terms',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
    {
        key: 'force_majeure',
        label: 'Force Majeure',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
    {
        key: 'comp_tickets',
        label: 'Complimentary Tickets',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
    {
        key: 'cons_tickets',
        label: 'Consignment Tickets',
        input: FormRowInput,
        inputType: 'input'
    },
    {
        key: 'governing_rules',
        label: 'Governing Rules',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
    {
        key: 'radio_rights',
        label: 'Radio Rights',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
    {
        key: 'tv_rights',
        label: 'Video Rights',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
    {
        key: 'broadcast_rights',
        label: 'Broadcast Rights',
        input: FormRowTextArea,
        inputType: 'textarea'
    },
]

class HomeContestFields extends Component {
    state = {
        expanded: false
    }
    render() {
        return (
            <div>
                {/* 
                    opponent dropdown
                    sport dropdown
                    date picker
                    time picker
                    location
                    officials
                    description
                    other conditions
                    future commitment
                    guarantee

                    edit more details

                    contract to be exchanged off platform
                        paper contract sent
                        paper contract sent, signed, received
                        upload
                        uploaded

                    request blank copy
                    request signed copy

                    delete and update
                */}
                <FormRow
                    label={'Opponent'}
                    required
                >
                    <ModalSelect
                        placeholder='Select opponent'
                        name='opponentId'
                        value={this.props.contest.opponentId}
                        options={this.props.opponentOptions}
                        onChange={(field, data) => this.props.contestChange(field, data.value)} 
                    />
                </FormRow>
                <FormRow
                    label={'Sport'}
                    required
                >
                    <ModalSelect
                        placeholder='Select sport'
                        name='sport'
                        value={this.props.contest.sport}
                        options={sportSelectOptions}
                        onChange={(attr, data) => {this.props.contestChange('sport', data.value)}} 
                    />
                </FormRow>
                <FormRow
                    label={'Date'}
                    required
                >
                    <CustomDatePicker 
                        hintText="Select Date"
                        user={this.props.user}
                        value={new Date(this.props.contest.date)}
                        onChange={(data) => {this.props.contestChange('date', data)}}
                    />
                </FormRow>
                <FormRow
                    label={'Time'}
                >
                    <CustomTimePicker 
                        hintText="Select Time"
                        minutesStep={5}
                        hour={this.props.contest.hour}
                        minute={this.props.contest.minute}
                        setTBA={() => {
                            this.props.contestChange('hour', 24);
                            this.props.contestChange('minute', 0);
                        }}
                        value={new Date(2018, 6, 1, this.props.contest.hour, this.props.contest.minute)}
                        onChange={(e, data) => {
                            this.props.contestChange('hour', data.getHours())
                            this.props.contestChange('minute', data.getMinutes())
                        }}
                    />
                </FormRow>
                <Form>
                    <FormRowInput
                        label={'Location'}
                        field={'location'}
                        type='text'
                        value={this.props.contest.location}
                        formUpdate={update => {
                            this.props.contestChange('location', update['location'])
                        }}
                    />
                    <FormRowInput
                        label={'Officials'}
                        field={'officials'}
                        type='text'
                        value={this.props.contest.officials}
                        formUpdate={update => {
                            this.props.contestChange('officials', update['officials'])
                        }}
                    />
                    <FormRowInput
                        label={'Description'}
                        field={'description'}
                        type='text'
                        value={this.props.contest.description}
                        formUpdate={update => {
                            this.props.contestChange('description', update['description'])
                        }}
                    />
                    <FormRowTextArea
                        label={'Other Conditions'}
                        field={'other_conditions'}
                        type='text'
                        value={this.props.contest.other_conditions}
                        formUpdate={update => {
                            this.props.contestChange('other_conditions', update['other_conditions'])
                        }}
                    />
                    <FormRowTextArea
                        label={'Future Commitment'}
                        field={'future'}
                        type='text'
                        value={this.props.contest.future}
                        formUpdate={update => {
                            this.props.contestChange('future', update['future'])
                        }}
                    />
                    <FormRowTextArea
                        label={'Guarantee'}
                        field={'guarantee'}
                        type='text'
                        value={this.props.contest.guarantee}
                        formUpdate={update => {
                            this.props.contestChange('guarantee', update['guarantee'])
                        }}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <div style={{flex: 1, margin: '0 7px'}} />
                        <div style={{flex: 3, margin: '0 7px'}}>
                            <Button basic onClick={() => {this.setState({expanded: !this.state.expanded})}}>{`${this.state.expanded ? 'Hide' : 'Show'} additional fields`}</Button>
                        </div>
                    </div>
                    {this.state.expanded && (
                        additionalFields.map((field, index) => {
                            let Input = field.input;
                            return (
                                <Input 
                                    key={index} 
                                    field={field.key}
                                    {...field}
                                    value={this.props.contest[field.key]}
                                    type='text'
                                    formUpdate={update => {
                                        this.props.contestChange(field.key, update[field.key])
                                    }}
                                /> 
                            )
                        })
                    )}
                </Form>
            </div>
        )
    }
};

export default class EditContestModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opponentSearchQuery: '',
            sportSearchQuery: '',
            popupVisible: false,
            dirty: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.contest.index !== prevProps.contest.index) {
            this.setState({
                contest: {...this.props.contest}
            })
        }
    }

    cancelChanges = () => {
        let contest = {...this.props.contest};
        this.setState({
            contest,
            dirty: false
        })
    }

    contestChange = (attr, value) => {
        this.props.onChange({[attr]: value})
        // let contest = this.state.contest;
        // contest[attr] = value;
        // this.setState({
        //     contest,
        //     dirty: true
        // })
    }

    notify = success => {
    
        if (success) {
            toast.success("Contest updated successully", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            });
        }
    }

    getModalAction = (contestUpdate) => {

        let modalAction;
        let {contest} = this.state;
        let updateMeta = JSON.stringify({
            user_email: this.props.user.email,
            user_id: this.props.user.id
        })
        let updateContest = () => {
            contestUpdate({variables: Object.assign({},
                contest,
                {
                    date: new Date(contest.date),
                    updateMeta
                }
            )}).then(res => {
                this.props.contestsRefetch();
                this.notify(true);
                this.props.onClose();
            }, err => {
                console.log('editing error', err)
            })
        };
        if ([statuses.EDITED, statuses.AWAY_PLACEHOLDER, statuses.HOME_PAPER_CONTRACT].includes(contest.status)) {
            modalAction = (
                <ModalAction
                    key={0} 
                    primary
                    title={`Update Contest`}
                    onClick={() => {
                        updateContest()
                    }}
                />
            )
        } 
        return modalAction
    }

    getCancelChanges = () => {
        return this.state.dirty ? (
            <ModalAction
                key={-1} 
                title={`Cancel Changes`}
                onClick={() => {
                    this.cancelChanges()
                }}
            />
        ) : null
    }

    deleteContest = () => {
        return (
            <Mutation
                key={-2} 
                mutation={contestRemoveMutation}
            >
                {(contestRemove, {loading, error}) => (
                    <Popup
                        position='top center'
                        on='click'
                        open={this.state.deletePopupVisible}
                        onClose={() => this.setState({deletePopupVisible: false})}
                        trigger={<Button negative onClick={() => this.setState({deletePopupVisible: true})}>Delete Contest</Button>}
                        content={
                            <div>
                                <div style={{marginBottom: '10px'}}>
                                    Are you sure you want to delete this contest?
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <Button
                                        primary
                                        fluid
                                        onClick={() => {
                                            contestRemove({
                                                variables: {
                                                    id: this.props.contest.id,
                                                }
                                            }).then(() => {
                                                this.props.onClose();
                                                this.setState({deletePopupVisible: false})
                                                this.props.contestsRefetch();
                                            })
                                        }}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        fluid
                                        onClick={() => this.setState({deletePopupVisible: false})}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        }
                    />
                )}
            </Mutation>
        )
    }

    render() {
        let {contest, opponentOptions} = this.props;
        
        return(
            <Mutation mutation={contestUpdateMutation}>
                {(contestUpdate, {data, loading, error}) => (
                    <BaseControlledModal
                        header={this.props.header}
                        size={'small'}
                        open={this.props.open}
                        onClose={this.props.onClose}
                        actions={[
                            <ActionButton 
                                key={0}
                                title={'Close modal'} 
                                onClick={this.props.onClose}
                            />
                        ]}
                        >
                        {contest.homeoraway === 'home' ? 
                            <HomeContestFields
                                {...this.props} 
                                contestChange={this.contestChange}
                                contest={contest}
                                contestsRefetch={this.props.contestsRefetch}
                                contestUpdate={contestUpdate}
                                opponentOptions={opponentOptions} 
                                opponentSearchQuery={this.state.opponentSearchQuery}
                                sportSearchQuery={this.state.sportSearchQuery}
                                onChangeSearchQuery={(query, value) => {this.setState({[query]: value})}}
                                onClose={this.props.onClose}
                            /> : 
                            <AwayContestFields 
                                {...this.props} 
                                contestChange={this.contestChange}
                                contest={contest}
                                contestsRefetch={this.props.contestsRefetch}
                                contestUpdate={contestUpdate}
                                opponentOptions={opponentOptions} 
                                opponentSearchQuery={this.state.opponentSearchQuery}
                                sportSearchQuery={this.state.sportSearchQuery}
                                onChangeSearchQuery={(query, value) => {this.setState({[query]: value})}}
                                onClose={this.props.onClose}
                            />
                        }
                    </BaseControlledModal>
                )}   
            </Mutation>
        )
    }
};

