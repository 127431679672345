import React, {Component} from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import {Mutation, Query} from 'react-apollo';
import { toast } from 'react-toastify';


import {userUpdateMutation, userQuery, userOrgContactListQuery} from '../../gql/user';
import {orgUpdateMutation} from '../../gql/org';
import {BaseControlledModal, ModalAction} from '../modals/modals';
import { FormRowInput } from '../utilityComponents/form/formRow';
import { AttributeRow } from './accountUtils';
import {colors} from '../../utils/colors';
import { UserPermissionsModal } from './userPermissions';

const fields = [
    {
        key: 'firstname',
        label: 'First Name'
    },
    {
        key: 'lastname',
        label: 'Last Name'
    },
    {
        key: 'title',
        label: 'Title'
    },
    {
        key: 'email',
        label: 'Email',
        disabled: true
    },
    {
        key: 'phone_work',
        label: 'Office Phone'
    },
    {
        key: 'phone_cell',
        label: 'Cell Phone'
    },
    {
        key: 'office_addr',
        label: 'Office Address'
    }
];

class UpdateUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            errors: {},
            user: Object.assign(
                {
                    email: '',
                    firstname: '',
                    lastname: '',
                    title: '',
                    phone_work: '',
                },
                {...props.user}
            )
        }
    }

    updateUserState = (update) => {
        let user = {...this.state.user};
        Object.assign(user, update);
        this.setState({
            user
        })
    }

    notify = success => {
        if (success) {
            toast.success("Your profile was updated successully", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            });
        }
    }

    render() {
        let user = {...this.state.user}
        let {auth} = this.props;
        return (
            <Mutation mutation={userUpdateMutation}>
                {(userUpdate, { data, loading, error }) => (
                    <BaseControlledModal
                        header={'Edit My Profile'}
                        size={'small'}
                        trigger={<Button basic onClick={() => this.setState({open: true})}>Edit Profile</Button>}
                        open={this.state.open}
                        onClose={() => this.setState({open: false})}
                        actions={[
                            <Button
                                key={0}
                                basic
                                color='red'
                                style={{
                                    flex: 1
                                }}
                                onClick={() => {
                                    auth.auth0.changePassword({email: this.props.user.email, connection: 'Username-Password-Authentication'}, (err, resp) => {
                                        if (err) {
                                            toast.error('Something went wrong. Please try again later.', {
                                                position: toast.POSITION.TOP_CENTER,
                                                autoClose: 4000,
                                                hideProgressBar: true,
                                            })
                                        } else {
                                            toast.success(resp, {
                                                position: toast.POSITION.TOP_CENTER,
                                                autoClose: 4000,
                                                hideProgressBar: true,
                                            });
                                        }
                                    })
                                }}
                            >
                                Change Password
                            </Button>,
                            <ModalAction 
                                key={1}
                                primary
                                title={'Update Profile'}
                                onClick={() => {
                                    let { user } = this.state;
                                    let errors = {};
                                    let errorFields = ['email']
                                    errorFields.forEach(field => {
                                        if (!user[field]) {
                                            errors[field] = value => !value
                                        }
                                    })
                                    if (Object.keys(errors).length > 0) {
                                        this.setState({errors})
                                    } else {
                                        userUpdate(
                                            {
                                                variables: Object.assign({},
                                                    {...user},
                                                    {
                                                        id: this.props.user.id,
                                                    }
                                                ),
                                                refetchQueries: [
                                                    {
                                                        query: userQuery,
                                                        variables: {
                                                            email: this.props.user.email
                                                        }
                                                    }
                                                ]
                                            }
                                        ).then(res => {
                                            this.notify(true);
                                            this.setState({open: false})
                                        },err => console.log('err', err))
                                    }
                                }} />
                                
                        ]}
                        >
                        {fields.map((field, index) => {
                            return (
                                <FormRowInput 
                                    key={index}
                                    label={field.label}
                                    field={field.key}
                                    disabled={field.disabled}
                                    value={user[field.key]}
                                    type='text'
                                    formUpdate={this.updateUserState}
                                />
                            )
                        })}
                    </BaseControlledModal>
                )}
            </Mutation>
        )
    }
}

export default class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editedUser: {...props.user}
        }
    }

    render() {
        let {auth} = this.props
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '15px 0',
                        borderBottom: `1px solid ${colors.greyBorder}`
                    }}
                >
                    <div>
                        <Header as='h2'>My Profile</Header>
                    </div>
                    <div>
                        <UpdateUser {...this.props} />
                    </div>
                </div>
                <div
                    style={{
                        marginTop: '10px'
                    }}
                >
                    {fields.map((field, index) => (
                        <AttributeRow 
                            key={index} 
                            label={field.label} 
                            value={this.props.user[field.key]} />
                    ))}
                </div>
                <div
                    style={{
                        marginTop: '10px'
                    }}
                >
                    {this.props.user.is_admin && (
                        <Query
                            query={userOrgContactListQuery}
                            variables={{
                                orgid: this.props.user.orgid
                            }}
                        >
                            {({data, loading, error, refetch}) => {
                                if (loading) {
                                    return null
                                }
                                let primaryContact = data.homePrimaryContact || {};
                                return (
                                    <Mutation
                                        mutation={orgUpdateMutation}
                                    >
                                        {(orgUpdate, {loading, error}) => (
                                            
                                            <Button
                                                basic={primaryContact.id !== this.props.user.id}
                                                disabled={primaryContact.id === this.props.user.id}
                                                primary
                                                style={{
                                                    flex: 1
                                                }}
                                                onClick={() => {
                                                    if (primaryContact.id !== this.props.user.id) {
                                                        orgUpdate({
                                                            variables: {
                                                                id: this.props.user.orgid,
                                                                primary_contact: this.props.user.id
                                                            }
                                                        }).then(() => {
                                                            refetch()
                                                        })
                                                    }
                                                    
                                                }}
                                            >
                                                <span>
                                                    {primaryContact.id === this.props.user.id && <Icon name='check circle outline' />}
                                                    {primaryContact.id !== this.props.user.id ? `Make Myself the Primary Contact` : `I am the primary contact`}
                                                </span>
                                            </Button> 
                                        )}
                                    </Mutation>
                                )
                            }}
                            
                        </Query>
                    )}
                    
                    {!this.props.user.is_admin && (
                        <Button
                            basic
                            positive
                            style={{
                                flex: 1,
                                marginTop: '5px'
                            }}
                            onClick={() => {
                                this.setState({permissionsModalVisible: this.props.user})
                            }}
                        >
                            <span>
                                View My Permissions
                            </span>
                        </Button> 
                    )}
                </div>
                <UserPermissionsModal 
                    permissionsModalVisible={this.state.permissionsModalVisible}
                    org={this.props.org}
                    readOnly={true}
                    refetch={() => {}}
                    onClose={() => {
                        this.setState({
                            permissionsModalVisible: null
                        })
                    }}
                />
            </div>
        )
    }
}
