import React, { Component } from 'react';
import './App.css';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import qs from 'qs';
import ReactGA from 'react-ga';

import { userQuery, userUpdateMutation } from './gql/user';

import HomeContainer from './components/home/homeContainer';
import LandingContainer from './components/landing/landingContainer';
import Nav from './components/nav/nav';
import RegisterContainer from './components/register/registerContainer';
import SignContainer from './components/sign/signContainer';
import ChangeLog from './components/changelog/changelog';
import { Policies } from './components/modals/policyModal';
import { orgQuery } from './gql/org';
import { createPermissionsObj } from './components/account/userPermissions';
import TemplateMaker from './components/templateMaker/templateMaker';
import { GA_ANALYTICS } from './settings';
import HowItWorks from './components/landing/howItWorks';
import { ErrorPage } from './ErrorPage';

ReactGA.initialize(GA_ANALYTICS);

class App extends Component<{ auth?: any; location?: any }> {
    constructor(props: { auth?: any; location?: any }) {
        super(props);
        let urlQuery = qs.parse(props.location.search);
        let urlVerified = urlQuery.is_verified || urlQuery['?is_verified'];
        if (urlVerified) {
            props.auth.logout();
        }
        // this.state = {
        //     email: '',
        //     loggedIn: false,
        // };
    }

    login() {
        const { auth } = this.props;

        auth?.login();
    }

    logout() {
        const { auth } = this.props;
        auth?.logout({}, () => this.forceUpdate());
    }

    render() {
        const { auth } = this.props;
        let loggedIn = auth.isAuthenticated();
        let isVerified = auth.isVerified();
        let email = loggedIn ? localStorage.getItem('perfect_email') : '';
        return (
            <div className="App">
                <Query query={userQuery} variables={{ email }}>
                    {({ loading, error, data }: any) => {
                        if (loading) return null;
                        if (error) return <ErrorPage error={error} />;
                        if (loggedIn) {
                            let isUser =
                                data.user &&
                                data.user.permission_level === 'FULL';
                            let hasSeenChangelog =
                                data.user && data.user.seen_changelog;
                            let { user = {} } = data;
                            if (isUser && isVerified) {
                                console.log('is user and verified');
                                return (
                                    <div>
                                        <Query
                                            query={orgQuery}
                                            variables={{
                                                id: user.orgid,
                                            }}
                                        >
                                            {({
                                                data: orgData,
                                                loading: orgLoading,
                                                error: orgError,
                                            }: any) => {
                                                let { org = {} } = orgData;
                                                let {
                                                    sports: unparsedSports = '[]',
                                                } = org;
                                                let parsedSports =
                                                    JSON.parse(unparsedSports);
                                                let permissions =
                                                    createPermissionsObj(
                                                        user.is_admin,
                                                        user.permissions,
                                                        parsedSports
                                                    );

                                                return (
                                                    <div>
                                                        <Nav
                                                            {...this.props}
                                                            user={data.user}
                                                            loggedIn={loggedIn}
                                                            auth={auth}
                                                        />
                                                        <div
                                                            style={{
                                                                paddingTop:
                                                                    '65px',
                                                            }}
                                                        >
                                                            <HomeContainer
                                                                {...this.props}
                                                                user={data.user}
                                                                permissions={
                                                                    permissions
                                                                }
                                                            />
                                                            <Switch>
                                                                <Route
                                                                    path="/sign"
                                                                    render={(
                                                                        props
                                                                    ) => (
                                                                        <SignContainer
                                                                            {...props}
                                                                            {...this
                                                                                .props}
                                                                            user={
                                                                                data.user
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path="/template-maker"
                                                                    render={(
                                                                        props
                                                                    ) => (
                                                                        <TemplateMaker
                                                                            {...props}
                                                                            {...this
                                                                                .props}
                                                                            user={
                                                                                data.user
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                                <Route
                                                                    exact
                                                                    path="/register"
                                                                    render={(
                                                                        props
                                                                    ) => (
                                                                        <RegisterContainer
                                                                            {...props}
                                                                        />
                                                                    )}
                                                                />
                                                            </Switch>
                                                        </div>
                                                        <Mutation
                                                            mutation={
                                                                userUpdateMutation
                                                            }
                                                        >
                                                            {(
                                                                updateUser: any,
                                                                _: any
                                                            ) => {
                                                                return (
                                                                    <ChangeLog
                                                                        open={
                                                                            !hasSeenChangelog
                                                                        }
                                                                        onGotIt={() => {
                                                                            return new Promise<void>(
                                                                                (
                                                                                    resolve,
                                                                                    reject
                                                                                ) => {
                                                                                    updateUser(
                                                                                        {
                                                                                            variables:
                                                                                                {
                                                                                                    id: user.id,
                                                                                                    email: user.email,
                                                                                                    seen_changelog:
                                                                                                        true,
                                                                                                },
                                                                                        }
                                                                                    ).then(
                                                                                        () => {
                                                                                            resolve();
                                                                                        }
                                                                                    );
                                                                                }
                                                                            );
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        </Mutation>
                                                    </div>
                                                );
                                            }}
                                        </Query>
                                    </div>
                                );
                            }
                            return (
                                <div>
                                    <Nav
                                        {...this.props}
                                        auth={auth}
                                        loggedIn={loggedIn}
                                        notAUser={!isUser}
                                    />
                                    <div
                                        style={{
                                            paddingTop: '65px',
                                        }}
                                    >
                                        <Switch>
                                            <Route
                                                exact
                                                path="/"
                                                component={(props: any) => (
                                                    <LandingContainer
                                                        {...props}
                                                        auth={auth}
                                                        notAUser={!isUser}
                                                        loggedIn={loggedIn}
                                                        isVerified={isVerified}
                                                    />
                                                )}
                                            />
                                            <Route
                                                exact
                                                path="/how-it-works"
                                                component={(props: any) => (
                                                    <HowItWorks
                                                        {...props}
                                                        auth={auth}
                                                        notAUser={!isUser}
                                                        loggedIn={loggedIn}
                                                        isVerified={isVerified}
                                                    />
                                                )}
                                            />
                                            <Route
                                                path="/sign"
                                                render={(props) => (
                                                    <SignContainer
                                                        {...props}
                                                        {...this.props}
                                                    />
                                                )}
                                            />
                                            <Redirect to="/" />
                                        </Switch>
                                    </div>
                                </div>
                            );
                        }
                        return (
                            <div>
                                <Nav
                                    {...this.props}
                                    auth={auth}
                                    loggedIn={loggedIn}
                                />
                                <div
                                    style={{
                                        paddingTop: '65px',
                                    }}
                                >
                                    <Switch>
                                        <Route
                                            exact
                                            path="/"
                                            component={LandingContainer}
                                        />
                                        <Route
                                            exact
                                            path="/how-it-works"
                                            component={HowItWorks}
                                        />
                                        <Route
                                            path="/sign"
                                            render={(props) => (
                                                <SignContainer
                                                    {...props}
                                                    {...this.props}
                                                />
                                            )}
                                        />
                                        <Redirect to="/" />
                                    </Switch>
                                </div>
                            </div>
                        );
                    }}
                </Query>
                <Policies {...this.props} />
            </div>
        );
    }
}

export default App;
