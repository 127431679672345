import settings from './settings';

// let urls = {
// 	apiRoot: process.env.REACT_APP_API_ROOT || 'http://localhost:8080',
// 	// apiRoot: 'http://rewrite.perfectrecordsoftware.com/api',
//         callback: 'http://localhost:3000/callback',
//         returnTo: 'http%3A%2F%2Flocalhost:3000',
//         // callback: 'http://localhost:5000/callback',
//         // returnTo: 'http%3A%2F%2Flocalhost:5000',
//         // callback: 'http://rewrite.perfectrecordsoftware.com/callback',
// };

let urls = {
    v2Prod: {
        apiRoot: '/api',
        callback: 'https://perfectrecordsoftware.com/callback',
        returnTo: 'https%3A%2F%2Fperfectrecordsoftware.com',
    },
    v2Dev: {
        apiRoot: '/api',
        callback: 'http://dev.perfectrecordsoftware.com/callback',
        returnTo: 'http%3A%2F%2Fdev.perfectrecordsoftware.com',
    },
    local: {
        apiRoot: 'http://localhost:8080',
        callback: 'http://localhost:3000/callback',
        returnTo: 'http%3A%2F%2Flocalhost:3000',
    },
    localRemoteDev: {
        apiRoot: 'http://dev.perfectrecordsoftware.com/api',
        callback: 'http://localhost:3000/callback',
        returnTo: 'http%3A%2F%2Flocalhost:3000',
    },
    localRemoteProd: {
        apiRoot: 'https://perfectrecordsoftware.com/api',
        callback: 'http://localhost:3000/callback',
        returnTo: 'http%3A%2F%2Flocalhost:3000',
    },
};

export default urls[settings.env];
