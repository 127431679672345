import React from 'react';
import {Popup} from 'semantic-ui-react';

let ConditionalPopup = props => {
    let {content, hasPopup, trigger} = props;
    if (hasPopup) {
        return (
            <Popup 
                {...props}
            />
        )
    } else {
        return trigger
    }
}

export default ConditionalPopup;
