import React from 'react';
import { Container, Message } from 'semantic-ui-react';

import MyOrg from './myOrg';
import MyOrgUsers from './myOrgUsers';
import MyProfile from './myProfile';

import { Query } from 'react-apollo';
import { orgQuery } from '../../gql/org';
import Spinner from '../utilityComponents/spinner';


let Account = props => (
    <Query query={orgQuery} variables={{id: props.user.orgid}}>
        {({loading, error, data}) => {
            if (loading) {
                return <Spinner />
            }
            if (error) {
                return <div>Something went wrong</div>
            }
            let org = data.org || {};
            let {
                show_usage_agreement,
                usage_agreement_signature
            } = org
            return (
                <Container>
                    {data.org && props.user.is_admin && show_usage_agreement && !usage_agreement_signature && (
                        <div
                            style={{
                                marginTop: '15px',
                                cursor: 'pointer'
                            }}
                        >
                            <Message
                                color='yellow'
                                onClick={() => {
                                    props.history.push('usage-agreement')
                                }}
                            >
                                <Message.Header>Usage Agreement</Message.Header>
                                <p>
                                    Click here to accept our Usage Agreement
                                </p>
                            </Message>
                        </div>
                    )}
                    
                    <div 
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            margin: '10px 0 25px'
                        }}
                        >
                        <div
                            style={{
                                flex: 1,
                                padding: '5px 35px',
                            }}
                        >
                            <MyProfile {...props} org={data.org} />
                        </div>
                        <div
                            style={{
                                flex: 1,
                                padding: '5px 35px',
                            }}
                        >
                            <MyOrg {...props} org={data.org} />
                        </div>
                    </div>
                    <div
                        style={{
                            padding: '5px 35px',
                        }}>
                        <MyOrgUsers 
                            {...props} 
                            org={data.org}
                        />
                    </div>
                </Container>
            )
        }}
    </Query>
)

export default Account
