import React, {Component} from 'react';
import {
    Button, 
} from 'semantic-ui-react';

import {
    BaseModal,
    BaseControlledModal 
} from './modals';

export default class ViewSignedModal extends Component {
    render() {
        let key = this.props.contest.onPlatform ? `${this.props.contest.guid}.png` : this.props.contest.paper_contract_key
        let imageLink = `https://s3-us-west-2.amazonaws.com/contest-pdfs/${key}`;
		let image;
		if (key) {
			let pdfFormat = key.indexOf('pdf') >= 0;
			if (pdfFormat) {
				image = (
					<div>
						<p>The in-app contract preview currently is not available for PDF documents.</p>
						<p>Please click the button below to download a copy of the saved PDF contract.</p>
					</div>
				)
			} else {
				image = (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
					    <img src={imageLink} style={{border: '1px solid grey', boxShadow: '5px 5px 8px #aaa'}}/>
                    </div>
				)
			}
            return(
                
                <BaseControlledModal
                    header={`View Uploaded Contract`}
                    size={'large'}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    actions={[
                        <Button
                            key={0}
                            primary
                        >
                            <a 
                                href={imageLink} 
                                style={{
                                    color: 'white'
                                }}
                            >
                                Download a Copy
                            </a>
                        </Button>
                    ]}
                    >
                        {image}
                </BaseControlledModal>
            )
        } else {
            return null
        }
    }
};

