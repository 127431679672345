import gql from 'graphql-tag';

export const orgTemplateQuery = gql`
    query orgTemplate (
        $orgid: ID
    ) {
        orgTemplate (
            orgid: $orgid
        ) {
            id
            orgid
            contracts
        }
    }
`;

export const hasCustomTemplateQuery = gql`
    query hasCustomTemplate (
        $orgid: ID
    ) {
        hasCustomTemplate (
            orgid: $orgid
        ) {
            self
            managed
        }
    }
`;

export const orgTemplateCreate = gql`
    mutation orgTemplateCreate (
        $orgid: ID
        $headerBreak: Boolean
        $headerType: Int
        $headerContent: [headerFooterInput]
        $headerAlignItems: String
        $watermarkImage: String
        $footerImages: [headerFooterInput]
        $footerAlignItems: String
        $fieldsFormatting: fieldsFormattingInput
        $watermark: watermarkInput
        $bodyHeader: [bodyInput]
        $bodyFooter: [bodyInput]
        $contentFooter: [bodyInput]
        $signatureBlockOrgNames: Boolean
        $signatureBlock: [bodyInput]
        $signatureLineText: String
        $fontFamily: String
    ) {
        orgTemplateCreate (
            orgid: $orgid
            headerBreak: $headerBreak
            headerType: $headerType
            headerContent: $headerContent
            headerAlignItems: $headerAlignItems
            watermarkImage: $watermarkImage
            footerImages: $footerImages
            footerAlignItems: $footerAlignItems
            fieldsFormatting: $fieldsFormatting
            watermark: $watermark
            bodyHeader: $bodyHeader
            bodyFooter: $bodyFooter
            contentFooter: $contentFooter
            signatureBlockOrgNames: $signatureBlockOrgNames
            signatureBlock: $signatureBlock
            signatureLineText: $signatureLineText
            fontFamily: $fontFamily
        )
    }
`;

export const templateQuery = gql`
    query template (
        $orgid: ID
        $headerBreak: Boolean
        $headerType: Int
        $headerContent: [headerFooterInput]
        $headerAlignItems: String
        $watermarkImage: String
        $footerImages: [headerFooterInput]
        $footerAlignItems: String
        $fieldsFormatting: fieldsFormattingInput
        $watermark: watermarkInput
        $bodyHeader: [bodyInput]
        $bodyFooter: [bodyInput]
        $contentFooter: [bodyInput]
        $signatureBlockOrgNames: Boolean
        $signatureBlock: [bodyInput]
        $signatureLineText: String
        $fontFamily: String
    ) {
        template (
            orgid: $orgid
            headerBreak: $headerBreak
            headerType: $headerType
            headerContent: $headerContent
            headerAlignItems: $headerAlignItems
            watermarkImage: $watermarkImage
            footerImages: $footerImages
            footerAlignItems: $footerAlignItems
            fieldsFormatting: $fieldsFormatting
            watermark: $watermark
            bodyHeader: $bodyHeader
            bodyFooter: $bodyFooter
            contentFooter: $contentFooter
            signatureBlockOrgNames: $signatureBlockOrgNames
            signatureBlock: $signatureBlock
            signatureLineText: $signatureLineText
            fontFamily: $fontFamily
        ) {
            html
        }
    }
`

export const templatePdfPreviewMutation = gql`
    mutation templatePdfPreview (
        $orgid: ID
        $headerBreak: Boolean
        $headerType: Int
        $headerContent: [headerFooterInput]
        $headerAlignItems: String
        $watermarkImage: String
        $footerImages: [headerFooterInput]
        $footerAlignItems: String
        $fieldsFormatting: fieldsFormattingInput
        $watermark: watermarkInput
        $bodyHeader: [bodyInput]
        $bodyFooter: [bodyInput]
        $contentFooter: [bodyInput]
        $signatureBlockOrgNames: Boolean
        $signatureBlock: [bodyInput]
        $signatureLineText: String
        $fontFamily: String
    ) {
        templatePdfPreview (
            orgid: $orgid
            headerBreak: $headerBreak
            headerType: $headerType
            headerContent: $headerContent
            headerAlignItems: $headerAlignItems
            watermarkImage: $watermarkImage
            footerImages: $footerImages
            footerAlignItems: $footerAlignItems
            fieldsFormatting: $fieldsFormatting
            watermark: $watermark
            bodyHeader: $bodyHeader
            bodyFooter: $bodyFooter
            contentFooter: $contentFooter
            signatureBlockOrgNames: $signatureBlockOrgNames
            signatureBlock: $signatureBlock
            signatureLineText: $signatureLineText
            fontFamily: $fontFamily
        ) {
            html
            urlKey
            location
        }
    }
`
