import React, { useState, useEffect } from 'react';
import {Dropdown, Header, Icon, Button, Form, Popup} from 'semantic-ui-react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import {toast} from 'react-toastify';

import { BaseControlledModal } from './modals';
import { sports } from '../../utils/sports';
import { formatDate, getOpponentString, timeString } from '../schedule/schedule';
import { statuses, icons, statusDescriptions } from '../../utils/statuses';
import { contestResubmitMutation, contestUpdateRecipientsMutation } from '../../gql/contest';
import { validateEmail } from '../../utils/validators';
import { userOrgContactListFromContestQuery, userQuery } from '../../gql/user';
import { Query } from 'react-apollo';


let StatusModalRow = props => {
    let {
        actions,
        rowStyle={},
        role,
        who,
        title,
        icon,
        date,
        sent_to_date,
        active,
        onEdit,
        onConfirm=() => {},
        onRemove,
        disabled,
        added=false,
        options=[]
    } = props;
    let [recipient, setRecipient] = useState(who);
    let [newRecipient, setNewRecipient] = useState('');
    let [editing, setEditing] = useState(added || false);
    let [error, setError] = useState(false);


    useEffect(()=>{
        if (editing) {
            let newError = !(validateEmail(newRecipient) || validateEmail(recipient))
            setError(newError)
        }
    }, [recipient, newRecipient])


    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '5px',
                backgroundColor: active ? '#EBECF0' : undefined,
                ...rowStyle,
            }}
        >
            <div
                style={{
                    flex: 2,
                    textAlign: 'left'
                }}
            >
                <div
                    style={{
                        paddingRight: '15px'
                    }}
                >
                    {role}
                </div>
            </div>
            <div
                style={{
                    flex: 2,
                    textAlign: 'left'
                }}
            >
                <div
                    style={{
                        paddingRight: '15px'
                    }}
                >
                    {editing ? options && options.length > 0 ? (
                        <Form>
                            <Form.Dropdown 
                                fluid
                                scrolling
                                upward
                                selection
                                type='text'
                                options={options}
                                value={recipient}
                                onChange={(e, {value}) => {
                                    setRecipient(value)
                                }}
                            />
                            {recipient === 'newRecipient' && (
                                <Form.Input 
                                    fluid
                                    type='text'
                                    value={newRecipient}
                                    onChange={(e, {value}) => {
                                        setNewRecipient(value)
                                    }}
                                    error={error}
                                />
                            )}
                        </Form>
                    ) : (
                        <Form>
                            <Form.Input 
                                fluid
                                type='text'
                                value={recipient}
                                onChange={(e, {value}) => {
                                    setRecipient(value)
                                }}
                                error={error}
                            />
                        </Form>
                    ) : newRecipient || recipient}
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    textAlign: 'left'
                }}
            >
                <div
                    style={{
                        paddingRight: '15px'
                    }}
                >
                    {title}
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    textAlign: 'center'
                }}
            >
                <div
                    style={{
                        paddingRight: '15px'
                    }}
                >
                    {icon}
                </div>
            </div>
            <div
                style={{
                    flex: 2,
                    textAlign: 'center'
                }}
            >
                <div
                    style={{
                        paddingRight: '15px'
                    }}
                >
                    {sent_to_date}
                </div>
            </div>
            <div
                style={{
                    flex: 2,
                    textAlign: 'center'
                }}
            >
                <div
                    style={{
                        paddingRight: '15px'
                    }}
                >
                    {date}
                </div>
            </div>
            <div
                style={{
                    flex: 1,
                    textAlign: 'center'
                }}
            >
                <div
                    style={{
                        paddingRight: '15px',
                        flexDirection: 'row',
                        display: 'flex'
                    }}
                >
                    {actions}
                    {onEdit && !disabled && !editing && (
                        <Popup content='Edit this recipient' trigger={
                                <Button 
                                icon='edit'
                                basic
                                color='blue'
                                onClick={() => {
                                    setEditing(true)
                                    onEdit()
                                }}
                            />}
                        />
                    )}
                    {onEdit && (recipient !== who || editing) && (
                        <Popup content='Undo changes' trigger={
                            <Button 
                                icon='undo'
                                onClick={() => {
                                    if (added) {
                                        onRemove && onRemove()
                                    } else {
                                        setEditing(false)
                                        setRecipient(who)
                                    }
                                }}
                            />}
                        />
                    )}
                    {onEdit && editing && (
                        <Popup content='Confirm changes' trigger={
                            <Button 
                                icon='check'
                                color='green'
                                onClick={() => {
                                    if (!error) {
                                        setEditing(false)
                                        if (recipient === 'newRecipient') {
                                            onConfirm(newRecipient)
                                        } else {
                                            onConfirm(recipient)
                                        }
                                        
                                    }
                                }}
                            />}
                        />
                    )}
                    {onRemove && !disabled && (
                        <Popup content='Remove this recipient' trigger={
                            <Button 
                                icon='remove'
                                basic
                                color='red'
                                onClick={onRemove}
                            />}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

let newApproverObj = (email) => ({
    approved: false,
    approved_date: '',
    // submitted_to_approver_date: new Date().toJSON(),
    email,
})

let makeJSONApproverObj = (previous, value) => {
    let approvers = [...previous];
    value.forEach((email) => {
        if (approvers.find((item) =>  item.email === email)) {

        } else {
            approvers.push({
                ...newApproverObj(email)
            })
        }
    })
    approvers.forEach((approver, index) => {
        
        if (value.indexOf(approver.email) === -1) {
            approvers.splice(index, 1)
        }
    })
    return approvers;
}

const renderLabel = label => {
    return ({
        color: label.approved ? 'blue' : undefined,
        content: `${label.text}`
    })
}

let InternalApproval = props => {
    let {state={}, onChange=() => {}, active, top, isAway} = props;
    let {meta={}} = state;
    let {
        approvers=[]
    } = meta;
    let values = approvers.map(approver => approver.email);
    let approved = approvers.filter(approver => {
        return approver.approved
    })
    let approved_emails = approved.map(approver => approver.email)
    let [options, setOptions] = useState([...values.map(value => {
        return ({text: value, value: value, approved: approved_emails.includes(value)})
    })]);
    
    return (
        <div
            style={{
                width: '100%',
                borderBottom: top ? undefined : '3px solid rgba(0,0,0,.35)',
                borderTop: top ? '3px solid rgba(0,0,0,.35)' : undefined
            }}
        >
            {approvers.map((approver, index) => {
                return (
                    <StatusModalRow 
                        key={`${approver.email}+${index}`}
                        role={`${isAway ? 'Away' : 'Home'} Internal Approver`}
                        who={approver.email}
                        title={approver.approver_title}
                        active={!approver.approved && active}
                        disabled={approver.approved}
                        added={approver.added}
                        icon={
                            <Popup 
                                position='top center'
                                style={{cursor: 'pointer'}}
                                content={
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            <span>
                                                {approver.approved ? 'Signature complete' : 'Has received link to sign contract but has not yet signed'}
                                            </span>
                                        </div>
                                    </div>
                                }
                                trigger={
                                    <Icon 
                                        size='big'
                                        {...icons[approver.approved ? 'check' : 'x']()}
                                    />
                                }
                            />
                            
                        }
                        sent_to_date={
                            approver.submitted_to_approver_date ? new Date(approver.submitted_to_approver_date).toLocaleString() : '--'
                        }
                        date={
                            approver.approved ? new Date(approver.approved_date).toLocaleString() : '--'
                        }
                        onEdit={top ? null : () => {}}
                        onRemove={top ? null : () => {
                            let home_internal_approval = {...state};
                            let homeMeta = home_internal_approval.meta;
                            if (homeMeta.approvers.length === 1) {
                                home_internal_approval = null
                            } else {
                                homeMeta.approvers.splice(index, 1);
                            }
                            onChange(home_internal_approval)
                        }}
                        onConfirm={who => {
                            let home_internal_approval = {...state};
                            let homeMeta = home_internal_approval.meta;
                            homeMeta.approvers[index] = {
                                email: who,
                                approved: false,
                                approved_date: null,
                                submitted_to_approver_date: null,
                                approver_email: null,
                                added: approver.added
                            }
                            onChange(home_internal_approval)
                        }}
                    />
                )
            })}
            {active && (
                <div
                    style={{
                        marginTop: '10px',
                        marginBottom: '15px'
                    }}
                >
                    <Button 
                        basic
                        icon='plus'
                        content='Add an additional internal approver (optional)'
                        onClick={() => {
                            let home_internal_approval = {...state};
                            let homeMeta = home_internal_approval.meta;
                            homeMeta.approvers.push({
                                email: '',
                                approved: false,
                                approved_date: null,
                                submitted_to_approver_date: null,
                                approver_email: null,
                                added: true
                            })
                            onChange(home_internal_approval)
                        }}
                    />
                </div>
            )}
        </div>
    )
}

let OtherSigner = props => {
    let {state={}, onChange=() => {}, active, contactList, noAction, top, canEdit, isAway, signature_date, signors_title, signors_email} = props;
    let {meta={}} = state;
    let {email, approved, approved_date, submitted_to_different_signer_date, added} = meta;
    let list = contactList && contactList.map((contact) => {
        return ({value: contact.email, text: `${contact.firstname && contact.lastname ? `${contact.firstname} ${contact.lastname} <${contact.email}>` : `<${contact.email}>`}`})
    })
    let [options, setOptions] = useState(list);

    
    if (isAway && signature_date) {
        approved = true;
        approved_date = signature_date;
    }

    useEffect(() => {
        setOptions(list)
    }, [contactList])

    return (
        <div
            style={{
                width: '100%',
                borderBottom: top ? undefined : '3px solid rgba(0,0,0,.35)',
                borderTop: top ? '3px solid rgba(0,0,0,.35)' : undefined
            }}
        >
            <StatusModalRow 
                active={active}
                role={`${isAway ? 'Away' : 'Home'}  Different Signer`}
                who={signors_email || email}
                options={options}
                title={signors_title || '--'}
                added={added}
                icon={
                    <Popup 
                        position='top center'
                        style={{cursor: 'pointer'}}
                        content={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <span>
                                        {approved ? 'Signature complete' : active ? 'Has received link to sign contract but has not yet signed' : 'Not yet required to sign – awaiting other signatures'}
                                    </span>
                                </div>
                            </div>
                        }
                        trigger={
                            <Icon 
                                size='big'
                                {...icons[approved ? 'check' : active ? 'x' : 'minus']()}
                            />
                        }
                    />
                }
                sent_to_date={
                    submitted_to_different_signer_date ? new Date(submitted_to_different_signer_date).toLocaleString() : '--'
                }
                date={approved_date ? new Date(approved_date).toLocaleString() : '--'}
                onEdit={noAction || !canEdit ? null : () => {}}
                onRemove={noAction || !canEdit ? null : () => {
                    onChange(null)
                }}
                onConfirm={(who) => {
                    let signor_different_user = {...state};
                    let signorMeta = signor_different_user.meta;
                    Object.assign(signorMeta, {
                        email: who,
                        approved: false,
                        approved_date: null,
                        submitted_to_different_signer_date: null,
                    })
                    onChange(signor_different_user)
                }}
            />
        </div>
    )
}

let ContractRecipient = props => {
    let {state={}, onChange=()=>{}, active, contactList} = props;
    let {meta={}} = state;
    let {email, approved, sent_date, signature_date} = meta;
    let list = contactList && contactList.map((contact) => {
        return ({value: contact.email, text: `${contact.firstname && contact.lastname ? `${contact.firstname} ${contact.lastname} <${contact.email}>` : `<${contact.email}>`}`})
    })
    if (email && list && Array.isArray(list) && list.filter(contact => contact.value === email).length === 0) {
        list.push({
            text: email,
            value: email
        })
    }
    if (email !== 'newRecipient' && list && Array.isArray(list)) {
        list.push({
            text: 'Add a new recipient',
            value: 'newRecipient'
        })
    }

    let [options, setOptions] = useState(list);
    let [value, setValue] = useState(list && list.length === 1 ? 'newRecipient' : email);

    useEffect(() => {
        setOptions(list)
    }, [contactList])
    
    return (
        <div
            style={{
                width: '100%'
            }}
        >
            <StatusModalRow 
                active={active}
                role={'Away Initial Recipient'}
                who={value}
                title={'--'}
                options={options}
                icon={
                    <Popup 
                        position='top center'
                        style={{cursor: 'pointer'}}
                        content={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: 'center'
                                    }}
                                >
                                    <span>
                                        {approved ? 'Signature complete' : active ? 'Has received link to sign contract but has not yet signed' : 'Not yet required to sign – awaiting other signatures'}
                                    </span>
                                </div>
                            </div>
                        }
                        trigger={
                            <Icon 
                                size='big'
                                {...icons[approved ? 'check' : active ? 'x' : 'minus']()}
                            />
                        }
                    />
                }
                sent_to_date={sent_date ? new Date(sent_date).toLocaleString() : '--'}
                date={signature_date ? new Date(signature_date).toLocaleString() : '--'}
                onConfirm={who => {
                    let submitted = {...state};
                    let submittedMeta = submitted.meta;
                    Object.assign(submittedMeta, {
                        email: who,
                        approved: false,
                        sent_date: null,
                        updated: true
                    })
                    onChange(submitted)
                }}
                onEdit={approved ? null : () => {

                }}
            />
        </div>
    )

}


let getAvailableStates = contest => {
    let states = {};
    if (contest.home_requires_internal_approval) {
        let approvers = []
        let parsed;
        try {
            parsed = JSON.parse(contest.home_internal_approvers || '[]');
            parsed.forEach((approval) => {
                approvers.push({
                    ...approval,
                    email: approval.approver_email,
                    approved: !!approval.approved_date,
                    approved_date: approval.approved_date,
                    submitted_to_approver_date: approval.submitted_to_approver_date,
                    approver_title: approval.approver_title,
                });

            })
            states.home_internal_approval = {
                key: 'home_internal_approval',
                active_state: statuses.SUBMITTED_INTERNAL_HOME,
                meta: {
                    approvers
                }
            }
        } catch (e) {
            console.log({noParse: contest.home_internal_approvers, e})
        }
    }
    if (contest.signor_different_user) {
        states.signor_different_user = {
            key: 'signor_different_user',
            active_state: statuses.SUBMITTED_DIFFERENT_SIGNER,
            meta: {
                email: contest.signor_different_user_email,
                approved: !!contest.sent_date,
                approved_date: contest.sent_date,
                submitted_to_different_signer_date: contest.submitted_to_different_signer_date
            }
        }
    }

    states.submitted = {
        key: 'submitted',
        active_states: [statuses.SUBMITTED, statuses.SUBMITTED_NOTCLICKED, statuses.SUBMITTED_INTERNAL_AWAY_COMPLETE],
        clicked: contest.email_link,
        
        meta: {
            email: contest.contract_recipient,
            sent_date: contest.sent_date,
            approved: !!contest.signature || contest.status === statuses.SIGNED,
            signature_date: contest.signature_date
        }
    }
    
    if (contest.away_requires_internal_approval) {
        let approvers = []
        let parsed;
        try {
            parsed = JSON.parse(contest.away_internal_approvers || '[]');
            parsed.forEach((approval) => {
                approvers.push({
                    email: approval.approver_email,
                    approved: !!approval.approved_date,
                    approved_date: approval.approved_date,
                    submitted_to_approver_date: approval.submitted_to_approver_date
                });

            })
            states.away_internal_approval = {
                key: 'away_internal_approval',
                active_state: statuses.SUBMITTED_INTERNAL_AWAY,
                meta: {
                    approvers
                }
            }
        } catch (e) {
            console.log({noParse: contest.away_internal_approvers, e})
        }
    }
    if (contest.status === statuses.SUBMITTED_INTERNAL_AWAY_COMPLETE) {
        states.submitted_internal_away_complete = {
            key: 'submitted_internal_away_complete',
            active_state: statuses.SUBMITTED_INTERNAL_AWAY_COMPLETE,
            meta: {}
        }
    }
    if (contest.away_different_signer) {
        states.away_different_signer = {
            key: 'away_different_signer',
            active_state: statuses.SUBMITTED_AWAY_DIFFERENT_SIGNER,
            meta: {
                email: contest.away_different_signer_email,
                submitted_to_different_signer_date: contest.submitted_to_away_different_signer_date
            }
        }
    }
    return states
}

let getMutationFromStates = (contest, states) =>  {
    let {
        home_requires_internal_approval,
        home_internal_approvers,
        contract_recipient,
        signor_different_user,
        signor_different_user_email,
        guid,
        status,
        sent_date,
    } = contest;
    if (states.home_internal_approval) {
        home_requires_internal_approval = true;
        let home_approvers = states.home_internal_approval.meta.approvers;
        let approvers = [];
        let allApproved = true;
        home_approvers.forEach((approver) => {
            if (!approver.approver_signature) {
                allApproved = false;
            }
            approvers.push({
                approver_email: approver.email,
                approver_title: approver.approver_title || '',
                approved_date: approver.approved_date || '',
                approver_signature: approver.approver_signature || '',
                submitted_to_approver_date: approver.submitted_to_approver_date || new Date().toISOString(),
            })
        })
        home_internal_approvers = JSON.stringify(approvers)

        if (allApproved && status === statuses.SUBMITTED_INTERNAL_HOME) {
            if (states.signor_different_user) {
                status = statuses.SUBMITTED_DIFFERENT_SIGNER
            } else {
                status = statuses.SUBMITTED_NOTCLICKED
            }
        }
        
    } else if (status === statuses.SUBMITTED_INTERNAL_HOME) {
        home_requires_internal_approval = false;
        home_internal_approvers = JSON.stringify([]);
        if (states.signor_different_user) {
            status = statuses.SUBMITTED_DIFFERENT_SIGNER
        } else {
            status = statuses.SUBMITTED_NOTCLICKED
        }
    }

    if (states.signor_different_user) {
        signor_different_user = true;
        signor_different_user_email = states.signor_different_user.meta.email;
    } else {
        if (status === statuses.SUBMITTED_DIFFERENT_SIGNER) {
            status = statuses.SUBMITTED_NOTCLICKED;
        }
        signor_different_user = false;
        signor_different_user_email = '';
    }

    contract_recipient = states.submitted.meta.email
    if (states.submitted.meta.updated) {
        sent_date = new Date().toISOString();
    }


    let update = {
        home_requires_internal_approval,
        home_internal_approvers,
        contract_recipient,
        signor_different_user,
        signor_different_user_email,
        guid,
        status,
        sent_date,
    }
    return (update)
}

let order = [
    'home_internal_approval',
    'signor_different_user',
    'submitted_notclicked',
    'submitted',
    'away_internal_approval',
    'submitted_internal_away_complete',
    'away_different_signer'
];


let ContestStatusModal = props => {
    let {contest={}, open, onClose=()=>{}} = props;
    let [dirty, setDirty] = useState(false);
    let [buttonError, setButtonError] = useState(false);
    let [remindDisabled, setRemindDisabled] = useState(false);
    let [updateDisabled, setUpdateDisabled] = useState(false);
    let [states, setStates] = useState(getAvailableStates(contest));

    let userOrgContactList = useQuery(userOrgContactListFromContestQuery, {
        variables: {
            guid: contest.guid
        }
    })

    let {homeOrg={}, awayOrg={}} = (userOrgContactList.data && userOrgContactList.data.userOrgContactListFromContest) || {}; 

    let mutationData = getMutationFromStates(contest, states);

    let contestUpdateRecipients = useMutation(contestUpdateRecipientsMutation, {
        variables: mutationData
    })

    let contestResubmit = useMutation(contestResubmitMutation, {
        variables: {guid: contest.guid}
    })

    let {
        date,
        sport,
        hour,
        minute,
        orgid
    } = contest;

    if (contest.status === statuses.AWAY_PLACEHOLDER || contest.status === statuses.AWAY_PLACEHOLDER_PAPER_RECEIVED) {
        return (
            <BaseControlledModal
                header={'Contract Status'}
                size='fullscreen'
                open={open}
                onClose={() => {
                    onClose()
                }}
                actions={[]}
            >
                <div>
                    Away placeholder – no signature information is tracked
                </div>
            </BaseControlledModal>
        )
    }

    let isHome = orgid === props.user.orgid;

    let actions = [
        <Popup key={0} content='Send a system-generated reminder email to the current contract recipient' trigger={
            <Button 
                key={0}
                disabled={remindDisabled}
                icon='alarm'
                content='Remind Current Recipient'
                basic
                color='blue'
                onClick={() => {
                    setRemindDisabled(true);
                    contestResubmit().then(res => {
                        if (res && res.data && res.data.contestResubmit) {
                            toast.success('Recipients have been reminded');
                            setTimeout(() => {
                                setRemindDisabled(false)
                            }, 1000)
                        } else {
                            toast.error('Something went wrong, try again later');
                            setTimeout(() => {
                                setRemindDisabled(false)
                            }, 1000)
                        }
                    })
                }}
            />}
        />,
    ];
    if (dirty) {
        actions.push(
            <div
                key={3}
                style={{
                    border: buttonError ? '4px solid #efcfcf' : undefined,
                    backgroundColor: buttonError ? '#efcfcf' : undefined,
                    borderTopLeftRadius: '4px',
                    borderBottomLeftRadius: '4px',
                    paddingLeft: buttonError ? '3px' : undefined
                }}
            >
                <Button 
                    key={3}
                    icon='undo'
                    content='Cancel all changes'
                    onClick={() => {
                        setDirty(false);
                        setButtonError(false);
                        setStates(getAvailableStates(contest));
                    }}
                />
            </div>
        )
        actions.push(
            <div
                key={1}
                style={{
                    border: buttonError ? '4px solid #efcfcf' : undefined,
                    backgroundColor: buttonError ? '#efcfcf' : undefined,
                    borderTopRightRadius: '4px',
                    borderBottomRightRadius: '4px',
                }}
            >
                <Button 
                    key={1}
                    content='Save all changes'
                    color='blue'
                    disabled={updateDisabled}
                    onClick={() => {
                        setUpdateDisabled(true)
                        contestUpdateRecipients().then(res => {
                            if (res && res.data && res.data.contestUpdateRecipients) {
                                setDirty(false);
                                setButtonError(false);
                                toast.success('Recipients have been updated');
                                setTimeout(() => {
                                    setUpdateDisabled(false)
                                }, 1000)
                            } else {
                                toast.error('Something went wrong, try again later');
                                setTimeout(() => {
                                    setUpdateDisabled(false)
                                }, 1000)
                            }
                        })
                    }}
                />
            </div>
        )
    }


    return (
        <BaseControlledModal
            header={'Contract Status'}
            size='fullscreen'
            open={open}
            onClose={() => {
                if (dirty) {
                    setButtonError(true)
                } else {
                    onClose()

                }
            }}
            actions={actions}
        >
            <div>
                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    <div
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        {sports[sport]} {` ${isHome ? 'vs. ' : '@ '}${getOpponentString(props)[1]}`}
                    </div>
                    <div
                        style={{
                            fontWeight: 'bold'
                        }}
                    >
                        {`${formatDate(date)}, ${timeString(hour, minute)}`}
                    </div>
                    <div
                        style={{
                            margin: '10px 0'
                        }}
                    >

                        {contest.created_at && contest.created_by && (
                            <div>
                                <Query
                                    query={userQuery}
                                    variables={{
                                        id: contest.created_by
                                    }}
                                >
                                    {({data, loading, error}) => {
                                        if (loading || error) {
                                            return null
                                        }
                                        
                                        let {user={}} = data;
                                        if (!user) {
                                            return null
                                        }
                                        let {firstname, lastname, email} = user;
                                        return (
                                            <div>
                                                {`Created by ${
                                                    firstname || lastname ? `${firstname} ${lastname}` : `${email}`
                                                } on ${contest.created_at}`}
                                            </div>
                                        )
                                    }}
                                </Query>
                            </div>
                        )}

                        {(contest.submitter_fullname) &&
                            (
                                <div>
                                    Originally submitted by {contest.submitter_fullname}
                                </div>
                            )
                        }

                    </div>
                    <div>
                        Contract Status: <span style={{fontStyle: 'italic'}}>{statusDescriptions[contest.status]}</span>
                    </div>
                    {contest.last_reminder && new Date(contest.last_activity).getDate() === new Date(contest.last_reminder).getDate() && (
                        <div>
                            {`Last reminder sent: ${new Date(contest.last_reminder).toLocaleString()}`}
                        </div>
                    )}
                </div>
                <div
                    style={{
                        margin: '20px 0'
                    }}
                >
                    <StatusModalRow 
                        role={<strong>Role</strong>}
                        who={<strong>Email</strong>}
                        title={<strong>Title</strong>}
                        icon={<strong>Signed?</strong>}
                        date={<strong>Signature Date</strong>}
                        sent_to_date={<strong>Sent Date</strong>}
                        actions={<strong>Actions</strong>}
                    />
                    
                    {states.home_internal_approval && (
                        
                        <InternalApproval 
                            state={states.home_internal_approval}
                            active={states.home_internal_approval.active_state === contest.status}
                            onChange={home_internal_approval => {
                                setDirty(true);
                                setStates({
                                    ...states,
                                    home_internal_approval
                                })
                            }}
                        />
                    )}
                    {
                        states.signor_different_user ? (
                            <OtherSigner 
                                contactList={homeOrg.contactList}
                                canEdit={contest.status === statuses.SUBMITTED_INTERNAL_HOME || contest.status === statuses.SUBMITTED_DIFFERENT_SIGNER}
                                state={states.signor_different_user}
                                active={states.signor_different_user.active_state === contest.status}
                                onChange={signor_different_user => {
                                    setDirty(true);
                                    setStates({
                                        ...states,
                                        signor_different_user
                                    })
                                }}
                            />
                        ) : contest.status === statuses.SUBMITTED_INTERNAL_HOME ? (
                            <div
                                style={{
                                    marginTop: '10px',
                                    marginBottom: '15px',
                                    paddingBottom: '10px',
                                    borderBottom: '3px solid rgba(0,0,0,.35)',
                                }}
                            >
                                <Button 
                                    basic
                                    icon='plus'
                                    content='Add a different signer at your organization (optional)'
                                    onClick={() => {
                                        setDirty(true);
                                        setStates({
                                            ...states,
                                            signor_different_user: {
                                                key: 'signor_different_user',
                                                active_state: statuses.SUBMITTED_DIFFERENT_SIGNER,
                                                meta: {
                                                    email: '',
                                                    approved: false,
                                                    approved_date: '',
                                                    submitted_to_different_signer_date: '',
                                                    added: true
                                                }
                                            }
                                        })
                                    }}
                                />
                            </div>
                        ) : null
                    }

                    {states.away_internal_approval && (
                        <InternalApproval 
                            noAction
                            isAway
                            state={states.away_internal_approval}
                            active={states.away_internal_approval.active_state === contest.status}
                        />
                    )}
                    {states.away_different_signer ? (
                        <OtherSigner 
                            noAction
                            isAway
                            signature_date={contest.signature_date}
                            signors_title={contest.signors_title}
                            signors_email={contest.signors_email}
                            state={states.away_different_signer}
                            active={states.away_different_signer.active_state === contest.status}
                        />
                    ) : (
                        <ContractRecipient 
                            contactList={awayOrg.contactList}
                            state={states.submitted}
                            active={states.submitted.active_states.includes(contest.status)}
                            onChange={submitted => {
                                setDirty(true);
                                setStates({
                                    ...states,
                                    submitted
                                })
                            }}

                        />
                    )}
                    
                        
                    

                    
                    
                    
                </div>
            </div>
        </BaseControlledModal>
    )
}

let ContestStatusModalContainer = props => {
    
    return props.contest && props.contest.id ? (
        <ContestStatusModal 
            {...props}
        />
    ) : null
}

export default ContestStatusModalContainer;