import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './App';
import AuthCallback from './AuthCallback';
import Auth from './Auth';
import { AppContext } from './components/context/AppContext';
import { useState } from 'react';
import {
    FreeTrialModal,
    RequestNewOrgModal,
} from './components/modals/requestDemoModal';

const auth = new Auth();

const handleAuthentication = (props: any) => {
    if (/access_token|id_token|error/.test(props.location.hash)) {
        auth.handleAuthentication(props);
    }
};

export const MainRoutes: React.FC<any> = () => {
    const [showContactUsModal, setShowContactUsModal] =
        useState<boolean>(false);
    const [requestNewOrgModal, setRequestNewOrgModal] = useState<{
        open: boolean;
        input?: string;
        user?: any;
    }>({ open: false });

    return (
        <AppContext.Provider
            value={{
                showContactUsModal,
                setShowContactUsModal,
                requestNewOrgModal,
                setRequestNewOrgModal,
            }}
        >
            <Router>
                <div>
                    <Route
                        path="/callback"
                        render={(props) => {
                            handleAuthentication(props);
                            return <AuthCallback {...props} />;
                        }}
                    />
                    <Route
                        path="/"
                        render={(props) => {
                            return <App {...props} auth={auth} />;
                        }}
                    />
                </div>
            </Router>
            <FreeTrialModal
                open={showContactUsModal}
                onClose={() => setShowContactUsModal(false)}
            />
            <RequestNewOrgModal
                open={requestNewOrgModal.open}
                input={requestNewOrgModal.input}
                user={requestNewOrgModal.user}
                onClose={() => setRequestNewOrgModal({ open: false })}
            />
        </AppContext.Provider>
    );
};
