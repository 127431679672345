var AWS = window.AWS; 

const IdentityPoolId = 'us-west-2:2712ae15-692f-4d26-be8d-a17b8471d8fa';
export const albumBucketName = 'contest-pdfs';
export const bucketRegion = 'us-west-2';

AWS.config.update({
	region: bucketRegion,
	credentials: new AWS.CognitoIdentityCredentials({
		IdentityPoolId: IdentityPoolId
	})
});

const s3 = new AWS.S3({
	apiVersion: '2006-03-01',
	params: {Bucket: albumBucketName}
});

export let isUploaded = (guid) => {
	return new Promise((resolve, reject) => {
		const params = {Bucket: albumBucketName, Key: (`${guid}.png`)};
		s3.getObject(params, (err, data) => {
			if (err) {
				resolve(false)
			} else {
				if (data.Body) {
					resolve(true)
				} else {
					resolve(false)
				}
			}
		})
	})
}



export default s3;
