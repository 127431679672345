import gql from 'graphql-tag';

export interface QBInvoice {
    Id: string;
    CustomerRef: {
        value: string;
        name: string;
    };
    SyncToken: string;
    BillEmail: { Address: string }; // must be a valid email
    TxnDate: string; // yyyy/MM/dd
    AllowOnlineACHPayment: boolean; // may need to track whether the client has this enabled
    AllowOnlineCreditCardPayment: boolean;
    DueDate: string; // UTC: YYYY-MM-DDZ
    EmailStatus?: string; // NotSet, NeedToSend, EmailSent
    TotalAmt: number;
    Balance: number;
    DocNumber: string;
}
export interface Org {
    id: string;
    name: string;
    nick?: string;
    team_nick?: string;
    conference: string;
    division: string;
    addr: string;
    city: string;
    region: string;
    country: string;
    primary_client: string;
    is_client: boolean;
    quickbooks_customer_id: string;
    billing_sports: string;
    billing_sports_num: number;
    billing_price_per_sport: number;
    notes: string;
    trial_end_date: string;
    invoices?: QBInvoice[];
    conf_bill?: boolean;
}

export const orgQuery = gql`
    query org($id: ID) {
        org(id: $id) {
            id
            name
            conference
            division
            addr
            city
            region
            country
            primary_contact
            is_client
            sports
            show_usage_agreement
            usage_agreement_signature
            usage_agreement_signature_date
            billing_sports
        }
    }
`;

export const orgsUsageQuery = gql`
    {
        orgsUsage
    }
`;

export const orgsUsageArgsQuery = gql`
    query orgsUsageArgs($orgid: ID, $year: Int) {
        orgsUsageArgs(orgid: $orgid, year: $year)
    }
`;

export const orgsQuery = gql`
    query orgs($clients: Boolean) {
        orgs(clients: $clients) {
            id
            name
            conference
            addr
            city
            region
            country
            location
            billing_sports
            billing_sports_num
            billing_price_per_sport
            is_client
            hide_client
            trial_end_date
            notes
            quickbooks_customer_id
        }
    }
`;

export const orgsLengthQuery = gql`
    query orgsLength($clients: Boolean, $search: String) {
        orgsLength(clients: $clients, search: $search)
    }
`;

export const adminOrgsQuery = gql`
    query orgs($clients: Boolean, $search: String, $page: Int) {
        orgs(clients: $clients, search: $search, page: $page) {
            id
            name
            conference
            addr
            city
            region
            country
            location
            billing_sports
            billing_sports_num
            billing_price_per_sport
            is_client
            hide_client
            trial_end_date
            notes
            quickbooks_customer_id
            conf_bill
            invoices {
                Id
                BillEmail {
                    Address
                }
                TxnDate
                AllowOnlineACHPayment
                AllowOnlineCreditCardPayment
                DueDate
                EmailStatus
                TotalAmt
                Balance
                DocNumber
            }
        }
    }
`;

export const orgCreateMutation = gql`
    mutation orgCreate(
        $name: String
        $nick: String
        $team_nick: String
        $conference: String
        $division: String
        $addr: String
        $city: String
        $region: String
        $country: String
        $is_client: Boolean
        $notes: String
    ) {
        orgCreate(
            name: $name
            nick: $nick
            team_nick: $team_nick
            conference: $conference
            division: $division
            addr: $addr
            city: $city
            region: $region
            country: $country
            is_client: $is_client
            notes: $notes
        ) {
            id
        }
    }
`;

export const orgUpdateMutation = gql`
    mutation orgUpdate(
        $id: ID
        $conference: String
        $division: String
        $addr: String
        $primary_contact: ID
        $sports: String
        $billing_sports: String
        $billing_sports_num: Int
        $billing_price_per_sport: Float
        $is_client: Boolean
        $trial_end_date: String
        $notes: String
        $conf_bill: Boolean
    ) {
        orgUpdate(
            id: $id
            conference: $conference
            division: $division
            addr: $addr
            primary_contact: $primary_contact
            sports: $sports
            billing_sports: $billing_sports
            billing_sports_num: $billing_sports_num
            billing_price_per_sport: $billing_price_per_sport
            is_client: $is_client
            trial_end_date: $trial_end_date
            notes: $notes
            conf_bill: $conf_bill
        ) {
            id
        }
    }
`;

export const orgAcceptUsageAgreementMutation = gql`
    mutation orgAcceptUsageAgreement(
        $orgid: ID
        $usage_agreement_signature: String
        $usage_agreement_signature_date: String
        $usage_agreement_signature_meta: String
    ) {
        orgAcceptUsageAgreement(
            orgid: $orgid
            usage_agreement_signature: $usage_agreement_signature
            usage_agreement_signature_date: $usage_agreement_signature_date
            usage_agreement_signature_meta: $usage_agreement_signature_meta
        )
    }
`;
