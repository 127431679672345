import React, { Component, useState, useEffect } from 'react';
import { Button, Dropdown, Icon, Popup } from 'semantic-ui-react';
import { Mutation, Query } from 'react-apollo';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import TimePicker from 'material-ui/TimePicker';
import { timeString } from '../../schedule/schedule';
import ReactTimePicker from 'rc-time-picker';
import moment from 'moment';
import './timeInput.css';
import 'react-datepicker/dist/react-datepicker.css';

import { userUpdateMutation, userQuery } from '../../../gql/user';

import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';

registerLocale('en-GB', enGB);
registerLocale('en-US', enUS);

const format = 'h:mm a';

let errorStyle = {
    background: '#efcfcf',
    borderColor: '#9f3a38',
    color: '#9f3a38',
};

let LocalePicker = (props) => {
    return (
        <Dropdown
            trigger={<Icon name="ellipsis vertical" />}
            options={[
                { text: 'Display week Sun-Sat', value: 'en-US' },
                { text: 'Display week Mon-Sun', value: 'en-GB' },
            ]}
            onChange={(e, { value }) => props.changeLocale({ value })}
            icon={null}
            value={props.locale}
        />
    );
};

export let CustomDatePickerInner = (props) => {
    let { calendarLocale, hasError, hideLocalePicker } = props;
    let [locale, setLocale] = useState(calendarLocale || 'en-US');

    useEffect(() => {
        setLocale(calendarLocale);
    }, [calendarLocale]);
    return (
        <div
            style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    zIndex: 2,
                    display: 'flex',
                }}
            >
                <DatePicker
                    className={`semanticField ${hasError ? 'hasError' : ''}`}
                    placeholderText={props.placeholderText}
                    selected={props.value}
                    onChange={props.onChange}
                    monthsShown={2}
                    locale={locale}
                />
            </div>
            {hideLocalePicker ? null : (
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <LocalePicker
                        changeLocale={(newLocale) => {
                            props.onUserUpdate({
                                calendarLocale: newLocale.value,
                            });
                        }}
                        locale={locale}
                    />
                </div>
            )}
        </div>
    );
};

export let CustomDatePicker = (props) => {
    return (
        <Query
            query={userQuery}
            variables={{
                email: props.user.email,
            }}
        >
            {({ data, loading, refetch }) => {
                if (loading) {
                    return null;
                }
                return (
                    <Mutation mutation={userUpdateMutation}>
                        {(userUpdate) => {
                            return (
                                <CustomDatePickerInner
                                    {...props}
                                    calendarLocale={data.user.calendarLocale}
                                    user={data.user}
                                    onUserUpdate={(variables) => {
                                        userUpdate({
                                            variables: {
                                                email: props.user.email,
                                                id: props.user.id,
                                                ...variables,
                                            },
                                        }).then(() => {
                                            refetch();
                                        });
                                    }}
                                />
                            );
                        }}
                    </Mutation>
                );
            }}
        </Query>
    );
};

export class CustomTimePicker extends Component {
    render() {
        let textFieldStyle = Object.assign(
            // {
            //     display: 'none'
            // },
            {
                border: '1px solid rgba(34,36, 38, .15)',
                borderRadius: '.28571429rem',
                boxShadow: '0 0 0 0 transparent inset',
                fontFamily:
                    'Lato, Helvetica Neue, Arial, Helvetica, sans-serif',
                fontSize: '14px',
                height: 'auto',
                width: '100%',
                lineHeight: '14px',
                margin: '0',
                padding: '1em',
                transition: 'color .1s ease, border-color .1s ease',
            },
            this.props.error ? errorStyle : {}
        );

        let {
            hideTimeString,
            hour,
            minute,
            setTBA,
            hasError,
            placeholder,
            onClose = () => {},
        } = this.props;
        let value =
            hour && minute !== undefined
                ? moment().hour(hour).minute(minute)
                : undefined;
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {hour === 24 ? (
                            !hideTimeString ? (
                                <div
                                    style={{
                                        width: this.props.width || '200px',
                                        paddingLeft: '15px',
                                    }}
                                >
                                    <span>{timeString(hour, minute)}</span>
                                </div>
                            ) : null
                        ) : (
                            <div
                                style={{
                                    width: this.props.width || '200px',
                                }}
                            >
                                <ReactTimePicker
                                    className={`${hasError ? 'hasError' : ''}`}
                                    showSecond={false}
                                    value={value}
                                    placeholder={placeholder}
                                    onChange={(newVal) => {
                                        if (newVal) {
                                            let data = {
                                                getHours: () => newVal.hour(),
                                                getMinutes: () =>
                                                    newVal.minute(),
                                            };
                                            this.props.onChange('event', data);
                                        } else {
                                            setTBA();
                                        }
                                    }}
                                    onClose={onClose}
                                    allowEmpty={false}
                                    format={format}
                                    minuteStep={5}
                                    use12Hours
                                    inputReadOnly
                                    // style={textFieldStyle}
                                />
                            </div>
                        )}
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingLeft: hideTimeString ? 0 : '15px',
                        }}
                    >
                        <Button.Group>
                            <Popup
                                position="top center"
                                trigger={
                                    <Button
                                        icon
                                        style={{
                                            position: 'relative',
                                        }}
                                        positive={hour !== 24}
                                        onClick={() => {
                                            let data = {
                                                getHours: () => 12,
                                                getMinutes: () => 0,
                                            };
                                            this.props.onChange('event', data);
                                        }}
                                    >
                                        <Icon name="clock" size="large" />
                                    </Button>
                                }
                                content={
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: 'center',
                                            }}
                                        >
                                            <span>{`Select Time`}</span>
                                        </div>
                                    </div>
                                }
                            ></Popup>
                            <Button.Or style={{ zIndex: 1 }} />
                            <Popup
                                position="top center"
                                trigger={
                                    <Button
                                        onClick={setTBA}
                                        positive={hour === 24}
                                    >
                                        TBA
                                    </Button>
                                }
                                content={
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                textAlign: 'center',
                                            }}
                                        >
                                            <span>{`Set time to TBA`}</span>
                                        </div>
                                    </div>
                                }
                            ></Popup>
                        </Button.Group>
                    </div>
                </div>
            </div>
        );
    }
}
