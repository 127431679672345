import React, {useState} from 'react';
import {Mutation} from 'react-apollo';
import {
    Button,
    Popup
} from 'semantic-ui-react';
import { requestCopyMutation } from '../../gql/contract';

let RequestCopyButtons = props => {
    let {onClose=() => {}, notify=()=>{}, guid, user} = props;
    const [showPopup, setShowPopup] = useState(false)
    return (
        <Mutation
            mutation={requestCopyMutation}
        >
            {(requestCopy, {loading, error}) => (
                <Popup 
                    trigger={
                        <Button 
                            onClick={() => {
                                setShowPopup(true)
                            }}
                        >
                            Request Copy
                        </Button>
                    }
                    on='click'
                    open={showPopup}
                    onClose={() => setShowPopup(false)}
                    wide='very'
                >
                    <div>
                        <div>
                            Use these buttons <strong>ONLY</strong> if you want to send this contract{" "}
                            <em>outside</em> of the Perfect Record platform (through US Mail, for
                            example).
                        </div>
                        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                            <strong>Request Signed Contract</strong> will email you a copy of this
                            contract with your signature information in the home signature block.
                        </div>
                        <div>
                            <strong>Request Blank Contract</strong> will email you a copy of this
                            contract without any information in the signature block. Use this if
                            someone other than yourself will be the primary signer of the contract.
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <em>Reminder:</em> If you exchange this contract outside of the Perfect
                            Record platform, please remember to record it as such ('Contract to be
                            exchanged off Perfect Record platform' checkbox above).
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '16px'}}>
                            <Button 
                                onClick={() => {
                                    requestCopy({
                                        variables: {
                                            guid: guid,
                                            recipient: user.email,
                                            home: true,
                                            user_fullname: user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : '',
                                            user_title: user.title,
                                            user_office_phone: user.phone_work,
                                            blank: false
                                        }
                                    }).then(() => {
                                        onClose();
                                        setShowPopup(false)
                                        notify(true, {
                                            message: `You will receive a copy of the contract in your email shortly`
                                        });
                                    })
                                }}
                            >
                                Request Signed Copy
                            </Button>
                            <Button 
                                onClick={() => {
                                    requestCopy({
                                        variables: {
                                            guid: guid,
                                            recipient: user.email,
                                            blank: true,
                                        }
                                    }).then(() => {
                                        onClose();
                                        setShowPopup(false)
                                        notify(true, {
                                            message: `You will receive a copy of the contract in your email shortly`
                                        });
                                    })
                                }}
                            >
                                Request Blank Copy
                            </Button>
                        </div>
                    </div>
                </Popup>
            )}
        </Mutation>
    )
}

export default RequestCopyButtons;