import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { addDays, addMonths } from 'date-fns';
import { Button, Form, Loader, Modal } from 'semantic-ui-react';
import { Org } from '../../gql/org';
import {
    QBCustomer,
    QBInvoice,
    quickbooksCustomersQuery,
    quickbooksInvoiceCreateAndSend,
    quickbooksInvoicesQuery,
} from '../../gql/qb';
import { colors } from '../../utils/colors';
import {
    FormRow,
    FormRowInput,
    FormRowTextArea,
} from '../utilityComponents/form/formRow';
import { CustomDatePicker } from '../utilityComponents/form/materialUI';
import { ModalSelect } from '../modals/modals';
import { userQuery } from '../../gql/user';

const dollarUSLocale = Intl.NumberFormat('en-US');

interface CreateInvoice {
    orgid: string;
    due_date: Date;
    txn_date: Date;
    doc_number?: string;
    bill_email: string;
    qty: number;
    unit_price: number;
    memo?: string;
    sales_item_detail_item_ref_name?: string;
    description?: string;
}

interface CreateNewInvoiceProps {
    org: Org;
    onClose: () => void;
    onCloseModal: () => void;
    user: any;
    refetchInvoices: () => Promise<any>;
    qbCustomer: QBCustomer;
    numInvoices: number;
}

const academicYears = [
    2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
    2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041,
    2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050,
];
const academicYearOptions = academicYears.map((year) => ({
    label: `${year}-${year + 1}`,
    value: year,
}));

const CreateNewInvoice: (props: CreateNewInvoiceProps) => JSX.Element = ({
    org,
    onClose,
    onCloseModal,
    refetchInvoices,
    user,
    qbCustomer,
    numInvoices,
}) => {
    const [academicYear, setAcademicYear] = useState<number>(
        new Date().getFullYear()
    );
    const [invoice, setInvoice] = useState<CreateInvoice>({
        orgid: org.id,
        txn_date: new Date(),
        due_date: addDays(new Date(), 60),
        bill_email: qbCustomer?.PrimaryEmailAddr?.Address || '',
        qty: org.billing_sports_num,
        unit_price: org.billing_price_per_sport,
        memo: '',
        description: `Annual usage fee for game contract management platform. ${org.billing_sports_num} sports @ $${org.billing_price_per_sport}/sport/year`,
        sales_item_detail_item_ref_name:
            'Usage fee:Perfect Record Software 2024-2025 Usage Fee',
    });
    // get user options from org to pick email, or allow for add new

    const createAndSendQBInvoice = useMutation(quickbooksInvoiceCreateAndSend);

    const handleCreate = () => {
        const toSave = {
            ...invoice,
            doc_number: `${academicYear}-${org.id}-${
                numInvoices === 0 ? 1 : numInvoices + 1
            }`,
        };
        createAndSendQBInvoice({
            variables: {
                ...toSave,
            },
        }).then(() => {
            refetchInvoices();
            onClose();
            onCloseModal();
        });
    };

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button onClick={onClose}>Close</Button>
                <Button primary onClick={handleCreate}>
                    Save
                </Button>
            </div>
            <div>
                <FormRow label="Academic Year" required>
                    <ModalSelect
                        placeholder="Select academic year"
                        name="academicYear"
                        required
                        value={academicYear}
                        options={academicYearOptions}
                        onChange={(_: string, value: number) => {
                            setAcademicYear(value);
                        }}
                    />
                </FormRow>
                <FormRow label="Txn Date" required>
                    <CustomDatePicker
                        hintText="Select Date"
                        value={invoice.txn_date}
                        user={user}
                        onChange={(data: Date) => {
                            setInvoice({
                                ...invoice,
                                txn_date: data,
                            });
                        }}
                    />
                </FormRow>
                <FormRow label="Due Date" required>
                    <CustomDatePicker
                        hintText="Select Date"
                        user={user}
                        value={invoice.due_date}
                        onChange={(data: Date) => {
                            setInvoice({
                                ...invoice,
                                txn_date: data,
                            });
                        }}
                    />
                </FormRow>
                <FormRowInput
                    label="Email"
                    type="text"
                    field="bill_email"
                    value={invoice.bill_email}
                    formUpdate={(update: any) => {
                        setInvoice({
                            ...invoice,
                            bill_email: update.bill_email,
                        });
                    }}
                />
                <FormRowInput
                    label="Qty"
                    type="number"
                    field="qty"
                    value={invoice.qty}
                    formUpdate={(update: any) => {
                        setInvoice({
                            ...invoice,
                            qty: update.qty,
                        });
                    }}
                />
                <FormRowInput
                    label="Unit Price"
                    type="text"
                    field="unit_price"
                    value={invoice.unit_price}
                    formUpdate={(update: any) => {
                        setInvoice({
                            ...invoice,
                            unit_price: update.unit_price,
                        });
                    }}
                />
                <FormRowInput
                    label="Total"
                    type="text"
                    field="total"
                    value={invoice.unit_price * invoice.qty}
                    disabled
                />
                <Form>
                    <FormRowTextArea
                        label="Invoice Description"
                        field="description"
                        value={invoice.description}
                        formUpdate={(update: any) => {
                            setInvoice({
                                ...invoice,
                                description: update.description,
                            });
                        }}
                    />
                    <FormRowTextArea
                        label="Sales Item Description"
                        field="sales_item_detail_item_ref_name"
                        value={invoice.sales_item_detail_item_ref_name}
                        formUpdate={(update: any) => {
                            setInvoice({
                                ...invoice,
                                sales_item_detail_item_ref_name:
                                    update.sales_item_detail_item_ref_name,
                            });
                        }}
                    />
                    <FormRowTextArea
                        label="Memo"
                        field="memo"
                        value={invoice.memo}
                        formUpdate={(update: any) => {
                            setInvoice({
                                ...invoice,
                                memo: update.memo,
                            });
                        }}
                    />
                </Form>
            </div>
        </div>
    );
};

const getAcademicYear: (opts: {
    DocNumber: QBInvoice['DocNumber'];
    TxnDate: QBInvoice['TxnDate'];
}) => string = ({ DocNumber, TxnDate }) => {
    let year;
    if (DocNumber.indexOf('-') > 0) {
        year = parseInt(DocNumber.split('-')[0], 10);
    } else {
        year = new Date(TxnDate).getFullYear();
    }
    return `${year}-${year + 1}`;
};

interface InvoicesModalProps {
    open: boolean;
    onClose: () => void;
    org: Org;
}

export const InvoicesModal: (props: InvoicesModalProps) => JSX.Element = ({
    open,
    onClose,
    org,
}) => {
    const [createNew, setCreateNew] = useState<boolean>(false);
    const { data, loading, refetch } = useQuery(quickbooksInvoicesQuery, {
        variables: {
            orgid: org.id,
        },
        skip: !org.id,
        fetchPolicy: 'network-only',
    });
    const qbCustomersGql = useQuery(quickbooksCustomersQuery);

    const userGql = useQuery(userQuery, {
        variables: {
            id: '4011',
        },
    });

    const user = userGql.data?.user || null;

    const invoices = data?.quickbooksInvoices || [];

    const qbCustomer: QBCustomer | undefined =
        qbCustomersGql.data?.quickbooksCustomers?.find(
            (c: QBCustomer) => c.Id === org.quickbooks_customer_id
        );

    return (
        <Modal
            open={open}
            onClose={() => {
                setCreateNew(false);
                onClose();
            }}
            size="large"
        >
            <Modal.Header>{`${org.name} Invoices`}</Modal.Header>
            <Modal.Content>
                <div>
                    {createNew && user && qbCustomer ? (
                        <CreateNewInvoice
                            org={org}
                            user={user}
                            onClose={() => setCreateNew(false)}
                            onCloseModal={onClose}
                            refetchInvoices={refetch}
                            qbCustomer={qbCustomer}
                            numInvoices={invoices.length}
                        />
                    ) : loading ? (
                        <Loader active size="medium" />
                    ) : invoices.length ? (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button onClick={() => setCreateNew(true)}>
                                    Create New Invoice
                                </Button>
                            </div>
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '8px 0',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        Academic Year
                                    </div>
                                    <div
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        Date
                                    </div>
                                    <div
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        No.
                                    </div>

                                    <div
                                        style={{
                                            flex: 2,
                                        }}
                                    >
                                        Recipient
                                    </div>
                                    <div
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        Email Status
                                    </div>
                                    <div
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        Total Amount
                                    </div>
                                    <div
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        Balance
                                    </div>
                                </div>
                                {invoices.map(
                                    (invoice: QBInvoice, index: number) => {
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    padding: '8px 0',
                                                    backgroundColor:
                                                        index % 2
                                                            ? colors.white
                                                            : colors.lightGrey,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    {getAcademicYear({
                                                        DocNumber:
                                                            invoice.DocNumber,
                                                        TxnDate:
                                                            invoice.TxnDate,
                                                    })}
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    {invoice.TxnDate}
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    {invoice.DocNumber}
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 2,
                                                    }}
                                                >
                                                    {invoice.BillEmail.Address}
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    {invoice.EmailStatus}
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    {`$${dollarUSLocale.format(
                                                        invoice.TotalAmt
                                                    )}`}
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    {`$${dollarUSLocale.format(
                                                        invoice.Balance
                                                    )}`}
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button onClick={() => setCreateNew(true)}>
                                    Create New Invoice
                                </Button>
                            </div>
                            <div>No invoices for this organization</div>
                        </>
                    )}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
};
