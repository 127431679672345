import React, {Component} from 'react';
import {
    Modal,
    Popup,
    Icon,
    Button
} from 'semantic-ui-react';

import {
    BaseControlledModal,
    BaseModal,
    ModalAction
} from '../modals/modals';
import {statuses, statusDescriptions, statusIcons, notViewReminderHistory, CANCELLED} from '../../utils/statuses';
import { Mutation, Query } from 'react-apollo';
import { contestSafeUpdate } from '../../gql/contest';
import { ReminderInterval } from '../modals/submitContestModal';
import { userQuery } from '../../gql/user';

let HistoryRow = props => {
    return (
        <div 
            style={{
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <div style={{flex: 1}}>
                <span
                    style={{
                        fontWeight: props.header ? 'bold' : 'normal'
                    }}
                >
                    {props.date}
                </span>
            </div>
            <div style={{flex: 3}}>
                <span
                    style={{
                        fontWeight: props.header ? 'bold' : 'normal'
                    }}
                >
                    {props.text}
                </span>
            </div>
        </div>
    )
}

let ContestHistoryModal = props => {
    
    let {contest, openStatusModal=() => {}} = props;
    return (
        <Popup
            key={contest.id}
            position='top center'
            trigger={
                <Icon 
                    style={{cursor: 'pointer'}} 
                    size={'big'} 
                    {...statusIcons[contest.status]} 
                    onClick={openStatusModal}
                />
            }
            content={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        <span>
                            {statusDescriptions[contest.status]}
                        </span>
                    </div>
                    <div
                        style={{
                            marginTop: '10px',
                            fontSize: '10px'
                        }}
                    >
                        <span>
                            Click icon to see history
                        </span>
                    </div>
                    
                </div>
            }
        />
    );
}

export default ContestHistoryModal
