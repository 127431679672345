import React, { useState } from 'react';
import { Input } from 'semantic-ui-react';

export interface BlurInputProps {
    onBlurChange: (value: string | number) => void;
    value: string | number;
    icon?: any;
    placeholder?: string;
}

export const BlurInput: React.FC<BlurInputProps> = ({
    onBlurChange,
    value,
    icon,
    placeholder,
}) => {
    const [val, setVal] = useState(value);
    return (
        <Input
            icon={icon}
            placeholder={placeholder}
            value={val}
            onChange={(e, { value }) => {
                setVal(value);
            }}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    onBlurChange(val);
                    e.currentTarget.blur();
                }
            }}
            onBlur={() => onBlurChange(val)}
        />
    );
};
