import gql from 'graphql-tag';

export const contactUsMutation = gql`
    mutation contactUs(
        $email: String
        $name: String
        $phone: String
        $school: String
        $message: String
        $demo: Boolean
        $emailTemplate: String
    ) {
        contactUs(
            email: $email
            name: $name
            phone: $phone
            school: $school
            message: $message
            demo: $demo
            emailTemplate: $emailTemplate
        )
    }
`;
