import React, { Component, useState, useEffect } from 'react';
import { Mutation, Query, graphql } from 'react-apollo';
import CSVReader from 'react-csv-reader';
import {
    Button,
    Container,
    Header,
    Icon,
    Modal,
    Popup,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import XLSX from 'xlsx';

import BulkContestModal from '../modals/bulkContestModal';
import { ActionButton, timeString, FilterSelect } from '../schedule/schedule';
import {
    orgMatches,
    orgBulkDefaults,
    orgBulkDefaultsUpsert,
} from '../../gql/bulk';
import { ModalSelect, ModalAction } from '../modals/modals';
import { sportSelectOptions, sports } from '../../utils/sports';
import { sportsDefaultQuery } from '../../gql/defaults';
import {
    contestsCreateMutation,
    contestCreateMutation,
} from '../../gql/contest';
import {
    CustomDatePicker,
    CustomTimePicker,
} from '../utilityComponents/form/materialUI';
import { colors } from '../../utils/colors';
import { sportsAlphaWithGenders } from './../../utils/sports';

import templateCSV from './Perfect_Record_Bulk_Upload_Template.xlsx';
import { orgQuery } from '../../gql/org';
import { InfoModalVideo } from '../utilityComponents/InfoModalVideo';

/* list of supported file types */
const SheetJSFT = [
    'xlsx',
    'xlsb',
    'xlsm',
    'xls',
    'xml',
    'csv',
    'txt',
    'ods',
    'fods',
    'uos',
    'sylk',
    'dif',
    'dbf',
    'prn',
    'qpw',
    '123',
    'wb*',
    'wq*',
    'html',
    'htm',
]
    .map(function (x) {
        return '.' + x;
    })
    .join(',');

/* generate an array of column objects */
const make_cols = (refstr) => {
    let o = [],
        C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; ++i)
        o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
};

let handleFile = (file, setData) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */

        const data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            raw: false,
            type: 'string',
        });
        /* Update state */

        setData({ data: data, cols: make_cols(ws['!ref']) });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
};

let XlsxThing = (props) => {
    let [data, setData] = useState({
        cols: [],
        data: [],
    });

    useEffect(() => {
        if (data.data && data.data.length) {
            props.theThing(data.data);
        }
    }, [data]);

    return <DataInput handleFile={(file) => handleFile(file, setData)} />;
};

class DataInput extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        const files = e.target.files;
        if (files && files[0]) this.props.handleFile(files[0]);
    }
    render() {
        return (
            <form className="form-inline">
                <div className="form-group" style={{ display: 'none' }}>
                    <input
                        type="file"
                        className="form-control"
                        id="file"
                        accept={SheetJSFT}
                        onChange={this.handleChange}
                    />
                </div>
            </form>
        );
    }
}

const academicYears = [
    2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030,
];
const academicYearOptions = academicYears.map((year) => ({
    label: `${year - 1}-${year}`,
    value: year,
}));

let AcademicYearSelect = (props) => {
    return (
        <FilterSelect
            name="academicYears"
            placeholder="Academic Years"
            isMulti={false}
            notSearchable
            value={props.academicYear}
            options={academicYearOptions}
            onChange={(value) => props.setAcademicYear(value)}
        />
    );
};

const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

const formatGroupLabel = (data) => (
    <div style={groupStyles}>
        <span>{data.label}</span>
        {data.label !== 'All' && (
            <span style={groupBadgeStyles}>{data.options.length}</span>
        )}
    </div>
);

let Opponent = (props) => {
    let {
        bulkOpponents,
        homeoraway,
        homeorawayTry,
        opponent,
        orgMatches = {},
        opponentOptions,
        opponentId,
        bulkOpponentsPicked,
        value,
    } = props;
    let { orgs, error, exact, searched } = orgMatches;
    const [showSelect, setShowSelect] = useState(false);

    let post;
    let foundOpponent;
    let foundOpponentId;
    let errorText = 'Click here to select opponent';
    let style = {};
    if (value) {
        let foundOpponentOption =
            opponentOptions.find((org) => org.value === value) || {};
        foundOpponent = foundOpponentOption.label;
        foundOpponentId = value;
    } else if (exact) {
        let org = orgs[0];
        foundOpponent = org.name;
        foundOpponentId = org.id;
    } else if (error) {
        foundOpponent = errorText;
        style = {
            backgroundColor: '#efcfcf',
            borderRadius: '2px',
            padding: '0 3px',
            color: colors.danger,
        };
    } else {
        foundOpponent = errorText;
        style = {
            backgroundColor: '#efcfcf',
            borderRadius: '2px',
            padding: '0 3px',
            color: colors.danger,
        };
    }

    useEffect(() => {
        if (!value && foundOpponentId) {
            props.onChange('opponentId', { value: foundOpponentId });
        }
    }, [foundOpponentId]);

    if (showSelect) {
        let searchedOrgsOptions =
            orgs && orgs.length
                ? orgs.map((org) => ({ value: org.id, label: org.name }))
                : [];
        let options = [
            {
                label: 'Possible Matches',
                options: searchedOrgsOptions,
            },
            {
                label: 'All',
                options: opponentOptions,
            },
        ];
        if (bulkOpponents) {
            let defaultOrgsOptions =
                bulkOpponents && bulkOpponents.length
                    ? bulkOpponents.map((id) => ({
                          value: id,
                          label: opponentOptions.find((org) => org.value === id)
                              .label,
                      }))
                    : [];
            options.unshift({
                label: 'Org Defaults',
                options: defaultOrgsOptions,
            });
        }
        post = (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <span style={{ width: '25px' }}>
                    {homeoraway === 'home' ? 'vs.' : `@`}
                </span>
                <div
                    style={{
                        flex: 1,
                        fontWeight: 'normal',
                    }}
                >
                    <ModalSelect
                        placeholder="Select opponent"
                        name="org"
                        required
                        defaultValue={undefined}
                        value={foundOpponentId}
                        options={options}
                        grouped
                        error={foundOpponent === errorText}
                        ungroupedOptions={opponentOptions}
                        onChange={(attr, data) => {
                            if (foundOpponent === errorText) {
                                props.handleDefaultSet({
                                    [opponent]: data.value,
                                });
                            } else if (
                                bulkOpponentsPicked &&
                                foundOpponentId !== data.value
                            ) {
                                props.handleDefaultSet({
                                    [opponent]: data.value,
                                });
                            } else if (
                                exact &&
                                foundOpponentId !== data.value
                            ) {
                                props.handleDefaultSet({
                                    [opponent]: data.value,
                                });
                            }
                            props.onChange(attr, data);
                            setShowSelect(false);
                        }}
                        formatGroupLabel={formatGroupLabel}
                    />
                </div>
            </div>
        );
    } else {
        post = (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <span style={{ width: '25px' }}>
                    {homeoraway === 'home' ? 'vs.' : `@`}
                </span>
                <div
                    style={{
                        flex: 1,
                        fontWeight: 'normal',
                        cursor: 'pointer',
                        ...style,
                    }}
                    onClick={() => setShowSelect(true)}
                >
                    {`${foundOpponent}`}
                </div>
            </div>
        );
    }

    return <Cell pre={`${opponent} (${homeorawayTry})`} post={post} ƒ />;
};

class OpponentConfirm extends Component {
    state = {};
    render() {
        let {
            homeoraway,
            homeorawayTry,
            opponent,
            orgMatches = {},
            opponentOptions,
            opponentId,
            bulkOpponentsPicked,
        } = this.props;
        let { orgs, error, exact, searched } = orgMatches;
        if (error) {
            return (
                <div>
                    <span>{error}</span>
                    <ModalSelect
                        placeholder="Select organization"
                        name="org"
                        required
                        value={opponentId}
                        options={opponentOptions}
                        onChange={this.props.onChange}
                    />
                </div>
            );
        }

        if (exact) {
            let org = orgs[0];
            return (
                <Cell
                    pre={`${opponent} (${homeorawayTry})`}
                    post={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ width: '25px' }}>
                                {homeoraway === 'home' ? 'vs.' : `@`}
                            </span>
                            <span>{org.name}</span>
                        </div>
                    }
                />
            );
        } else if (bulkOpponentsPicked) {
            let matchedOrg = opponentOptions.find(
                (org) => org.value === opponentId
            );
            return (
                <Cell
                    pre={`${opponent} (${homeorawayTry})`}
                    post={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ width: '25px' }}>
                                {homeoraway === 'home' ? 'vs.' : `@`}
                            </span>
                            <span>{matchedOrg.label}</span>
                        </div>
                    }
                />
            );
        } else {
            let searchedOrgsOptions = orgs.map((org) => ({
                value: org.id,
                label: org.name,
            }));
            let options = [
                {
                    label: 'Searched',
                    options: searchedOrgsOptions,
                },
                {
                    label: 'All',
                    options: opponentOptions,
                },
            ];
            return (
                <div>
                    <Cell
                        pre={`${searched} (${homeorawayTry})`}
                        post={
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{ width: '25px' }}>
                                    {homeoraway === 'home' ? 'vs.' : `@`}
                                </span>
                                <div
                                    style={{
                                        flex: 1,
                                        fontWeight: 'normal',
                                    }}
                                >
                                    <ModalSelect
                                        placeholder="Select organization"
                                        name="org"
                                        required
                                        defaultValue={this.props.value}
                                        value={this.props.value}
                                        options={options}
                                        onChange={this.props.onChange}
                                        formatGroupLabel={formatGroupLabel}
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
            );
        }
    }
}

let Cell = (props) => {
    let { pre, post, hidePostString } = props;
    return (
        <div
            style={{
                textAlign: 'left',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        width: '80px',
                    }}
                >
                    <span>You input:</span>
                </div>
                <div>{pre}</div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {!hidePostString && (
                    <div
                        style={{
                            width: '80px',
                        }}
                    >
                        <span>Recorded:</span>
                    </div>
                )}

                <div
                    style={{
                        fontWeight: 'bold',
                        flex: 1,
                        display: 'flex',
                    }}
                >
                    {post}
                </div>
            </div>
        </div>
    );
};

let DatePicker = (props) => {
    let { dateTry, dateError, errors = [], index, date, user } = props;
    let [showSelect, setShowSelect] = useState(false);
    let post;
    let errorText = 'Select date';
    let style = {};
    if (showSelect) {
        post = (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
            >
                <CustomDatePicker
                    hintText="Select Date"
                    placeholderText="Select Date"
                    hasError={dateError && errors.includes('date')}
                    hideDateString
                    value={date && new Date(date)}
                    user={user}
                    onChange={(data) => {
                        props.onChange(index, { date: data });
                        setShowSelect(false);
                    }}
                />
            </div>
        );
    } else {
        if (dateError && errors.includes('date')) {
            style = {
                backgroundColor: '#efcfcf',
                borderRadius: '2px',
                padding: '0 3px',
                color: colors.danger,
                cursor: 'pointer',
            };
            post = (
                <div
                    style={style}
                    onClick={() => {
                        setShowSelect(true);
                    }}
                >
                    <span>{errorText}</span>
                </div>
            );
        } else {
            post = (
                <div
                    style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                    }}
                    onClick={() => {
                        setShowSelect(true);
                    }}
                >
                    <span>{`${new Date(date).toLocaleDateString(
                        'en-US'
                    )}`}</span>
                </div>
            );
        }
    }
    return <Cell pre={dateTry} post={post} />;
};

let Sport = (props) => {
    let { sportTry, sport, filteredOrgPermissions, user } = props;
    let [showSelect, setShowSelect] = useState(false);
    let post;
    let errorText = 'Click to select sport';
    let style = {};
    let isOrgPermissionedSport =
        user.is_admin ||
        filteredOrgPermissions.find((option) => option.value === sport);
    if (showSelect) {
        post = (
            <ModalSelect
                placeholder="Select sport"
                name="sport"
                required
                error={!(sport && sports[sport])}
                value={sport}
                options={filteredOrgPermissions}
                onChange={(attr, data) => {
                    props.onChange(props.index, { sport: data.value });
                    setShowSelect(false);
                }}
            />
        );
    } else {
        if (sport && sports[sport] && isOrgPermissionedSport) {
            post = (
                <div
                    style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                    }}
                    onClick={() => {
                        setShowSelect(true);
                    }}
                >
                    <span>{`${sport} - ${sports[sport]}`}</span>
                </div>
            );
        } else {
            style = {
                backgroundColor: '#efcfcf',
                borderRadius: '2px',
                padding: '0 3px',
                color: colors.danger,
                cursor: 'pointer',
                fontWeight: 'bold',
            };
            post = (
                <div
                    style={style}
                    onClick={() => {
                        setShowSelect(true);
                    }}
                >
                    <span>{errorText}</span>
                </div>
            );
        }
    }
    return (
        <Cell
            pre={sportTry}
            post={
                <div
                    style={{
                        flex: 1,
                        fontWeight: 'normal',
                    }}
                >
                    {post}
                </div>
            }
        />
    );
};

let TimePicker = (props) => {
    let { hour, minute, timeTry, errors = [], index, user } = props;
    let [showSelect, setShowSelect] = useState(false);
    let post;
    let errorText = 'Select time';
    let style = {};
    if (showSelect) {
        post = (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <CustomTimePicker
                    minutesStep={5}
                    hour={hour}
                    minute={minute}
                    width={'75px'}
                    hasError={!(hour && minute !== undefined)}
                    placeholder={`Select time`}
                    setTBA={() => {
                        props.onChange(props.index, { hour: 24 });
                        props.onChange(props.index, { minute: 0 });
                        setShowSelect(false);
                    }}
                    value={
                        hour && minute !== undefined
                            ? new Date(2018, 6, 1, hour, minute)
                            : undefined
                    }
                    onChange={(e, data) => {
                        props.onChange(props.index, { hour: data.getHours() });
                        props.onChange(props.index, {
                            minute: data.getMinutes(),
                        });
                    }}
                    onClose={() => {
                        setShowSelect(false);
                    }}
                />
            </div>
        );
    } else {
        if (!(hour && minute !== undefined)) {
            style = {
                backgroundColor: '#efcfcf',
                borderRadius: '2px',
                padding: '0 3px',
                color: colors.danger,
                cursor: 'pointer',
                fontWeight: 'bold',
            };
            post = (
                <div
                    style={style}
                    onClick={() => {
                        setShowSelect(true);
                    }}
                >
                    <span>{errorText}</span>
                </div>
            );
        } else {
            post = (
                <div
                    style={{
                        cursor: 'pointer',
                        fontWeight: 'bold',
                    }}
                    onClick={() => {
                        setShowSelect(true);
                    }}
                >
                    <span>{timeString(hour, minute)}</span>
                </div>
            );
        }
    }
    return <Cell hidePostString={showSelect} pre={timeTry} post={post} />;
};

const headerMap = {
    opponentId: 'Opponent',
    date: 'Date',
    sport: 'Sport',
    time: 'Time',
};

const ScheduleRow = (props) => {
    let {
        date,
        dateError,
        dateTry,
        errors = [],
        hour,
        minute,
        timeError,
        timeTry,
        sport,
        sportTry,
        user,
    } = props;
    let [deletePopupVisible, setDeletePopupVisible] = useState(false);
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: props.index % 2 ? '#efefef' : 'white',
                padding: '5px 0',
                height: '60px',
                textAlign: 'left',
                position: 'relative',
            }}
        >
            <div style={{ flex: 3, paddingRight: '15px' }}>
                <Sport {...props} />
            </div>
            <div style={{ flex: 2, paddingRight: '15px' }}>
                <DatePicker {...props} />
            </div>
            <div style={{ flex: 2, paddingRight: '15px' }}>
                <TimePicker {...props} />
            </div>
            <div style={{ flex: 4, paddingRight: '15px' }}>
                <Opponent
                    {...props}
                    opponentOptions={props.opponentOptions}
                    opponent={props.opponent}
                    opponentId={props.opponentId}
                    orgMatches={props.orgMatches}
                    homeoraway={props.homeoraway}
                    homeorawayTry={props.homeorawayTry}
                    value={props.opponentId}
                    onChange={(attr, data) => {
                        props.onChange(props.index, { opponentId: data.value });
                    }}
                    handleDefaultSet={(update) => {
                        props.onChange(props.index, { newDefaults: update });
                    }}
                />
            </div>
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {props.errors && props.errors.length > 0 ? (
                    <Popup
                        on="hover"
                        position="top center"
                        trigger={
                            <Icon
                                size={'big'}
                                name="remove circle"
                                color="red"
                            />
                        }
                        content={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <span>
                                        This contest was not read correctly
                                    </span>
                                </div>
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <span>{`The following fields contain invalid data:\n${props.errors
                                        .map((error) => headerMap[error])
                                        .join(', ')}`}</span>
                                </div>
                            </div>
                        }
                    />
                ) : (
                    <Popup
                        on="hover"
                        position="top center"
                        trigger={
                            <Icon
                                size="big"
                                name="check circle"
                                color="green"
                            />
                        }
                        content={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    <span>
                                        All fields contain valid data, contest
                                        may be created
                                    </span>
                                </div>
                            </div>
                        }
                    />
                )}
            </div>
            <div
                style={{
                    flex: 4,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <ActionButton
                    title="Edit details"
                    onClick={props.setOtherDetails}
                />
                <Mutation mutation={contestCreateMutation}>
                    {(createContest, args) => {
                        return (
                            <ActionButton
                                positive
                                disabled={errors.length !== 0}
                                title="Create Contest"
                                onClick={() => {
                                    if (errors.length === 0) {
                                        props.createOne(
                                            props.index,
                                            createContest
                                        );
                                    }
                                }}
                            />
                        );
                    }}
                </Mutation>
                <Popup
                    on="click"
                    open={deletePopupVisible}
                    trigger={
                        <Button
                            basic
                            icon
                            style={{
                                padding: '.62em',
                            }}
                            onClick={() => setDeletePopupVisible(true)}
                        >
                            <Icon name="trash" size="large" />
                        </Button>
                    }
                    content={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: '5px',
                                    textAlign: 'center',
                                }}
                            >
                                Are you sure you want to remove this contest?
                            </div>
                            <Button
                                color="red"
                                onClick={() => {
                                    setDeletePopupVisible(false);
                                    props.deleteOne(props.index);
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                    }
                />
            </div>
        </div>
    );
};

const ScheduleHeader = (props) => {
    let style = {
        marginRight: '15px',
    };
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                fontWeight: 'bold',
                textAlign: 'left',
            }}
        >
            <div style={{ flex: 3, ...style }}>Sport</div>
            <div style={{ flex: 2, ...style }}>Date</div>
            <div style={{ flex: 2, ...style }}>Time</div>
            <div style={{ flex: 4, ...style }}>Opponent</div>
            <div style={{ flex: 1, textAlign: 'center' }}>Ready?</div>
            <div style={{ flex: 4, textAlign: 'center' }}>Actions</div>
        </div>
    );
};

const requiredFields = [
    {
        key: 'sport',
        label: 'Sport',
    },
    {
        key: 'opponent',
        label: 'Opponent',
    },
    {
        key: 'homeoraway',
        label: 'Home/Away',
    },
    {
        key: 'date',
        label: 'Date',
    },
    {
        key: 'time',
        label: 'Time',
    },
];

const isValidDate = (date) => date instanceof Date && !isNaN(date);

function compare(a, b) {
    if (a[3] < b[3]) return -1;
    if (a[3] > b[3]) return 1;
    return 0;
}

const BulkInstructions = () => {
    const instructionStep = {
        marginTop: '10px',
        fontWeight: 'bold',
        fontSize: '1.1em',
    };

    const underlineSubheader = {
        marginTop: '8px',
        textDecoration: 'underline',
    };

    return (
        <Modal trigger={<Button color="teal">Instructions</Button>} closeIcon>
            <Modal.Header>Bulk Create Contests - Instructions</Modal.Header>
            <Modal.Content scrolling>
                <Header as="h3">Step-by-step instructions</Header>
                <div style={instructionStep}>
                    <div>1. Download the template</div>
                </div>
                <div>
                    Click the "Download Template" button on the upper-right
                    corner of the page.
                </div>
                <div style={instructionStep}>
                    <div>2. Copy your schedule into the template</div>
                </div>
                <div>
                    The required fields are <strong>Sport</strong>,{' '}
                    <strong>Date</strong>, <strong>Time</strong>,{' '}
                    <strong>Opponent</strong>, and <strong>HomeOrAway</strong>.
                    See the section "Tips for using the spreadsheet template"
                    below for further details.
                </div>
                <div style={instructionStep}>
                    <div>3. Save the completed template to your computer</div>
                </div>
                <div>It doesn't matter what you name the file.</div>
                <div style={instructionStep}>
                    <div>4. Upload your saved template</div>
                </div>
                <div>
                    Click the "Upload Contest File" button and select the file
                    that you just saved to your computer.
                </div>
                <div style={instructionStep}>
                    <div>
                        5. Check that your contests were recorded accurately
                    </div>
                </div>
                <div>
                    After you upload your schedule, you will see a list of all
                    of the contests that will be created. For each contest, we
                    will show you what you entered in your spreadsheet, along
                    with how we recorded your entry. If we were not able to
                    determine what you entered for a particular field, that
                    field will be highlighted in red, and you can click on it to
                    select the correct entry.
                </div>
                <div style={{ marginTop: '5px' }}>
                    <em>Note</em>: You can click on any of the fields we
                    recorded to alter them. You can also click on the "Edit
                    Details" button on each row to view/edit the entire contest
                    record.
                </div>
                <div style={instructionStep}>
                    <div>6. Create your contests</div>
                </div>
                <div>
                    Each contest will have a "Ready?" icon that tells you
                    whether all of the required fields for a contest have been
                    entered. Once a contest is ready, you may create the contest
                    individually by clicking the green "Create Contest" button
                    on that line. Alternatively, if all of your contests are
                    ready, you can create all of them at once by clicking the
                    green "Create All Contests" button at the bottom of the
                    page.
                </div>
                <Header as="h3" style={{ margin: '20px 0 5px 0' }}>
                    Tips for using the spreadsheet template
                </Header>
                <div style={{ ...underlineSubheader, margin: '0' }}>
                    Column Headers
                </div>
                <div>
                    Row 1 must be the name of a field, so do not paste your data
                    in the first row.
                </div>
                <div style={{ ...underlineSubheader }}>Additional Fields</div>
                <div>
                    Bulk-created contests will incorporate your Contract
                    Defaults. If you want to include additional fields, select
                    the appropriate field name from the dropdown in a new column
                    and add your information.
                </div>
                <div style={{ marginTop: '5px' }}>
                    <em>Note</em>: We <strong>HIGHLY</strong> recommend that you
                    make sure your Contract Defaults are complete and up to date
                    before using this Bulk Create Contests tool. You can manage
                    your Contract Defaults on the MY ACCOUNT tab (MY ACCOUNT
                    -&gt; My Organization -&gt; Edit Default Contract Fields).
                </div>
                <div style={underlineSubheader}>Red and Yellow Cells</div>
                <div>
                    All cells start with a white background. A light red
                    background indicates our system will probably not be able to
                    read that entry. A yellow background indicates a missing
                    required field.
                </div>
                <div style={underlineSubheader}>"Sport" Column</div>
                <div>
                    Enter the sport code rather than the full sport name. For a
                    full list of sport codes, click on the "View Sport Codes"
                    button at the top of the page.
                </div>
                <div style={underlineSubheader}>"Date" and "Time" Columns</div>
                <div>
                    Our system accepts many different date and time formats. If
                    your preferred date or time format doesn't automatically
                    work with our system, please let us know.
                </div>
                <div style={underlineSubheader}>"Opponent" Column</div>
                <div>
                    If an opponent name on your spreadsheet does not match the
                    name of a school in our database, you will be asked to
                    select which school you intended from a dropdown. In the
                    future, our system will remember which school you selected
                    for that name and automatically choose that school. Our
                    system "learns" your preferences quickly!
                </div>
                <div style={underlineSubheader}>"HomeOrAway" Column</div>
                <div>
                    For home contests, enter "H" or "home". For away contests,
                    enter "A", "away", or "@".
                </div>
            </Modal.Content>
        </Modal>
    );
};

const ViewSportCodeList = () => {
    return (
        <Modal trigger={<Button>View Sport Code List</Button>} closeIcon>
            <Modal.Header>Sport Codes</Modal.Header>
            <Modal.Content>
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '10px' }}>
                        <div
                            style={{
                                textAlign: 'center',
                                borderBottom: '2px solid grey',
                                marginBottom: '5px',
                                fontWeight: 'bold',
                            }}
                        >
                            Men's Sports
                        </div>
                        <table
                            style={{
                                borderSpacing: '0',
                                borderCollapse: 'collapse',
                                lineHeight: '1',
                            }}
                        >
                            <tbody>
                                {sportsAlphaWithGenders
                                    .filter((sport) => sport[2] === 'M')
                                    .sort(compare)
                                    .map((sport) => (
                                        <tr key={sport[0]}>
                                            <td
                                                style={{ verticalAlign: 'top' }}
                                            >
                                                <strong>{sport[0]}: </strong>
                                            </td>
                                            <td>{sport[3]}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginRight: '10px' }}>
                        <div
                            style={{
                                textAlign: 'center',
                                borderBottom: '2px solid grey',
                                marginBottom: '5px',
                                fontWeight: 'bold',
                            }}
                        >
                            Women's Sports
                        </div>
                        <table
                            style={{
                                borderSpacing: '0',
                                borderCollapse: 'collapse',
                                lineHeight: '1',
                            }}
                        >
                            <tbody>
                                {sportsAlphaWithGenders
                                    .filter((sport) => sport[2] === 'W')
                                    .sort(compare)
                                    .map((sport) => (
                                        <tr key={sport[0]}>
                                            <td
                                                style={{ verticalAlign: 'top' }}
                                            >
                                                <strong>{sport[0]}: </strong>
                                            </td>
                                            <td>{sport[3]}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginRight: '10px' }}>
                        <div
                            style={{
                                textAlign: 'center',
                                borderBottom: '2px solid grey',
                                marginBottom: '5px',
                                fontWeight: 'bold',
                            }}
                        >
                            M/W Sports
                        </div>
                        <table
                            style={{
                                borderSpacing: '0',
                                borderCollapse: 'collapse',
                                lineHeight: '1',
                            }}
                        >
                            <tbody>
                                {sportsAlphaWithGenders
                                    .filter((sport) => sport[2] === 'MW')
                                    .sort(compare)
                                    .map((sport) => (
                                        <tr key={sport[0]}>
                                            <td
                                                style={{ verticalAlign: 'top' }}
                                            >
                                                <strong>{sport[0]}: </strong>
                                            </td>
                                            <td>{sport[3]}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Content>
        </Modal>
    );
};

class Bulk extends Component {
    state = {
        contests: [],
        searched_orgs: [],
        otherDetailsIndex: undefined,
        otherDetailsModalVisible: false,
        academicYear: { label: `${2024}-${2025}`, value: 2025 },
    };

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
    }

    makeContests = (data) => {
        try {
            let { opponentOptions, sportsDefault, bulkDefaults } = this.props;
            let headers = data[0];
            let contestsData = data.slice(1);
            let searchedOrgsSet = new Set();
            let contests = [];
            contestsData.forEach((contest, index) => {
                if (contest && contest.length > 4) {
                    let contestObj = { index, hour: 24, minute: 0 };
                    contestObj.errors = [];

                    headers.forEach((header, index) => {
                        let loweredHeader = header.toLowerCase();
                        contestObj[loweredHeader] = contest[index];
                        contestObj[`${loweredHeader}Try`] = contest[index];
                        if (loweredHeader === 'opponent') {
                            searchedOrgsSet.add(contest[index]);
                            let exactMatch = opponentOptions.find(
                                (opponent) => opponent.label === contest[index]
                            );
                            let bulkDefaultsMatch =
                                bulkDefaults[contest[index]] || [];
                            if (exactMatch) {
                                contestObj.opponentId = exactMatch.value;
                            } else {
                                contestObj.errors.push('opponentId');
                            }

                            if (!exactMatch && bulkDefaultsMatch.length > 0) {
                                if (bulkDefaultsMatch.length === 1) {
                                    contestObj.opponentId =
                                        bulkDefaultsMatch[0];
                                    contestObj.bulkOpponentsPicked = true;
                                    contestObj.errors.splice(
                                        contestObj.errors.indexOf('opponentId'),
                                        1
                                    );
                                } else {
                                    contestObj.bulkOpponents =
                                        bulkDefaultsMatch;
                                }
                            }
                        }
                        if (loweredHeader === 'sport') {
                            contestObj.sport =
                                contestObj.sport &&
                                contestObj.sport.toUpperCase();
                            let isOrgPermissionedSport =
                                this.props.user.is_admin ||
                                this.props.filteredOrgPermissions.find(
                                    (option) =>
                                        option.value === contestObj.sport
                                );
                            if (
                                !sports[contestObj.sport] ||
                                !isOrgPermissionedSport
                            ) {
                                contestObj.errors.push('sport');
                            }
                        }
                        if (loweredHeader === 'date') {
                            let gameDate = new Date(contest[index]);

                            if (!isValidDate(gameDate)) {
                                contestObj.dateError = true;
                                contestObj.errors.push('date');
                                contestObj.dateTry = contest[index];
                                contestObj.date = undefined;
                            }

                            if (gameDate.getFullYear() === 2001) {
                                if (gameDate.getMonth() < 6) {
                                    gameDate.setFullYear(
                                        this.state.academicYear.value
                                    );
                                } else {
                                    gameDate.setFullYear(
                                        this.state.academicYear.value - 1
                                    );
                                }
                                contestObj.date = gameDate;
                            }
                        }

                        if (loweredHeader === 'homeoraway') {
                            contestObj.homeorawayTry = contest[index];
                            let atSymbolIndex = contest[index]
                                .toLowerCase()
                                .indexOf('@');
                            let aIndex = contest[index]
                                .toLowerCase()
                                .indexOf('a');
                            contestObj.homeoraway =
                                atSymbolIndex > -1 || aIndex > -1
                                    ? 'away'
                                    : 'home';
                        }

                        if (loweredHeader === 'time') {
                            let timeString = contest[index];
                            if (timeString) {
                                // must be of format 9:00 PM
                                try {
                                    let colonIndex = timeString.indexOf(':');
                                    let aIndex = timeString
                                        .toLowerCase()
                                        .indexOf('a');
                                    let pIndex = timeString
                                        .toLowerCase()
                                        .indexOf('p');
                                    let tIndex = timeString
                                        .toLowerCase()
                                        .indexOf('t');
                                    let bIndex = timeString
                                        .toLowerCase()
                                        .indexOf('b');
                                    let dIndex = timeString
                                        .toLowerCase()
                                        .indexOf('d');
                                    let hour;
                                    let minute;
                                    let timePart;
                                    let amOrPm;
                                    if (
                                        (tIndex > -1 &&
                                            bIndex > -1 &&
                                            aIndex > -1) ||
                                        (tIndex > -1 &&
                                            bIndex > -1 &&
                                            dIndex > -1)
                                    ) {
                                        contestObj.hour = 24;
                                        contestObj.minute = 0;
                                    } else if (colonIndex > -1) {
                                        if (aIndex > -1) {
                                            timePart = timeString.slice(
                                                0,
                                                aIndex
                                            );
                                            amOrPm = 'am';
                                        } else if (pIndex > -1) {
                                            timePart = timeString.slice(
                                                0,
                                                pIndex
                                            );
                                            amOrPm = 'pm';
                                        } else {
                                            timePart = timeString;
                                        }
                                        [hour, minute] = timePart.split(':');
                                        contestObj.minute = parseInt(minute);
                                        if (amOrPm === 'am') {
                                            contestObj.hour = parseInt(hour);
                                        } else if (amOrPm === 'pm') {
                                            contestObj.hour =
                                                (parseInt(hour) % 12) + 12;
                                        } else {
                                            let amHours = [9, 10, 11];
                                            let isAmHour = amHours.includes(
                                                parseInt(hour)
                                            );
                                            contestObj.hour = isAmHour
                                                ? parseInt(hour)
                                                : (parseInt(hour) % 12) + 12;
                                        }
                                    } else {
                                        if (aIndex > -1) {
                                            timePart = timeString.slice(
                                                0,
                                                aIndex
                                            );
                                            amOrPm = 'am';
                                        } else if (pIndex > -1) {
                                            timePart = timeString.slice(
                                                0,
                                                pIndex
                                            );
                                            amOrPm = 'pm';
                                        } else {
                                            timePart = timeString;
                                        }
                                        hour = timePart;
                                        let parsedHour = parseInt(hour);
                                        if (isNaN(parsedHour)) {
                                            throw new Error('NaN');
                                        } else {
                                            if (amOrPm === 'am') {
                                                contestObj.hour = parsedHour;
                                            } else if (amOrPm === 'pm') {
                                                contestObj.hour =
                                                    (parsedHour % 12) + 12;
                                            } else {
                                                let amHours = [9, 10, 11];
                                                let isAmHour =
                                                    amHours.includes(
                                                        parsedHour
                                                    );
                                                contestObj.hour = isAmHour
                                                    ? parsedHour
                                                    : (parsedHour % 12) + 12;
                                            }
                                        }
                                    }
                                } catch {
                                    contestObj.hour = undefined;
                                    contestObj.minute = undefined;
                                    contestObj.timeError = true;
                                    contestObj.timeTry = timeString;
                                    contestObj.errors.push('time');
                                }
                            }
                        }
                    });
                    const globalDef = sportsDefault.global || {};
                    const sportsDef = sportsDefault[contestObj.sport] || {};
                    const newContestObj = Object.assign(
                        {},
                        globalDef,
                        Object.entries(sportsDef).reduce(
                            (r, [k, v]) =>
                                v ? Object.assign(r, { [k]: v }) : r,
                            {}
                        ),
                        contestObj
                    );
                    contests.push(newContestObj);
                }
            });
            contests.forEach((contest, index) => {
                contest.index = index;
            });
            this.setState({
                contests,
                searched_orgs: [...searchedOrgsSet],
            });
        } catch (e) {
            console.log(e);
            toast.error(
                'Something went wrong. Try again. If you continue having problems, please contact us for help.'
            );
        }
    };

    handleContestChange = (index, update) => {
        let { sportsDefault } = this.props;
        let { contests } = this.state;

        let contest = contests[index];
        if (contest) {
            let { errors = [] } = contest;
            if (errors && errors.length > 0) {
                Object.keys(update).forEach((key) => {
                    let keyIndex = errors.indexOf(key);
                    if (keyIndex > -1) {
                        errors.splice(keyIndex, 1);
                    }
                });
                if (errors.includes('time') && update.hour !== undefined) {
                    let keyIndex = errors.indexOf('time');
                    if (keyIndex > -1) {
                        errors.splice(keyIndex, 1);
                    }
                }
            }
            if (update.sport) {
                const globalDef = sportsDefault.global || {};
                const sportsDef = sportsDefault[update.sport] || {};
                Object.assign(
                    contest,
                    globalDef,
                    Object.entries(sportsDef).reduce(
                        (r, [k, v]) => (v ? Object.assign(r, { [k]: v }) : r),
                        {}
                    ),
                    update
                );
            } else {
                Object.assign(contest, update);
            }
            this.setState({ contests });
        }
    };

    handleDeleteOne = (index) => {
        let { contests } = this.state;
        contests.splice(index, 1);
        contests.forEach((contest, index) => {
            contest.index = index;
        });
        this.setState({ contests });
        toast.warn('Your contest was removed successfully', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 4000,
            hideProgressBar: true,
        });
    };

    handleCreateOne = (index, createContest) => {
        let { contests } = this.state;
        let { bulkDefaults, orgBulkDefaultsUpsert } = this.props;
        let newBulkDefaults = { ...bulkDefaults };
        let updateBulk;
        let contest = contests[index];
        let newContest = {
            ...contest,
            homeOrAway: contest.homeoraway,
            date: new Date(contest.date),
            creating_orgid: this.props.user.orgid,
            created_at: new Date().toLocaleString(),
            created_by: this.props.user.id,
            away_orgid:
                contest.homeoraway === 'home'
                    ? contest.opponentId
                    : this.props.user.orgid,
            orgid:
                contest.homeoraway === 'home'
                    ? this.props.user.orgid
                    : contest.opponentId,
        };
        delete newContest.index;
        delete newContest.opponent;
        delete newContest.time;
        delete newContest.home_internal_approvers;

        if (newContest.newDefaults) {
            updateBulk = true;
            Object.keys(newContest.newDefaults).forEach((opponent) => {
                if (newBulkDefaults[opponent]) {
                    newBulkDefaults[opponent].push(
                        newContest.newDefaults[opponent]
                    );
                } else {
                    newBulkDefaults[opponent] = [
                        newContest.newDefaults[opponent],
                    ];
                }
            });
        }
        createContest({ variables: newContest }).then(({ data }) => {
            if (data.contestCreate) {
                toast.success('Your contest was created successfully', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    hideProgressBar: true,
                });
                contests.splice(index, 1);
                contests.forEach((contest, index) => {
                    contest.index = index;
                });
                this.setState({ contests });
                orgBulkDefaultsUpsert({
                    variables: {
                        orgid: this.props.user.orgid,
                        defaults: JSON.stringify(newBulkDefaults),
                    },
                });
            }
        });
    };

    bulkCreate = (contestsCreate) => {
        let { bulkDefaults, orgBulkDefaultsUpsert } = this.props;
        let newBulkDefaults = { ...bulkDefaults };
        let contests = [];
        let updateBulk = false;
        this.state.contests.forEach((contest) => {
            let newContest = {
                ...contest,
                homeOrAway: contest.homeoraway,
                date: new Date(contest.date),
                creating_orgid: this.props.user.orgid,
                created_at: new Date().toLocaleString(),
                created_by: this.props.user.id,
                away_orgid:
                    contest.homeoraway === 'home'
                        ? contest.opponentId
                        : this.props.user.orgid,
                orgid:
                    contest.homeoraway === 'home'
                        ? this.props.user.orgid
                        : contest.opponentId,
            };
            delete newContest.index;
            delete newContest.opponent;
            delete newContest.time;
            delete newContest.errors;
            delete newContest.sportTry;
            delete newContest.bulkOpponentsPicked;
            delete newContest.bulkOpponents;
            delete newContest.opponentId;
            delete newContest.opponentTry;
            delete newContest.homeorawayTry;
            delete newContest.timeTry;
            delete newContest.dateTry;
            delete newContest.sportTry;
            delete newContest.homeoraway;
            delete newContest.home_read_only;
            delete newContest.home_internal_approvers;
            delete newContest.dateError;
            delete newContest.timeError;
            if (newContest.newDefaults) {
                updateBulk = true;
                Object.keys(newContest.newDefaults).forEach((opponent) => {
                    if (newBulkDefaults[opponent]) {
                        newBulkDefaults[opponent].push(
                            newContest.newDefaults[opponent]
                        );
                    } else {
                        newBulkDefaults[opponent] = [
                            newContest.newDefaults[opponent],
                        ];
                    }
                });
            }
            delete newContest.newDefaults;
            contests.push(newContest);
        });
        contestsCreate({
            variables: {
                contests,
            },
        }).then((resp) => {
            this.setState({
                contests: [],
                searched_orgs: [],
                otherDetailsIndex: undefined,
                otherDetailsModalVisible: false,
                createDisabled: false,
            });
            toast.success('Your contests were created successfully', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            });
            if (updateBulk) {
                orgBulkDefaultsUpsert({
                    variables: {
                        orgid: this.props.user.orgid,
                        defaults: JSON.stringify(newBulkDefaults),
                    },
                });
            }
        });
    };

    validateContests = () =>
        new Promise((resolve, reject) => {
            let { contests } = this.state;
            let hasErrors = [];
            contests.forEach((contest, index) => {
                if (contest.errors && contest.errors.length > 0) {
                    hasErrors.push(index);
                }
            });
            if (hasErrors.length > 0) {
                reject(hasErrors);
            } else {
                resolve();
            }
        });

    render() {
        let { contests, otherDetailsIndex, otherDetailsModalVisible } =
            this.state;
        let { opponentOptions, filteredOrgPermissions } = this.props;
        return (
            <div
                style={{
                    marginTop: '45px',
                    paddingBottom: '60px',
                }}
            >
                <Container>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '15px 0',
                        }}
                    >
                        <div style={{ textAlign: 'left' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                <Header as="h2" style={{ marginBottom: '0' }}>
                                    Bulk Create Contests
                                </Header>
                                <InfoModalVideo
                                    videoId={'qjZiqZSFock'}
                                    header="Create Contests"
                                />
                            </div>
                            <Header as="h4" style={{ marginTop: '0' }}>
                                Upload a schedule spreadsheet to quickly create
                                multiple contests
                            </Header>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <BulkInstructions />
                            <ViewSportCodeList />
                            <a href={templateCSV}>
                                <Button
                                    primary
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    Download Template
                                </Button>
                            </a>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            padding: '0 0 15px 0',
                            borderBottom: `1px solid ${colors.greyBorder}`,
                            marginBottom: '20px',
                        }}
                    >
                        <Icon
                            size={'large'}
                            name="exclamation triangle"
                            color="orange"
                        />
                        <div
                            style={{
                                color: '#00b5ad',
                                fontWeight: 'bold',
                            }}
                        >
                            Reminder: please check that your Contract Defaults
                            are accurate and up-to-date before using this tool
                        </div>
                        <div
                            style={{
                                padding: '0 10px',
                            }}
                        >
                            <Button
                                basic
                                size={'mini'}
                                onClick={() => {
                                    this.props.history.push('/org-defaults');
                                }}
                            >
                                Edit Contract Defaults
                            </Button>
                        </div>
                        <Icon
                            size={'large'}
                            name="exclamation triangle"
                            color="orange"
                        />
                    </div>
                </Container>
                {this.state.contests && this.state.contests.length > 0 ? (
                    <div>
                        <Query
                            query={orgMatches}
                            variables={{
                                searched_orgs: this.state.searched_orgs,
                            }}
                        >
                            {({ data, loading, error }) => {
                                if (loading) {
                                    return <span>Loading...</span>;
                                }
                                if (data.orgMatches) {
                                    let searchedMap = {};
                                    data.orgMatches.forEach(
                                        (match) =>
                                            (searchedMap[match.searched] =
                                                match)
                                    );
                                    return (
                                        <div
                                            style={{
                                                padding: '0 25px',
                                            }}
                                        >
                                            <ScheduleHeader />
                                            {this.state.contests.map(
                                                (contest, index) => {
                                                    return (
                                                        <ScheduleRow
                                                            {...this.props}
                                                            key={index}
                                                            index={index}
                                                            orgMatches={
                                                                searchedMap[
                                                                    contest
                                                                        .opponent
                                                                ]
                                                            }
                                                            onChange={
                                                                this
                                                                    .handleContestChange
                                                            }
                                                            createOne={
                                                                this
                                                                    .handleCreateOne
                                                            }
                                                            deleteOne={
                                                                this
                                                                    .handleDeleteOne
                                                            }
                                                            filteredOrgPermissions={
                                                                filteredOrgPermissions
                                                            }
                                                            setOtherDetails={() => {
                                                                this.setState({
                                                                    otherDetailsIndex:
                                                                        index,
                                                                    otherDetailsModalVisible: true,
                                                                });
                                                            }}
                                                            {...contest}
                                                        />
                                                    );
                                                }
                                            )}
                                            <BulkContestModal
                                                contest={
                                                    otherDetailsIndex !==
                                                    undefined
                                                        ? contests[
                                                              otherDetailsIndex
                                                          ]
                                                        : {}
                                                }
                                                contestsRefetch={() => {}}
                                                open={otherDetailsModalVisible}
                                                onChange={(update) =>
                                                    this.handleContestChange(
                                                        otherDetailsIndex,
                                                        update
                                                    )
                                                }
                                                onClose={() =>
                                                    this.setState({
                                                        otherDetailsModalVisible: false,
                                                        otherDetailsIndex:
                                                            undefined,
                                                    })
                                                }
                                                header={`Other Details`}
                                                filteredOrgPermissions={
                                                    filteredOrgPermissions
                                                }
                                                user={this.props.user}
                                                opponentOptions={
                                                    opponentOptions
                                                }
                                                actions={[]}
                                                viewUploadedContract={() => {}}
                                            />
                                        </div>
                                    );
                                }
                            }}
                        </Query>
                        <div
                            style={{
                                marginTop: '25px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'row',
                            }}
                        >
                            <Button
                                onClick={() => {
                                    this.setState({ contests: [] });
                                }}
                            >
                                Clear Contests
                            </Button>
                            <Mutation mutation={contestsCreateMutation}>
                                {(contestsCreate, _) => {
                                    return (
                                        <Button
                                            positive
                                            disabled={this.state.createDisabled}
                                            onClick={() => {
                                                this.setState({
                                                    createDisabled: true,
                                                });
                                                this.validateContests().then(
                                                    () => {
                                                        this.bulkCreate(
                                                            contestsCreate
                                                        );
                                                    },
                                                    (errors) => {
                                                        toast.warn(
                                                            `${errors.length} contests still contain invalid information — please fix any contests that are not ready`,
                                                            {
                                                                position:
                                                                    toast
                                                                        .POSITION
                                                                        .TOP_CENTER,
                                                                autoClose: 4000,
                                                                hideProgressBar: true,
                                                            }
                                                        );
                                                        this.setState({
                                                            createDisabled: false,
                                                        });
                                                    }
                                                );
                                            }}
                                        >
                                            {`Creat${
                                                this.state.createDisabled
                                                    ? 'ing'
                                                    : 'e'
                                            } All Contests`}
                                        </Button>
                                    );
                                }}
                            </Mutation>
                        </div>
                    </div>
                ) : null}
                <Container>
                    {!(
                        this.state.contests && this.state.contests.length > 0
                    ) && (
                        <div
                            style={{
                                marginTop: '25px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div
                                style={{
                                    display: 'none',
                                }}
                            >
                                <CSVReader
                                    cssClass="csv-input"
                                    inputId="csvInput"
                                    onFileLoaded={this.makeContests}
                                />
                            </div>
                            <div
                                style={{
                                    width: '200px',
                                    marginRight: '15px',
                                }}
                            >
                                <AcademicYearSelect
                                    setAcademicYear={(academicYear) =>
                                        this.setState({ academicYear })
                                    }
                                    academicYear={this.state.academicYear}
                                />
                            </div>
                            <Button
                                primary
                                style={{
                                    position: 'relative',
                                    cursor: 'pointer',
                                }}
                            >
                                <label
                                    htmlFor="file"
                                    style={{
                                        position: 'absolute',
                                        cursor: 'pointer',
                                        top: 0,
                                        right: 0,
                                        left: 0,
                                        bottom: 0,
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    Upload Contest File
                                </label>
                                <div
                                    style={{
                                        color: 'transparent',
                                    }}
                                >
                                    Upload Contest File
                                </div>
                            </Button>

                            <XlsxThing
                                theThing={(data) => this.makeContests(data)}
                            />
                        </div>
                    )}
                </Container>
            </div>
        );
    }
}

let OrgBulkDefaultsUpsertGQL = graphql(orgBulkDefaultsUpsert, {
    name: 'orgBulkDefaultsUpsert',
});

Bulk = OrgBulkDefaultsUpsertGQL(Bulk);

let BulkWrapper = (props) => (
    <Query
        query={orgQuery}
        variables={{
            id: props.user.orgid,
        }}
    >
        {({ data: orgData, loading: orgLoading, err: orgErr }) => {
            if (orgLoading) {
                return null;
            }
            return (
                <Query
                    query={orgBulkDefaults}
                    variables={{
                        orgid: props.user.orgid,
                    }}
                >
                    {({
                        data: bulkData,
                        loading: bulkLoading,
                        err: bulkError,
                    }) => {
                        let bulkDefaults;
                        if (bulkLoading || bulkError) {
                            bulkDefaults = {};
                        }
                        if (
                            bulkData &&
                            bulkData.orgBulkDefaults &&
                            bulkData.orgBulkDefaults.defaults
                        ) {
                            bulkDefaults = JSON.parse(
                                bulkData.orgBulkDefaults.defaults
                            );
                        } else {
                            bulkDefaults = {};
                        }

                        return (
                            <Query
                                query={sportsDefaultQuery}
                                variables={{
                                    orgid: props.user.orgid,
                                }}
                            >
                                {({ data, loading, error }) => {
                                    if (loading || error) {
                                        return null;
                                    }
                                    let sportsDefault = JSON.parse(
                                        data.sportsDefault.properties
                                    );
                                    let { org = {} } = orgData;
                                    let { sports: unparsedSports = '[]' } = org;
                                    let parsedSports =
                                        JSON.parse(unparsedSports);
                                    let orgSports;
                                    let permissions = props.permissions;
                                    if (
                                        parsedSports &&
                                        parsedSports.length > 0
                                    ) {
                                        orgSports = parsedSports;
                                    } else {
                                        orgSports = Object.keys(sports);
                                    }
                                    let filteredOrgPermissions =
                                        sportSelectOptions.filter((option) => {
                                            return (
                                                orgSports.includes(
                                                    option.value
                                                ) &&
                                                permissions[option.value] &&
                                                permissions[option.value].write
                                            );
                                        });
                                    return (
                                        <Bulk
                                            sportsDefault={sportsDefault}
                                            bulkDefaults={bulkDefaults}
                                            org={orgData.org}
                                            filteredOrgPermissions={
                                                filteredOrgPermissions
                                            }
                                            {...props}
                                        />
                                    );
                                }}
                            </Query>
                        );
                    }}
                </Query>
            );
        }}
    </Query>
);

export default BulkWrapper;
