import gql from 'graphql-tag';

export const userQuery = gql`
    query user($email: String, $id: ID) {
        user(email: $email, id: $id) {
            id
            email
            firstname
            lastname
            office_addr
            orgid
            permission_level
            phone_cell
            phone_work
            title
            calendarLocale
            seen_changelog
            is_admin
            permissions
        }
    }
`;

export const userOrgContactListQuery = gql`
    query userOrgContactList($away_orgid: ID, $orgid: ID) {
        homeUserOrgContactList: userOrgContactList(orgid: $orgid) {
            id
            firstname
            lastname
            email
            title
            phone_work
            is_admin
            permissions
            permission_level
        }

        homePrimaryContact: primaryContact(orgid: $orgid) {
            id
            firstname
            lastname
            email
            title
        }

        awayUserOrgContactList: userOrgContactList(orgid: $away_orgid) {
            id
            firstname
            lastname
            email
            title
            phone_work
            is_admin
            permissions
        }

        awayPrimaryContact: primaryContact(orgid: $away_orgid) {
            id
            firstname
            lastname
            email
            title
        }
    }
`;

export const userOrgContactListFromContestQuery = gql`
    query userOrgContactListFromContest($guid: ID) {
        userOrgContactListFromContest(guid: $guid) {
            homeOrg {
                contactList {
                    id
                    firstname
                    lastname
                    email
                    is_admin
                    permissions
                }
                primaryContact {
                    id
                    firstname
                    lastname
                    email
                }
            }
            awayOrg {
                contactList {
                    id
                    firstname
                    lastname
                    email
                }
                primaryContact {
                    id
                    firstname
                    lastname
                    email
                }
            }
        }
    }
`;

export const userCreateMutation = gql`
    mutation userCreate(
        $email: String!
        $orgid: String
        $firstname: String
        $lastname: String
        $title: String
        $phone_work: String
        $permission_level: String
        $notifyEmail: Boolean
        $creatingUser: ID
    ) {
        userCreate(
            email: $email
            orgid: $orgid
            firstname: $firstname
            lastname: $lastname
            title: $title
            phone_work: $phone_work
            permission_level: $permission_level
            notifyEmail: $notifyEmail
            creatingUser: $creatingUser
        ) {
            id
            email
            firstname
            lastname
            permissions
            title
        }
    }
`;

export const userUpdateMutation = gql`
    mutation userUpdate(
        $id: ID
        $email: String!
        $firstname: String
        $lastname: String
        $title: String
        $phone_work: String
        $phone_cell: String
        $office_addr: String
        $calendarLocale: String
        $seen_changelog: Boolean
        $is_admin: Boolean
        $permissions: String
        $permission_level: String
    ) {
        userUpdate(
            id: $id
            email: $email
            firstname: $firstname
            lastname: $lastname
            title: $title
            phone_work: $phone_work
            phone_cell: $phone_cell
            office_addr: $office_addr
            calendarLocale: $calendarLocale
            seen_changelog: $seen_changelog
            is_admin: $is_admin
            permissions: $permissions
            permission_level: $permission_level
        ) {
            id
        }
    }
`;

export const userRemoveMutation = gql`
    mutation userRemove($id: ID!) {
        userRemove(id: $id)
    }
`;
