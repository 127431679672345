import React, { useState, Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import Youtube from 'react-youtube';

const tutorialVideos = [
    {
        title: 'Platform Overview',
        videoId: 'Z9rc-BPu23Q',
    },
    {
        title: "Set your organization's sports",
        videoId: 'cuUr6lOMbjk',
    },
    {
        title: 'Set contract defaults',
        videoId: 'IxbXxFYN9mo',
    },
    {
        title: 'Create your contract template',
        videoId: '42-7M8wn8Qk',
    },
    {
        title: 'Create contests',
        videoId: 'qjZiqZSFock',
    },
    {
        title: 'Send a contract',
        videoId: 'OTrXINvlDw0',
    },
    {
        title: 'Manage away contracts using Away Placeholders',
        videoId: 'chHpvKCEP-Y',
    },
    {
        title: 'Download schedule as a spreadsheet',
        videoId: 'T2XATvYAkek',
    },
    {
        title: 'Create new users',
        videoId: 'O8XER9EWkqc',
    },
    {
        title: 'Set user permissions',
        videoId: 'KfPi7aA0-OM',
    },
];

export const TutorialVideosAccordion = () => {
    return (
        <>
            <h3>Tutorial Videos</h3>
            <Accordion allowZeroExpanded>
                {tutorialVideos.map((video) => {
                    return (
                        <AccordionItem key={video.videoId}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    {video.title}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <Youtube
                                    videoId={video.videoId}
                                    opts={{
                                        playerVars: {
                                            rel: 0,
                                        },
                                    }}
                                />
                            </AccordionItemPanel>
                        </AccordionItem>
                    );
                })}
            </Accordion>
        </>
    );
};

class FaqModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
    }

    handleOpen = () => this.setState({ modalOpen: true });

    handleClose = () => this.setState({ modalOpen: false });

    render() {
        let { trigger } = this.props;
        let { modalOpen } = this.state;
        return (
            <Modal
                trigger={trigger}
                open={modalOpen}
                onClose={this.handleClose}
                size="large"
                closeIcon
                closeOnDimmerClick
            >
                <Modal.Header>Frequently Asked Questions</Modal.Header>
                <Modal.Content>
                    <TutorialVideosAccordion />
                    <h3>Contract Defaults</h3>
                    <Accordion allowZeroExpanded>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    How does the "All Sports" default section
                                    work?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                Sport-specific contract defaults will override
                                defaults set under the "All Sports" section.
                                When a contract is created, each field will
                                first check for defaults set under the specific
                                sport, and if there is a default saved, the
                                sport-specific default will apply. If there is
                                no sport-specific default set, the field will
                                then check the "All Sports" default section. If
                                both the sport-specific field and the "All
                                Sports" field are blank, no default information
                                will be entered into the contract for that
                                field.
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    My contract defaults aren't applying
                                    correctly!
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                Contract defaults only apply to contracts
                                created {` `}
                                <span style={{ fontStyle: 'italic' }}>
                                    after
                                </span>{' '}
                                the defaults are set, so they will not affect
                                any existing contracts.
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                    <h3>Creating Contracts</h3>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    How do I create contracts for events with
                                    multiple opponents/games (e.g. a volleyball
                                    tournament)?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <div>
                                    For multi-contest events (such as
                                    tournaments), we suggest that you create one
                                    contract for each school that will
                                    participate. For the date and time fields on
                                    each contract, you can enter either 1) the
                                    school's first contest of the event, or 2)
                                    the school's contest vs. the home team.
                                    Then, spell out the details of the entire
                                    event schedule in the "Other Conditions"
                                    Field. Something like:
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <div>
                                        Fall Invitational -- Event Schedule
                                    </div>
                                    <div>Friday 9/4 4:00PM -- A vs. B</div>
                                    <div>Friday 9/4 7:00PM -- C vs. D</div>
                                    <div>Saturday 9/5 10:00AM -- B vs. D</div>
                                    <div>Saturday 9/5 1:00PM -- C vs. A</div>
                                    <div>Saturday 9/5 4:00PM -- D vs. A</div>
                                    <div>Saturday 9/5 7:00PM -- C vs. B</div>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    This way, you can send a separate contract
                                    to each school invited to the event, and
                                    everyone has the full event schedule.
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    I created a contest and I can’t see it!
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                Please be sure that your schedule filters
                                (left-hand side of the Schedule page, below the
                                Create Contest buttons) are set correctly to
                                allow you to see the newly-created contest. The
                                filter defaults to displaying the current
                                academic year, so if you just created a contest
                                for the following year, you will need to change
                                the academic year filter to show contests for
                                next year.
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                    <h3>Contracts exchanged outside Perfect Record</h3>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    My opponent is unable to sign electronic
                                    contracts. How can I create a contract to
                                    send outside of Perfect Record's electronic
                                    signature system?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                In the "Edit Details" window for the contest,
                                you can generate a separate contract using the
                                buttons on the bottom-left corner of the window,
                                "Request Signed Copy" or "Request Blank Copy".
                                "Request Signed Copy" will generate a copy of
                                the contract with your signature information
                                already on the contract, and "Generate Blank
                                Copy" will generate a copy without any signature
                                information included. You can then send this
                                generated contract to your opponent through your
                                email.
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    I have a contract that will be/has been
                                    completed outside of the Perfect Record
                                    electronic signature system. I want to use
                                    Perfect Record to keep track of{' '}
                                    <span style={{ fontStyle: 'italic' }}>
                                        all
                                    </span>{' '}
                                    of my contracts -- how do I record that this
                                    contract is complete?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                Create a record in our system for this contest,
                                even though the contract is already complete. In
                                the "Edit Details" window for the contest, there
                                is a checkbox near the bottom labeled "Contract
                                to be exchanged off Perfect Record Platform".
                                Checking this box enables you to manage the
                                status of this contract outside of our
                                electronic signature system. You can mark the
                                contract "Paper contract has been sent" if the
                                contract has been sent out but not yet signed
                                and returned, and you can check "Paper contract
                                complete" once you have the completed, signed
                                contract in hand. You then also have the option
                                to upload a copy of the completed contract if
                                you choose to do so.
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={this.handleClose}>Close</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default FaqModal;
