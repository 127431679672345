/* eslint-disable */
import React, { Component, useState, useEffect } from 'react';
import { Mutation, Query } from 'react-apollo';
import {
    Button,
    Checkbox,
    Container,
    Dropdown,
    Form,
    Icon,
    Popup,
} from 'semantic-ui-react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import { toast } from 'react-toastify';

import {
    FormRow,
    FormRowDropdown,
    FormRowInput,
    FormRowTextArea,
} from '../utilityComponents/form/formRow';
import Spinner from '../utilityComponents/spinner';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

import {
    sportsDefaultQuery,
    sportsDefaultUpdateMutation,
} from '../../gql/defaults';
import { sports, sportsAlphabetized } from '../../utils/sports';
import { userOrgContactListQuery } from '../../gql/user';
import { InfoModalVideo } from '../utilityComponents/InfoModalVideo';
import { colors } from '../../utils/colors';

export const checkSubmitRequiredPairs = (update) => {
    let errors = [];
    if (update.home_read_only) {
        if (!update.home_read_only_recipients) {
            errors.push('home_read_only_recipients');
        } else if (update.home_read_only_recipients.indexOf('@') === -1) {
            errors.push('home_read_only_recipients');
        }
    }
    if (update.home_requires_internal_approval) {
        if (!update.home_internal_approvers) {
            errors.push('home_internal_approvers');
        } else if (update.home_internal_approvers.indexOf('@') === -1) {
            errors.push('home_internal_approvers');
        }
    }
    if (update.signor_different_user) {
        if (!update.signor_different_user_email) {
            errors.push('signor_different_user_email');
        } else if (update.signor_different_user_email.indexOf('@') === -1) {
            errors.push('signor_different_user_email');
        }
    }
    return errors;
};

let contestSubmitFields = (values) => [
    {
        boolean: 'home_read_only',
        boolLabel: {
            children: (
                <p
                    style={{
                        fontWeight: values.home_read_only ? 'bold' : 'normal',
                    }}
                >
                    Send a read-only copy of this contract to people within my
                    institution
                </p>
            ),
        },
        input: 'home_read_only_recipients',
        inputLabel: (
            <p>
                Input emails of read-only recipients (comma separated). These
                individuals will receive a link to view this contract as a
                read-only viewer.
            </p>
        ),
    },
    {
        boolean: 'home_requires_internal_approval',
        boolLabel: {
            children: (
                <p
                    style={{
                        fontWeight: values.home_requires_internal_approval
                            ? 'bold'
                            : 'normal',
                    }}
                >
                    Require internal approval (head coach, etc.)
                </p>
            ),
        },
        input: 'home_internal_approvers',
        inputLabel: (
            <p>
                Input emails of required approvers (comma separated). Once the
                internal approvers sign off, the contract will be sent to the
                recipient at the opposing school as directed below.
            </p>
        ),
    },
    {
        boolean: 'signor_different_user',
        boolLabel: {
            children: (
                <p
                    style={{
                        fontWeight: values.signor_different_user
                            ? 'bold'
                            : 'normal',
                    }}
                >
                    Require a different person to be the primary signatory for
                    my school
                </p>
            ),
        },
        input: 'signor_different_user_email',
        inputLabel: (
            <p>
                Use the dropdown below to select the primary signatory on this
                contract. After any internal approvals are complete, this person
                will receive an email with a link to the contract requesting
                review and signature. The dropdown includes all registered
                Perfect Record users for your school, so if the person you want
                choose as the signatory is not on the dropdown list, please
                create a user account for that person (under the 'MY ACCOUNT'
                section).
            </p>
        ),
        dropdown: true,
    },
];

let SubmitContestDefaultRow = (props) => {
    let {
        boolean,
        boolLabel,
        checked,
        dropdown,
        input,
        inputLabel,
        onChange = () => {},
        options,
        primary_contact,
        sport,
        value = '',
        error,
        attemptedSave,
    } = props;
    let [hasChanged, setHasChanged] = useState(false);
    useEffect(() => {
        if (attemptedSave) {
            setHasChanged(false);
        }
    }, [attemptedSave]);
    let dropdownOptions = [];
    if (dropdown) {
        options &&
            options.length > 0 &&
            options.forEach((contact) => {
                let is_admin = contact.is_admin;
                let permissions = JSON.parse(contact.permissions || '{}') || {};
                if (
                    sport === 'global'
                        ? is_admin
                        : is_admin ||
                          (permissions[sport] && permissions[sport].write)
                ) {
                    dropdownOptions.push({
                        text: `${
                            contact.firstname && contact.lastname
                                ? `${contact.firstname} ${contact.lastname} <${contact.title}> | ${contact.email}`
                                : `${contact.email}`
                        }`,
                        value: contact.email,
                    });
                }
            });
    }
    return (
        <div
            style={{
                textAlign: 'left',
                marginBottom: '10px',
            }}
        >
            <div>
                <Checkbox
                    checked={checked}
                    label={boolLabel}
                    onChange={() => {
                        setHasChanged(true);
                        onChange({ [boolean]: !checked });
                    }}
                />
            </div>
            {checked && (
                <div>
                    {inputLabel}
                    {!dropdown ? (
                        <Form.Input
                            fluid
                            type="text"
                            value={value}
                            error={error && !hasChanged}
                            placeholder={`jane@college.edu, john@college.edu, etc.`}
                            onChange={(e, data) => {
                                setHasChanged(true);
                                onChange({ [input]: data.value });
                            }}
                        />
                    ) : (
                        <Dropdown
                            fluid
                            scrolling
                            upward
                            selection
                            type="text"
                            options={dropdownOptions}
                            value={value}
                            error={error && !hasChanged}
                            placeholder={`Select a recipient`}
                            onChange={(e, data) => {
                                setHasChanged(true);
                                onChange({ [input]: data.value });
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

let SubmitContestDefaults = (props) => {
    let { onChange, sport, user = {}, values, attemptedSave, errors } = props;
    return (
        <div
            style={{
                marginTop: '5px',
                marginBottom: '10px',
                paddingTop: '10px',
                paddingLeft: '10px',
            }}
        >
            {contestSubmitFields(values).map((field, index) => {
                return field.dropdown ? (
                    <Query
                        key={index}
                        query={userOrgContactListQuery}
                        variables={{
                            orgid: user.orgid,
                        }}
                    >
                        {({ data, loading, error }) => {
                            if (loading || error) {
                                return null;
                            }
                            return (
                                <SubmitContestDefaultRow
                                    {...field}
                                    checked={values[field.boolean]}
                                    value={values[field.input]}
                                    options={data.homeUserOrgContactList}
                                    primary_contact={data.homePrimaryContact}
                                    sport={sport}
                                    onChange={onChange}
                                    attemptedSave={attemptedSave}
                                    error={
                                        attemptedSave &&
                                        errors.includes(field.input)
                                    }
                                />
                            );
                        }}
                    </Query>
                ) : (
                    <SubmitContestDefaultRow
                        key={index}
                        checked={values[field.boolean]}
                        value={values[field.input]}
                        {...field}
                        sport={sport}
                        onChange={onChange}
                        attemptedSave={attemptedSave}
                        error={attemptedSave && errors.includes(field.input)}
                    />
                );
            })}
        </div>
    );
};

let fields = [
    {
        key: 'head_coach',
        label: 'Head Coach',
        input: FormRowInput,
        inputType: 'input',
    },
    {
        key: 'head_coach_email',
        label: 'Head Coach Email',
        input: FormRowInput,
        inputType: 'input',
    },
    {
        key: 'head_coach_phone',
        label: 'Head Coach Phone',
        input: FormRowInput,
        inputType: 'input',
    },
    {
        key: 'location',
        label: 'Location',
        input: FormRowInput,
        inputType: 'input',
    },
    {
        key: 'officials',
        label: 'Officials',
        input: FormRowInput,
        inputType: 'input',
    },
    {
        key: 'guarantee',
        label: 'Guarantee',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'other_conditions',
        label: 'Other Conditions',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'season',
        label: 'Season',
        input: FormRowDropdown,
        inputType: 'select',
        options: [
            { value: '', text: '' },
            { value: 'FALL', text: 'Fall' },
            { value: 'WINTER', text: 'Winter' },
            { value: 'SPRING', text: 'Spring' },
        ],
    },
    {
        key: 'comp_level',
        label: 'Competition Level',
        input: FormRowInput,
        inputType: 'input',
    },
    {
        key: 'cancellation_terms',
        label: 'Cancellation Terms',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'force_majeure',
        label: 'Force Majeure',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'comp_tickets',
        label: 'Complimentary Tickets',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'cons_tickets',
        label: 'Consignment Tickets',
        input: FormRowInput,
        inputType: 'input',
    },
    {
        key: 'governing_rules',
        label: 'Governing Rules',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'radio_rights',
        label: 'Radio Rights',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'tv_rights',
        label: 'Video Rights',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'broadcast_rights',
        label: 'Broadcast Rights',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
];

const numbersFunc = () => {
    let array = [];
    for (let i = 1; i < 7; i++) {
        array.push({
            value: i,
            text: `${i} day${i === 1 ? '' : 's'}`,
        });
    }
    for (let w = 1; w < 5; w++) {
        array.push({
            value: w * 7,
            text: `${w} week${w === 1 ? '' : 's'}`,
        });
    }
    return array;
};

export const numbersOptions = numbersFunc();

export const ReminderInterval = (props) => {
    let { remind, interval, onRemindChange, onIntervalChange } = props;
    return (
        <div
            style={{
                marginTop: '10px',
            }}
        >
            <FormRow label={`Remind for inactive contracts`}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Checkbox checked={remind} onChange={onRemindChange} />
                    {remind && (
                        <span
                            style={{
                                paddingLeft: '10px',
                            }}
                        >
                            Reminder interval: every{' '}
                            {remind ? (
                                <Dropdown
                                    inline
                                    options={numbersOptions}
                                    value={interval}
                                    onChange={(event, { name, value }) =>
                                        onIntervalChange(value)
                                    }
                                />
                            ) : null}
                        </span>
                    )}
                </div>
            </FormRow>
        </div>
    );
};

export const RequireAwayHeadCoachInfo = (props) => {
    let { require_away_head_coach_info = '{}', onChange = () => {} } = props;
    let requireAwayHeadCoachInfo =
        JSON.parse(require_away_head_coach_info) || {};
    let { name, email, phone } = requireAwayHeadCoachInfo;
    let hasRequirement = name || email || phone;
    return props.location === 'submitContestModal' ? (
        <div style={{ ...props.style, display: 'flex', flexDirection: 'row' }}>
            <div>
                <Checkbox
                    checked={hasRequirement}
                    label={'Require Away Head Coach info'}
                    onChange={() => {
                        onChange(
                            JSON.stringify({
                                name: !hasRequirement,
                                email: !hasRequirement,
                                phone: !hasRequirement,
                            })
                        );
                    }}
                />
            </div>
            {hasRequirement && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: '20px',
                    }}
                >
                    <span style={{ margin: '0 5px 0 20px' }}>Name:</span>
                    <Checkbox
                        checked={name}
                        onChange={() => {
                            onChange(
                                JSON.stringify({
                                    name: !name,
                                    email,
                                    phone,
                                })
                            );
                        }}
                    />
                    <span style={{ margin: '0 5px 0 20px' }}>Email:</span>
                    <Checkbox
                        checked={email}
                        onChange={() => {
                            onChange(
                                JSON.stringify({
                                    name,
                                    email: !email,
                                    phone,
                                })
                            );
                        }}
                    />
                    <span style={{ margin: '0 5px 0 20px' }}>
                        Phone Number:
                    </span>
                    <Checkbox
                        checked={phone}
                        onChange={() => {
                            onChange(
                                JSON.stringify({
                                    name,
                                    email,
                                    phone: !phone,
                                })
                            );
                        }}
                    />
                </div>
            )}
        </div>
    ) : (
        <div
            style={{
                marginTop: '15px',
            }}
        >
            <FormRow label={`Require Away Head Coach Info`}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Checkbox
                        toggle
                        checked={hasRequirement}
                        onChange={() => {
                            onChange(
                                JSON.stringify({
                                    name: !hasRequirement,
                                    email: !hasRequirement,
                                    phone: !hasRequirement,
                                })
                            );
                        }}
                    />
                    {hasRequirement && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginLeft: '20px',
                            }}
                        >
                            <span style={{ margin: '0 5px 0 20px' }}>
                                Name:
                            </span>
                            <Checkbox
                                checked={name}
                                onChange={() => {
                                    onChange(
                                        JSON.stringify({
                                            name: !name,
                                            email,
                                            phone,
                                        })
                                    );
                                }}
                            />
                            <span style={{ margin: '0 5px 0 20px' }}>
                                Email:
                            </span>
                            <Checkbox
                                checked={email}
                                onChange={() => {
                                    onChange(
                                        JSON.stringify({
                                            name,
                                            email: !email,
                                            phone,
                                        })
                                    );
                                }}
                            />
                            <span style={{ margin: '0 5px 0 20px' }}>
                                Phone Number:
                            </span>
                            <Checkbox
                                checked={phone}
                                onChange={() => {
                                    onChange(
                                        JSON.stringify({
                                            name,
                                            email,
                                            phone: !phone,
                                        })
                                    );
                                }}
                            />
                        </div>
                    )}
                </div>
            </FormRow>
        </div>
    );
};

const SportOverride = ({ sportCodes = [] }) => {
    const [showPopup, setShowPopup] = useState(false);
    return (
        <Popup
            on="click"
            open={showPopup}
            onClose={() => setShowPopup(false)}
            trigger={
                <div
                    style={{
                        position: 'relative',
                        cursor: 'pointer',
                    }}
                    onClick={() => setShowPopup(true)}
                >
                    <Icon name={'sitemap'} />
                    <div
                        style={{
                            height: '14px',
                            width: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            top: '-5px',
                            right: '0px',
                            fontSize: '10px',
                        }}
                    >
                        {sportCodes.length}
                    </div>
                </div>
            }
        >
            <div
                style={{
                    maxHeight: '88px',
                    width: '400px',
                    overflowY: 'auto',
                }}
            >
                <div style={{ fontWeight: 'bold' }}>
                    Text entered in this field will be overridden and replaced
                    by sport-specific language in the following{' '}
                    {sportCodes.length} sports:
                </div>
                <div style={{ marginTop: '8px' }}>
                    {sportCodes.map((code) => {
                        return <div key={code}>{sports[code]}</div>;
                    })}
                </div>
            </div>
        </Popup>
    );
};

const GlobalDefault = ({ value = '' }) => {
    const [showPopup, setShowPopup] = useState(false);
    return (
        <Popup
            on="click"
            open={showPopup}
            position="top left"
            onClose={() => setShowPopup(false)}
            trigger={
                <div
                    style={{
                        position: 'relative',
                        cursor: 'pointer',
                    }}
                    onClick={() => setShowPopup(true)}
                >
                    <Icon name={'sitemap'} />
                </div>
            }
        >
            <div
                style={{
                    maxHeight: '88px',
                    width: '400px',
                    overflowY: 'auto',
                }}
            >
                <div style={{ fontWeight: 'bold' }}>
                    This text will override and replace the following text from
                    the “All Sports” category:
                </div>
                <div style={{ marginTop: '8px' }}>{value}</div>
            </div>
        </Popup>
    );
};

class ItemBody extends Component {
    constructor(props) {
        super(props);
        let values = { ...props.sportDefault };
        if (props.sport === 'global') {
            values.remind = values.remind === false ? false : true;
            values.interval = values.interval || 3;
        } else {
            let { globalDefault } = props;
            values.remind =
                values.remind ||
                (values.remind === false
                    ? false
                    : globalDefault.remind === false
                    ? false
                    : true);
            values.interval = values.interval || globalDefault.interval || 3;
        }
        this.state = {
            values,
        };
    }

    defaultChange = (update) => {
        let values = { ...this.state.values };
        Object.assign(values, update);
        this.setState({ values, dirty: true });
    };

    cancelChanges = () => {
        let { state, props } = this;
        let values = { ...props.sportDefault };
        if (props.sport === 'global') {
            values.remind = values.remind === false ? false : true;
            values.interval = values.interval || 3;
        } else {
            let { globalDefault } = props;
            values.remind =
                values.remind ||
                (values.remind === false
                    ? false
                    : globalDefault.remind === false
                    ? false
                    : true);
            values.interval = values.interval || globalDefault.interval || 3;
        }
        this.setState({ values, dirty: false });
    };

    render() {
        const { sport, sportsDefault, globalDefault = {} } = this.props;
        const { values } = this.state;
        let defaultFields = fields.map((field, index) => {
            let Input = field.input;
            let override;
            if (sport === 'global' && sportsDefault) {
                const sportCodes = Object.keys(sportsDefault).reduce(
                    (acc, code) => {
                        if (
                            sportsDefault[code][field.key] &&
                            code !== 'global'
                        ) {
                            return [...acc, code];
                        }
                        return acc;
                    },
                    []
                );
                override =
                    sportCodes.length > 0 ? (
                        <SportOverride sportCodes={sportCodes} />
                    ) : null;
            } else {
                override =
                    values[field.key] && globalDefault[field.key] ? (
                        <GlobalDefault value={globalDefault[field.key]} />
                    ) : null;
            }
            return (
                <div
                    key={index}
                    style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <Input
                        key={index}
                        field={field.key}
                        {...field}
                        style={{
                            flex: 1,
                        }}
                        placeholder={
                            globalDefault[field.key]
                                ? `FROM "ALL SPORTS": ${
                                      globalDefault[field.key]
                                  }`
                                : ''
                        }
                        value={this.state.values[field.key]}
                        type="text"
                        formUpdate={(update) => {
                            this.defaultChange(update);
                        }}
                    />
                    <div
                        style={{
                            width: '32px',
                        }}
                    >
                        {override}
                    </div>
                </div>
            );
        });

        return (
            <div>
                {this.props.sport === 'global' && (
                    <p>
                        Here, in the “All Sports” section, you can set default
                        information for all contracts that you create. However,
                        any defaults entered in the sport-specific sections
                        below will override and replace defaults entered in this
                        “All Sports” section. If you see a{' '}
                        <Icon name="sitemap" /> icon next to a field, you can
                        click it to see a list of sports with a sport-specific
                        default entered for that field (which will override and
                        replace the “All Sports entry).
                    </p>
                )}
                <div
                    style={{
                        marginBottom: '10px',
                    }}
                >
                    {this.state.dirty && (
                        <Button onClick={() => this.cancelChanges()}>
                            Cancel Changes
                        </Button>
                    )}
                    <Button
                        primary
                        onClick={() => {
                            this.props.update(
                                this.props.sport,
                                this.state.values
                            );
                        }}
                    >
                        Save {sports[this.props.sport]}
                    </Button>
                </div>
                <Form>
                    <div>
                        <div
                            style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: '18px',
                            }}
                        >
                            Contract Submission Defaults
                        </div>
                        <SubmitContestDefaults
                            sport={this.props.sport}
                            user={this.props.user}
                            errors={this.props.errors}
                            values={this.state.values}
                            onChange={(update) => this.defaultChange(update)}
                            attemptedSave={this.props.attemptedSave}
                        />
                        <div style={{ paddingLeft: '10px' }}>
                            <RequireAwayHeadCoachInfo
                                location="submitContestModal"
                                require_away_head_coach_info={
                                    this.state.values
                                        .require_away_head_coach_info
                                }
                                onChange={(require_away_head_coach_info) => {
                                    this.defaultChange({
                                        require_away_head_coach_info,
                                    });
                                }}
                            />
                        </div>
                        <ReminderInterval
                            remind={this.state.values.remind}
                            interval={this.state.values.interval}
                            onRemindChange={() =>
                                this.defaultChange({
                                    remind: !this.state.values.remind,
                                })
                            }
                            onIntervalChange={(interval) =>
                                this.defaultChange({ interval })
                            }
                        />
                    </div>

                    <div
                        style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            fontSize: '18px',
                            marginTop: '35px',
                        }}
                    >
                        Game Contract Defaults
                    </div>

                    {defaultFields}
                </Form>
                <div
                    style={{
                        marginTop: '10px',
                    }}
                >
                    {this.state.dirty && (
                        <Button onClick={() => this.cancelChanges()}>
                            Cancel Changes
                        </Button>
                    )}
                    <Button
                        primary
                        onClick={() => {
                            this.props.update(
                                this.props.sport,
                                this.state.values
                            );
                        }}
                    >
                        Save {sports[this.props.sport]}
                    </Button>
                </div>
            </div>
        );
    }
}

class Defaults extends Component {
    state = {
        attemptedSave: false,
        errors: [],
    };
    notify = (success, sport) => {
        if (success) {
            toast.success(`${sport} defaults updated successfully!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            });
        }
    };

    render() {
        let { permissions } = this.props;
        let sportsToShow = !permissions
            ? Object.keys(sports)
            : Object.keys(sports).filter((sport) => {
                  return permissions[sport] && permissions[sport].write;
              });
        return (
            <div
                style={{
                    marginTop: '25px',
                    marginBottom: '45px',
                }}
            >
                <div
                    style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <h1>Contract Defaults</h1>
                        <InfoModalVideo
                            {...{
                                videoId: 'IxbXxFYN9mo',
                                header: 'Set contract defaults',
                            }}
                        />
                    </div>
                    <div
                        style={{
                            width: '100%',
                            maxWidth: '700px',
                            textAlign: 'left',
                        }}
                    >
                        <p>
                            {`
                                Some of the information included in your contracts will probably 
                                not change much from game to game, such as location, officials 
                                assignor, etc.  Information you enter on this page will automatically 
                                be included on new contracts you create, saving you time and effort when creating contracts.
                            `}
                        </p>
                    </div>
                </div>
                <Mutation mutation={sportsDefaultUpdateMutation}>
                    {(sportsDefaultUpdate, { data, error, loading }) => (
                        <Accordion allowZeroExpanded={true}>
                            {this.props.user.is_admin && (
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            All Sports
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <ItemBody
                                            sport={'global'}
                                            sportDefault={
                                                (this.props.sportsDefault &&
                                                    this.props.sportsDefault[
                                                        'global'
                                                    ]) ||
                                                {}
                                            }
                                            globalDefault={
                                                (this.props.sportsDefault &&
                                                    this.props.sportsDefault[
                                                        'global'
                                                    ]) ||
                                                {}
                                            }
                                            sportsDefault={
                                                this.props.sportsDefault
                                            }
                                            user={this.props.user}
                                            attemptedSave={
                                                this.state.attemptedSave
                                            }
                                            errors={this.state.errors}
                                            update={(sport, update) => {
                                                this.setState({
                                                    attemptedSave: true,
                                                });
                                                let properties = Object.assign(
                                                    {},
                                                    this.props.sportsDefault,
                                                    { [sport]: update }
                                                );
                                                let {
                                                    require_away_head_coach_info = '{}',
                                                } =
                                                    this.props.sportsDefault[
                                                        'global'
                                                    ] || {};
                                                let requireAwayHeadCoachInfo =
                                                    JSON.parse(
                                                        require_away_head_coach_info
                                                    ) || {};
                                                let updateRequireAwayHeadCoachInfo =
                                                    JSON.parse(
                                                        update.require_away_head_coach_info ||
                                                            '{}'
                                                    ) || {};
                                                let changeDefaults = false;
                                                Object.keys(
                                                    updateRequireAwayHeadCoachInfo
                                                ).forEach((key) => {
                                                    if (
                                                        requireAwayHeadCoachInfo[
                                                            key
                                                        ] !==
                                                        updateRequireAwayHeadCoachInfo[
                                                            key
                                                        ]
                                                    ) {
                                                        changeDefaults = true;
                                                    }
                                                });
                                                if (changeDefaults) {
                                                    Object.keys(
                                                        properties
                                                    ).forEach((sport) => {
                                                        if (
                                                            sport !== 'global'
                                                        ) {
                                                            properties[
                                                                sport
                                                            ].require_away_head_coach_info =
                                                                update.require_away_head_coach_info;
                                                        }
                                                    });
                                                }
                                                properties =
                                                    JSON.stringify(properties);
                                                sportsDefaultUpdate({
                                                    variables: {
                                                        orgid: this.props.user
                                                            .orgid,
                                                        properties,
                                                    },
                                                }).then(() => {
                                                    this.notify(true, 'All');
                                                    this.props.defaultRefetch();
                                                    this.setState({
                                                        attemptedSave: false,
                                                    });
                                                });
                                            }}
                                        />
                                    </AccordionItemPanel>
                                </AccordionItem>
                            )}
                            {sportsToShow
                                .sort((a, b) => {
                                    return sportsAlphabetized[a] <
                                        sportsAlphabetized[b]
                                        ? -1
                                        : 1;
                                })
                                .map((sport, index) => {
                                    return (
                                        <AccordionItem key={index}>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    {sportsAlphabetized[sport]}
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <ItemBody
                                                    sport={sport}
                                                    sportDefault={
                                                        (this.props
                                                            .sportsDefault &&
                                                            this.props
                                                                .sportsDefault[
                                                                sport
                                                            ]) ||
                                                        {}
                                                    }
                                                    globalDefault={
                                                        (this.props
                                                            .sportsDefault &&
                                                            this.props
                                                                .sportsDefault[
                                                                'global'
                                                            ]) ||
                                                        {}
                                                    }
                                                    user={this.props.user}
                                                    attemptedSave={
                                                        this.state.attemptedSave
                                                    }
                                                    errors={this.state.errors}
                                                    update={(sport, update) => {
                                                        this.setState({
                                                            attemptedSave: true,
                                                        });
                                                        let submitFieldsCheck =
                                                            checkSubmitRequiredPairs(
                                                                update
                                                            );
                                                        if (
                                                            submitFieldsCheck.length >
                                                            0
                                                        ) {
                                                            this.setState({
                                                                errors: submitFieldsCheck,
                                                            });
                                                        } else {
                                                            let properties =
                                                                JSON.stringify(
                                                                    Object.assign(
                                                                        {},
                                                                        this
                                                                            .props
                                                                            .sportsDefault,
                                                                        {
                                                                            [sport]:
                                                                                update,
                                                                        }
                                                                    )
                                                                );
                                                            sportsDefaultUpdate(
                                                                {
                                                                    variables: {
                                                                        orgid: this
                                                                            .props
                                                                            .user
                                                                            .orgid,
                                                                        properties,
                                                                    },
                                                                }
                                                            ).then(() => {
                                                                this.notify(
                                                                    true,
                                                                    sports[
                                                                        sport
                                                                    ]
                                                                );
                                                                this.props.defaultRefetch();
                                                                this.setState({
                                                                    attemptedSave: false,
                                                                });
                                                            });
                                                        }
                                                    }}
                                                />
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    );
                                })}
                        </Accordion>
                    )}
                </Mutation>
            </div>
        );
    }
}

class DefaultsContainer extends Component {
    render() {
        return (
            <div
                style={{
                    marginTop: '25px',
                }}
            >
                <Container>
                    <Query
                        query={sportsDefaultQuery}
                        variables={{
                            orgid: this.props.user.orgid,
                        }}
                        fetchPolicy={'network-only'}
                    >
                        {({ data, refetch, error, loading }) => {
                            if (loading) {
                                return <Spinner />;
                            }
                            let sportsDefault = JSON.parse(
                                data.sportsDefault.properties
                            );
                            return (
                                <Defaults
                                    {...this.props}
                                    sportsDefault={sportsDefault}
                                    defaultRefetch={() => {
                                        refetch();
                                        this.forceUpdate();
                                    }}
                                    user={this.props.user}
                                />
                            );
                        }}
                    </Query>
                </Container>
            </div>
        );
    }
}

export default DefaultsContainer;
