import gql from 'graphql-tag';


export const orgMatches = gql`
    query orgMatches (
        $searched_orgs: [String]
    ) {
        orgMatches (
            searched_orgs: $searched_orgs
        ) {
            orgs {
                id
                name
            }
            searched
            exact
            error
        }
    }
`;

export const orgBulkDefaults = gql`
    query orgBulkDefaults (
        $orgid: ID
    ) {
        orgBulkDefaults (
            orgid: $orgid
        ) {
            orgid
            defaults
        }
    }
`;

export const orgBulkDefaultsUpsert = gql`
    mutation orgBulkDefaultsUpsert (
        $orgid: ID
        $defaults: String
    ) {
        orgBulkDefaultsUpsert (
            orgid: $orgid
            defaults: $defaults
        )
    }
`;
