import React, { Component, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ApolloError } from 'apollo-client';
import { Query } from 'react-apollo';

import { orgsQuery } from '../../gql/org';

import Account from '../account/account';
import { Admin } from '../admin/admin';
import Bulk from '../bulk/bulk';
import Dashboard from '../dashboard/dashboard';
import Defaults from '../defaults/defaults';
import Schedule from '../schedule/schedule';
import Template from '../account/template';
import UsageAgreement from '../account/usageAgreement';
import { AppContext } from '../context/AppContext';

const HomeContainer: React.FC<any> = (props) => {
    const month = new Date().getMonth();
    const year = new Date().getFullYear() + (month > 3 ? 1 : 0);
    const defaultSearchFilters = {
        academicYears: [{ label: `${year - 1}-${year}`, value: year }],
        homeOrAway: '',
        opponents: [],
        past: true,
        seasons: [],
        sports: [],
        statuses: [],
    };
    const [searchFilters, setSearchFilters] = useState(defaultSearchFilters);

    const updateSearchFilter = (update: any) => {
        return new Promise((resolve, reject) => {
            let newSearchFilters = Object.assign(
                {},
                { ...searchFilters },
                update
            );
            setSearchFilters(newSearchFilters);
            resolve(true);
        });
    };

    return (
        <Query query={orgsQuery}>
            {({
                loading,
                error,
                data,
            }: {
                loading: boolean;
                error?: ApolloError;
                data: any;
            }) => {
                if (loading) return null;
                let orgs: { [key: string]: string } = {};
                let opponentOptions: any;
                if (data.orgs) {
                    data.orgs
                        // .sort((a, b) => a.name.localeCompare(b.name))
                        .forEach((org: any) => {
                            orgs[org.id] = org.name;
                        });
                    opponentOptions = data.orgs.map((org: any) => ({
                        label: org.name,
                        value: org.id,
                    }));
                    // .sort((a: any, b: any) => {
                    //     return a.label.localeCompare(b.);
                    // });
                } else {
                    opponentOptions = [];
                }
                return (
                    <div>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                render={(compProps: any) => (
                                    <Dashboard
                                        {...props}
                                        {...compProps}
                                        orgs={orgs}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/schedule"
                                render={(compProps: any) => (
                                    <Schedule
                                        {...props}
                                        {...compProps}
                                        orgs={orgs}
                                        opponentOptions={opponentOptions}
                                        searchFilters={searchFilters}
                                        updateSearchFilter={updateSearchFilter}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/org-defaults"
                                render={(compProps: any) => (
                                    <Defaults {...props} {...compProps} />
                                )}
                            />
                            <Route
                                exact
                                path="/account"
                                render={(compProps: any) => (
                                    <Account {...props} {...compProps} />
                                )}
                            />
                            <Route
                                exact
                                path="/account/template"
                                render={(compProps: any) => (
                                    <Template {...props} {...compProps} />
                                )}
                            />
                            <Route
                                exact
                                path="/usage-agreement"
                                render={(compProps: any) => (
                                    <UsageAgreement {...props} {...compProps} />
                                )}
                            />
                            <Route
                                exact
                                path="/bulk"
                                render={(compProps: any) => (
                                    <Bulk
                                        {...props}
                                        {...compProps}
                                        opponentOptions={opponentOptions}
                                        orgs={orgs}
                                    />
                                )}
                            />
                            {props.user &&
                            [
                                'creedjm@gmail.com',
                                'lwalczew@gmail.com',
                            ].includes(props.user.email) ? (
                                <Route
                                    exact
                                    path="/admin"
                                    render={(compProps: any) => (
                                        <Admin {...props} {...compProps} />
                                    )}
                                />
                            ) : null}
                        </Switch>
                    </div>
                );
            }}
        </Query>
    );
};

export default HomeContainer;
