const settings = {
    env: 'v2Prod',
    // env: 'localRemoteProd',
    // env: 'local',
    // env: 'localRemoteDev',
    // env: 'v2Dev',
};

export default settings;

export const GA_ANALYTICS = 'UA-139774933-1';
export const GOOGLE_API_KEY = 'AIzaSyDdFAzfMvoL7LgXxSHv2Cd4fC83ST_Xyy8';
