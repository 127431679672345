import React from 'react';
import {
    Loader
} from 'semantic-ui-react';

let Spinner = ({inverted}) => (
    <div
        style={{
            height: 300,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}
    >
        <Loader inverted={inverted} active inline='centered'/>
    </div>
)

export default Spinner
