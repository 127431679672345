import { createContext } from 'react';

export const AppContext = createContext<{
    showContactUsModal: boolean;
    setShowContactUsModal: React.Dispatch<React.SetStateAction<boolean>>;
    requestNewOrgModal: { open: boolean; input?: string };
    setRequestNewOrgModal: React.Dispatch<
        React.SetStateAction<{ open: boolean; input?: string }>
    >;
}>({
    showContactUsModal: false,
    setShowContactUsModal: () => {},
    requestNewOrgModal: { open: false },
    setRequestNewOrgModal: () => {},
});
