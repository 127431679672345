export const AWAY_PLACEHOLDER = `AWAY_PLACEHOLDER`;
export const AWAY_PLACEHOLDER_PAPER_RECEIVED = `AWAY_PLACEHOLDER_PAPER_RECEIVED`;
export const CANCELLED = 'CANCELLED';
export const EDITED = `EDITED`;
export const HOME_PAPER_CONTRACT = `HOME_PAPER_CONTRACT`;
export const HOME_PAPER_CONTRACT_RECEIVED = `HOME_PAPER_CONTRACT_RECEIVED`;
export const HOME_PAPER_CONTRACT_SENT =  `HOME_PAPER_CONTRACT_SENT`;
export const SIGNED = `SIGNED`;
export const SUBMITTED = `SUBMITTED`;
export const SUBMITTED_AWAY_DIFFERENT_SIGNER = `SUBMITTED_AWAY_DIFFERENT_SIGNER`;
export const SUBMITTED_DIFFERENT_SIGNER = `SUBMITTED_DIFFERENT_SIGNER`;
export const SUBMITTED_INTERNAL_AWAY =  `SUBMITTED_INTERNAL_AWAY`;
export const SUBMITTED_INTERNAL_AWAY_COMPLETE =  `SUBMITTED_INTERNAL_AWAY_COMPLETE`;
export const SUBMITTED_INTERNAL_HOME =  `SUBMITTED_INTERNAL_HOME`;
export const SUBMITTED_NOTCLICKED =  `SUBMITTED_NOTCLICKED`;

export const statuses = {
    AWAY_PLACEHOLDER,
	AWAY_PLACEHOLDER_PAPER_RECEIVED,
	CANCELLED,
    EDITED,
    HOME_PAPER_CONTRACT,
    HOME_PAPER_CONTRACT_RECEIVED,
    HOME_PAPER_CONTRACT_SENT,
    SIGNED,
    SUBMITTED,
    SUBMITTED_AWAY_DIFFERENT_SIGNER,
    SUBMITTED_DIFFERENT_SIGNER,
    SUBMITTED_INTERNAL_AWAY,
    SUBMITTED_INTERNAL_AWAY_COMPLETE,
    SUBMITTED_INTERNAL_HOME,
    SUBMITTED_NOTCLICKED,
}

export const statusIcons = {
	AWAY_PLACEHOLDER: {name: 'remove circle', color: 'red'},
	AWAY_PLACEHOLDER_PAPER_RECEIVED: {name: 'check circle', color: 'green'},
	CANCELLED: {name: 'copyright', color: 'green', style:{cursor: 'pointer', opacity: .5}},
	EDITED: {name: 'remove circle', color: 'red'},
	HOME_PAPER_CONTRACT: {name: 'remove circle', color: 'red'},
	HOME_PAPER_CONTRACT_RECEIVED: {name: 'check circle', color: 'green'},
	HOME_PAPER_CONTRACT_SENT: {name: 'minus circle', color: 'yellow', inverted: true},
	SIGNED: {name: 'check circle', color: 'green'},
	// SUBMITTED: {name: 'minus circle', style:{color: '#fbeba4'}, inverted: true},
	SUBMITTED: {name: 'minus circle', color: 'yellow', inverted: true},
	SUBMITTED_AWAY_DIFFERENT_SIGNER: {name: 'minus circle', color: 'yellow', inverted: true},
	SUBMITTED_DIFFERENT_SIGNER: {name: 'minus circle', color: 'yellow', inverted: true, },
	SUBMITTED_INTERNAL_AWAY: {name: 'minus circle', color: 'yellow', inverted: true, },
	SUBMITTED_INTERNAL_AWAY_COMPLETE: {name: 'minus circle', color: 'yellow', inverted: true, },
	SUBMITTED_INTERNAL_HOME: {name: 'minus circle', color: 'yellow', inverted: true, },
	SUBMITTED_NOTCLICKED: {name: 'minus circle', color: 'orange', inverted: true, },
};

export const icons = {
	check: (color='green') => ({name: 'check circle', color}),
	x: (color='red') => ({name: 'remove circle', color}),
	minus: (color='yellow') => ({name: 'minus circle', color}),
}

export const statusDescriptions = {
	AWAY_PLACEHOLDER: 'Away placeholder: No contract has been received',
	AWAY_PLACEHOLDER_PAPER_RECEIVED: 'Away placeholder: Paper contract has been received',
	CANCELLED: 'This contest has been cancelled',
	EDITED: 'No signed contract in place',
	HOME_PAPER_CONTRACT: 'Contract to be exchanged by paper',
	HOME_PAPER_CONTRACT_RECEIVED: 'Paper contract has been signed and received',
	HOME_PAPER_CONTRACT_SENT: 'Paper contract has been sent',
	SIGNED: 'Signed contract in place',
	SUBMITTED: 'Contract submitted for signature (recipient has viewed)',
	SUBMITTED_AWAY_DIFFERENT_SIGNER: 'Contract submitted for signature to away organization contract signer',
	SUBMITTED_DIFFERENT_SIGNER: 'Contract submitted for signature to home organization contract signer',
	SUBMITTED_INTERNAL_AWAY: 'Contract has been submitted for internal approval and owner will be notified after internal approvals complete',
	SUBMITTED_INTERNAL_AWAY_COMPLETE: 'Contract has been approved by all internal stakeholders',
	SUBMITTED_INTERNAL_HOME: 'Contract has been submitted for internal approval and will be sent automatically',
	SUBMITTED_NOTCLICKED: 'Contract submitted for signature (recipient has not viewed)',
};

export const notViewReminderHistory = [
	EDITED,
	SIGNED,
	AWAY_PLACEHOLDER,
	AWAY_PLACEHOLDER_PAPER_RECEIVED,
	HOME_PAPER_CONTRACT,
	HOME_PAPER_CONTRACT_RECEIVED,
	HOME_PAPER_CONTRACT_SENT
];

export const statusSubmittedOrder = [
	EDITED,
	SUBMITTED_INTERNAL_HOME,
	SUBMITTED_DIFFERENT_SIGNER,
	SUBMITTED_NOTCLICKED,
	SUBMITTED,
	SUBMITTED_INTERNAL_AWAY,
	SUBMITTED_INTERNAL_AWAY_COMPLETE,
	SUBMITTED_AWAY_DIFFERENT_SIGNER,
	SIGNED,
	CANCELLED,
]
