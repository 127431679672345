import React, {Component} from 'react';
import {Mutation, Query} from 'react-apollo';
import { Button, Card, Form, Header, Icon, Popup } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { userOrgContactListQuery, userCreateMutation, userRemoveMutation } from '../../gql/user';
import { orgUpdateMutation } from '../../gql/org';
import {BaseControlledModal, ModalAction} from '../modals/modals';
import { FormRowInput } from '../utilityComponents/form/formRow';
import {AttributeRow} from './accountUtils';
import Spinner from '../utilityComponents/spinner';
import { colors } from '../../utils/colors';
import { UserPermissionsModal, UserUpdateWrapper } from './userPermissions';
import { InfoModalVideo } from '../utilityComponents/InfoModalVideo';
import { validateEmail } from '../../utils/validators';

const fields = [
    {
        key: 'email',
        label: 'Email'
    },
    {
        key: 'phone_work',
        label: 'Office Phone'
    },
    {
        key: 'phone_cell',
        label: 'Cell Phone'
    },
    {
        key: 'office_addr',
        label: 'Office Address'
    }
]

class CreateOrgUser extends Component {
    state = {
        newUser: {
            email: '',
            firstname: '',
            lastname: '',
            title: '',
            phone_work: '',
        },
        errors: {},
        creating: false,
    }

    updateNewUser = (update) => {
        let newUser = {...this.state.newUser};
        Object.assign(newUser, update);
        this.setState({
            newUser
        })
    }

    notify = (success, action) => {
        if (success) {
            if (action === 'create') {
                toast.success("User created successully", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    hideProgressBar: true,
                });
            } else if (action === 'remove') {
                toast.success("User removed successully", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    hideProgressBar: true,
                });
            }
        } else {
            if (action === 'create') {
                toast.error("User has not been created. Please check the email address.", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    hideProgressBar: true                
                })
            }
        }
    }

    render() {
        let newUser = {...this.state.newUser}
        const {creating} = this.state;
        const {contactList} = this.props;
        console.log({errors: this.state.errors})
        return (
            <Mutation mutation={userCreateMutation}>
                {(userCreate, { data, loading, error }) => (
                    <BaseControlledModal
                        header={(
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                            >
                                {'Create New User'}
                                <InfoModalVideo {...{
                                    videoId: 'O8XER9EWkqc',
                                    header: 'Create new users'
                                }} />
                            </div>
                        )}
                        size={'small'}
                        trigger={<Button basic primary onClick={() => this.setState({open: true})}>Create New User</Button>}
                        open={this.state.open}
                        onClose={() => this.setState({open: false})}
                        actions={[
                            <ModalAction 
                                key={0}
                                primary
                                loading={creating}
                                title={'Create User'}
                                onClick={() => {
                                    this.setState({creating: true})
                                    console.log('create state', this.state)
                                    let { newUser, errors } = this.state;
                                    
                                    if (Object.keys(errors).length > 0 && Object.keys(errors).some(field => errors[field])) {
                                        this.setState({creating: false})
                                        return
                                    } else {
                                        userCreate({variables: Object.assign({},
                                            {...newUser},
                                            {
                                                orgid: this.props.user.orgid,
                                                permission_level: 'FULL',
                                                notifyEmail: true,
                                                creatingUser: this.props.user.id,
                                            }
                                        )}).then(res => {
                                            this.props.refetchUsers().then(() => {
                                                let data = res.data || {};
                                                let user = data.userCreate
                                                this.props.onUserCreate(user)
                                            });
                                            this.notify(true, 'create');
                                            this.setState({open: false, creating: false})
                                        },err => {
                                            this.notify(false, 'create')
                                            console.log('err', err)
                                            this.setState({creating: false})
                                        })
                                    }
                                }} />
                        ]}
                        >
                        <div style={{marginBottom: '12px'}}>
                            Please enter the new user's information below, then click the "Create User" button. After you create 
                            a new user, you will be prompted to set access permissions for that user.  Our system will also automatically email that user 
                            with instructions on how to log in to our platform.
                        </div>
                        <Form>
                            <FormRowInput 
                                label={'Email:'}
                                required
                                field={'email'}
                                value={newUser.email}
                                type='email'
                                formUpdate={this.updateNewUser}
                                error={this.state.errors['email']}
                                onBlur={(e) => {
                                    if (!validateEmail(e.target.value)) {
                                        this.setState({
                                            errors: {
                                                ...this.state.errors,
                                                email: {
                                                    content: 'Please enter a valid email',
                                                }
                                            }
                                        })
                                    } else if (contactList.findIndex(c => c.email === newUser.email) > -1) {
                                        this.setState({
                                            errors: {
                                                ...this.state.errors,
                                                email: {
                                                    content: 'A user with this email already exists in your organization',
                                                }
                                            }
                                        })
                                    } else {
                                        this.setState({
                                            errors: {
                                                ...this.state.errors,
                                                email: false
                                            }
                                        })
                                    }
                                }}
                            />
                            <FormRowInput 
                                label={'First Name:'}
                                field={'firstname'}
                                value={newUser.firstname}
                                type='text'
                                formUpdate={this.updateNewUser}
                            />
                            <FormRowInput 
                                label={'Last Name:'}
                                field={'lastname'}
                                value={newUser.lastname}
                                type='text'
                                formUpdate={this.updateNewUser}
                            />
                            <FormRowInput 
                                label={'Title:'}
                                field={'title'}
                                value={newUser.title}
                                type='text'
                                formUpdate={this.updateNewUser}
                            />
                            <FormRowInput 
                                label={'Work Phone:'}
                                field={'phone_work'}
                                value={newUser.phone_work}
                                type='text'
                                formUpdate={this.updateNewUser}
                            />
                        </Form>
                    </BaseControlledModal>
                )}
            </Mutation>
        )
    }
}

export default class MyOrgUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editedOrg: {},
            permissionsModalVisible: null
        }
    }

    notify = (success, action) => {
        if (success) {
            if (action === 'create') {
                toast.success("User created successully", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    hideProgressBar: true,
                });
            } else if (action === 'remove') {
                toast.success("User removed successully", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    hideProgressBar: true,
                });
            }
        }
    }

    render() {
        let {permissionsModalVisible} = this.state;
        return (
            <Query query={userOrgContactListQuery} variables={{orgid: this.props.user.orgid}}>
                {({loading, error, data, refetch}) => {
                    if (loading) {
                        return <Spinner />
                    }
                    if (error) {
                        return <div>Something went wrong</div>
                    }
                    let contactList = data.homeUserOrgContactList || [];
                    let primaryContact = data.homePrimaryContact || {};
                    return (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '15px 0',
                                    borderBottom: `1px solid ${colors.greyBorder}`
                                }}
                            >
                                <div>
                                    <Header as='h2'>Other Users at My Organization</Header>
                                </div>
                                {this.props.user.is_admin && (
                                    <div>
                                        <CreateOrgUser 
                                            {...this.props} 
                                            contactList={contactList}
                                            refetchUsers={refetch} 
                                            onUserCreate={user => {
                                                if (user && user.id) {
                                                    this.setState({
                                                        permissionsModalVisible: user,
                                                        isNewUser: true
                                                    })
                                                }
                                               
                                            }} 
                                        />
                                    </div>
                                )}
                                <UserUpdateWrapper>
                                    {updateUser => (
                                        <UserPermissionsModal 
                                            permissionsModalVisible={permissionsModalVisible}
                                            isNewUser={this.state.isNewUser}
                                            refetch={refetch}
                                            org={this.props.org}
                                            onClose={() => {
                                                this.setState({
                                                    permissionsModalVisible: null,
                                                    isNewUser: false
                                                })
                                            }}
                                            updateUser={updateUser}
                                        />
                                    )}
                                </UserUpdateWrapper>
                            </div>
                            <div
                                style={{
                                    marginTop: '10px'
                                }}
                            >
                                <Card.Group
                                    itemsPerRow={3}
                                >
                                    {contactList.map((contact, i) => {
                                        if (contact.id !== this.props.user.id) {
                                            return (
                                                <Card key={i}>
                                                    <Card.Content>
                                                        <Card.Header>
                                                            <div
                                                                style={{
                                                                    paddingTop: '25px'
                                                                }}
                                                            >
                                                                {contact.firstname && contact.lastname ? 
                                                                    `${contact.firstname} ${contact.lastname}` :
                                                                    contact.email}
                                                                <div
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '5px',
                                                                        right: 0,
                                                                        left: 0,
                                                                        justifyContent: 'space-between',
                                                                        display: 'flex',
                                                                        flexDirection: 'row'
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            marginLeft: '3px',
                                                                            backgroundColor: '#21ba45',
                                                                            borderRadius: '3px',
                                                                            color: 'white',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center'
                                                                        }}
                                                                    >
                                                                        {contact.is_admin && `\xa0 Admin \xa0`}
                                                                    </div>
                                                                    {this.props.user.is_admin ? (
                                                                        <Mutation
                                                                            mutation={userRemoveMutation}
                                                                        >
                                                                            {(userRemove, {loading, error}) => (
                                                                                <Popup
                                                                                    position='top center'
                                                                                    on='click'
                                                                                    open={this.state.deletePopupVisible === contact.id}
                                                                                    onClose={() => this.setState({deletePopupVisible: false})}
                                                                                    // trigger={<Button basic icon onClick={() => this.setState({deletePopupVisible: contact.id})}><Icon name='remove circle' color='red' /></Button>}
                                                                                    trigger={<Icon name='remove circle' size='large' color={primaryContact.id !== contact.id ? 'red' : 'grey'} style={{cursor: 'pointer'}} onClick={() => this.setState({deletePopupVisible: contact.id})} />}
                                                                                    content={
                                                                                        primaryContact.id !== contact.id ? (<div>
                                                                                            <div style={{marginBottom: '10px'}}>
                                                                                                Are you sure you want to remove this user?
                                                                                            </div>
                                                                                            <div
                                                                                                style={{
                                                                                                    display: 'flex',
                                                                                                    flexDirection: 'row'
                                                                                                }}
                                                                                            >
                                                                                                <Button
                                                                                                    primary
                                                                                                    fluid
                                                                                                    onClick={() => {
                                                                                                        userRemove({
                                                                                                            variables: {
                                                                                                                id: contact.id,
                                                                                                            }
                                                                                                        }).then(() => {
                                                                                                            this.setState({deletePopupVisible: false})
                                                                                                            this.notify(true, 'remove')
                                                                                                            refetch();
                                                                                                        })
                                                                                                    }}
                                                                                                >
                                                                                                    Yes
                                                                                                </Button>
                                                                                                <Button
                                                                                                    fluid
                                                                                                    onClick={() => this.setState({deletePopupVisible: false})}
                                                                                                >
                                                                                                    Cancel
                                                                                                </Button>
                                                                                            </div>
                                                                                        </div>) : (
                                                                                            <div>
                                                                                                This user is currently the primary recipient for your organization. Please select a different primary recipient before deleting this user.
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Mutation>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </Card.Header>
                                                        <Card.Meta>
                                                            {contact.title}
                                                        </Card.Meta>
                                                        <Card.Description>
                                                            {fields.slice(0, 2).map((field, j) => (
                                                                <AttributeRow
                                                                    key={j}
                                                                    label={field.label}
                                                                    value={contact[field.key]}
                                                                />
                                                            ))}
                                                        </Card.Description>
                                                    </Card.Content>
                                                    
                                                        
                                                            {this.props.user.is_admin && (
                                                                <Card.Content extra>
                                                                    <div 
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'column'
                                                                        }}
                                                                    >   
                                                                        <Mutation
                                                                            mutation={orgUpdateMutation}
                                                                        >
                                                                            {(orgUpdate, {loading, error}) => (
                                                                                
                                                                                <Button
                                                                                    basic={primaryContact.id !== contact.id}
                                                                                    disabled={primaryContact.id === contact.id}
                                                                                    primary
                                                                                    style={{
                                                                                        flex: 1
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        if (primaryContact.id !== contact.id) {
                                                                                            orgUpdate({
                                                                                                variables: {
                                                                                                    id: this.props.user.orgid,
                                                                                                    primary_contact: contact.id
                                                                                                }
                                                                                            }).then(() => {
                                                                                                refetch()
                                                                                            })
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <span>
                                                                                        {primaryContact.id === contact.id && <Icon name='check circle outline' color='white' />}
                                                                                        {primaryContact.id === contact.id ? `Primary Contact` : `Make Primary Contact`}
                                                                                    </span>
                                                                                </Button> 
                                                                            )}
                                                                        </Mutation>
                                                                    
                                                                        <Button
                                                                            basic
                                                                            positive
                                                                            style={{
                                                                                flex: 1,
                                                                                marginTop: '5px'
                                                                            }}
                                                                            onClick={() => {
                                                                                this.setState({permissionsModalVisible: contact})
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                Edit User Permissions
                                                                            </span>
                                                                        </Button>
                                                                    </div>
                                                        
                                                       
                                                                </Card.Content> 
                                                            )}
                                                            
                                                            
                                                        
                                                </Card>
                                            )
                                        }
                                    })
                                }
                                </Card.Group>
                            </div>
                        </div>
                    )
                }}
            </Query>
        )
    }
}