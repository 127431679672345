import React, { Component, useContext, useState } from 'react';
import { Mutation } from 'react-apollo';
import { Button, Form } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { sportDropdownOptions, sportSelectOptions } from '../../utils/sports';
import { contestCreateMutation } from '../../gql/contest';

import {
    BaseControlledModal,
    ModalAction,
    ModalDropdownTypeahead,
    ModalSelect,
} from './modals';

import {
    FormRow,
    FormRowInput,
    FormRowTextArea,
    FormRowDropdown,
} from '../utilityComponents/form/formRow';

import {
    CustomDatePicker,
    CustomTimePicker,
} from '../utilityComponents/form/materialUI';
import { InfoModalVideo } from '../utilityComponents/InfoModalVideo';
import { AppContext } from '../context/AppContext';

let getDateError = (contestDate) => {
    let cDate = new Date(contestDate);
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return cDate < yesterday;
};

let AwayContestFields = (props) => {
    let { sportsFilterOptions } = props;
    return (
        <div style={{ paddingBottom: '60px' }}>
            <FormRow label={'Sport'} required>
                <ModalSelect
                    placeholder="Select sport"
                    name="sport"
                    required
                    error={
                        props.errors.sport &&
                        props.errors.sport(props.newContest.sport)
                    }
                    value={props.newContest.sport}
                    options={sportsFilterOptions}
                    onChange={props.newContestChange}
                />
            </FormRow>
            <FormRow label="Opponent" required>
                <ModalSelect
                    placeholder="Select opponent"
                    name="orgid"
                    required
                    error={
                        props.errors.orgid &&
                        props.errors.orgid(props.newContest.orgid)
                    }
                    value={props.newContest.orgid}
                    options={props.opponentOptions}
                    onChange={props.newContestChange}
                />
            </FormRow>
            <FormRow label={'Date'} required>
                <CustomDatePicker
                    hintText="Select Date"
                    user={props.user}
                    value={new Date(props.newContest.date)}
                    onChange={(data) => {
                        props.newContestChange('date', data);
                    }}
                />
            </FormRow>
            <FormRow label={'Time'}>
                <CustomTimePicker
                    hintText="Select Time"
                    minutesStep={5}
                    hour={props.newContest.hour}
                    minute={props.newContest.minute}
                    setTBA={() => {
                        props.newContestChange('hour', 24);
                        props.newContestChange('minute', 0);
                    }}
                    value={
                        new Date(
                            2018,
                            6,
                            1,
                            props.newContest.hour,
                            props.newContest.minute
                        )
                    }
                    onChange={(e, data) => {
                        props.newContestChange('hour', data.getHours());
                        props.newContestChange('minute', data.getMinutes());
                    }}
                />
            </FormRow>
            <FormRowInput
                label={'Description'}
                field={'away_placeholder_description'}
                type="text"
                value={props.newContest.away_placeholder_description}
                formUpdate={(update) => {
                    props.newContestChange(
                        'away_placeholder_description',
                        update['away_placeholder_description']
                    );
                }}
            />
        </div>
    );
};

let additionalFields = [
    {
        key: 'head_coach',
        label: 'Head Coach',
        input: FormRowInput,
        inputType: 'input',
    },
    {
        key: 'head_coach_email',
        label: 'Head Coach Email',
        input: FormRowInput,
        inputType: 'input',
    },
    {
        key: 'head_coach_phone',
        label: 'Head Coach Phone',
        input: FormRowInput,
        inputType: 'input',
    },
    {
        key: 'season',
        label: 'Season',
        input: FormRowDropdown,
        inputType: 'select',
        options: [
            { value: '', text: '' },
            { value: 'FALL', text: 'Fall' },
            { value: 'WINTER', text: 'Winter' },
            { value: 'SPRING', text: 'Spring' },
        ],
    },
    {
        key: 'comp_level',
        label: 'Competition Level',
        input: FormRowInput,
        inputType: 'input',
    },
    {
        key: 'cancellation_terms',
        label: 'Cancellation Terms',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'force_majeure',
        label: 'Force Majeure',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'comp_tickets',
        label: 'Complimentary Tickets',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'cons_tickets',
        label: 'Consignment Tickets',
        input: FormRowInput,
        inputType: 'input',
    },
    {
        key: 'governing_rules',
        label: 'Governing Rules',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'radio_rights',
        label: 'Radio Rights',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'tv_rights',
        label: 'Video Rights',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
    {
        key: 'broadcast_rights',
        label: 'Broadcast Rights',
        input: FormRowTextArea,
        inputType: 'textarea',
    },
];

let HomeContestFields = (props) => {
    const { setRequestNewOrgModal } = useContext(AppContext);
    let [expanded, setExpanded] = useState(false);
    let { sportsFilterOptions } = props;
    let dateError = getDateError(props.newContest.date);
    return (
        <div>
            {/* 
                opponent dropdown
                sport dropdown
                date picker
                time picker
                location
                officials
                description
                other conditions
                future commitment
                guarantee
            */}
            <FormRow label={'Sport'} required>
                <ModalSelect
                    placeholder="Select sport"
                    name="sport"
                    multiple={false}
                    error={
                        props.errors.sport &&
                        props.errors.sport(props.newContest.sport)
                    }
                    value={props.newContest.sport}
                    options={sportsFilterOptions}
                    onChange={props.newContestChange}
                />
            </FormRow>
            <FormRow label={'Opponent'} required>
                <ModalSelect
                    placeholder="Select opponent"
                    name="away_orgid"
                    error={
                        props.errors.away_orgid &&
                        props.errors.away_orgid(props.newContest.away_orgid)
                    }
                    onNoOptionClick={(input) => {
                        setRequestNewOrgModal({
                            open: true,
                            user: props.user,
                            input: '',
                        });
                    }}
                    value={props.newContest.away_orgid}
                    options={props.opponentOptions}
                    onChange={props.newContestChange}
                />
            </FormRow>
            <FormRow label={'Date'} required>
                <CustomDatePicker
                    hintText="Select Date"
                    user={props.user}
                    error={dateError}
                    value={new Date(props.newContest.date)}
                    onChange={(data) => {
                        props.newContestChange('date', data);
                    }}
                />
                {dateError && (
                    <div
                        style={{
                            marginTop: '3px',
                        }}
                    >
                        <span style={{ fontSize: '10px', color: '#9f3a38' }}>
                            This date has passed. You may still create this
                            contest if you wish.
                        </span>
                    </div>
                )}
            </FormRow>

            <FormRow label={'Time'}>
                <CustomTimePicker
                    hintText="Select Time"
                    error={false}
                    minutesStep={5}
                    hour={props.newContest.hour}
                    minute={props.newContest.minute}
                    setTBA={() => {
                        props.newContestChange('hour', 24);
                        props.newContestChange('minute', 0);
                    }}
                    value={
                        new Date(
                            2018,
                            6,
                            1,
                            props.newContest.hour,
                            props.newContest.minute
                        )
                    }
                    onChange={(e, data) => {
                        props.newContestChange('hour', data.getHours());
                        props.newContestChange('minute', data.getMinutes());
                    }}
                />
            </FormRow>
            <Form>
                <FormRowInput
                    label={'Location'}
                    field={'location'}
                    value={props.newContest.location}
                    error={false}
                    type="text"
                    formUpdate={(update) => {
                        props.newContestChange('location', update['location']);
                    }}
                />
                <FormRowInput
                    label={'Officials'}
                    field={'officials'}
                    error={false}
                    value={props.newContest.officials}
                    type="text"
                    formUpdate={(update) => {
                        props.newContestChange(
                            'officials',
                            update['officials']
                        );
                    }}
                />
                <FormRowInput
                    label={'Description'}
                    field={'description'}
                    value={props.newContest.description}
                    error={false}
                    type="text"
                    formUpdate={(update) => {
                        props.newContestChange(
                            'description',
                            update['description']
                        );
                    }}
                />
                <FormRowTextArea
                    label={'Other Conditions'}
                    field={'other_conditions'}
                    value={props.newContest.other_conditions}
                    error={false}
                    type="text"
                    formUpdate={(update) => {
                        props.newContestChange(
                            'other_conditions',
                            update['other_conditions']
                        );
                    }}
                />

                <FormRowTextArea
                    label={'Future Commitment'}
                    field={'future'}
                    value={props.newContest.future}
                    error={false}
                    type="text"
                    formUpdate={(update) => {
                        props.newContestChange('future', update['future']);
                    }}
                />
                <FormRowTextArea
                    label={'Guarantee'}
                    field={'guarantee'}
                    value={props.newContest.guarantee}
                    error={false}
                    type="text"
                    formUpdate={(update) => {
                        props.newContestChange(
                            'guarantee',
                            update['guarantee']
                        );
                    }}
                />

                <div
                    style={{
                        marginTop: '15px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <Button
                            basic
                            onClick={() => setExpanded(!expanded)}
                        >{`${
                            expanded ? 'Hide' : 'Show'
                        } additional fields`}</Button>
                    </div>
                    {expanded &&
                        additionalFields.map((field, index) => {
                            let Input = field.input;
                            return (
                                <Input
                                    key={index}
                                    field={field.key}
                                    {...field}
                                    value={props.newContest[field.key]}
                                    type="text"
                                    formUpdate={(update) => {
                                        props.newContestChange(
                                            field.key,
                                            update[field.key]
                                        );
                                    }}
                                />
                            );
                        })}
                </div>
            </Form>
        </div>
    );
};

class CreateContestModal extends Component {
    constructor(props) {
        super(props);
        let newContest = {
            ...((props.sportsDefault && props.sportsDefault.global) || {}),
            homeOrAway: 'home',
            date: new Date(),
            hour: 24,
            minute: 0,
        };
        this.state = {
            opponentSearchQuery: '',
            sportSearchQuery: '',
            newContest,
            open: false,
            errors: {},
            createDisabled: false,
        };
    }

    notify = (success) => {
        if (success) {
            toast.success('Contest created successully', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 4000,
                hideProgressBar: true,
            });
        }
    };

    newContestChange = (attr, value) => {
        let newContest = this.state.newContest;
        newContest[attr] = value;
        if (attr === 'sport' && value && newContest.homeOrAway === 'home') {
            // if changing from a sport with a default to a sport without it leaves the old fields
            if (!this.props.sportsDefault[value.value]) {
                Object.assign(newContest, this.props.sportsDefault.global, {
                    homeOrAway: 'home',
                    date: new Date(),
                    hour: 24,
                    minute: 0,
                    sport: value,
                });
            } else {
                const sportsDefault = this.props.sportsDefault[value.value];

                Object.assign(
                    newContest,
                    this.props.sportsDefault.global,
                    Object.entries(sportsDefault).reduce(
                        (r, [k, v]) => (v ? Object.assign(r, { [k]: v }) : r),
                        {}
                    )
                );
            }
        }
        if (attr === 'homeOrAway' && value !== 'home') {
            newContest = {
                homeOrAway: 'away_placeholder',
                date: new Date(),
                hour: 12,
                minute: 0,
            };
        }
        this.setState({
            newContest,
        });
    };

    handleClose = () => {
        let newContest = Object.assign({}, this.props.sportsDefault.global, {
            homeOrAway: 'home',
            date: new Date(),
            hour: 24,
            minute: 0,
        });
        this.setState({
            opponentSearchQuery: '',
            sportSearchQuery: '',
            newContest,
            open: false,
            errors: {},
        });
    };

    render() {
        let { canCreate, permissions } = this.props;
        let sportsFilterOptions = !permissions
            ? sportSelectOptions
            : sportSelectOptions.filter((option) => {
                  return (
                      permissions[option.value] &&
                      permissions[option.value].write
                  );
              });
        let opponentOptions = [];
        Object.keys(this.props.orgs).forEach((opponentId) => {
            if (opponentId !== this.props.user.orgid) {
                opponentOptions.push({
                    label: this.props.orgs[opponentId],
                    value: opponentId,
                });
            }
        });
        opponentOptions.sort((a, b) => {
            return a.label.localeCompare(b.label);
        });
        return (
            <Mutation mutation={contestCreateMutation}>
                {(contestCreate, { data, loading, error }) => (
                    <BaseControlledModal
                        header={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}
                            >
                                Create Contest
                                <InfoModalVideo
                                    {...{
                                        videoId: 'qjZiqZSFock',
                                        header: 'Create contests',
                                    }}
                                />
                            </div>
                        }
                        size={'small'}
                        trigger={
                            <Button
                                disabled={!canCreate}
                                fluid
                                primary
                                onClick={() => this.setState({ open: true })}
                            >
                                Create Contest
                            </Button>
                        }
                        open={this.state.open}
                        onClose={() => this.handleClose()}
                        leftActions={[]}
                        actions={this.props.actions.map((action, index) => (
                            <ModalAction
                                {...action}
                                title={
                                    this.state.createDisabled
                                        ? `Creating Contest`
                                        : action.title
                                }
                                disabled={this.state.createDisabled}
                                onClick={() => {
                                    let { newContest, createDisabled } =
                                        this.state;
                                    if (createDisabled) {
                                        return;
                                    }
                                    let errors = {};
                                    let errorFields = [
                                        'sport',
                                        newContest.homeOrAway === 'home'
                                            ? 'away_orgid'
                                            : 'orgid',
                                    ];
                                    errorFields.forEach((field) => {
                                        if (!newContest[field]) {
                                            errors[field] = (value) => !value;
                                        }
                                    });
                                    console.log({ errors, newContest });
                                    if (Object.keys(errors).length > 0) {
                                        this.setState({ errors });
                                    } else {
                                        this.setState({ createDisabled: true });
                                        let submitDefaults = {};
                                        delete newContest.home_internal_approvers;
                                        // if (newContest.home_internal_approvers) {
                                        //     let homeContestApprovers = [];
                                        //     let homeApproverEmails = newContest.home_internal_approvers.replace(' ', '').split(',');
                                        //     homeApproverEmails.forEach(email => {
                                        //         if (email && email.indexOf('@') > 0) {
                                        //             homeContestApprovers.push({
                                        //                 approver_email: email,
                                        //                 approver_title: '',
                                        //                 approved_date: '',
                                        //                 approver_signature: '',
                                        //                 submitted_to_approver_date: new Date()
                                        //             })
                                        //         }
                                        //     })

                                        // }
                                        contestCreate({
                                            variables: Object.assign(
                                                {},
                                                { ...newContest },
                                                {
                                                    away_orgid:
                                                        newContest.homeOrAway ===
                                                        'away_placeholder'
                                                            ? this.props.user
                                                                  .orgid
                                                            : newContest
                                                                  .away_orgid
                                                                  .value,
                                                    created_at:
                                                        new Date().toLocaleString(),
                                                    created_by:
                                                        this.props.user.id,
                                                    creating_orgid:
                                                        this.props.user.orgid,
                                                    date: `${newContest.date.getFullYear()}-${
                                                        newContest.date.getMonth() +
                                                        1
                                                    }-${newContest.date.getDate()}`,
                                                    orgid:
                                                        newContest.homeOrAway ===
                                                        'home'
                                                            ? this.props.user
                                                                  .orgid
                                                            : newContest.orgid
                                                                  .value,
                                                    sport: newContest.sport
                                                        .value,
                                                }
                                            ),
                                        }).then(
                                            (res) => {
                                                this.props.refetchContests();
                                                this.notify(true);
                                                this.setState({
                                                    open: false,
                                                    createDisabled: false,
                                                });
                                            },
                                            (err) => console.log('err', err)
                                        );
                                    }
                                }}
                            />
                        ))}
                    >
                        {/* 
                            opponent dropdown
                            sport dropdown
                            date picker
                            time picker
                            description
                        */}
                        <FormRow label={'Home or Away'}>
                            <ModalDropdownTypeahead
                                placeholder="Home or Away"
                                name="homeOrAway"
                                defaultValue="home"
                                onChange={this.newContestChange}
                                options={[
                                    { text: 'Home', value: 'home' },
                                    {
                                        text: 'Away Placeholder',
                                        value: 'away_placeholder',
                                    },
                                ]}
                            />
                        </FormRow>
                        {this.state.newContest.homeOrAway === 'home' ? (
                            <HomeContestFields
                                errors={this.state.errors}
                                newContestChange={this.newContestChange}
                                newContest={this.state.newContest}
                                opponentOptions={opponentOptions}
                                opponentSearchQuery={
                                    this.state.opponentSearchQuery
                                }
                                sportSearchQuery={this.state.sportSearchQuery}
                                sportsFilterOptions={sportsFilterOptions}
                                onChangeSearchQuery={(query, value) => {
                                    this.setState({ [query]: value });
                                }}
                                {...this.props}
                            />
                        ) : (
                            <AwayContestFields
                                errors={this.state.errors}
                                newContestChange={this.newContestChange}
                                newContest={this.state.newContest}
                                opponentOptions={opponentOptions}
                                opponentSearchQuery={
                                    this.state.opponentSearchQuery
                                }
                                sportSearchQuery={this.state.sportSearchQuery}
                                sportsFilterOptions={sportsFilterOptions}
                                onChangeSearchQuery={(query, value) => {
                                    this.setState({ [query]: value });
                                }}
                                {...this.props}
                            />
                        )}
                    </BaseControlledModal>
                )}
            </Mutation>
        );
    }
}

export default CreateContestModal;
