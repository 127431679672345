import React, { Component, useContext, useRef } from 'react';
import { Route } from 'react-router-dom';
import { Button, Container, Image, Form, Menu, Modal } from 'semantic-ui-react';

import FaqModal from '../modals/faqModal';
import RequestDemoModal from '../modals/requestDemoModal';

import PRlogo from '../../images/PRlogo.svg';
import { AppContext } from '../context/AppContext';

const ButtonToNavigate = ({ component, history, newRoute }) => (
    <Menu.Item onClick={() => history.push(`/${newRoute}`)}>
        {component}
    </Menu.Item>
);

const LogoNav = () => (
    <Route
        path="/"
        render={(props) => (
            <ButtonToNavigate
                {...props}
                newRoute=""
                component={<Image src={PRlogo} height="40px" />}
            />
        )}
    />
);

let LoggedInNav = (parentProps) => {
    let { auth, notAUser, user } = parentProps;
    let faqRef = useRef(null);
    console.log({ notAUser, user });
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <LogoNav />

                {!notAUser && user && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        {user.email === 'creedjm@gmail.com' ||
                        user.email === 'lwalczew@gmail.com' ? (
                            <Route
                                path="/"
                                render={(props) => (
                                    <ButtonToNavigate
                                        {...props}
                                        newRoute="admin"
                                        component={<div>CLIENT MANAGEMENT</div>}
                                    />
                                )}
                            />
                        ) : null}
                        <Route
                            path="/"
                            render={(props) => (
                                <ButtonToNavigate
                                    {...props}
                                    newRoute=""
                                    component={<div>DASHBOARD</div>}
                                />
                            )}
                        />
                        <Route
                            path="/"
                            render={(props) => (
                                <ButtonToNavigate
                                    {...props}
                                    newRoute="schedule"
                                    component={<div>SCHEDULE</div>}
                                />
                            )}
                        />
                        <Route
                            path="/"
                            render={(props) => (
                                <ButtonToNavigate
                                    {...props}
                                    newRoute="account"
                                    component={<div>MY ACCOUNT</div>}
                                />
                            )}
                        />
                    </div>
                )}

                <Route
                    path="/"
                    render={(renderProps) => (
                        <FaqModal
                            ref={faqRef}
                            trigger={
                                <Menu.Item
                                    onClick={() => {
                                        faqRef.current.handleOpen();
                                    }}
                                >
                                    <div>FAQ</div>
                                </Menu.Item>
                            }
                        />
                    )}
                />
                <Route
                    path="/"
                    render={(renderProps) => (
                        <RequestDemoModal
                            title="Contact Us"
                            demo={false}
                            user={user}
                            triggerFunc={(setOpen) => (
                                <Menu.Item
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                >
                                    <div>CONTACT US</div>
                                </Menu.Item>
                            )}
                        />
                    )}
                />
            </div>
            <Menu.Menu position="right">
                <Menu.Item
                    onClick={() => {
                        auth.logout(parentProps, () => parentProps.update());
                    }}
                >
                    <div>LOG OUT</div>
                </Menu.Item>
            </Menu.Menu>
        </div>
    );
};

let LoggedOutNav = (parentProps) => {
    let { auth, error } = parentProps;
    const { setShowContactUsModal } = useContext(AppContext);
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <LogoNav />
                {error ? null : (
                    <ButtonToNavigate
                        {...parentProps}
                        newRoute="how-it-works"
                        component={<div>HOW IT WORKS</div>}
                    />
                )}
                {error ? null : (
                    <Route
                        path="/"
                        render={(props) => (
                            <RequestDemoModal
                                title="Contact Us"
                                demo={false}
                                triggerFunc={(setOpen) => (
                                    <Menu.Item
                                        onClick={() => {
                                            setOpen(true);
                                        }}
                                    >
                                        <div>CONTACT US</div>
                                    </Menu.Item>
                                )}
                            />
                        )}
                    />
                )}
            </div>
            <Menu.Menu position="right">
                {error ? null : (
                    <>
                        <Menu.Item
                            onClick={() => {
                                setShowContactUsModal(true);
                            }}
                        >
                            <Button primary>Start Free Trial</Button>
                        </Menu.Item>
                        <Menu.Item
                            onClick={() => {
                                auth.login(parentProps);
                            }}
                        >
                            <div>LOG IN</div>
                        </Menu.Item>
                    </>
                )}
            </Menu.Menu>
        </div>
    );
};

class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: props.loggedIn || false,
        };
    }

    update = () => {
        this.forceUpdate();
    };

    render() {
        const { error } = this.props;
        const { loggedIn } = this.state;
        return (
            <Menu fluid fixed="top" borderless>
                <Container>
                    {loggedIn ? (
                        <LoggedInNav {...this.props} update={this.update} />
                    ) : (
                        <LoggedOutNav
                            {...this.props}
                            update={this.update}
                            error={error}
                        />
                    )}
                </Container>
            </Menu>
        );
    }
}

export default Nav;
