import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import 'styled-components/macro';

const truncateString = (str: string, n: number) => {
    return str.length > n ? `${str.substr(0, n - 1)}\u2026` : str;
};

const multilineFormat = (value: string, presentOneLine = false) => {
    const values = value.split(/\r\n|\r|\n/g).map((item, index) => {
        return <p key={index}>{item}</p>;
    });
    if (presentOneLine) {
        return values[0];
    }
    return values;
};

interface EditInPlaceFieldProps {
    value?: string | number;
    disabled?: boolean;
    onUpdate: (update: any, callback: any) => void;
    placeholder?: string;
    type?: string;
    name?: string;
    label?: string;
    defaultEditing?: boolean;
    Component?: any;
    fluid?: boolean;
    preformat?: string;
    postformat?: string;
    multiline?: boolean;
    presentOneLine?: boolean;
    formatter?: (value: any) => string;
    viewChildren?: React.ReactNode;
    viewContainerCssProp?: string;
}

export const EditInPlaceField: FunctionComponent<EditInPlaceFieldProps> = (
    props
) => {
    const {
        value,
        disabled,
        onUpdate = () => {},
        placeholder,
        type = 'text',
        name,
        label,
        defaultEditing = false,
        Component = Input,
        fluid,
        preformat = '',
        postformat = '',
        multiline = false,
        presentOneLine = false,
        viewChildren,
        viewContainerCssProp,
        formatter,
    } = props;
    const [editing, setEditing] = useState(defaultEditing);
    const [tempValue, setTempValue] = useState(value);

    const fieldRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (editing) {
            fieldRef?.current?.focus?.();
        }
    }, [editing, fieldRef]);

    useEffect(() => {
        if (value !== tempValue) {
            setEditing(false);
            // will also want to add a confirmation of save or error animation here
        }
    }, [value]);

    return (
        <Form.Field>
            {label ? <label>{label}</label> : null}
            {editing ? (
                <Component
                    ref={fieldRef}
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    value={tempValue}
                    fluid={fluid}
                    onChange={(e: any, data: any) => {
                        setTempValue(data.value);
                    }}
                    onKeyPress={(e: any) => {
                        if (!multiline) {
                            if (e.key === 'Enter') {
                                if (tempValue !== value) {
                                    onUpdate(tempValue, () => {
                                        setEditing(false);
                                    });
                                } else {
                                    setEditing(false);
                                }
                            }
                        }
                    }}
                    onBlur={() => {
                        if (tempValue !== value) {
                            onUpdate(tempValue, () => {
                                setEditing(false);
                            });
                        } else {
                            setEditing(false);
                        }
                    }}
                />
            ) : (
                <div
                    css={`
                        cursor: ${disabled ? 'not-allowed' : 'pointer'};
                        ${viewContainerCssProp}
                    `}
                    onClick={() => {
                        if (!disabled) {
                            setEditing(true);
                        }
                    }}
                >
                    {viewChildren ||
                        (value && multiline
                            ? multilineFormat(
                                  typeof value === 'string'
                                      ? truncateString(value, 55)
                                      : value.toString(),
                                  presentOneLine
                              )
                            : value || value === 0
                            ? `${preformat} ${truncateString(
                                  `${formatter?.(value) || value}`,
                                  55
                              )} ${postformat}`
                            : `Click to add`)}
                </div>
            )}
        </Form.Field>
    );
};
