import React, {Component} from 'react';
import { compose, graphql } from 'react-apollo';

import SubmitContestModal from './submitContestModal';

import {
    userOrgContactListQuery
} from '../../gql/user';
import { sportsDefaultQuery } from '../../gql/defaults';

class SubmitContestModalContainer extends Component {
    render() {
        if (this.props.userOrgContactList.loading || (!this.props.sportsDefault || this.props.sportsDefault.loading)) {
            return null
        }
        return (
            <SubmitContestModal 
                {...this.props}
                userOrgContactList={this.props.userOrgContactList}
            />
        )
    }
}

let multipleGQL = compose(
    graphql(userOrgContactListQuery, {
        options: ownProps => {
            return {
                variables: {
                    orgid: ownProps.contest.orgid,
                    away_orgid: ownProps.contest.away_orgid
                },
            };
        },
        name: 'userOrgContactList'
    }),
    graphql(sportsDefaultQuery, {
        options: ownProps => {
            return {
                variables: {
                    orgid: ownProps.contest.orgid
                }
            }
        },
        skip: (ownProps) => !ownProps.contest || !ownProps.contest.orgid,
        name: 'sportsDefault'
    })
)

export default multipleGQL(SubmitContestModalContainer)