import React, { useState, useEffect } from 'react';
import {Button} from 'semantic-ui-react';
import urls from '../../urls';


function postData(url = ``, data = {}) {
    // Default options are marked with *
    return fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: {
            "Content-Type": "application/json",
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
        .then(response => {
            return response.body
        }) // parses JSON response into native Javascript objects 
        .then(body => {
            const reader = body.getReader();
            return new ReadableStream({
                start(controller) {
                    return pump();
                    function pump() {
                    return reader.read().then(({ done, value }) => {
                        // When no more data needs to be consumed, close the stream
                        if (done) {
                            controller.close();
                            return;
                        }
                        // Enqueue the next data chunk into our target stream
                        controller.enqueue(value);
                        return pump();
                    });
                    }
                }  
            })
        })
        .then(stream => {
            return new Response(stream)
        })
        .then(response => {
            return response.blob()
        })
        .then(blob => {
            return URL.createObjectURL(blob)
        })
        .then(url => url)
        .catch(err => console.log({err}))
}

let ServerImage = props => {
    let {html} = props;
    let [src, setImage] = useState(null);

    let onMakeImage = () => {        
        postData(`http://dev.perfectrecordsoftware.com/api/generate-image`, {html})
            .then((url) => {
                setImage(url)
            })
    }

    return (
        <div>
            <Button
                onClick={() => {
                    onMakeImage();
                }}
            >
                Make Image
            </Button>
            <div>
                {src && (
                    <div

                    >
                        <img 
                            src={src} 
                            style={{
                                border: '1px solid grey', 
                                boxShadow: '5px 5px 8px #aaa'
                            }}
                        />

                    </div>
                )}
            </div>
        </div>
    )
}

export default ServerImage
