import React, {useState, Component, useRef} from 'react';
import {useMutation, useQuery} from 'react-apollo-hooks';
import { Button, Checkbox, Container, Input } from "semantic-ui-react";
import {toast} from 'react-toastify';

import PolicyModal from '../modals/policyModal';
import { orgAcceptUsageAgreementMutation, orgQuery } from '../../gql/org';
import RequestDemoModal from '../modals/requestDemoModal';

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

let UsageAgreementContainer = props => {
    let {user={}} = props;
    let {orgid} = user;
    let {data, loading, error} = useQuery(orgQuery, {
        variables: {
            id: orgid  
        }
    })
    if (loading) return null
    return <UsageAgreement {...props} org={data.org} />
}

let UsageAgreement = props => {
    let {user={}, org} = props;
    let {orgid} = user;
    let disabled = !!(org && org.usage_agreement_signature);
    let [agreementTerm, setAgreementTerm] = useState(false);
    let [autoRenewal, setAutoRenewal] = useState(false);
    let [invoices, setInvoices] = useState(false);
    let [terms, setTerms] = useState(false);
    let [signature, setSignature] = useState('');
    let [termsOfService, setTermsOfService] = useState(false);

    let acceptUsageAgreement = useMutation(orgAcceptUsageAgreementMutation);
    return (
        <Container text style={{textAlign: 'left', marginTop: '15px'}}>
            <PolicyModal 
                document={'tos'}
                open={termsOfService}
                onClose={() => {setTermsOfService(false)}}
            />
            <h2 style={{ textAlign: "center", marginTop: "10px" }}>
                Perfect Record Software Usage Agreement
            </h2>
            <h2 style={{ textAlign: "center", marginTop: "10px", marginBottom: '10px' }}>
                {org.name}
            </h2>
            <div style={{ padding: "0 0 20px 10px" }}>
                You understand and agree that:
            </div>
            <div>
                <Checkbox
                    style={{ padding: "10px" }}
                    disabled={disabled}
                    label="Your subscription to Perfect Record Software starts on July 1 and ends on June 30 of the following year."
                    onChange={() => {setAgreementTerm(!agreementTerm)}}
                    checked={agreementTerm || disabled}
                />
            </div>
            <div>
                <Checkbox
                    style={{ padding: "10px" }}
                    disabled={disabled}
                    label="Invoices are sent annually and are due within 30 days."
                    onChange={() => {setAutoRenewal(!autoRenewal)}}
                    checked={autoRenewal || disabled}
                />
            </div>
            <div>
                <Checkbox
                    style={{ padding: "10px" }}
                    disabled={disabled}
                    label="Your subscription will be automatically renewed each year unless you notify us at least 30 days prior to the start of a new agreement term.  If you cancel before the end of the current term, you will retain access to the platform until the end of the current term.  No partial refunds will be allotted."
                    onChange={() => {setInvoices(!invoices)}}
                    checked={invoices || disabled}
                />
            </div>
            <div>
            <Checkbox
                style={{ padding: "10px" }}
                disabled={disabled}
                label={
                <label>
                    You accept Perfect Record's <a onClick={() => {setTermsOfService(true)}}>Terms of Service</a>.
                </label>
                }
                onChange={() => {setTerms(!terms)}}
                checked={terms || disabled}
            />
            </div>
            <div style={{ width: "50%", margin: "auto" }}>
                <Input
                    placeholder="Your name"
                    disabled={disabled}
                    label="Signature"
                    labelPosition="right"
                    value={signature || org.usage_agreement_signature}
                    onChange={(e, data) => setSignature(data.value)}
                />
                <div>{user.title}</div>
                <div>{org.name}</div>
                <div>
                    Date:{" "}
                    {disabled ? `${
                    months[new Date(org.usage_agreement_signature_date).getMonth()]
                    } ${new Date(org.usage_agreement_signature_date).getDate()}, ${new Date(org.usage_agreement_signature_date).getFullYear()}` : 
                    `${
                        months[new Date().getMonth()]
                        } ${new Date().getDate()}, ${new Date().getFullYear()}`
                    }
                </div>
            </div>
            <div style={{ textAlign: "center", paddingTop: "10px" }}>
                {disabled ? (
                    <div>
                        <RequestDemoModal 
                            title={'Contact Us'}
                            demo={false}
                            user={user}
                            triggerFunc={(setOpen) => (
                                <Button
                                    primary
                                    onClick={() => {
                                        setOpen(true)
                                    }}
                                >
                                    Contact Us
                                </Button>
                            )}
                        />
                    </div>
                ) : (
                    <Button
                        primary
                        disabled={
                        !(
                            agreementTerm &&
                            autoRenewal &&
                            invoices &&
                            terms
                        )
                        }
                        onClick={() => {
                            let meta = {
                                id: user.id,
                                email: user.email,
                                firstname: user.firstname,
                                lastname: user.lastname,
                                orgid: user.orgid,
                                title: user.title,
                                is_admin: user.is_admin
                            }
                            acceptUsageAgreement({
                                variables: {
                                    orgid,
                                    usage_agreement_signature: signature,
                                    usage_agreement_signature_date: new Date(),
                                    usage_agreement_signature_meta: JSON.stringify({
                                        ...meta
                                    })
                                },
                                refetchQueries: [
                                    {
                                        query: orgQuery,
                                        variables: {
                                            id: user.orgid
                                        }
                                    }
                                ]
                            }).then(() => {
                                toast.success("Thank you for accepting our usage agreement, and thank you for being a user of Perfect Record Software", {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 4000,
                                    hideProgressBar: true,
                                });
                                props.history.replace('account');
                            })
                        }}
                    >
                        Sign Agreement
                    </Button>
                )}
                
            </div>
        </Container>
    )
}

export default UsageAgreementContainer
