import React from "react";
import { Icon, Popup } from "semantic-ui-react";

const InfoPopup = props => {
  return (
      <Popup
        trigger={<Icon name="info circle" color="grey" inverted />}
        on="hover"
        position={props.position ? props.position : "top center"} // options are 'top left','top right','bottom right','bottom left','right center','left center','top center','bottom center
        wide={props.wide} // options are normal (no wide prop; default value), wide (wide prop with no value), or wide='very'
      >
        {props.children}
      </Popup>
  );
};

export default InfoPopup;
