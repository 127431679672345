import React, { Component } from 'react';
import Spinner from './components/utilityComponents/spinner';

class Callback extends Component {
  render() {

    return (
        <Spinner />
    );
  }
}

export default Callback;