import React, {
    Component,
    useState,
    useEffect,
    useRef,
    useContext,
} from 'react';
import {
    Button,
    Card,
    Container,
    Header,
    Image,
    List,
    Modal,
    Statistic,
} from 'semantic-ui-react';
import { Query } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import Youtube from 'react-youtube';

import RequestDemoModal from '../modals/requestDemoModal';

import Creed from '../../images/creed.jpeg';
import Erin from '../../images/erin600.jpg';
import Fencing from '../../images/fencing.jpg';
import FieldHockey from '../../images/fieldhockey.jpg';
import GettingStarted from '../../images/GettingStarted.jpg';
import Risk from '../../images/risk.jpg';
import Save from '../../images/save.jpg';
import Slide from '../../images/slide.jpg';
import Swimming from '../../images/swimming.jpg';
import Luke from '../../images/luke600.jpg';
import Schedule from '../../images/schedule.png';

import { contestsSigned } from '../../gql/contest';
import { orgsQuery } from '../../gql/org';

import PRlogo from '../../images/PRlogo.svg';
import { TutorialVideosAccordion } from '../modals/faqModal';
import Spinner from '../utilityComponents/spinner';
import { useQuery } from 'react-apollo-hooks';
import MapWrapper from '../utilityComponents/MapWrapper';
import { Map } from 'google-maps-react';
import { placeSearch } from '../../utils/googlePlaceSearch';
import { AppContext } from '../context/AppContext';

export let LandingHeading = (props) => {
    const { message } = props;
    const { setShowContactUsModal } = useContext(AppContext);
    let [loading, setLoading] = useState(true);

    return (
        <div
            className="landingHeading"
            style={{
                paddingTop: '45px',
                paddingBottom: '45px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Container
                text
                style={{
                    flex: 3,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                {message}
                <div>
                    <Header inverted>
                        <Header.Content as="h2">
                            Perfect Record Platform Overview
                            <Header.Subheader>
                                Scroll to the bottom of this page to see more
                                tutorial videos
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </div>
                <div
                    style={{
                        marginTop: '24px',
                        marginBottom: '24px',
                        height: '360px',
                        position: 'relative',
                    }}
                >
                    <Youtube
                        videoId="Z9rc-BPu23Q"
                        opts={{
                            playerVars: {
                                rel: 0,
                            },
                        }}
                        onReady={() => setLoading(false)}
                    />
                    {loading && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                            }}
                        >
                            <Spinner inverted />
                        </div>
                    )}
                </div>
                <div style={{ width: '75%' }}>
                    <div>
                        <Button
                            primary
                            fluid
                            size="huge"
                            onClick={() => {
                                setShowContactUsModal(true);
                            }}
                        >
                            Interested? Start your free trial!
                        </Button>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <RequestDemoModal
                            title="Contact Us"
                            demo={false}
                            triggerFunc={(setOpen) => (
                                <Button
                                    size="huge"
                                    fluid
                                    color="teal"
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                >
                                    Questions? Contact us!
                                </Button>
                            )}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

const sortClientOrgs = (orgs) => {
    let orgsSorted = orgs.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        } else if (a.name > b.name) {
            return 1;
        } else {
            return 0;
        }
    });
    let groupedOrgs = {};
    orgsSorted.forEach((org) => {
        if (!org.hide_client) {
            if (groupedOrgs[org.conference]) {
                groupedOrgs[org.conference].push(org.name);
            } else {
                groupedOrgs[org.conference] = [org.name];
            }
        }
    });
    let conferencesSorted = Object.keys(groupedOrgs).sort();
    return {
        conferencesSorted,
        groupedOrgs,
        orgCount: orgsSorted.length,
        conferenceCount: conferencesSorted.length,
        orgsSorted,
    };
};

const MapContainer = (props) => {
    console.log({ props });
    const { orgs } = props;
    useEffect(() => {
        if (orgs && orgs.length) {
            placeSearch(orgs);
        }
    }, [JSON.stringify(orgs)]);
    return (
        <>
            <div></div>
            <div id="map" style={{ height: '400px', width: '100%' }} />
        </>
        // <Map
        //     google={window.google}
        //     zoom={4}
        //     style={{
        //         width: '100%',
        //         height: '100%'
        //     }}
        //     containerStyle={{
        //         position: 'relative',
        //         width: '100%',
        //         height: '400px'
        //     }}
        //     initialCenter={{
        //         lat: 39.8283,
        //         lng: -98.5795
        //     }}
        // >

        // </Map>
    );
};

const WrappedMap = MapWrapper(MapContainer);

export const ClientMap = () => {
    const { data, loading, error } = useQuery(orgsQuery, {
        variables: {
            clients: true,
        },
    });
    if (loading || error) {
        return null;
    }
    let {
        conferencesSorted,
        groupedOrgs,
        orgsSorted,
        orgCount,
        conferenceCount,
    } = sortClientOrgs(data.orgs);

    return (
        <div>
            <div>
                <MapContainer orgs={orgsSorted} />
                <div style={{ marginTop: '20px' }}>
                    <Clients />
                </div>
            </div>
        </div>
    );
};

const Clients = () => {
    const { data, loading, error } = useQuery(orgsQuery, {
        variables: {
            clients: true,
        },
    });
    if (loading || error) {
        return null;
    }
    let { conferencesSorted, groupedOrgs, orgCount, conferenceCount } =
        sortClientOrgs(data.orgs);

    return (
        <div>
            <div
                style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    fontSize: '18px',
                }}
            >
                {`${orgCount} institutions from ${conferenceCount} conferences`}
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                    lineHeight: '1',
                }}
            >
                {conferencesSorted.map((conference, conferenceIndex) => (
                    <div
                        key={conferenceIndex}
                        style={{
                            marginTop: '15px',
                            marginLeft: '5px',
                            flex: '1 1 250px',
                        }}
                    >
                        <div
                            style={{
                                fontWeight: 'bold',
                            }}
                        >
                            {conference}
                        </div>
                        <div
                            style={{
                                marginLeft: '5px',
                            }}
                        >
                            {groupedOrgs[conference].map((name, nameIndex) => (
                                <div key={nameIndex}>{name}</div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const LandingSection = ({ children, odd }) => (
    <div
        className={`landingSection ${odd && `landingSectionOdd`}`}
        style={{
            backgroundColor: !odd ? '#f6f6f6' : 'white',
            paddingTop: '35px',
            paddingBottom: '35px',
        }}
    >
        <Container>
            <div
                style={{
                    display: 'flex',
                    flexDirection: odd ? 'row' : 'row-reverse',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}
            >
                {children}
            </div>
        </Container>
    </div>
);

const SectionContent = ({ title, content, media }) => {
    return (
        <>
            <div
                style={{
                    flex: 1,
                    padding: '0 45px',
                    textAlign: 'left',
                }}
            >
                <Header as="h2">{title}</Header>
                {content}
            </div>
            {media && (
                <div
                    style={{
                        flex: 1,
                        padding: '0 45px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {media}
                </div>
            )}
        </>
    );
};

const sections = (demoRef) => [
    {
        content: (
            <SectionContent
                title={'Where Perfect Record is Being Used'}
                content={<ClientMap />}
            />
        ),
    },
    {
        content: (
            <SectionContent
                title={'Pricing'}
                content={
                    <List bulleted>
                        <List.Item>
                            Free trial period &mdash; you can use the platform
                            for at least one full season (fall/winter/spring)
                            without payment or commitment. We are confident that
                            our platform is a huge improvement, and we want you
                            to share that confidence before charging you
                        </List.Item>
                        <List.Item>
                            The more the merrier: We can work with entire
                            conferences at reduced rates for each organization
                        </List.Item>
                        <List.Item>
                            We understand that may still be budgetary
                            uncertainty due to the COVID-19 pandemic. We want to
                            be your partners and understand that things are not
                            “normal” and we are willing to work with you
                        </List.Item>
                    </List>
                }
                media={
                    <Card>
                        <Card.Header>
                            <div
                                style={{
                                    padding: '16px 0',
                                }}
                            >
                                <Header as="h3">Annual License Cost</Header>
                            </div>
                        </Card.Header>
                        <Card.Content>
                            <Statistic
                                value="$100"
                                label={
                                    <div>
                                        <Header>per sport</Header>
                                    </div>
                                }
                            />
                        </Card.Content>
                    </Card>
                }
            />
        ),
    },
    {
        content: (
            <SectionContent
                title={'Streamline Your Contracting Process'}
                content={
                    <>
                        <div>
                            Perfect Record replaces a largely manual process and
                            makes you more efficient with fast, easy contract
                            creation and tracking. Notable features:
                        </div>
                        <List bulleted>
                            <List.Item>
                                Instant conversion of a spreadsheet of multiple
                                contests into a full set of game contracts (bulk
                                upload)
                            </List.Item>
                            <List.Item>
                                Automatically include default information for
                                most contract terms on a sport-by-sport basis
                                (location, officials, TV/radio rights,
                                cancellation terms, etc.)
                            </List.Item>
                            <List.Item>
                                Easy collection of signatures for all
                                signatories, both home and away
                            </List.Item>
                            <List.Item>
                                Automatic identification of the correct contract
                                recipient at the opposing school, including that
                                person’s contact information
                            </List.Item>
                            <List.Item>
                                Automatic reminders sent to opposing schools
                                about incomplete contracts
                            </List.Item>
                            <List.Item>
                                Notification of upcoming contests with
                                incomplete contracts
                            </List.Item>
                            <List.Item>
                                Away contract management: track and store
                                contracts received for away contests, even if
                                the opposing school is not a Perfect Record user
                            </List.Item>
                            <List.Item>
                                One central repository for all game contracts,
                                both home and away
                            </List.Item>
                            <List.Item>
                                Unlimited number of user accounts for your
                                department, with sport-by-sport permission
                                levels that control what each user can see and
                                do
                            </List.Item>
                        </List>
                    </>
                }
                media={<Image src={Swimming} width="100%" rounded />}
            />
        ),
    },
    {
        content: (
            <SectionContent
                title={'Save on Employee Time Costs'}
                content={
                    <>
                        <div>
                            Budget-conscious departments understand being asked
                            to do more with less. Perfect Record allows athletic
                            departments to reduce employee time spent on game
                            contract administration, freeing them up to focus on
                            other departmental priorities. In an athletic
                            department with 20 sports:
                        </div>
                        <List bulleted>
                            <List.Item>
                                Administrators spend approximately 5 hours per
                                week generating and managing game contracts
                            </List.Item>
                            <List.Item>
                                Using Perfect Record reduces the time on task by
                                70-80%
                            </List.Item>
                            <List.Item>
                                The department saves approximately $5,000 –
                                $7,000 per year in employee time by using
                                Perfect Record Software
                            </List.Item>
                        </List>
                    </>
                }
                media={<Image src={Save} width="100%" rounded />}
            />
        ),
    },
    {
        content: (
            <SectionContent
                title={'Reduce Your Risk'}
                content={
                    <>
                        <List bulleted>
                            <List.Item>
                                Automatic tracking and notifications &rarr;
                                increased reliability
                            </List.Item>
                            <List.Item>
                                Doing contracts the old way takes a lot of work,
                                and some contracts inevitably fall through the
                                cracks
                            </List.Item>
                            <List.Item>
                                Mistakes due to incomplete or inaccurate
                                contracts are embarrassing and costly
                            </List.Item>
                            <List.Item>
                                If you save one mistaken bus trip, you likely
                                have already saved the annual usage fee of the
                                Perfect Record platform, even setting aside all
                                of the other benefits
                            </List.Item>
                            <List.Item>
                                Perfect Record gives you confidence that a
                                completed, signed contract is in place for every
                                one of your contests, before the contest occurs
                            </List.Item>
                        </List>
                    </>
                }
                media={<Image src={Risk} width="100%" rounded />}
            />
        ),
    },
    {
        content: (
            <SectionContent
                title={'Access Your Centralized Records Anywhere'}
                content={
                    <>
                        <List bulleted>
                            <List.Item>
                                Keep all your game contracts (both home and
                                away) in one central, easily-accessible location
                            </List.Item>
                            <List.Item>
                                Maintain efficiency when working remotely
                                &mdash; a benefit that has become very clear to
                                our users since the COVID-19 pandemic
                            </List.Item>
                            <List.Item>
                                Adjust user accounts and permission levels at
                                any time as game contract responsibilities shift
                                among staff members
                            </List.Item>
                            <List.Item>
                                Contracts are centralized to your institution,
                                not individual users, simplifying transitions
                                from departing employees
                            </List.Item>
                            <List.Item>
                                Easily access past years’ contracts, opponents,
                                and other information from the platform
                            </List.Item>
                        </List>
                    </>
                }
                media={<Image src={Slide} width="100%" rounded />}
            />
        ),
    },
    {
        content: (
            <SectionContent
                title={'Getting Started'}
                content={
                    <>
                        <List bulleted>
                            <List.Item>
                                Click the button above to start your free trial
                            </List.Item>
                            <List.Item>
                                We will set up a quick call to answer any
                                questions that you have and enable your account
                            </List.Item>
                            <List.Item>
                                You will have a free trial try out the platform
                                with real contracts - no charge, no commitment,
                                no risk
                            </List.Item>
                            <List.Item>
                                We will work with you to ensure our platform
                                fills your organizations needs
                            </List.Item>
                            <List.Item>
                                After the trial period concludes, if you would
                                like to continue using the platform, we will
                                work with you to develop a billing schedule
                                according to the pricing above
                            </List.Item>
                        </List>
                        <RequestDemoModal
                            demo={true}
                            ref={demoRef}
                            title={'Contact Us'}
                            demo={false}
                            trigger={
                                <Button
                                    primary
                                    fluid
                                    size="huge"
                                    onClick={() => {
                                        demoRef.current.handleOpen({
                                            messagePlaceholder:
                                                'I want to get started with Perfect Record!',
                                        });
                                    }}
                                >
                                    Get Started with Perfect Record
                                </Button>
                            }
                        />
                    </>
                }
                media={<Image src={GettingStarted} width="100%" rounded />}
            />
        ),
    },
    {
        content: (
            <SectionContent
                title={'Additional details about how our platform works:'}
                content={<TutorialVideosAccordion />}
            />
        ),
    },
];

export const LandingSections = ({ demoRef }) => {
    return (
        <>
            {sections(demoRef).map((section, index) => {
                return (
                    <LandingSection odd={!(index % 2)}>
                        {section.content}
                    </LandingSection>
                );
            })}
        </>
    );
};

export const LandingContainer = (props) => {
    let { auth, notAUser, isVerified, loggedIn } = props;
    const demoRef = useRef(null);
    let [showNotAUserModal, setShowNotAUserModal] = useState(notAUser);
    let [showVerifyEmailModal, setShowVerifyEmailModal] = useState(
        !isVerified && loggedIn && !notAUser
    );
    return (
        <div>
            <RequestDemoModal
                demo={true}
                ref={demoRef}
                title={'Request a Demo'}
                demo={false}
            />
            <LandingHeading />
            <LandingSections demoRef={demoRef} />
        </div>
    );
};

const HowItWorks = (props) => {
    return (
        <div>
            <LandingContainer />
        </div>
    );
};

export default HowItWorks;
