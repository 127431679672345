import React from 'react';

export let AttributeRow = props => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        }}
    >
        <div
          style={{
              textAlign: 'left'
          }}  
        >
            {props.label}
        </div>
        <div
            style={{
                textAlign: 'right'
            }}
        >
            {props.value}
        </div>
    </div>
)
