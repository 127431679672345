import React, {
    Component,
    useState,
    useEffect,
    useRef,
    useContext,
} from 'react';
import {
    Button,
    Card,
    Container,
    Header,
    Image,
    List,
    Modal,
    Statistic,
} from 'semantic-ui-react';
import { Query } from 'react-apollo';
import { Redirect, Link } from 'react-router-dom';
import YouTube from 'react-youtube';

import RequestDemoModal from '../modals/requestDemoModal';

import Creed from '../../images/creed.jpeg';
import Erin from '../../images/erin600.jpg';
import Fencing from '../../images/fencing.jpg';
import Laptop from '../../images/laptop.jpg';
import Luke from '../../images/luke600.jpg';
import Schedule from '../../images/schedule.png';

import { contestsSigned } from '../../gql/contest';
import { orgsQuery } from '../../gql/org';

import Spinner from '../utilityComponents/spinner';
import PRlogo from '../../images/PRlogo.svg';
import { AppContext } from '../context/AppContext';

let LandingHeading = (props) => {
    let [loading, setLoading] = useState(true);
    const { setShowContactUsModal } = useContext(AppContext);
    return (
        <div
            className="landingHeading"
            style={{
                paddingTop: '72px',
                paddingBottom: '45px',
                height: '700px',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: '50px',
                    right: '50px',
                }}
            >
                <Query query={contestsSigned}>
                    {({ loading, error, data }) => {
                        if (loading || error) {
                            return null;
                        }
                        return (
                            <Statistic
                                inverted
                                value={parseInt(
                                    data.contestsSigned
                                ).toLocaleString()}
                                label={
                                    <div
                                        style={{
                                            color: 'white',
                                        }}
                                    >
                                        <div>Contracts Signed</div>
                                        <div>with Perfect Record</div>
                                    </div>
                                }
                            />
                        );
                    }}
                </Query>
            </div>

            <Container
                text
                style={{
                    flex: 3,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Header
                    as="h1"
                    content="Game Contracts Simplified"
                    size="huge"
                    inverted
                />
                <div
                    style={{
                        marginTop: '16px',
                        marginBottom: '24px',
                        height: '360px',
                        position: 'relative',
                    }}
                >
                    <YouTube
                        videoId="8ec4E5FgXN8"
                        opts={{
                            playerVars: {
                                rel: 0,
                            },
                        }}
                        onReady={() => setLoading(false)}
                    />
                    {loading && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                            }}
                        >
                            <Spinner inverted />
                        </div>
                    )}
                </div>
                <div
                    style={{
                        color: 'white',
                        width: '75%',
                    }}
                >
                    <div>
                        <Button
                            primary
                            fluid
                            size="huge"
                            onClick={() => {
                                setShowContactUsModal(true);
                            }}
                        >
                            Interested? Start your free trial!
                        </Button>
                    </div>
                    <div style={{ marginTop: '16px' }}>
                        <Link to="/how-it-works">
                            <Button color="teal" fluid size="huge">
                                Learn more about how it works
                            </Button>
                        </Link>
                    </div>
                </div>
            </Container>
        </div>
    );
};

const LandingSection = ({ children, odd }) => (
    <div
        className={`landingSection ${odd && `landingSectionOdd`}`}
        style={{
            backgroundColor: odd ? '#f6f6f6' : 'white',
            paddingTop: '35px',
            paddingBottom: '35px',
        }}
    >
        <Container>
            <div
                style={{
                    display: 'flex',
                    flexDirection: odd ? 'row' : 'row-reverse',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}
            >
                {children}
            </div>
        </Container>
    </div>
);

const LandingFoundingStory = () => (
    <LandingSection odd={true}>
        <Container>
            <Card fluid>
                <Card.Content header={`Founding Story`} />
                <Card.Content
                    description={`
                    Sitting in his office at Harvard Athletics back in 2014, our founder Luke Walczewski was
                    frustrated with the inefficiency of the college game contracting system. Keeping track of paper
                    contracts that were going out to opponents, coming in from other schools, and sitting in piles
                    on his desk wasn’t a good use of his time. So in 2015, he built a team and entered a startup
                    competition for innovation in sports, ending with a top five finish. After a brief hiatus during
                    the 2015-2016 year when he was tapped to serve as an interim dean at Harvard College, Luke came back
                    to his idea, reconstituted a team, and built an online platform for athletic schedulers everywhere
                    to streamline the process and make life a little easier.
                `}
                />
            </Card>
        </Container>
    </LandingSection>
);

const LandingFounder = ({ header, description, image }) => (
    <Card>
        <Image src={image} />
        <div
            style={{
                height: '50px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <Header>{header}</Header>
        </div>
        <Card.Content description={description} />
    </Card>
);

const LandingFounders = () => (
    <LandingSection odd={true}>
        <Card.Group centered>
            <LandingFounder
                image={Luke}
                header={`Luke — CEO`}
                description={`A former Assistant Director of Athletics, Luke worked for the Harvard Department
                of Athletics in different positions for five years, covering everything from travel
                to scheduling and everything in between. Luke holds a Master in Higher Education
                from Harvard University, a J.D. from Washington University School of Law in St. Louis,
                and both a B.S. and a B.A. in finance from Truman State University, where he played football
                and rugby.`}
            />
            <LandingFounder
                image={Creed}
                header={`Creed — CTO`}
                description={`A former captain of the MIT varsity baseball team, Creed
                combines his interest in innovative technology and his passion for sports in
                his position as our Chief Technology Officer and lead developer. He holds
                a B.S. in computer science and biology from MIT.`}
            />
            <LandingFounder
                image={Erin}
                header={`Erin — COO`}
                description={`Erin has worked with startups on business and legal strategy since 2009, and
                she teaches courses on both innovation and problem-solving at Harvard Law School.
                Erin earned her J.D. from Harvard Law School and her B.S. in political science
                from Truman State University.`}
            />
        </Card.Group>
    </LandingSection>
);

let NotAUserModal = (props) => {
    let { open, handleClose = () => {}, handleRequestDemo = () => {} } = props;

    return (
        <Query query={contestsSigned}>
            {({ loading, error, data }) => {
                if (loading || error) {
                    return null;
                }
                return (
                    <Modal size={'tiny'} open={open}>
                        <Modal.Header>{`Not a registered user?`}</Modal.Header>
                        <Modal.Content>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    margin: '15px 0 25px',
                                }}
                            >
                                <Image src={PRlogo} height="65px" />
                            </div>
                            <div>
                                {`It looks like you tried to sign up with an unregistered email address.  If your school already uses Perfect Record, please contact the primary user from your school and ask them to create an account for you.`}
                            </div>
                            <Query
                                query={orgsQuery}
                                variables={{ clients: true }}
                            >
                                {({ data: orgsData, loading: orgsLoading }) => {
                                    if (orgsLoading) {
                                        return null;
                                    }
                                    return (
                                        <div
                                            style={{
                                                marginTop: '15px',
                                            }}
                                        >
                                            {`If your school does not use Perfect Record currently, give us a try! With over ${
                                                orgsData.orgs &&
                                                Math.floor(
                                                    orgsData.orgs.length / 5
                                                ) * 5
                                            } colleges/universities as clients and over ${
                                                Math.floor(
                                                    data.contestsSigned / 100
                                                ) * 100
                                            } contracts signed through our platform, we would love to help you modernize and streamline your game contract workflow. To learn more about how our platform works, please click the button below to request a demo!`}
                                        </div>
                                    );
                                }}
                            </Query>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={handleClose}>Close</Button>
                            <Button
                                primary
                                onClick={() => {
                                    handleRequestDemo();
                                }}
                            >
                                Request Demo
                            </Button>
                        </Modal.Actions>
                    </Modal>
                );
            }}
        </Query>
    );
};

let VerifyEmailModal = (props) => {
    let { open, handleClose = () => {} } = props;

    return (
        <Modal size={'tiny'} open={open}>
            <Modal.Header
                style={{
                    display: 'flex',
                }}
            >
                <div
                    style={{
                        flex: 1,
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    {`Please verify your email`}
                </div>
            </Modal.Header>
            <Modal.Content>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '15px 0 25px',
                    }}
                >
                    <Image src={PRlogo} height="65px" />
                </div>
                <div>
                    You must verify your email before accessing the platform.
                    Please check your email for a message from{' '}
                    <a href="mailto:no-reply@perfectrecordsoftware.com">
                        no-reply@perfectrecordsoftware.com
                    </a>
                    , and verify your email by clicking the link contained in
                    the message.
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={handleClose}>Close</Button>
            </Modal.Actions>
        </Modal>
    );
};

const LandingContainer = (props) => {
    if (props.location.hash && props.location.hash.indexOf('#') >= 0) {
        let redirectUrl = props.location.hash.split('#')[1];
        return <Redirect to={redirectUrl} />;
    }
    let { auth, notAUser, isVerified, loggedIn } = props;
    let [showNotAUserModal, setShowNotAUserModal] = useState(notAUser);
    let [showVerifyEmailModal, setShowVerifyEmailModal] = useState(
        !isVerified && loggedIn && !notAUser
    );
    let demoRef = useRef(null);
    return (
        <div>
            <NotAUserModal
                open={showNotAUserModal}
                handleClose={() => setShowNotAUserModal(false)}
                handleRequestDemo={() => {
                    setShowNotAUserModal(false);
                    demoRef.current.handleOpen();
                }}
            />
            <VerifyEmailModal
                open={showVerifyEmailModal}
                handleClose={() => {
                    auth.logout();
                    setShowVerifyEmailModal(false);
                }}
            />
            <RequestDemoModal
                demo={true}
                ref={demoRef}
                title={'Request a Demo'}
                demo={false}
            />
            <LandingHeading />
            <LandingSection odd={true}>
                <div
                    style={{
                        flex: 1,
                        paddingLeft: '45px',
                        paddingRight: '45px',
                        textAlign: 'left',
                    }}
                >
                    <Header as="h2" content={`Easy Contract Exchange`} />

                    <List bulleted>
                        <List.Item>
                            You send electronic contracts to opposing schools
                            with the click of a button—no more paper!
                        </List.Item>
                        <List.Item>
                            Opposing schools receive a link to the contract by
                            email and can electronically sign in seconds
                        </List.Item>
                        <List.Item>
                            Both sides immediately receive a copy of the signed
                            contract by email
                        </List.Item>
                    </List>
                </div>
                <div
                    style={{
                        flex: 1,
                        paddingLeft: '45px',
                        paddingRight: '45px',
                    }}
                >
                    <Image src={Laptop} width="100%" />
                </div>
            </LandingSection>
            <LandingSection odd={false}>
                <div
                    style={{
                        flex: 1,
                        paddingLeft: '45px',
                        paddingRight: '45px',
                        textAlign: 'left',
                    }}
                >
                    <Header as="h2" content={`Automatic Contract Tracking`} />
                    <List bulleted>
                        <List.Item>
                            Effortlessly view the completion status of all of
                            your contracts with our simple tracking interface
                        </List.Item>
                        <List.Item>
                            Receive automatic notifications of your most
                            important contract to-dos with our intuitive user
                            dashboard
                        </List.Item>
                        <List.Item>
                            With this information at your fingertips, have
                            confidence that your contracts will always be
                            accurate and complete
                        </List.Item>
                    </List>
                </div>
                <div
                    style={{
                        flex: 1,
                        paddingLeft: '45px',
                        paddingRight: '45px',
                    }}
                >
                    <Image src={Schedule} width="100%" />
                </div>
            </LandingSection>
            <LandingSection odd={true}>
                <div
                    style={{
                        flex: 1,
                        paddingLeft: '45px',
                        paddingRight: '45px',
                        textAlign: 'left',
                    }}
                >
                    <Header
                        as="h2"
                        content={`Risk Management and Efficiency`}
                    />
                    <List bulleted>
                        <List.Item>
                            Never again worry about missing or incomplete
                            contracts, avoiding divisive and stressful contest
                            disputes
                        </List.Item>
                        <List.Item>
                            Your contracts can appear exactly the same as they
                            do currently, no need to get approval for a new
                            format
                        </List.Item>
                        <List.Item>
                            Time that was previously spent pushing paper can now
                            be spent doing what you do best—supporting your
                            teams and your department
                        </List.Item>
                    </List>
                </div>
                <div
                    style={{
                        flex: 1,
                        paddingLeft: '45px',
                        paddingRight: '45px',
                    }}
                >
                    <Image src={Fencing} width="100%" />
                </div>
            </LandingSection>
            <LandingFoundingStory />
            <LandingFounders />
        </div>
    );
};

export default LandingContainer;
