import React, { Component } from 'react';
import { Button, Modal, Dropdown, Form, TextArea } from 'semantic-ui-react';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import TextField from 'material-ui/TextField';
import Select from 'react-select';

export const ModalAction = (props: any) => {
    return <Button {...props}>{props.title}</Button>;
};

export const ModalFormInput = (props: any) => (
    <Form.Input
        name={props.name.toLowerCase()}
        defaultValue={props.defaultValue}
        type="text"
        fluid
        required={props.required}
        placeholder={props.placeholder || props.name}
        onChange={(event, { name, value }) => props.onChange(name, value)}
    />
);

export const ModalMaterialInput = (props: any) => {
    return (
        <TextField
            name={props.name.toLowerCase()}
            type="text"
            hintText={props.name}
        />
    );
};

export const ModalFormTextArea = (props: any) => (
    <Form.TextArea
        name={props.name.toLowerCase()}
        defaultValue={props.defaultValue}
        required={props.required}
        placeholder={props.placeholder || props.name}
        onChange={(event, { name, value }) => props.onChange(name, value)}
    />
);

export const ModalOpponentDropdownTypeahead = (props: any) => (
    <Dropdown
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        multiple={props.multiple}
        closeOnChange={true}
        fluid
        search
        searchQuery={props.opponentSearchQuery}
        onSearchChange={
            props.onChangeSearchQuery
                ? (e, { searchQuery }) => props.onChangeSearchQuery(searchQuery)
                : () => {}
        }
        selection
        required={props.required}
        options={props.options}
        value={props.value}
        onChange={(event, { value }) => {
            props.onChange(props.name, value);
            props.onChangeSearchQuery('');
        }}
    />
);

export const ModalDropdownTypeahead = (props: any) => (
    <Dropdown
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        multiple={props.multiple}
        closeOnChange={true}
        fluid
        selection
        required={props.required}
        options={props.options}
        value={props.value}
        onChange={(event, { value }) => {
            props.onChange(props.name, value);
        }}
    />
);

let errorStyles = {
    control: (base: any) => ({
        ...base,
        background: '#efcfcf',
        borderColor: '#e0b4b4',
    }),
    placeholder: (base: any) => ({
        ...base,
        color: '#9f3a38',
    }),
};

interface ModalSelectProps {
    [key: string]: any;
    placeholder: string;
    isMulti?: boolean;
    onNoOptionClick?: (inputValue?: string) => void;
}

export const ModalSelect = (props: ModalSelectProps) => {
    const { defaultValue, onNoOptionClick } = props;
    let styles = {
        control: (base: any) => ({
            ...base,
            background: 'white',
        }),
        valueContainer: (base: any) => ({
            ...base,
            paddingLeft: '12px',
            paddingRight: '12px',
        }),
        menu: (base: any) => ({
            ...base,
            zIndex: 5,
        }),
    };
    if (props.error) {
        Object.assign(styles, errorStyles);
    }

    const CustomNoOptionsMessage = (props) => {
        const { selectProps } = props;
        return onNoOptionClick ? (
            <div
                style={{
                    cursor: 'pointer',
                    padding: '8px',
                    paddingLeft: '15px',
                }}
                onClick={() => {
                    onNoOptionClick(selectProps?.inputValue || '');
                }}
            >
                <strong>{selectProps?.inputValue || 'Your choice'}</strong>
                {` could not be found. Click here to request addition.`}
            </div>
        ) : (
            <div>
                <strong>{selectProps?.inputValue || ''}</strong>
                {` could not be found.`}
            </div>
        );
    };

    return (
        <Select
            name={props.name}
            isMulti={props.isMulti}
            placeholder={props.placeholder}
            components={{ NoOptionsMessage: CustomNoOptionsMessage }}
            defaultValue={defaultValue}
            value={
                props.grouped
                    ? props.ungroupedOptions.find(
                          (option: any) => option.value === props.value
                      )
                    : props.options.find(
                          (option: any) => option.value === props.value
                      )
            }
            options={props.options}
            styles={styles}
            formatGroupLabel={props.formatGroupLabel}
            onChange={(value) => {
                props.onChange(props.name, value);
            }}
        />
    );
};

export const BaseModal = (props: any) => (
    <Modal size={props.size || 'large'} trigger={props.trigger} closeIcon>
        <Modal.Header>{props.header}</Modal.Header>
        <Modal.Content>{props.children}</Modal.Content>
        <Modal.Actions>{props.actions}</Modal.Actions>
    </Modal>
);

export const BaseControlledModal = (props: any) => {
    let {
        closeOnDimmerClick = true,
        closeIcon = true,
        centered = true,
    } = props;
    return (
        <Modal
            size={props.size || 'large'}
            trigger={props.trigger}
            open={props.open}
            onClose={props.onClose}
            closeOnDimmerClick={closeOnDimmerClick}
            closeIcon={closeIcon}
            centered={centered}
        >
            <Modal.Header>{props.header}</Modal.Header>
            <Modal.Content>{props.children}</Modal.Content>
            <Modal.Actions>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {props.actions}
                    </div>
                    {props.leftActions && props.leftActions.length > 0 && (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                            }}
                        >
                            {props.leftActions}
                        </div>
                    )}
                </div>
            </Modal.Actions>
        </Modal>
    );
};
