import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Container, Dropdown, Header, Icon } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import DatePicker from 'material-ui/DatePicker';

import { contestsQuery } from '../../gql/contest';
import { statuses } from '../../utils/statuses';
import { getDateDiffInDays } from '../../utils/helper';

import { ScheduleGrid } from '../schedule/schedule';
import EditContestModal from '../modals/editContestModal';
import SubmitContestModalContainer from '../modals/submitContestModalContainer';
import Spinner from '../utilityComponents/spinner';
import ViewSignedModal from '../modals/viewSignedModal';

import { colors } from '../../utils/colors';
import ContestHistoryModal from '../schedule/contestHistoryModal';
import ContestStatusModalContainer from '../modals/contestStatusModal';

let sectionHeaderStyle = {
    textAlign: 'left',
    margin: '20px',
    borderBottom: `2px solid ${colors.greyBorder}`,
};

const AwaitingSignature = (props) => (
    <Query
        query={contestsQuery}
        fetchPolicy="network-only"
        variables={{
            user_id: props.user.id,
            orgid: props.user.orgid,
            homeOrAway: 'away',
            past: false,
            statuses: [
                statuses.SUBMITTED,
                statuses.SUBMITTED_NOTCLICKED,
                statuses.SUBMITTED_INTERNAL_AWAY,
                statuses.SUBMITTED_INTERNAL_AWAY_COMPLETE,
                statuses.SUBMITTED_AWAY_DIFFERENT_SIGNER,
            ],
        }}
    >
        {({ loading, error, data, refetch, variables }) => {
            if (loading) {
                return <Spinner />;
            }
            if (error) {
                return <div>Something went wrong</div>;
            }

            return (
                <div>
                    <div style={sectionHeaderStyle}>
                        <Header as="h2">
                            Contracts Awaiting Your Signature
                        </Header>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        }}
                    >
                        <div
                            style={{
                                flex: 6,
                                paddingLeft: '10px',
                                paddingRight: '10px',
                            }}
                        >
                            <ScheduleGrid
                                contests={data.contests}
                                emptyMessage={`No contracts are awaiting your signature`}
                                history={props.history}
                                user={props.user}
                                orgs={props.orgs}
                                permissions={props.permissions}
                                onShowModal={(modal, contests) =>
                                    props.onShowModal(modal, contests, refetch)
                                }
                            />
                        </div>
                    </div>
                </div>
            );
        }}
    </Query>
);

const UpcomingIncomplete = (props) => {
    let today = new Date();
    let variables = {
        user_id: props.user.id,
        orgid: props.user.orgid,
        past: false,
        dateFilterStart: today,
        dateFilterEnd: props.dateFilterEnd || today.setDate(today + 30),
        statuses: [
            statuses.SUBMITTED,
            statuses.SUBMITTED_NOTCLICKED,
            statuses.SUBMITTED_INTERNAL_AWAY,
            statuses.SUBMITTED_INTERNAL_AWAY_COMPLETE,
            statuses.SUBMITTED_AWAY_DIFFERENT_SIGNER,
            statuses.SUBMITTED_DIFFERENT_SIGNER,
            statuses.SUBMITTED_INTERNAL_HOME,
            statuses.EDITED,
            statuses.AWAY_PLACEHOLDER,
            statuses.HOME_PAPER_CONTRACT,
            statuses.HOME_PAPER_CONTRACT_SENT,
        ],
    };
    return (
        <Query
            query={contestsQuery}
            fetchPolicy="network-only"
            variables={variables}
        >
            {({ loading, error, data, refetch, variables }) => {
                if (loading) {
                    return <Spinner />;
                }
                if (error) {
                    return <div>Something went wrong</div>;
                }

                let timeOptions = [10, 30, 60, 90].map((number) => ({
                    text: number,
                    value: number,
                }));

                return (
                    <div>
                        <div style={sectionHeaderStyle}>
                            <Header as="h2">Incomplete Contracts</Header>
                        </div>
                        <div
                            style={{
                                display: 'block',
                                textAlign: 'left',
                                margin: '0 20px 20px',
                            }}
                        >
                            <div>
                                <span
                                    style={{
                                        marginRight: '15px',
                                    }}
                                >
                                    {`Contests with unsigned contracts within`}
                                </span>
                                <Dropdown
                                    value={props.upcomingIncompleteDays}
                                    defaultValue={30}
                                    inline
                                    options={timeOptions}
                                    onChange={(e, data) => {
                                        let date = new Date();
                                        date.setDate(
                                            date.getDate() + data.value
                                        );
                                        props.onSetFilter({
                                            dateFilterEnd: date,
                                            upcomingIncompleteDays: data.value,
                                        });
                                    }}
                                />
                                <span
                                    style={{
                                        marginLeft: '5px',
                                    }}
                                >
                                    {`days of today`}
                                </span>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                            }}
                        >
                            <div
                                style={{
                                    flex: 6,
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                }}
                            >
                                <ScheduleGrid
                                    contests={data.contests}
                                    history={props.history}
                                    user={props.user}
                                    orgs={props.orgs}
                                    permissions={props.permissions}
                                    emptyMessage={`No incomplete contracts in this time frame`}
                                    onShowModal={(modal, contests) =>
                                        props.onShowModal(
                                            modal,
                                            contests,
                                            refetch
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                );
            }}
        </Query>
    );
};

const InactiveContracts = (props) => (
    <Query
        query={contestsQuery}
        fetchPolicy="network-only"
        variables={{
            user_id: props.user.id,
            orgid: props.user.orgid,
            past: false,
            lastActivity: props.lastActivityTime,
            statuses: [
                statuses.SUBMITTED_INTERNAL_HOME,
                statuses.SUBMITTED_DIFFERENT_SIGNER,
                statuses.SUBMITTED,
                statuses.SUBMITTED_NOTCLICKED,
                statuses.SUBMITTED_INTERNAL_AWAY,
                statuses.SUBMITTED_AWAY_DIFFERENT_SIGNER,
                statuses.SUBMITTED_INTERNAL_AWAY_COMPLETE,
            ],
        }}
    >
        {({ loading, error, data, refetch, variables }) => {
            if (loading) {
                return <Spinner />;
            }
            if (error) {
                return <div>Something went wrong</div>;
            }
            let timeOptions = [5, 10, 15, 20, 25, 30, 60, 90].map((number) => ({
                text: number,
                value: number,
            }));
            return (
                <div>
                    <div style={sectionHeaderStyle}>
                        <Header as="h2">Inactive Contracts</Header>
                    </div>
                    <div
                        style={{
                            display: 'block',
                            textAlign: 'left',
                            margin: '0 20px 20px',
                        }}
                    >
                        <div>
                            <span
                                style={{
                                    marginRight: '15px',
                                }}
                            >
                                {`Contests without recent action within the past`}
                            </span>
                            <Dropdown
                                value={props.lastActivityTime}
                                inline
                                options={timeOptions}
                                onChange={(e, data) =>
                                    props.setLastActivityTime(data.value)
                                }
                            />
                            <span
                                style={{
                                    marginLeft: '5px',
                                }}
                            >
                                {`days`}
                            </span>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                        }}
                    >
                        <div
                            style={{
                                flex: 6,
                                paddingLeft: '10px',
                                paddingRight: '10px',
                            }}
                        >
                            <ScheduleGrid
                                contests={data.contests}
                                history={props.history}
                                user={props.user}
                                orgs={props.orgs}
                                permissions={props.permissions}
                                lastActivity={true}
                                emptyMessage={`No inactive contracts in this time frame`}
                                onShowModal={(modal, contests) =>
                                    props.onShowModal(modal, contests, refetch)
                                }
                            />
                        </div>
                    </div>
                </div>
            );
        }}
    </Query>
);

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editContestModalVisible: false,
            contractStatusModalVisible: false,
            viewSignedModalVisible: false,
            editingContest: {},
            submitContestModalVisible: false,
            dateFilterStart: new Date(),
            dateFilterEnd: new Date(
                new Date().setDate(new Date().getDate() + 30)
            ),
            lastActivityTime: 15,
        };
    }

    onShowModal = (modal, contest, contestsRefetch) => {
        this.setState({
            [modal]: true,
            editingContest: contest,
            contestsRefetch,
        });
    };

    render() {
        if (
            this.props.location.hash &&
            this.props.location.hash.indexOf('#') >= 0
        ) {
            let redirectUrl = this.props.location.hash.split('#')[1];
            return <Redirect to={redirectUrl} />;
        }
        if (this.props.user === null) {
            return <Redirect to={'/register'} />;
        }
        let sectionStyle = {
            padding: '15px 0',
        };
        let { permissions } = this.props;
        return (
            <div
                style={{
                    padding: '15px 3%',
                }}
            >
                <div
                    style={{
                        padding: '15px 0',
                    }}
                >
                    <Header as="h1">Dashboard</Header>
                </div>
                <div style={sectionStyle}>
                    <AwaitingSignature
                        {...this.props}
                        onShowModal={this.onShowModal}
                    />
                </div>
                <div style={sectionStyle}>
                    <UpcomingIncomplete
                        {...this.props}
                        dateFilterEnd={this.state.dateFilterEnd}
                        dateFilterStart={this.state.dateFilterStart}
                        upcomingIncompleteDays={
                            this.state.upcomingIncompleteDays
                        }
                        onShowModal={this.onShowModal}
                        onSetFilter={(update) => this.setState(update)}
                    />
                </div>
                <div style={sectionStyle}>
                    <InactiveContracts
                        {...this.props}
                        onShowModal={this.onShowModal}
                        setLastActivityTime={(lastActivityTime) =>
                            this.setState({ lastActivityTime })
                        }
                        lastActivityTime={this.state.lastActivityTime}
                    />
                </div>
                <div>
                    <EditContestModal
                        contest={this.state.editingContest}
                        contestsRefetch={this.state.contestsRefetch}
                        open={this.state.editContestModalVisible}
                        onClose={() =>
                            this.setState({
                                editContestModalVisible: false,
                                editingContest: {},
                            })
                        }
                        header={`Edit Contest`}
                        user={this.props.user}
                        orgs={this.props.orgs}
                        permissions={permissions}
                    />
                    <ContestStatusModalContainer
                        contest={this.state.editingContest}
                        contestsRefetch={this.state.contestsRefetch}
                        open={this.state.contractStatusModalVisible}
                        onClose={() =>
                            this.setState({
                                contractStatusModalVisible: false,
                                editingContest: {},
                            })
                        }
                        header={`Edit Contest`}
                        user={this.props.user}
                        orgs={this.props.orgs}
                        permissions={permissions}
                    />

                    <SubmitContestModalContainer
                        contest={this.state.editingContest}
                        contestsRefetch={() => this.state.contestsRefetch}
                        open={this.state.submitContestModalVisible}
                        onClose={() =>
                            this.setState({
                                submitContestModalVisible: false,
                                editingContest: {},
                            })
                        }
                        header={`Submit Contest`}
                        orgs={this.props.orgs}
                        user={this.props.user}
                    />
                    <ViewSignedModal
                        contest={this.state.editingContest}
                        open={this.state.viewSignedModalVisible}
                        onClose={() =>
                            this.setState({
                                viewSignedModalVisible: false,
                                editingContest: {},
                            })
                        }
                    />
                </div>
            </div>
        );
    }
}

export default Dashboard;
