import React, {useEffect, useState} from 'react';

const getSize = () => ({
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
})

const useWindowSize = () => {
    let [windowSize, setWindowSize] = useState(getSize());

    let handleResize = () => {
        setWindowSize(getSize());
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return windowSize;
}


export let ContractView = props => {
    let {innerWidth} = useWindowSize();
    let [contractDivHeight, setContractDivHeight] = useState(0);
    useEffect(() => {
        let divHeight = document.getElementById('contractDiv').clientHeight;
        if (!contractDivHeight || contractDivHeight !== divHeight) {
            divHeight === 1100 ?
                setContractDivHeight(divHeight) :
                setContractDivHeight(divHeight + 20)
        }
    }, [])
    let {src, html, fullSrc, baseWidth = 800, scaled = 1, onContractDivHeight=() =>{}} = props;
    let viewWidth = (innerWidth > baseWidth) ? baseWidth : innerWidth;
    const scaleRatio = scaled ? scaled : viewWidth / baseWidth;
    const scaledHeight = scaleRatio * (innerWidth < 800 ? contractDivHeight : contractDivHeight || 1100);  
    let marginTop = scaleRatio === 1 ? 0 : `${(scaledHeight-contractDivHeight)/2}px`

    let scalingStyles = {
        width: '800px',
        textAlign: 'left',
        marginLeft: `${(viewWidth-baseWidth)/2}px`,
        marginTop,
        transform: `scale(${scaleRatio})`
    }
    if (contractDivHeight) {
        scalingStyles.height = `${contractDivHeight}px`
    }

    useEffect(() => {
        onContractDivHeight(contractDivHeight)
    }, [contractDivHeight])
    return (
        <div
            style={{
                height: `${scaledHeight}px`,
                width: `${viewWidth}px`
            }}
        >
            {fullSrc || src ? (
                <div
                    id='contractDiv'
                    style={{
                        ...scalingStyles
                    }}
                >
                    <img src={fullSrc || `https://s3-us-west-2.amazonaws.com/contest-pdfs/${src}.png`} style={{border: '1px solid grey', boxShadow: '5px 5px 8px #aaa'}}/>
                </div>
            ) : (
                <div 
                    id='contractDiv'
                    dangerouslySetInnerHTML={{__html: html}}
                    style={{
                        display: 'flex',
                        border: '1px solid grey',
                        boxShadow: '5px 5px 8px #aaa',
                        ...scalingStyles
                    }}>
                </div>
            )}
        </div>
    )
}
