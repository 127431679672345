import React, { Component, useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import { Button, Checkbox, Header } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { BaseControlledModal, ModalAction } from '../modals/modals';
import { FormRowInput } from '../utilityComponents/form/formRow';
import Spinner from '../utilityComponents/spinner';

import { orgQuery, orgUpdateMutation } from '../../gql/org';

import { AttributeRow } from './accountUtils';
import { colors } from '../../utils/colors';
import { sports, sportsAlphabetized } from '../../utils/sports';
import { useQuery } from 'react-apollo-hooks';
import { hasCustomTemplateQuery } from '../../gql/template';
import { InfoModalVideo } from '../utilityComponents/InfoModalVideo';

const fields = [
    {
        key: 'name',
        label: 'Name',
    },
    {
        key: 'conference',
        label: 'Conference',
    },
    {
        key: 'division',
        label: 'Division',
    },
    {
        key: 'addr',
        label: 'Address',
    },
];

class UpdateOrg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            org: Object.assign(
                {
                    email: '',
                    firstname: '',
                    lastname: '',
                    title: '',
                    phone_work: '',
                },
                { ...props.org }
            ),
        };
    }

    updateOrgState = (update) => {
        let org = { ...this.state.org };
        Object.assign(org, update);
        this.setState({
            org,
        });
    };

    notify = (success) => {
        if (success) {
            toast.success(
                "Your organization's profile was updated successully",
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 4000,
                    hideProgressBar: true,
                }
            );
        }
    };

    render() {
        let org = { ...this.state.org };
        return (
            <Mutation mutation={orgUpdateMutation}>
                {(orgUpdate, { data, loading, error }) => (
                    <BaseControlledModal
                        header={`Edit My Organization — ${org.name}`}
                        size={'small'}
                        trigger={
                            <Button
                                basic
                                onClick={() => this.setState({ open: true })}
                            >
                                Edit Organization
                            </Button>
                        }
                        open={this.state.open}
                        onClose={() => this.setState({ open: false })}
                        actions={[
                            <ModalAction
                                primary
                                title={'Update Organization'}
                                key={0}
                                onClick={() => {
                                    let { org } = this.state;
                                    orgUpdate({
                                        variables: Object.assign(
                                            {},
                                            { ...org },
                                            {
                                                id: this.props.org.id,
                                            }
                                        ),
                                        refetchQueries: [
                                            {
                                                query: orgQuery,
                                                variables: {
                                                    id: this.props.org.id,
                                                },
                                            },
                                        ],
                                    }).then(
                                        (res) => {
                                            this.notify(true);
                                            this.setState({ open: false });
                                        },
                                        (err) => console.log('err', err)
                                    );
                                }}
                            />,
                        ]}
                    >
                        {fields.slice(1).map((field, index) => {
                            return (
                                <FormRowInput
                                    key={index}
                                    label={field.label}
                                    field={field.key}
                                    value={org[field.key]}
                                    type="text"
                                    formUpdate={this.updateOrgState}
                                />
                            );
                        })}
                    </BaseControlledModal>
                )}
            </Mutation>
        );
    }
}

export const SportToggle = (props) => {
    let { checked, onChange, sport } = props;
    let sportLabel = sport === 'Misc' ? 'Misc' : sportsAlphabetized[sport];
    return (
        <div
            style={{
                marginBottom: '15px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
            }}
        >
            <div
                style={{
                    width: '100px',
                    textAlign: 'center',
                }}
            >
                <Checkbox toggle checked={checked} onClick={onChange} />
            </div>
            <div>{sportLabel}</div>
        </div>
    );
};

const MyOrg = (props) => {
    const [editOrgSportsModal, setEditOrgSportsModal] = useState(false);
    const hasTemplateGQL = useQuery(hasCustomTemplateQuery, {
        variables: {
            orgid: props.user.orgid,
        },
    });

    return (
        <Query query={orgQuery} variables={{ id: props.user.orgid }}>
            {({ loading, error, data, refetch }) => {
                if (loading) {
                    return <Spinner />;
                }
                if (error) {
                    return <div>Something went wrong</div>;
                }
                let org = data.org;
                let {
                    sports: unparsedSports = '[]',
                    show_usage_agreement,
                    usage_agreement_signature,
                } = org;
                let parsedSports = JSON.parse(unparsedSports) || [];
                return (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '15px 0',
                                borderBottom: `1px solid ${colors.greyBorder}`,
                            }}
                        >
                            <div>
                                <Header as="h2">My Organization</Header>
                            </div>
                            {props.user.is_admin && (
                                <div>
                                    <UpdateOrg {...props} org={org} />
                                </div>
                            )}
                        </div>
                        <div
                            style={{
                                marginTop: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                }}
                            >
                                {fields.map((field, index) => (
                                    <AttributeRow
                                        key={index}
                                        label={field.label}
                                        value={org[field.key]}
                                    />
                                ))}
                            </div>
                            {show_usage_agreement && usage_agreement_signature && (
                                <div
                                    style={{
                                        marginTop: '5px',
                                        textAlign: 'left',
                                    }}
                                >
                                    <a
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            props.history.push(
                                                'usage-agreement'
                                            )
                                        }
                                    >
                                        View usage agreement
                                    </a>
                                </div>
                            )}

                            <div
                                style={{
                                    margin: '15px 0 0',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                }}
                            >
                                {props.user.is_admin && (
                                    <Button
                                        basic
                                        primary
                                        onClick={() => {
                                            setEditOrgSportsModal(true);
                                        }}
                                    >
                                        Edit My Organization's Sports
                                    </Button>
                                )}

                                <Button
                                    basic
                                    primary
                                    onClick={() => {
                                        props.history.push('/org-defaults');
                                    }}
                                >
                                    Edit Contract Defaults
                                </Button>
                            </div>
                            <div
                                style={{
                                    marginTop: '.25em',
                                }}
                            >
                                {/* {props.user.is_admin && hasTemplateGQL.data && !hasTemplateGQL.loading && hasTemplateGQL.data.hasCustomTemplate && (hasTemplateGQL.data.hasCustomTemplate.self || !hasTemplateGQL.data.hasCustomTemplate.managed) && ( */}
                                <Button
                                    basic
                                    primary
                                    onClick={() => {
                                        props.history.push('/account/template');
                                    }}
                                >
                                    Edit My Contract Template
                                </Button>
                                {/* )} */}
                            </div>
                        </div>
                        <BaseControlledModal
                            header={
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {`My Organization's Sports`}
                                    <InfoModalVideo
                                        {...{
                                            videoId: 'cuUr6lOMbjk',
                                            header: "Set your organization's sports",
                                        }}
                                    />
                                </div>
                            }
                            size="tiny"
                            open={editOrgSportsModal}
                            onClose={() => {
                                setEditOrgSportsModal(false);
                            }}
                        >
                            <div
                                style={{
                                    marginBottom: '15px',
                                }}
                            >
                                Please select your organization's sports. Sports
                                that are selected here will be available for
                                your organization's use on our platform.
                            </div>

                            <Mutation mutation={orgUpdateMutation}>
                                {(updateOrg, _) =>
                                    Object.keys(sportsAlphabetized)
                                        .sort((a, b) => {
                                            return sportsAlphabetized[a] <
                                                sportsAlphabetized[b]
                                                ? -1
                                                : 1;
                                        })
                                        .map((sport, index) => {
                                            let checked =
                                                parsedSports.includes(sport);
                                            return (
                                                <SportToggle
                                                    key={index}
                                                    sport={sport}
                                                    checked={checked}
                                                    onChange={() => {
                                                        let newSports = [
                                                            ...parsedSports,
                                                        ];
                                                        if (checked) {
                                                            let sportIndex =
                                                                parsedSports.indexOf(
                                                                    sport
                                                                );
                                                            newSports.splice(
                                                                sportIndex,
                                                                1
                                                            );
                                                        } else {
                                                            newSports.push(
                                                                sport
                                                            );
                                                        }
                                                        updateOrg({
                                                            variables: {
                                                                id: org.id,
                                                                sports: JSON.stringify(
                                                                    newSports
                                                                ),
                                                            },
                                                        }).then(() => {
                                                            refetch();
                                                        });
                                                    }}
                                                />
                                            );
                                        })
                                }
                            </Mutation>
                        </BaseControlledModal>
                    </div>
                );
            }}
        </Query>
    );
};

export default MyOrg;
