import React from 'react';
import {
    Dropdown,
    Form,
    Input,
} from 'semantic-ui-react';
import InfoPopup from '../InfoPopup';


export let FormRowInput = props => { 
    return (
        <FormRow
            {...props}
        >
            <Form.Input
                fluid
                id={props.id}
                type={props.type} 
                disabled={props.disabled}
                placeholder={props.placeholder}
                value={props.value || ''}
                onChange={(e, data) => {
                    props.formUpdate({
                        [props.field]: data.value
                    })
                }}
                onBlur={props.onBlur}
                error={props.error}
            />
        </FormRow>
) 
}

export let FormRowTextArea = props => (
    <FormRow
        {...props}
    >
        <Form.TextArea
            placeholder={props.placeholder}
            required={props.required}
            value={props.value || ''}
            onChange={(event, {name, value}) => props.formUpdate({[props.field]: value})}
            error={props.error}
        />
    </FormRow>
)

export let FormRowDropdown = props => (
    <FormRow
        {...props}
    >
        <Dropdown 
            fluid
            scrolling
            upward 
            selection
            type="text" 
            options={props.options}
            value={props.value || ''} 
            placeholder={props.placeholder}
            onChange={(event, {name, value}) => props.formUpdate({[props.field]: value})} 
        />
    </FormRow>
)

export let FormRow = props => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: props.reversed ? 'row-reverse' : 'row',
                alignItems: 'center',
                margin: '5px 0',
                ...props.style
            }}
        >
            <div
                style={{
                    flex: props.reversed ? 3 : 1,
                    textAlign: props.reversed ? 'left' : 'right',
                    margin: '0 7px',
                    flexDirection: 'row',
                    display: 'flex'
                }}
            >
                <p>{props.label}{props.required && <span style={{color: '#9f3a38'}}>*</span>}</p>
            </div>
            <div
                style={{
                    flex: props.reversed ? 1 : 3,
                    textAlign: props.reversed ? 'right' : 'left',
                    margin: '0 7px',
                    display: props.info ? 'flex' : 'block',
                    justifyContent: props.reversed ? 'flex-end' : 'flex-start'
                }}
            >
                {props.info && (
                    <InfoPopup>
                        {props.info}
                    </InfoPopup>
                )}
                {props.children}
            </div>
        </div>
    )
}
