import gql from 'graphql-tag';

export const sportsDefaultQuery = gql`
    query sportsDefault (
        $orgid: ID
    ) {
        sportsDefault (
            orgid: $orgid
        ) {
            orgid
            properties
        }
    }
`;

export const sportsDefaultUpdateMutation = gql`
    mutation sportsDefaultUpdate (
        $orgid: ID
        $properties: String
    ) {
        sportsDefaultUpdate (
            orgid: $orgid
            properties: $properties
        ) {
            orgid
        }
    }
`;
