import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { MainRoutes } from './routes';
import { ApolloProvider } from 'react-apollo';
import {ApolloProvider as ApolloHooksProvider} from 'react-apollo-hooks';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { client } from './apollo';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// minified version is also included
import 'react-toastify/dist/ReactToastify.min.css';
import 'semantic-ui-css/semantic.min.css'

ReactDOM.render(
    (
        <ApolloProvider client={client}>
            <ApolloHooksProvider client={client}>
                <div>
                    <ToastContainer 
                        limit={1}
                    />
                    <MuiThemeProvider>
                        <MainRoutes />
                    </MuiThemeProvider>
                </div>
            </ApolloHooksProvider>
        </ApolloProvider>
    ), document.getElementById('root'));
