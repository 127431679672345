import React, {Component} from 'react';


export default class RegisterContainer extends Component {
    render() {
        return (
            <div>
                Welcome to Perfect Record
            </div>
        )
    }
}